/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Card from './card';
import Card_m from './card_mobile'
import useWindowSize from '../../hooks/useWindowSize';

import API from '../../redux/API';
import { IMAGE_BASE } from '../../config/constants';
import { toast } from 'react-toast';
import { useLocation } from 'react-router-dom';

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {width} = useWindowSize();

    const [researcherINFO, setResearcherINFO] = useState({})

    const [orderID, setOrderID]=useState(null)
    
    useEffect(()=>{

        
        const path = location.pathname;
        const researcherID = path.split('/')[3];
        const order = path.split('/')[4];
        setOrderID(order)

        if(researcherID && order){

            
            API.post(`order/researcherinfofeedback`, {researcherID:researcherID,orderID:order})
            .then((result) => {
                if(result.data.status === 'success'){
                    //toast.success('sucesso')
                    if(result.data.researcherINFO){
                        setResearcherINFO(result.data.researcherINFO)
                    }
                    else{
                        navigate('/myorders')
                        toast.error('Erro ao tentar qualificar pesquisador')
                    }
                }
                else if(result.data.status === 'duplicated'){
                    toast.error(result.data.message?result.data.message:'Você já avaliou este pesuisador para esta ordem')
                    navigate('/myorders')
                }
            })
            .catch((err) => {
                toast.error(err)
                console.log(err);
                navigate('/home')
                window.location.reload()
            });
        }
        else{
            toast.error('Erro com a URL informada')
            navigate('/myorders')
        }


    },[])











    const style= {
        subtitle: "font-[Poppins] mb:text-[32px] md:text-[32px] text-[#313131] font-normal leading-[148%] mb:mb-[17px] md:mb-[65px] text-center",
        noaccountTxt: "font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%]",
        registerTxt: "cursor-pointer font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%] underline",
    }

    return (
        <div className="w-full flex justify-center items-center mt-[80px] mb-[80px]">
            <div className="w-[80%] flex flex-col items-center">

                <div className={style.subtitle}>Avalie o <br className="md:hidden mb:block"/>Pesquisador</div>
                <div className='w-full flex mb:flex-col lg:flex-row items-center justify-center'>
                    <div className='md:w-[615px] mb:w-[320px]'>
                        {width<900?
                            <Card_m
                            researcherINFO={researcherINFO}
                            orderID={orderID}
                            />
                            :
                            <Card
                            researcherINFO={researcherINFO}
                            orderID={orderID}
                            />
                        }
                    </div>
                       
                </div>
            </div>
        </div>
    )
}