/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useLayoutEffect, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Select, MenuItem } from '@mui/material';
import ReactFlagsSelect from "react-flags-select";
import GeoLocation from './Geolocation';
import { toast } from 'react-toast';
import {
    Box,
    IconButton,
    Avatar
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
import { setUser } from '../../redux/actions/auth.action';
import { useDispatch } from 'react-redux';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default(props) => {

    const { currentUser } = useSelector(state => state.auth);
    const [country, setCountry] = useState('BR');
    const [currentcountry, setcurrentcountry] = useState({});
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null)
    const [isValidDate, setIsValidDate] = useState(true)
    const [complement, setComplement] = useState('');
    const [number, setNumber] = useState(null);
    const [address, setAddress] = useState(null);
    const [cep, setCep] = useState('');
    
    const [stateID, setStateID] = useState(null);
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        if(currentUser){
            // setCountry(currentUser.id_country === undefined ? 'BR' : currentUser.id_country);
            setCep(currentUser.cd_cep === undefined ? '' : currentUser.cd_cep);
            setAddress(currentUser.nm_address === undefined ? '' : currentUser.nm_address);
            setNumber(currentUser.nm_number === undefined ? '' : currentUser.nm_number);
            setComplement(currentUser.nm_complement === undefined ? '' : currentUser.nm_complement);
            setState(currentUser.nm_state === undefined ? null: currentUser.nm_state);
            setStateID(currentUser.nm_state === undefined ? null: currentUser.nm_state);
            setCity(currentUser.nm_city === undefined ? null : currentUser.nm_city)
        }
    }, [currentUser]);

    const [checkChanged, setCheckChanged] = useState(false);
    useEffect(()=>{
        if(currentcountry.cd_isoalpha2 !== country) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(cep !== currentUser.cd_cep) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(address !== currentUser.nm_address) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(number !== currentUser.nm_number) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(complement !== currentUser.nm_complement) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(stateID !== currentUser.nm_state) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(city !== currentUser.nm_city) {
            setCheckChanged(true);
            return;
        }        
        else{
            setCheckChanged(false);
        }


    }, [country,currentcountry, cep, address, number, complement, stateID, city])
    const { currentProfile } = useSelector(state => state.auth);
    const data ={
        id_country: country,
        cd_cep: cep,
        nm_address: address,
        nm_number: number,
        nm_complement: complement,
        nm_state: stateID+"",
        nm_city: city+"",
        ds_email: currentUser.ds_email,
    }
    const setPersonalAddress = async () => {
        API.post("user/setaddress", data)
        .then((result) => {
            if (result.data.status === "success") {
                dispatch(setUser(result.data.user))
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    

    const saveAddress = async () => {
        const cepUrl = `https://viacep.com.br/ws/${cep}/json/`;
        fetch(cepUrl, {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                
                if (data.hasOwnProperty("erro")) {
                    setWrongCep(true)
                    setAddress(null);
                    setStateID(null);
                    setCity(null);
                } else {
                    setAddress(data.logradouro);
                    setStateID(data.uf);
                    setCity(data.localidade);
                    setWrongCep(false)
                    
                }
            })
            .catch(err => {console.log(err); setWrongCep(true)});
       
    }
    const handleSave = () =>{
        saveAddress();
        saveAddress();
    }
    const [wrongCep, setWrongCep] = useState(false);

    useEffect(()=>{
        if(cep.length === 9){
            saveAddress()
        }
    },[cep])




    const onChangeCep = (e) => {
        var currentValue = e.target.value;
        if(currentValue.length>cep.length){
            const digitsOnly = currentValue.replace(/\D/g, '');
            if (currentValue.length===5) {
                const firstPart = digitsOnly.slice(0, 5);
                const secondPart = digitsOnly.slice(5, 8);
                currentValue = `${firstPart}-${secondPart}`;
            }
        }
        setCep(currentValue)
    }





    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    useEffect(() => {
        if(props.submitEvent === true){
            props.submitStateChange();
        }
        
    }, [props.submitEvent])


    useEffect(()=>{
        if(currentUser){
            API.post("user/checkcountrycode", {countryID:currentUser.id_country})
            .then((result) => {
                if (result.data.status === "success") {
                    setcurrentcountry(result.data.countrycode)
                    setCountry(result.data.countrycode.cd_isoalpha2)
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },[currentUser])

    const [allcountries,setallcountries]=useState([])

    const getallcountries =()=>{
        API.post('/order/getallcountries')
        .then((res)=>{
            if(res.data.allcountries){
                var aux = []
                res.data.allcountries.map((single)=>{
                    const cd_isoalpha2 = single.cd_isoalpha2
                    aux.push({cd_isoalpha2:cd_isoalpha2})
                })
            }
            setallcountries(res.data.allcountries)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        getallcountries()
    },[])

    const getCountryNamePt = (isoAlpha2) => {
        const country = allcountries.find((c) => c.cd_isoalpha2 === isoAlpha2);
        return country ? country.nm_country_pt : '';
    };    

    const customLabels = allcountries.reduce((obj, country) => {
        obj[country.cd_isoalpha2] = country.nm_country_pt;
        return obj;
      }, {});    

    return (
        <>
        <div className="mb:w-full sm:w-[95%]">
            <div className='w-full flex mb-[25px]'>
                <div className='w-full'>
                    <div className={style.title + " font-normal"}>Esse será o endereço de entrega dos documentos solicitados, revise se os dados estão corretos.</div>                
                </div>
            </div>
            <div className='w-full sm:flex mb-[25px] mb:block'>
                <div className='mb:w-full sm:w-[42%] mr-[25px]'>
                    <div className={style.title}>País origem</div>
                    <div className='w-full'>
                        <ReactFlagsSelect
                            className='w-full'
                            selected={country}
                            onSelect={(code) => {setCountry(code)}}
                            searchable="true"
                            searchPlaceholder="Pesquisar"
                            customLabels={customLabels}
                        />
                    </div>
                </div>
                <div className='mb:w-[50%] sm:w-[20%] mb:mt-[20px] sm:mt-[0px]'>
                    <div className={style.title}>CEP</div>
                    <div className='w-full'>
                    <TextField
                            placeholder='_____-___'
                            value={cep}
                            className="w-full"
                            onChange={onChangeCep}
                            InputProps={{
                                className: style.content,
                            }}
                            error={wrongCep}
                            helperText={wrongCep ? "Número do CEP errado!"  : ''}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>

                    </div>
                </div>
            </div>
            <div className='w-full sm:flex mb:block mb-[25px]'>
                <div className='mb:w-full sm:w-[42%] mr-[25px]'>
                    <div className={style.title}>Endereço</div>
                    <div>
                        <TextField
                            placeholder=''
                            value={address}
                            onChange={(e)=>setAddress(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className='mb:w-[50%] sm:w-[20%] mr-[25px] mb:mt-[20px] sm:mt-[0px]'>
                    <div className={style.title}>Número</div>
                    <div>
                        <TextField
                            placeholder=''
                            value={number}
                            onChange={(e)=>setNumber(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>

                    </div>
                </div>
                <div className='mb:w-[50%] sm:w-[20%]  mb:mt-[20px] sm:mt-[0px]'>
                    <div className={style.title}>Complemento</div>
                    <div>
                        <TextField
                            value={complement}
                            onChange={(e)=>setComplement(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>
                    </div>
                </div>
            </div>
            <div className='w-full sm:flex mb:block mb-[25px]'>
                <div className='mb:w-full sm:w-[42%] mr-[25px]'>
                    <div className={style.title}>Estado</div>
                    <div>
                        {
                            country === 'BR'?
                            <TextField
                            value={stateID}
                            onChange={(e)=>setStateID(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>:
                            <GeoLocation
                            locationTitle="State"
                            onChange={setStateID}
                            value={state}
                            geoId={country}
                            country={country}
                        />
                        }

                    </div>
                </div>
                <div className='mb:w-full sm:w-[calc(40%+25px)] mr-[25px] mb:mt-[20px] sm:mt-[0px]'>
                    <div className={style.title}>Cidade</div>
                    <div>                        
                        {
                            country === 'BR'?
                            <TextField
                            value={city}
                            onChange={(e)=>setCity(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>:
                            <GeoLocation
                                locationTitle="State"
                                onChange={setCity}
                                value={city}    
                                geoId={stateID}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="mb:mt-[0px] xl:mt-[60px] mb:text-center xl:float-right mb:mr-[0px] xl:mr-[-30px] mb-[16px] ">
            <HeaderBTNStyle disabled={!checkChanged} onClick={setPersonalAddress} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Salvar</HeaderBTNStyle>
        </div>
        </>
    )
}