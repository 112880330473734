import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
import {
    Grid,
    Box,
    Stack,
    TextareaAutosize,
    Divider,
} from '@mui/material';

import { setUser } from '../../redux/actions/auth.action';
import { useDispatch } from 'react-redux'
import { setMyLanguage } from '../../redux/actions/profile.action';
import { ConstructionOutlined } from '@mui/icons-material';
import userEvent from '@testing-library/user-event';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px', zIndex:9999 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        zIndex:9999 
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));



const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 35px 0px',
    [theme.breakpoints.up('sm')]: {
        padding: '70px 0px 95px 0px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 105px 3px'
    },
}));

export default function Modal({ openModal, setOpenModal, curLang, curLevel, languageList, setLanguageList, levellist, setLevellist, changed, setChanged }) {
    const navigate = useNavigate();

    const [page, setPage] = React.useState(1);
    const [language, setLanguage] = useState(0);
    const [level, setLevel] = useState(0);
    const { width } = useWindowSize()
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpenModal(false);
    };
    useLayoutEffect(() => {
        if(languageList.length === 0)
        {
            API.get(`user/getlanguagelist?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.languagelist;
                temp.sort((a, b) => a.nm_language.localeCompare(b.nm_language))
                setLanguageList(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
        if(levellist.length === 0)
        {
            API.get(`user/getlanguageproficiency?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.proficiencylist;
                setLevellist(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    },[])
    const {currentUser} = useSelector(state => state.auth);
    const handleConfirm = () => {
        try {
            var temp = [...currentUser?.about_me?.languages];
            if(temp === undefined) temp = []
            if(curLang && curLevel)
            {
                let index = -1;
                temp.map((item, i) => {
                    if(item.id_language == curLang && item.id_languageproficiency == curLevel) index = i
                })
                temp[index].id_language = language;
                temp[index].id_languageproficiency = level;
            }
            else{
                let index = -1;
                temp.map((item, i) => {
                    if(item.id_language == language) index = i
                })
                if(index == -1){
                    temp.push({id_language:language, id_languageproficiency:level});
                }
                else{
                    temp[index].id_language = language;
                    temp[index].id_languageproficiency = level;
                }
            }

        } catch (error) {
            console.log(error)
        }

        setOpenModal(false)
        if(JSON.stringify(temp) !== JSON.stringify(currentUser.about_me.languages)) {
            setChanged(true);
        }
        else{
            setChanged(false);
        }
        
        dispatch(setUser({...currentUser, about_me:{...currentUser.about_me, languages:temp}}))
 //       dispatch(setMyLanguage(temp));
    };
    // useEffect(() => {
    //     setPage(1);
    //     setLanguage('-- Selecione --');
    //     setLevel('-- Selecione --');
    // }, [openModal]);
    useEffect(() => {
        if(curLang) {
            setLanguage(curLang);
        }
        else{
            setLanguage(0);
        }
        if(curLevel) setLevel(curLevel)
        else  setLevel(0);
      }, [openModal, curLang, curLevel]);
    

      const sortByCdOrder = (array) =>{
        array.sort((a, b) => a.cd_order - b.cd_order);
        return array;
      }
      
    return (
        <div style={{maxHeight:'50%', maxWidth:'50%'}}>
           
            {page > 0 && page < 4 &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openModal} sx={{
                        '.MuiPaper-root': {
                            maxWidth: '837px',
                            width: '100%',
                        }
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    </BootstrapDialogTitle>
                    <DialogContent>
                        <GridWrapper className={page == 1 ? '!pb-[70px]' :'!pb-[100px]'}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>

                                    <HeaderText>Adicionar Idioma</HeaderText>
                                    <Divider sx={{ marginTop: '15px', marginBottom: '15px', width: '100%'}}/>
                                    <div className="mb:w-full md:w-[60%]">
                                            <div
                                                className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[33px] mb-[3px]">Idioma &nbsp;
                                                <span className='text-[#DE2B2B]'>*</span>
                                            </div>
                                            <div >
                                                <Select
                                                    value={language}
                                                    onChange={(e) => setLanguage(e.target.value)}
                                                    displayEmpty="displayEmpty"
                                                    className="w-[100%] mb:h-[50px] md:h-[34px]"
                                                    inputProps={{
                                                        'aria-label' : 'Without label'
                                                    }}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                                            },
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal'
                                                            },
                                                            maxHeight:'50%',

                                                        }
                                                    }}>
                                                        <MenuItem value="0">
                                                        <p
                                                            className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">-- Selecione --</p>
                                                        </MenuItem>
                                                    {
                                                        //dynamic

                                                        languageList.map((item,index) => {
                                                            return (
                                                                <MenuItem key={index} value={item._id}>
                                                                    <p
                                                                        className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">{item.nm_language}</p>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                    </div>
                                    <div className="mb:w-full md:w-[60%]">
                                            <div
                                                className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[33px] mb-[3px]">Proficiência&nbsp;
                                                <span className='text-[#DE2B2B]'>*</span>
                                            </div>
                                            <div>
                                                <Select
                                                    value={level}
                                                    onChange={(e) => setLevel(e.target.value)}
                                                    displayEmpty="displayEmpty"
                                                    className="w-[100%] mb:h-[50px] md:h-[34px]"
                                                    inputProps={{
                                                        'aria-label' : 'Without label'
                                                    }}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                                            },
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal'
                                                            }
                                                        }
                                                    }}>
                                                        <MenuItem value="0">
                                                        <p
                                                            className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">-- Selecione --</p>
                                                        </MenuItem>
                                                    {

                                                       
                                                        
                                                        sortByCdOrder(levellist).map((item,index) => {
                                                            return (
                                                                <MenuItem key={index} value={item._id}>
                                                                    <p
                                                                        className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">{item.nm_proficiency}</p>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                    </div>
                                </Grid>

                            </Grid>
                        </GridWrapper>
                    </DialogContent>
                    <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={width>640?'end':'center'} marginRight={width>640?'53px':'0px'} paddingTop={'20px'} className="items-center" >
                        <HeaderBTNStyle onClick={handleConfirm} disabled={language=='0' || level == '0'} sx={{'&:disabled': {backgroundColor: '#C6C6C6',cursor: 'not-allowed'}}}>Salvar</HeaderBTNStyle>
                    </Stack>
                    <DialogActions></DialogActions>
                    <DialogActions></DialogActions>
                </BootstrapDialog>
            }
        </div >
    );
}
