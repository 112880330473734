import { styled } from '@mui/material/styles';
import {
    Stack,
} from '@mui/material';

// components
import RatingStar from '../../RatingStar'

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    padding: '10px 0px 5px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
}));

const NumberText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '10px',
    color: '#313131',
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
    textAlign: 'justify',
    paddingTop: '10px'
}));

const TimeText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 275,
    fontSize: '10px',
    color: '#313131',
    lineHeight: '200%'
}));

// {
//     ratingNum: 4.6,
//     title: 'Pesquisador muito bom',
//     time: '22/03/22 - 23h 05',
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
// }

export default function ReviewCard({ reviewData }) {

    return (
        <RootStyle>
            <Stack flexDirection={'row'} alignItems={'center'}>
                <RatingStar value={reviewData.ratingNum} size={12} emptyIconType={2} />
                <NumberText>&nbsp;&nbsp;&nbsp;{reviewData.ratingNum}/5</NumberText>
                <TitleText>&nbsp;&nbsp;&nbsp;{reviewData.title}</TitleText>
            </Stack>
            <TimeText>{reviewData.time}</TimeText>
            <DescriptionText>{reviewData.description}</DescriptionText>
        </RootStyle>
    )
}