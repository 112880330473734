/* eslint-disable import/no-anonymous-default-export */
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import useWindowSize from '../../hooks/useWindowSize';
import { useEffect, useState } from 'react';
import Proposal from './proposal'
import Proposal_m from './proposal_mobile'
import { useNavigate } from 'react-router-dom';
import API from '../../redux/API';
import { API_BASE } from '../../config/constants';
import { IMAGE_BASE } from '../../config/constants';

import imagetocard from '../../assets/image/publications_image.png'

export default (props) => {
    const navigate = useNavigate();
    const {width} = useWindowSize();
    const [sortType, setSortType] = useState(0);
    const sortTypeList = [
        'Mais recentes',
        'Mais antigos',
        'Preço (Menor para Maior)',
        'Preço (Maior para Menor)', 
        'Ordem alfabética (A-Z)',
        'Ordem alfabética (Z-A)'
    ]
    
    const bids = props.bids
    
    const [proposalList, setProposalList] = useState([])
    const [thePrice, setPrice] = useState(0.0)    
    const [istimewithout, setistimewithout] = useState(false)
    const [timewithout, settimewithout] = useState(0)
    const anyAcceptedBID = props.anyAcceptedBID
    const acceptedBID_ID = props.acceptedBID.id_researcher
    const currentUser = props.currentUser
    const cancel = props.cancel

    useEffect(()=>{ 

        var auxToShowBids = []
        var auxPrice = 0

        // create a new Date object representing the current date

        var currentDate = null
        var dateToCheck = null
        var diffInMs = null
        var diffInDays = null

        bids.map((element, index) => {

            //console.log(element);
        
            auxToShowBids.push(
                {  
                    avatar:`${IMAGE_BASE}accounts/${element.id_researcher}/${element.researcher_avatar}`,
                    name: element.researcher_name,  // name:'G.M',
                    rating:element.researcher_rating,
                    countryknowledge: element.researcher_countryknowledge,
                    description: element.ds_bidcomments[0].ds_comment, //use the first comment
                    level: element.researcher_level,
                    price: element.vl_researcherbid,
                    price_negotiation: element.negotiation.vl_negotiation,
                    deadline: element.nr_deadline,
                    negotiationRefuse: element.negotiation.ic_negotiationdenied,
                    ic_bidrejected: element.ic_bidrejected,
                    negotiationAccepted: element.ic_bidaccepted ? element.ic_bidaccepted : element.ic_negotiationaccepted,
                    researcherID: element.id_researcher,
                    orderID: element.orderID,
                    existNegociation: (element.ic_bidnegotiated && !element.negotiation.ic_negotiationdenied && !element.ic_bidcanceled && !element.dt_bidcanceled)? element.ic_bidnegotiated : false,
                    acceptedBID_ID: acceptedBID_ID,
                    isCancelledByResearcher: element.ic_bidcanceled,
                    currentUser:currentUser,
                    dt_bid:element.dt_bid
                }
            )

            auxPrice += Number.parseFloat(element.vl_researcherbid)

            // check if the difference is greater than 20
            if (index===(auxToShowBids.length-1) ) {
                currentDate = new Date();
                dateToCheck = new Date(element.dt_bid)
                diffInMs = currentDate.getTime() - dateToCheck.getTime();
                diffInDays = diffInMs / (24 * 60 * 60 * 1000);
                settimewithout(parseInt(diffInDays))
            }
        });

        if(diffInDays>10){
            setistimewithout(true)
        }
        auxToShowBids.sort((a, b) => new Date(b.dt_bid) - new Date(a.dt_bid));
        setProposalList(auxToShowBids)
        setPrice(auxPrice/auxToShowBids.length)

    },[bids])

    const filterProposals = (index) => {
        var aux = [...proposalList]; // make a copy of proposalList
      
        if (index === 0) {
          aux.sort((a, b) => new Date(b.dt_bid) - new Date(a.dt_bid));
        } else if (index === 1) {
          aux.sort((a, b) => new Date(a.dt_bid) - new Date(b.dt_bid));
        } else if (index === 2) {
          aux.sort((a, b) => a.price - b.price);
        } else if (index === 3) {
          aux.sort((a, b) => b.price - a.price);
        } else if (index === 4) {
          aux.sort((a, b) => a.name.localeCompare(b.name));
        } else if (index === 5) {
          aux.sort((a, b) => b.name.localeCompare(a.name));
        }
      
        setProposalList(aux);
      };
      
    // const [sortType, setSortType] = useState(0);
    // const sortTypeList = [
    //     'Mais recentes',
    //     'Mais antigos',
    //     'Preço (Menor para Maior)',
    //     'Preço (Maior para Menor)', 
    //     'Ordem alfabética (A-Z)',
    //     'Ordem alfabética (Z-A)'
    // ]

    return(
        <>
        {istimewithout && !anyAcceptedBID &&
        <div className='items-center flex w-full bg-[#BA483A] bg-opacity-50 justify-center'>
            <div className='font-[Poppins] font-normal text-[18px] leading-[100%] text-[#313131] mt-[20px] mb-[20px] w-[80%] text-center'>
            Você não recebe propostas há {timewithout} dias, considere turbinar o seu pedido para uma melhor visibilidade. Turbine <span className='underline cursor-pointer' onClick={()=>{navigate('/account');}}>aqui</span>
            </div>
        </div>
        }

        <div className='flex mb:flex-col md:flex-row mb:items-start md:items-center mt-[28px] justify-between'>
            <div className='flex mb:flex-col md:flex-row'>
                <div className='flex flex-col mb:items-start md:items-start mb:ml-[0px] md:ml-[30px]'>
                    <div className="font-[Poppins] font-[275] text-[14px] leading-[100%] text-[#313131]">Pesquisadores interessados</div>
                    <div className='font-[Poppins] font-[600] text-[14px] leading-[100%] text-[#313131] mt-[5px]'>{proposalList.length}</div>
                </div>
                <div className='flex flex-col mb:items-start md:items-start  mb:ml-[0px] md:ml-[100px] mb:mt-[20px] md:mt-[0px]'>
                    {thePrice>0 && 
                    <>
                    <div className="font-[Poppins] font-[275] text-[14px] leading-[100%] text-[#313131]">Orçamento médio</div>
                    <div className='font-[Poppins] font-[600] text-[14px] leading-[100%] text-[#313131] mt-[5px]'>
                    {thePrice.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                    </div>
                    </>
                    }
                </div>
            </div>

            <div className="mb:mt-[20px] md:mt-[0px]">
                <Select
                    value={sortType}
                    onChange={(e) => {
                        setSortType(e.target.value);
                        filterProposals(e.target.value)
                    }}
                    displayEmpty="displayEmpty"
                    className="w-[250px] h-[35px]"
                    MenuProps={{
                        sx:{
                            "& .Mui-selected": {
                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                            },
                            "& .MuiMenuItem-root:hover": {
                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                            },
                            '& .MuiMenuItem-root': {
                                whiteSpace: 'normal',
                            },
                        }
                    }} 
                    >
                    {
                        //dynamic
                        sortTypeList.map((item, index) => {
                            return (
                                <MenuItem value={index}>
                                    <p
                                        className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item}</p>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </div>
        </div>
        { proposalList.length  === 0  &&
        
        <div className="w-full rounded-[5px] border-solid border-[2px] border-[#C6C6C6] mt-[20px] h-[101px] flex flex-row items-center mb-[80px]">
            <div className='font-[Poppins] font-normal text-[18px] leading-[100%] text-[#C6C6C6] ml-[17px]'>Aguardando receber propostas...</div>
        </div>
        }
        <div className='mb-[80px]'>
        {
            proposalList.map(item => {
                return(
                    (width > 1024 ? 
                    <Proposal data={item} cancel={cancel} imagetocard={imagetocard}/>
                     : 
                    <Proposal_m data={item} cancel={cancel}/>)
                )
            })
        }
        </div>
        </>
    )
}