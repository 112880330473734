/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    TextareaAutosize,
} from '@mui/material';

import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuccessIcon from '../../assets/icons/successicon.gif'
import { useRef } from 'react';

import flag_red from '../../assets/icons/Flag1.svg'
import flag_yellow from '../../assets/icons/Flag2.svg'


const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '0px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));




export default ({orderTypeList, selected, setSelected, selectedBoost, setSelectedBoost,setcheckoutData,setTotalPrice,totalPrice,currentOrder}) => {

    
    const { width } = useWindowSize();
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }

    const inputField = useRef(null);

    // const [totalPrice, setTotalPrice] = useState(0);
  

    useEffect(() => {
        let sum = 0;
        if(orderTypeList.length>0 && selected.length>0){
            //console.log('inside');
            selected.map((item, index) => {
                const hasthisBoost = Object.values(currentOrder.boosts).find(currentBoost=>currentBoost.tp_boost===orderTypeList[index].tp_boost && currentBoost.ic_boostactive)
                //console.log(index);
                //console.log(item);
                if(item && index > -1 &&!hasthisBoost){
                    sum+=Number(orderTypeList[index].vl_boost)
                }
                // if(item && index > -1) sum += Number.parseInt(orderTypeList[index].vl_boost.match(/\d+$/)[0]);
            })
            setTotalPrice(sum);
        }else{
            setTotalPrice(0);
        }
      }, [selected,currentOrder]);



    
    
    return (
            <ContainerStyle className='items-center flex-col mb:!mb-[30px] md:!mb-[40px] mb:pt-[20px] md:pt-[50px]'>
                <div className='flex flex-col items-center'>
                    
                    <div className='mb:w-full xl:w-[60%] flex mb:flex-col md:flex-row mb:items-center md:items-start'>
                    <Box component={'img'} src={SuccessIcon} className='w-[110px] h-[110px] mr-[10px]'/>
                        <div className="w-full font-[Poppins] font-bold text-[32px] leading-[148%] text-[#313131] text-center">
                        Parábens, sua solicitação foi publicada!
                        </div>
                    </div>
                    <div className='mb:w-full xl:w-[60%] flex mb:flex-col md:flex-row'>
                        <div className="w-[120px]"></div>
                        <div className="w-full font-[Poppins] font-light text-[22px] leading-[150%] text-[#313131] text-center mt-[25px]">
                        Agora só aguardar os pesquisadores te enviarem propostas para iniciar seu pedido.
                        </div>
                    </div>
                </div>
                <div className='mt-[50px] mb-[20px] w-[full] text-center font-[Poppins] font-bold text-[22px] leading-[100%] text-[#313131]'>Turbine sua busca para ordem "{currentOrder?currentOrder.nm_ancestor:''}"</div>
                {
                    orderTypeList && orderTypeList.map((item, index)=> {
                        return (
                            
                                width > 768 ?
                                <div className='w-[100%] rounded-[7px] shadow-[-2px_3px_50px_rgba(0,0,0,0.15)] mt-[20px] z-10 flex flex-row ' key={index}>
                                    <div className="ml-[16px] items-center flex">
                                        {/* {JSON.stringify(item)} */}
                                        <FormControlLabel control={<Checkbox      
                                         checked ={selected[index] || item.tp_boost==='Basic'}  
                                         onChange={(e)=>{
                                                let temp = [...selected];
                                                temp[index] = e.target.checked;
                                                //console.log(item);
                                                if (item.tp_boost==='Basic') {
                                                    temp[index] = true
                                                }
                                                const hasthisboost = Object.values(currentOrder.boosts).find(currentBoost=>currentBoost.tp_boost===item.tp_boost && currentBoost.ic_boostactive)
                                                if (hasthisboost){
                                                    temp[index]=true
                                                }
                                                setSelected(temp);

                                                let aux= [...selectedBoost]
                                                if (aux.some(single=>single.id_boost===item._id)) {
                                                    //console.log('exist');
                                                    if(!hasthisboost){
                                                        aux = aux.filter(single=>single.id_boost !== item._id)
                                                    }
                                                }
                                                else if (!hasthisboost) {

                                                    //console.log(item.tp_boost);
                                                        let dateStart = new Date
                                                    //console.log(dateStart);
                                                        let nr_boostduration = new Date(dateStart.getTime() + (item.nr_boostduration * 24 * 60 * 60 * 1000)); //addint the duration to the current date, to get the deadline
                                                    //console.log(nr_boostduration);



                                                    aux.push({
                                                        id_boost:item._id,
                                                        tp_boost:item.tp_boost,
                                                        dt_booststart:new Date,
                                                        dt_boostend:nr_boostduration,
                                                        nr_boostdurationleft:item.nr_boostduration,
                                                        ic_boostactive:true,
                                                        ds_boost:item.ds_boost,
                                                        vl_boost:item.vl_boost,
                                                        nm_boost:item.nm_boost,
                                                        image:item.tp_boost == 'Urgency' ? flag_red: item.tp_boost == 'Hightlight'? flag_yellow : null
                                                    })
                                                }


                                                setSelectedBoost(aux)
                                            }}          
                                            sx={{
                                                color: '#17686E',
                                                '&.Mui-checked': {
                                                    color: '#17686E',
                                                }
                                            }}      
                                        />} />
                                    </div>
                                    <div className='mt-[24px] mb-[24px] w-[70%] min-w-[300px] '>
                                        <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#313131] mb-[5px]'>{item.nm_boost}</div>
                                        <div className='flex'>
                                        {
                                            item.tp_boost == 'Basic' && 
                                            <div className='font-[Poppins] font-normal text-[14px] leading-[120%] text-[#313131]'>{item.ds_boost}      &nbsp;
                                            <span className='font-[Poppins] font-bold text-[14px] leading-[120%] text-[#313131] underline'>gratuitamente</span>
                                            <span className='font-[Poppins] font-normal text-[14px] leading-[120%] text-[#313131]'>.</span>
                                            </div>
                                            
                                        }
                                        {
                                            item.tp_boost == 'Hightlight' && 
                                            <div className='font-[Poppins] font-normal text-[14px] leading-[120%] text-[#313131]'>{item.ds_boost}</div>
                                        
                                        }
                                        {
                                            item.tp_boost == 'Urgency' && 
                                            <div className='font-[Poppins] font-normal text-[14px] leading-[120%] text-[#313131]'>{item.ds_boost}      &nbsp;
                                            <span className='font-[Poppins] font-bold text-[14px] leading-[120%] text-[#313131]'>10x mais propostas</span>
                                            <span className='font-[Poppins] font-normal text-[14px] leading-[120%] text-[#313131]'>&nbsp;no primeiro dia.</span>
                                            </div>                                        
                                        }
                                        </div>

                                    </div>
                                    <div className='items-center flex w-[62px] ml-[5%]'>
                                        <div className='font-[Poppins] font-bold text-[20px] leading-[100%] text-[#313131]'>R$ {item.vl_boost}</div>
                                    </div>

                                    <div className='lg:ml-[5%] xl:ml-[10%] mb:hidden sm:block'>
                                        {
                                            item.tp_boost == 'Basic' &&
                                            <div className='w-[40px]'></div>
                                        }
                                        {item.tp_boost == 'Hightlight' &&
                                        <Box component='img' src={flag_yellow} className="w-[40px] h-[54px] mt-[-5px]"/>
                                        }
                                        {item.tp_boost == 'Urgency' &&
                                        <Box component='img' src={flag_red} className="w-[40px] h-[54px] mt-[-5px]"/>
                                        }
                                    </div>
                                </div>
                                
                                
                                
                                :





                                 <div className='w-[100%] rounded-[7px] shadow-[-2px_3px_50px_rgba(0,0,0,0.15)] mt-[20px] z-10 flex flex-row'  key={index}>
                                 <div className="ml-[16px] items-center flex">
                                     <FormControlLabel control={<Checkbox      
                                         checked ={selected[index] || item.tp_boost==='Basic'}  
                                            onChange={(e)=>{
                                                let temp = [...selected];
                                                temp[index] = e.target.checked;
                                                //console.log(item);
                                                if (item.tp_boost==='Basic') {
                                                    temp[index] = true
                                                }
                                                setSelected(temp);

                                                let aux= [...selectedBoost]
                                                if (aux.find(single=>single.id_boost===item._id)) {
                                                    //console.log('exist');
                                                    aux = aux.filter(single=>single.id_boost !== item._id)
                                                }
                                                else  {
                                                    //console.log(item.tp_boost);
                                                        let dateStart = new Date
                                                    //console.log(dateStart);
                                                        let nr_boostduration = new Date(dateStart.getTime() + (item.nr_boostduration * 24 * 60 * 60 * 1000)); //addint the duration to the current date, to get the deadline
                                                    //console.log(nr_boostduration);


                                                    aux.push({
                                                        id_boost:item._id,
                                                        tp_boost:item.tp_boost,
                                                        dt_booststart:new Date,
                                                        dt_boostend:nr_boostduration,
                                                        nr_boostdurationleft:item.nr_boostduration,
                                                        ic_boostactive:true,
                                                        ds_boost:item.ds_boost,
                                                        vl_boost:item.vl_boost,
                                                        nm_boost:item.nm_boost,
                                                        image:item.tp_boost == 'Urgency' ? flag_red: item.tp_boost == 'Hightlight'? flag_yellow : null

                                                    })
                                                }
                                                setSelectedBoost(aux)
                                            }}          
                                         sx={{
                                             color: '#17686E',
                                             '&.Mui-checked': {
                                                 color: '#17686E',
                                             }
                                         }}      
                                     />} />
                                 </div>
                                 <div className='mt-[24px] mb-[24px] w-[70%] min-w-[253px] flex flex-col items-center'>
                                     <div className='font-[Poppins] font-bold text-[20px] leading-[150%] text-[#313131] mb-[10px]'>{item.nm_boost}</div>
                                     <div className='flex'>
                                     {
                                         item.tp_boost == 'Basic' && 
                                         <div className='font-[Poppins] font-normal text-[14px] leading-[150%] text-[#313131]'>{item.ds_boost}      &nbsp;
                                         <span className='font-[Poppins] font-bold text-[14px] leading-[150%] text-[#313131] underline'>gratuitamente</span>
                                         <span className='font-[Poppins] font-normal text-[14px] leading-[150%] text-[#313131]'>.</span>
                                         </div>
                                         
                                     }
                                     {
                                         item.tp_boost == 'Hightlight' && 
                                         <div className='font-[Poppins] font-normal text-[14px] leading-[150%] text-[#313131]'>{item.ds_boost}</div>
                                     
                                     }
                                     {
                                         item.tp_boost == 'Urgency' && 
                                         <div className='font-[Poppins] font-normal text-[14px] leading-[150%] text-[#313131]'>{item.ds_boost}      &nbsp;
                                         <span className='font-[Poppins] font-bold text-[14px] leading-[150%] text-[#313131]'>10x mais propostas</span>
                                         <span className='font-[Poppins] font-normal text-[14px] leading-[150%] text-[#313131]'>&nbsp;no primeiro dia.</span>
                                         </div>                                        
                                     }
                                     </div>
                                     <div className='items-center flex w-[62px] ml-[5%] mt-[10px]'>
                                        <div className='font-[Poppins] font-bold text-[20px] leading-[150%] text-[#313131]'>R$ {item.vl_boost}</div>
                                    </div>
                                 </div>


                                
                             </div>
                            
                           
                        )
                    })
                }
                <div className='w-full flex sm:justify-end mb:justify-center'>
                    <div className='mt-[25px] mb:w-full sm:w-[253px] text-center mb-[0px] font-[Poppins] font-[700] text-[22px] leading-[100%] text-[#313131]'>Total: R$ {
                        totalPrice
                    }</div>
                </div>


             


           </ContainerStyle>
    )
}