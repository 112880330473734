import React, { useState } from "react";
import { useEffect, } from "react";
import API from "../../redux/API";
import { toast } from "react-toast";
import { STRIPE_PUBLISHABLE_KEY } from "../../config/constants";

import Visa from '../../assets/icons/visa.svg'
import Master from '../../assets/icons/mastercard.svg'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import payment_discount from '../../assets/image/payment_discount.svg'
import { useSelector } from 'react-redux';

import CheckoutForm from "./Stripe";

  
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);



const formStyles = {
    // margin: 0,
    // padding: 0,
    // gridArea: 'form',
    // overflow: 'visible',
    width: '100%',
    gap:'15px',
    // height:'100%',
    // minHeight: 0,
    // minWidth: 0,
    display: 'grid',
    // justifySelf: 'center',
    margin:'10px auto auto 10px',
    // gridTemplate: '45px repeat(5, 1fr) 55px / repeat(2, 1fr)',
    gridTemplateAreas: `". ."
                        "fheading fheading"
                        "fname fname"
                        "fcno fcno"
                        "fyear fcvv"
                        "btn btn"
                        ". ."`,
  };
  
  const formInputStyles = {
    position: 'relative',
    marginTop: '-12px',
    border: '0',
    borderBottom: '1px solid #ddd',
    height: '38px',
    padding: '7px 0 0 0',
    fontSize: '15px',
    background: 'none',
    width: '100%',
    outline: 'none',
    color: '#000',
  };
  
  const formInputFocusStyles = {
    borderBottom: '1.5px solid #9ad0dd',
  };
  
  const formInputFocusH6Styles = {
    color: 'red',
  };
  const h6Styles = {
    gridArea: 'fheading',
    fontSize: '14 pt',
    fontWeight:'bold'
  };
  
  const inputConStyles = {
    minWidth: 0,
    minHeight: 0,
    position: 'relative',
    color: '#c1c1c1',
  };
  
  const inputConAfterStyles = {
    position: 'absolute',
    content: 'attr(data-top)',
    top: '-16px',
    left: 0,
    height: '10px',
    fontSize: '10.4px',
    letterSpacing: '.5px',
  };
  const nameInputStyles = {
    gridArea: 'fname',
  };
  
  const cardNumInputStyles = {
    gridArea: 'fcno',
  };
  
  const validYearInputStyles = {
    gridArea: 'fyear',
    marginRight: '13px',
  };
  
  const cvvInputStyles = {
    gridArea: 'fcvv',
    marginLeft: '13px',
  };
  
  const buttonStyles = {
  };
  
  const buttonFocusStyles = {
    outline: 'none',
  };
  
  const buttonSpanStyles = {
    display:'flex',
    gap:'10px',
    fontWeight: 'bold',
    color: '#fff',
  };

  const creditCardStyles = {
    gridArea: 'card',
    width: '280px',
    height: '161px',
    minWidth: 0,
    minHeight: 0,
    color: '#fff',
    background: 'linear-gradient(to right, #4092b5 60%, #3580aa 90%)',
    // marginTop: '82px',
    borderRadius: '8px',
    justifySelf: 'right',
    alignSelf: 'start',
    display: 'grid',
    justifyItems: 'center',
    alignItems:'center',
    gridTemplate: 'repeat(4, 1fr) / 19px repeat(4, 1fr) 19px',
    gridTemplateAreas: `". . . visa visa ."
                        ". chip . visa visa . "
                        ". no no no no ."
                        ". name name name year ."`,
    margin:'auto'
  };
  const creditCardStyles_mobile = {
    gridArea: 'card',
    width: '100%',
    height: '161px',
    minWidth: 0,
    minHeight: 0,
    color: '#fff',
    background: 'linear-gradient(to right, #4092b5 60%, #3580aa 90%)',
    // marginTop: '82px',
    borderRadius: '8px',
    justifySelf: 'right',
    alignSelf: 'start',
    display: 'grid',
    justifyItems: 'center',
    alignItems:'center',
    gridTemplate: 'repeat(4, 1fr) / 19px repeat(4, 1fr) 19px',
    gridTemplateAreas: `". . . visa visa ."
                        ". chip . visa visa . "
                        ". no no no no ."
                        ". name name name year ."`,
    margin:'auto'
  };
  
  const creditCardChildrenStyles = {
    overflow: 'hidden',
  };

  
  const visaLogoStyles = {
    display:'flex',
    margin:'auto 10px 10px auto auto',
    padding:'0px',
    gridArea: 'visa',
    overflow: 'hidden',
    // justifySelf: 'right',
    // gap:'0px',
  };
  
  const visaLogoSvgStyles = {
    height: '35px',
    width:'auto',
  };
  
  const chipLogoStyles = {
    gridArea: 'chip',
    overflow: 'hidden',
    width: '38px',
    height: '30px',
    justifySelf: 'left',
    marginTop: '10px',
  };
  
  
  const creditCardUlStyles = {
    gridArea: 'no',
    listStyleType: 'none',
    justifySelf: 'stretch',
    display: 'grid',
    gridTemplate: '1fr / repeat(1, 1fr)',
    };
  
  const creditCardLiStyles = {
    display: 'inline-block',
    fontSize: '14px',
    letterSpacing: '2px',
    textAlign: 'left',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 100,
    textShadow: '0px 0px 2px rgba(0, 0, 0, 0.6)',
    wordSpacing: '3.7px',
    };
  
  const creditCardLiCvvStyles = {
    display: 'inline-block',
    fontSize: '12px',
    letterSpacing: '2px',
    textAlign: 'right',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 100,
    textShadow: '0px 0px 2px rgba(0, 0, 0, 0.6)',
    wordSpacing: '3.7px',
    // right:'0'
    };
  
  const creditCardNameStyles = {
    gridArea: 'name',
    justifySelf: 'left',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '12.8px',
    fontWeight: 'lighter',
    textTransform: 'uppercase',
    letterSpacing: '1.3px',
    paddingBottom: '5px',
    textShadow: '0px 0px 2px rgba(0, 0, 0, 0.6)',
  };
  
  const creditCardYearStyles = {
    gridArea: 'year',
    justifySelf: 'right',
    fontSize: '14px',
    fontWeight: 'lighter',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '2.4px',
    paddingBottom: '5px',
    textShadow: '0px 0px 2px rgba(0, 0, 0, 0.6)',
  };

  

export default (props)=>{

  const {currentUser} = useSelector(state => state.auth);

  const isMobile = window.innerWidth < 700;

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [promocode, setPromoCode] = useState("");
  const [tryingcode, settryingcode] = useState("");
  const [isFullDiscount, setIsFullDiscount] = useState(false);
  const [codeOK, setcodeOK] = useState(false);
  const [codeNOTOK, setcodeNOTOK] = useState(false);
  const [couponinfo, setCouponInfo] = useState({});
  const [fullCouponInfo, setFullCouponInfo] = useState({});
  const [paymentIntent, setpaymentIntent] = useState({});
  const [message, setMessage] = useState(null);
  const [messagecolor, setMessagecolor] = useState('');
  const [stripe, setstripe] = useState(null);
  const [elements, setelements] = useState(null);
  const [canclick,setcanclick]=useState(false)

  const pay_stripe=async()=>{
    props.setLoading(true)
    setClientSecret('')
    props.setpaymentIntent_id('')
    // Create PaymentIntent as soon as the page loads
    API.post('gateway/pay_stripe',{
      billing:props.cardinfo,
      currentUser:currentUser,
      couponinfo,
      paymentIntent,
    },{
      headers:{"Content-Type": "application/json"}
    })
    .then((res)=>{
      if(res.data.isFullDiscount){
        setIsFullDiscount(true)
      }else{
        setIsFullDiscount(false)
        setClientSecret(res.data.clientSecret)
        props.setpaymentIntent_id(res.data.paymentIntent_id)
        setpaymentIntent(res.data.paymentIntent)
      }
      return props.setLoading(false)
    })
    .catch((error)=>{
      toast.error(error.message?error.message:'erro')
      setpaymentIntent({})
      return props.setLoading(false)
    })
  }
  

  useEffect(() => {
    //console.log(`paymentIntent= ${paymentIntent.id}`)
    //console.log(`clientSecret= ${clientSecret}`)
    // if(paymentIntent.id && clientSecret ){
    //   console.log('nothing change');
    //   return
    // }
    // console.log('changing');
    pay_stripe()
  }, [codeOK,codeNOTOK]);



  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,

    // mode: 'payment',
    // amount: 1099,
    // currency: 'usd',
  
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret || !paymentIntent || !paymentIntent.id) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Pagamento confirmado!");
          setMessagecolor('green')
          toast.success("Pagamento confirmado!")
          break;
        case "processing":
          setMessage("Processando pagamento.");
          setMessagecolor('blue')
          toast.info("Processando pagamento.")
          break;
        case "requires_payment_method":
          setMessage("Algo deu errado. Confira os dados e tente novamente.");
          setMessagecolor('red')
          toast.error("Algo deu errado. Confira os dados e tente novamente.")
          break;
        default:
          setMessage("Algo deu errado. Confira os dados e tente novamente.");
          setMessagecolor('red')
          toast.error('Algo deu errado. Confira os dados e tente novamente.')
          break;
      }
    });
  }, [stripe,clientSecret,paymentIntent]);

  const handleSubmit = async (e) => {
    props.setLoading(true)
    e.preventDefault();

    if (!stripe || !elements) {
      props.setLoading(false)
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:'https://docs-tree.com/account' ,
        receipt_email:currentUser.ds_email,
      },
      redirect:'if_required',
    });

    if(error){
      props.setLoading(false)
      console.log(error);
      if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
          setMessagecolor('red')
          toast.error(error.message)
      } else {
        props.setLoading(false)
        setMessage("Erro inesperado.");
        setMessagecolor('red')
        toast.error("Erro inesperado.")
      }
    }
    else{
      props.setLoading(false)

      // HANDLE SUCCESS
      
      // handle null paymentIntens and promocodes
      handlepaymentintents_after()
      // show the success payment message (next page)
      props.setisPaymentSucces(true)
      // confirm DT flow (according to billing type)
      props.confirmPayment()




    }
    setIsLoading(false);
  };

const priceafterpromocode =()=>{
  const price = paymentIntent && paymentIntent.amount?paymentIntent.amount/100:null
  return price
}


const checkPromoCode =async()=>{
  props.setLoading(true)
  settryingcode(promocode)
  // setClientSecret('')
  // props.setpaymentIntent_id('')
  API.post('gateway/checkPromoCode',{promocode:promocode},{
  })
  .then((res)=>{
    // toast.success(res.data.message?res.data.message:'Cupom válido')
    if(res.data.thiscoupon){
      setcodeOK(true)
      setcodeNOTOK(false)
      setCouponInfo(res.data.thiscoupon)
      setFullCouponInfo(res.data.fullpromocodedata)
    }
 
    props.setLoading(false)
  })
  .catch((error)=>{
    // toast.error('Cupon inválido')
    setcodeOK(false)
    setcodeNOTOK(true)
    setCouponInfo({})
    props.setLoading(false)
  })

  

}

const handlepaymentintents_after =  async()=>{
  const data = {
    promocode:promocode,
    fullCouponInfo:fullCouponInfo,
    paymentIntent:paymentIntent,
    paymentIntent_id:props.paymentIntent_id,
    clientSecret:clientSecret,
    currentUser:currentUser,
  }
  API.post('gateway/handlepaymentintents_after',data)
  .then((res)=>{
    console.log(`updated this user payments`);
    return console.log(res.data.status);
  })
  .catch((error)=>{
    console.log(`error updating this user payments`);
    return console.log(error.message?error.message:'error');
  })
}


useEffect(()=>{
if (isFullDiscount) {
  setpaymentIntent({
    amount:0
  })
}
},[isFullDiscount])



    return(

        <div style={isMobile?{display:'flex', flexDirection:'column', width:'100%',height:'500px', gap:'10px', padding:'10px', fontFamily:'poppins', }:{width:'800px',  gap:'10px', padding:'10px', fontFamily:'poppins'}}>
        <div style={isMobile?{ width:'100%', maxHeight:'430px', overflow:'auto',  padding:'auto auto 10px auto'}:{width:'100%',  display:'flex', gap:'10px', padding:'10px', }}>
        
        <div style={isMobile?{width:'90%', display:'flex', flexDirection:'column', margin:'auto'}:{width:'100%', display:'flex', flexDirection:'column', }}>
        <div style={isMobile?{width:'100%',}:{width:'100%',  height:'auto',margin:'auto', display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center' , }}>
          <div style={isMobile?creditCardStyles_mobile:creditCardStyles}>
            <div style={visaLogoStyles}>
              <img src={Visa} style={visaLogoSvgStyles}/>
              <img src={Master} style={visaLogoSvgStyles}/>
              {/* <svg
                style={visaLogoSvgStyles}
                enableBackground="new 0 0 291.764 291.764"
                version="1.1"
                viewBox="5 70 290 200"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="svgcolor"
                  d="m119.26 100.23l-14.643 91.122h23.405l14.634-91.122h-23.396zm70.598 37.118c-8.179-4.039-13.193-6.765-13.193-10.896 0.1-3.756 4.24-7.604 13.485-7.604 7.604-0.191 13.193 1.596 17.433 3.374l2.124 0.948 3.182-19.065c-4.623-1.787-11.953-3.756-21.007-3.756-23.113 0-39.388 12.017-39.489 29.204-0.191 12.683 11.652 19.721 20.515 23.943 9.054 4.331 12.136 7.139 12.136 10.987-0.1 5.908-7.321 8.634-14.059 8.634-9.336 0-14.351-1.404-21.964-4.696l-3.082-1.404-3.273 19.813c5.498 2.444 15.609 4.595 26.104 4.705 24.563 0 40.546-11.835 40.747-30.152 0.08-10.048-6.165-17.744-19.659-24.035zm83.034-36.836h-18.108c-5.58 0-9.82 1.605-12.236 7.331l-34.766 83.509h24.563l6.765-18.08h27.481l3.51 18.153h21.664l-18.873-90.913zm-26.97 54.514c0.474 0.046 9.428-29.514 9.428-29.514l7.13 29.514h-16.558zm-160.86-54.796l-22.931 61.909-2.498-12.209c-4.24-14.087-17.533-29.395-32.368-36.999l20.998 78.33h24.764l36.799-91.021h-24.764v-0.01z"
                  fill="#A9CBDC"
                />
                <path
                  className="svgtipcolor"
                  d="m51.916 111.98c-1.787-6.948-7.486-11.634-15.226-11.734h-36.316l-0.374 1.686c28.329 6.984 52.107 28.474 59.821 48.688l-7.905-38.64z"
                  fill="#DDEAF1"
                />
              </svg> */}
            </div>
            <div style={chipLogoStyles}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 230 384.4 300.4"
                width={38}
                height={70}
              >
                <path
                  d="M377.2 266.8c0 27.2-22.4 49.6-49.6 49.6H56.4c-27.2 0-49.6-22.4-49.6-49.6V107.6C6.8 80.4 29.2 58 56.4 58H328c27.2 0 49.6 22.4 49.6 49.6v159.2h-.4z"
                  data-original="#FFD66E"
                  data-old_color="#00FF0C"
                  fill="rgb(237,237,237)"
                />
                <path
                  d="M327.6 51.2H56.4C25.2 51.2 0 76.8 0 107.6v158.8c0 31.2 25.2 56.8 56.4 56.8H328c31.2 0 56.4-25.2 56.4-56.4V107.6c-.4-30.8-25.6-56.4-56.8-56.4zm-104 86.8c.4 1.2.4 2 .8 2.4 0 0 0 .4.4.4.4.8.8 1.2 1.6 1.6 14 10.8 22.4 27.2 22.4 44.8s-8 34-22.4 44.8l-.4.4-1.2 1.2c0 .4-.4.4-.4.8-.4.4-.4.8-.8 1.6v74h-62.8v-73.2-.8c0-.8-.4-1.2-.4-1.6 0 0 0-.4-.4-.4-.4-.8-.8-1.2-1.6-1.6-14-10.8-22.4-27.2-22.4-44.8s8-34 22.4-44.8l1.6-1.6s0-.4.4-.4c.4-.4.4-1.2.4-1.6V64.8h62.8v72.4c-.4 0 0 .4 0 .8zm147.2 77.6H255.6c4-8.8 6-18.4 6-28.4 0-9.6-2-18.8-5.6-27.2h114.4v55.6h.4zM13.2 160H128c-3.6 8.4-5.6 17.6-5.6 27.2 0 10 2 19.6 6 28.4H13.2V160zm43.2-95.2h90.8V134c-4.4 4-8.4 8-12 12.8h-122V108c0-24 19.2-43.2 43.2-43.2zm-43.2 202v-37.6H136c3.2 4 6.8 8 10.8 11.6V310H56.4c-24-.4-43.2-19.6-43.2-43.2zm314.4 42.8h-90.8v-69.2c4-3.6 7.6-7.2 10.8-11.6h122.8v37.6c.4 24-18.8 43.2-42.8 43.2zm43.2-162.8h-122c-3.2-4.8-7.2-8.8-12-12.8V64.8h90.8c23.6 0 42.8 19.2 42.8 42.8v39.2h.4z"
                  data-original="#005F75"
                  className="active-path"
                  data-old_color="#005F75"
                  fill="rgba(0,0,0,.4)"
                />
              </svg>
            </div>
            <ul style={creditCardUlStyles} className="ccList">
              <li style={creditCardLiStyles} >{!props.cardNumber?'0000 0000 0000 0000':props.cardNumber}</li>
              <li style={creditCardLiCvvStyles}>{!props.csv?'000':props.csv}</li>
            </ul>
            <h4 style={creditCardNameStyles}>{!props.ownerName?'Nome do proprietário':props.ownerName}</h4>
            <h4 style={creditCardYearStyles}>{!props.validate?'00/00':props.validate}</h4>
          </div>
        </div>

        <div style={{ margin:'20px auto 10px 0px' }}>
            {props.cardinfo.normalprice && 
                <div style={{fontSize:'10pt',  display:'flex', gap:'10px',  alignItems:'center',  }}>
                    <h5>Preço normal:</h5>
                    <h5 style={{fontWeight:'300', textDecoration:'line-through', color: 'red'}}>{props.cardinfo.normalprice?`R$ ${props.formatpricetoshow(props.cardinfo.normalprice)}`:''}</h5>
                </div>
            }
            <div style={isMobile?{fontWeight:'600',fontSize:'12pt', display:'flex', flexWrap: 'wrap', gap:'5px', alignItems:'center', }:{ fontWeight:'600',fontSize:'15pt', display:'flex', flexWrap: 'wrap', gap:'5px', alignItems:'center', }}>
                {
                  couponinfo && couponinfo.id && couponinfo.valid ?
                  <>               
                    <h5>Preço promocional + Cupom:</h5>
                    <h5 style={{ fontSize:!isMobile&&'15pt',  color:'green'}}>R$ {props.formatpricetoshow(priceafterpromocode())}</h5>
                  </>               
                        :
                  <>
                    <h5>Preço promocional:</h5>
                    <h5 style={{ fontSize:!isMobile&&'15pt',  color:'green'}}>R$ {props.cardinfo.promoprice?props.formatpricetoshow(props.cardinfo.promoprice):''}</h5>
                  </>
                }
            </div>
        </div>

        <div style={{display:'flex', margin:isMobile?'auto auto 10px auto':'0px auto 40px auto', width:'100%', gap:'10px',  }}
        >

        <div style={{
                        margin: '0px auto auto 0px', 
                        width: '75%', 
          
        }}>
          <input 
            type="text" 
            placeholder="Promo code"
            value={promocode||''}
            style={{
              margin: 'auto', 
              width: '100%', 
              height: '35px', 
              border: 'solid 1px', 
              borderRadius: '5px', 
              borderColor: codeNOTOK && !codeOK &&promocode && tryingcode && tryingcode===promocode ?'red':'rgba(0,0,0,0.2)', 
              textAlign: 'center',  
            }}
            onChange={(e)=>{setPromoCode(e.target.value);}}
          />
          {codeNOTOK && !codeOK && promocode && tryingcode && tryingcode===promocode &&
          <div style={{width:'100%', fontSize:'10pt', color:'red', textAlign:'center'}}>Cupom inválido ou expirado</div>
          } 
        </div>

        <button
          style={{margin:'0px 0px auto auto', width:'20%', height:'35px', borderRadius:'5px', backgroundColor:'rgba(0,0,0,0.2)', fontSize:'8pt',
          }}
          onClick={()=>{
            setcodeOK(false)
            setcodeNOTOK(false)
            checkPromoCode();
          }}
        >Check</button>

        </div>

        
      </div>



    <div style={isMobile?{  width:'90%', display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', padding:'10px', margin:'auto',  }:{width:'100%', display:'flex',flexDirection:'column', alignContent:'center', alignItems:'center', padding:'10px',   }}>
 

            {/* {clientSecret && (

              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            )} */}



            {clientSecret && !isFullDiscount ?

              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm paymentIntent={paymentIntent} couponinfo={couponinfo} formatpricetoshow={props.formatpricetoshow} priceafterpromocode={priceafterpromocode} isMobile={isMobile} cardinfo={props.cardinfo} isLoading={isLoading} setIsLoading={setIsLoading} handleSubmit={handleSubmit} setstripe={setstripe} stripe={stripe} setelements={setelements} elements={elements} canclick={canclick} setcanclick={setcanclick} />
              </Elements>
            
            :

            <div style={{display:'flex', flexDirection:'column', padding:'20px', gap:'10px', width:'100%', }}>

              <div style={{display:'flex', borderRadius:'10px',  width:'100%', overflow:'hidden'}}>
                <img  src={payment_discount} width='100%' />
              </div>

              {
                isFullDiscount && 
                <div style={{ margin:'0px auto 0px auto', color:messagecolor?messagecolor:'black', fontWeight:'300', textAlign:'center',   }}>Cupom aplicado!</div>
              }
            
              <div style={
                isMobile?
                {
                  display:'flex',
                  flexDirection:'column',
                  width:'100%',
                  maxWidth:'200px',
                  maxHeight: '100px',
                    margin:'auto auto 10px auto',
                alignContent:'center',
                  alignItems:'center',
                  justifyContent:'center',
                  justifyItems:'center',
                  padding:'10px 15px 10px 15px',
                  backgroundColor: isLoading?'grey':'#17686E',
                  borderRadius: '5px',
                  border: 'none',
                  textTransform: 'uppercase',
                  fontSize: '10pt',
                  color: isLoading?'black':'#fff',
                  cursor: 'pointer',
                }
                :
                {
                    display:'flex',
                    width:'100%',
                    maxWidth:'200px',
                    maxHeight: '100px',
                    margin:'auto auto 10px auto',
                    alignContent:'center',
                    alignItems:'center',
                    justifyContent:'center',
                    justifyItems:'center',
                    gap:'10px',
                    padding:'5px 10px 5px 10px',
                    backgroundColor: isLoading?'grey':'#17686E',
                    borderRadius: '5px',
                    border: 'none',
                    textTransform: 'uppercase',
                    fontSize: '12pt',
                    color: isLoading?'black':'#fff',
                    cursor: 'pointer',
              }}  

              onClick={()=>{
                if(isFullDiscount){
                  props.setLoading(true)
                  handlepaymentintents_after()
                  props.setisPaymentSucces(true)
                  props.confirmPayment()
                  return props.setLoading(false)
                }
                return toast.info('Aguarde um momento')
              }}

              >Avançar</div>

            </div>
          
            }
            {message && <div style={{ margin:'0px auto 0px auto', color:messagecolor?messagecolor:'black', fontWeight:'300', textAlign:'center',   }}>{message}</div>}

  </div>

    </div>


        



        </div> 
    )
}