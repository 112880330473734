/* eslint-disable import/no-anonymous-default-export */
import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
    Box
} from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress';
import Step0 from './step0'
import Step1 from './step1'
import Step2 from './step2'
import AddDocs from './AddDocs'
import Step4 from './step4'
import Step5 from './step5'
import API from '../../redux/API';
import {toast} from 'react-toast'
import { setCurrentOrder } from '../../redux/actions/order.action';
import Loading from '../../components/loading/Loading';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle1 = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%'
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#313131',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle2 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));

const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: props.width > 640 ? 'row':'column-reverse' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
        <div className="mb:ml-[0px] md:ml-[10px] mb:mb-[25px] md:mb-[0px]">{((props.value>=50 && props.value<75) ? 75 : props.value===10 ? 25 : props.value===25 ? 50 : props.value===75 ? 100 : props.value===100 ? 125 : props.value)/25}/5</div>
        </Box>
      </Box>
    );
}

export default (props) => {
    const { width } = useWindowSize();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {currentUser} = useSelector(state => state.auth);
    const { currentOrder } = useSelector(state => state.order);
    const { currentProfile } = useSelector(state => state.auth);

    useEffect(()=>{
        if(currentUser){
            if(currentProfile===0){
                navigate(-1);
            }

            if(!currentUser.qt_importavaiable){
                // verify if this user has avaiable import order
                navigate('/import-plans')
                toast.error('Suas importações esgotaram. Contrate mais agora')
            }
    
        }

    }, [currentUser])


    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }

    const [loading, setLoading] = useState(false);
    const [orderID, setOrderID] = useState('');
    const [needOtherType, setneedOtherType] = useState(false);
    const [otherDocOK, setOtherDocOK] = useState(false);
    const [ds_comments, setds_comments] = useState('');
    const [tp_order, settp_order] = useState(true);
    const [ds_familysearchprofile, setds_familysearchprofile] = useState('')

    const [progress, setProgress] = useState(10);
    const [id_documenttype, setid_documenttype] = useState('');
    const [nm_documentcountry, setnm_documentcountry] = useState('BR');
    const [locationSelected, setLocationSelected] = useState(false);
    const [nm_documentlocation, setnm_documentlocation] = useState('');
    const [nm_otherdocument, setnm_otherdocument] = useState('');
    const [nm_ancestor, setnm_ancestor] = useState('');
    const [ds_gender, setds_gender] = useState('');

    const [ic_searchdocument, setic_searchdocument] = useState(false);
    const [ic_fetchdocument, setic_fetchdocument] = useState(false);

    const [ic_digitaldocument, setic_digitaldocument] = useState(false);
    const [ic_physicaldocument, setic_physicaldocument] = useState(false);
    const [ic_stampeddocument, setic_stampeddocument] = useState(false);
    const [nm_ancestoralternative, setnm_ancestoralternative] = useState([]);
    const [allowUsers, setResearchList] = useState([]);
    const [family_members, setfamily_members] = useState({nm_spouse:'', nm_father:'', nm_mother:'', nm_otherperson:'', ds_otherpersonfamiliarity:''});
    const [stampAvailable, setStampAvailable] = useState(true);

    const [has_attachments, setHas_attachments] = useState(false);
    
    const [life_event_dates, setlife_event_dates] = useState({
        baptism: {
            dt_baptism: null,
            dt_baptismalternativefrom: null,
            dt_baptismalternativeto: null,
            ds_baptismlocation: null,
        },
        birth: {
            dt_birth: null,
            dt_birthalternativefrom: null,
            dt_birthalternativeto: null,
            ds_birthlocation: null,
        },
        marriage: {
            dt_marriage: null,
            dt_marriagealternativefrom: null,
            dt_marriagealternativeto: null,
            ds_marriagelocation: null,
        },
        immigration: {
            dt_immigration: null,
            dt_immigrationalternativefrom: null,
            dt_immigrationalternativeto: null,
            ds_immigrationlocation: null,
        },
        death: {
            dt_death: null,
            dt_deathalternativefrom: null,
            dt_deathalternativeto: null,
            ds_deathlocation: null,
        },
    });


    // const submitClientID = () => {
    //     API.post('/order/submitclientid', {allowUsers, _id: currentOrder._id})
    //     .then((result) => {
    //         if (result.data.status === "success") {
    //             toast.success('Submetido com sucesso!')
    //             dispatch(setCurrentOrder(result.data.newOrder))
    //         }
    //     })
    //     .catch((err) => {
    //             toast.error(JSON.parse(err.request.response).message)
    //     });  
    // }

    const checkCanFetch = ()=>{
        if(ic_searchdocument && !ic_fetchdocument){
            setic_digitaldocument(true)
            setic_physicaldocument(false)
            return setic_stampeddocument(false)
        }
            setic_stampeddocument(false)
            setic_physicaldocument(false)
            setic_digitaldocument(false)
    }


    useEffect(()=>{
        checkCanFetch()
    },[ic_searchdocument,ic_fetchdocument,stampAvailable])




    return (
       <RootStyle>
            {loading && <Loading nameToShow="Carregando"/>}
        {
            progress != 125 && 
            <ContainerStyle1 >
                <div className='mb:mb-[50px] md:mb-[40px] mb:mt-[-40px] md:mt-[0px] font-[Poppins] font-normal mb:text-[25px] md:text-[32px] leading-[148%] color-[#313131]' style={{color: "#313131"}}>Importe uma pesquisa realizada</div>
                <Box sx={{ width: width>640 ?'60%':'100%' }}>
                    <LinearProgressWithLabel  
                        sx={{
                            'backgroundColor': '#D9D9D9',
                            '& .MuiLinearProgress-bar1Determinate': {
                                backgroundColor: '#17686E',
                            }
                        }}
                        width={width}
                     value={progress> 100 ? 100: progress} />
                </Box>
            </ContainerStyle1>
        }
        <div className='min-h-[520px]'>         
            {
                progress === 10 && 
                <Step0 
                    ic_searchdocument={ic_searchdocument} setic_searchdocument={setic_searchdocument}
                    ic_fetchdocument={ic_fetchdocument} setic_fetchdocument={setic_fetchdocument}
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}
                ></Step0>

            }
           
            {
                progress === 25 &&
                <Step1 
                    checkCanFetch={checkCanFetch}
                    stampAvailable={stampAvailable}
                    setStampAvailable={setStampAvailable}
                    ic_searchdocument={ic_searchdocument}
                    ic_fetchdocument={ic_fetchdocument} 
                    nm_documentcountry={nm_documentcountry} setnm_documentcountry={setnm_documentcountry} 
                    nm_documentlocation={nm_documentlocation} setnm_documentlocation={setnm_documentlocation} 
                    id_documenttype={id_documenttype} setid_documenttype={setid_documenttype} 
                    ds_familysearchprofile={ds_familysearchprofile} setds_familysearchprofile={setds_familysearchprofile} 
                    nm_otherdocument={nm_otherdocument} setnm_otherdocument={setnm_otherdocument} 
                    ic_digitaldocument={ic_digitaldocument} setic_digitaldocument={setic_digitaldocument} 
                    ic_physicaldocument={ic_physicaldocument} setic_physicaldocument={setic_physicaldocument} 
                    ic_stampeddocument={ic_stampeddocument} setic_stampeddocument={setic_stampeddocument}
                    progress={progress} setProgress={setProgress}
                    setOtherDocOK={setOtherDocOK}
                    otherDocOK={otherDocOK}
                    needOtherType={needOtherType}
                    setneedOtherType={setneedOtherType}
                    isCreateOrder={false}
                    setLocationSelected={setLocationSelected}
                    locationSelected={locationSelected}
                    ></Step1>
            }
            {
                progress === 50 &&
                <Step2
                    tabIndex1={1}
                    ds_gender={ds_gender} setds_gender={setds_gender} 
                    nm_ancestor={nm_ancestor} setnm_ancestor={setnm_ancestor} 
                    nm_ancestoralternative={nm_ancestoralternative} setnm_ancestoralternative={setnm_ancestoralternative} 
                    family_members={family_members} setfamily_members={setfamily_members} 
                    life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates} 
                    isCreateOrder={false}
                    progress={progress} setProgress={setProgress}
                ></Step2>
            }
            {
                progress === 60 &&
                <Step2
                    tabIndex1={2}
                    ds_gender={ds_gender} setds_gender={setds_gender} 
                    nm_ancestor={nm_ancestor} setnm_ancestor={setnm_ancestor} 
                    nm_ancestoralternative={nm_ancestoralternative} setnm_ancestoralternative={setnm_ancestoralternative} 
                    family_members={family_members} setfamily_members={setfamily_members} 
                    life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates} 
                    isCreateOrder={false}
                    progress={progress} setProgress={setProgress}
                ></Step2>
            }
            {
                progress === 70 &&
                <Step2
                    tabIndex1={3}
                    ds_gender={ds_gender} setds_gender={setds_gender} 
                    nm_ancestor={nm_ancestor} setnm_ancestor={setnm_ancestor} 
                    nm_ancestoralternative={nm_ancestoralternative} setnm_ancestoralternative={setnm_ancestoralternative} 
                    family_members={family_members} setfamily_members={setfamily_members} 
                    life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates}
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={false}
                >
                </Step2>
            }
            {
                progress === 75 &&
                <Step4
                    ds_comments={ds_comments} setds_comments={setds_comments}
                    progress={progress} setProgress={setProgress}
                >                        
                </Step4>
            }
            {
                progress === 100 &&
                <AddDocs
                    progress={progress} setProgress={setProgress}
                    setHas_attachments={setHas_attachments}
                    orderID={orderID}
                >                    
                </AddDocs>
            }
            {
                progress === 125 &&
                <Step5
                    researcherList={allowUsers}
                    setResearchList={setResearchList}
                    // submitClientID={submitClientID}
                    progress={progress} setProgress={setProgress}
                >                        
                </Step5>
            }      
      
        </div>
        {
            progress !== 125 ?
            <div className='w-[90%]' style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="flex mb:justify-center md:just`ify-end mb-[80px]">
                    <HeaderBTNStyle1

                    disabled={progress===100}
                    style={{ marginRight: "10px" }}
                        onClick={()=>{
                            setLoading(true)
                            if(progress  === 100) {setProgress(progress - 25);setLoading(false)}
                            else if(progress  === 75) {setProgress(progress - 5);setLoading(false)}
                            else if(progress === 70) {setProgress(progress - 10);setLoading(false)}
                            else if(progress === 60) {setProgress(progress - 10);setLoading(false)}
                            else if(progress === 50) {setProgress(25);setLoading(false)}
                            else if(progress === 25) {setProgress(10);setLoading(false)}
                            else if(progress === 10) {setLoading(false);navigate('/home')}
                            }}
                    >
                        Voltar
                    </HeaderBTNStyle1>
                    <HeaderBTNStyle
                        style={{ marginLeft: "10px" }}
                        disabled={(
                            progress === 10 ? (!ic_fetchdocument && !ic_searchdocument ):
                            progress === 25 ? (!nm_documentcountry || !id_documenttype || !ic_digitaldocument || !nm_documentlocation || !locationSelected || (needOtherType && !otherDocOK)):
                            progress === 50 ? (!ds_gender || !nm_ancestor || !/[a-zA-Z]/.test(nm_ancestor)) : 
                            progress === 100 ? !has_attachments : false
                        )}
                        onClick={()=>{
                            setLoading(true)
                            if (progress === 75) {
                                API.post('/order/createorder', {id_researcher:currentUser.id === undefined ? currentUser._id : currentUser.id, id_documenttype, nm_documentcountry, nm_documentlocation, nm_otherdocument, nm_ancestor, ds_gender, ic_digitaldocument, ic_physicaldocument, ic_stampeddocument, nm_ancestoralternative, family_members, life_event_dates,  ds_comments, tp_order, ds_familysearchprofile, tp_ordervisibility:true, vl_budget:0, ic_searchdocument, ic_fetchdocument})
                                    .then((result) => {
                                        if (result.data.status === "success") {
                                            toast.success('Ordem importada!\nAgora vamos adicionar os documentos.')
                                            dispatch(setCurrentOrder(result.data.newOrder))
                                            setOrderID(result.data.newOrder._id?result.data.newOrder._id:result.data.newOrder.id?result.data.newOrder.id:null)
                                            setLoading(false) 
                                            setProgress(progress + 25)
                                        }
                                    })
                                    .catch((err) => {
                                            toast.error("Erro ao importar ordem. Contate o suporte.")
                                            setLoading(false)
                                    });
                            }

                            if(progress === 50) {setProgress(progress + 10);setLoading(false)}
                            else if(progress === 60) {setProgress(progress + 10);setLoading(false)}
                            else if(progress === 70) {setProgress(progress + 5);setLoading(false)}
                            else if(progress < 50 && progress > 10) {setProgress(progress + 25);setLoading(false)}
                            else if(progress === 10) {setProgress(25);setLoading(false)}
                            else if(progress === 100) {setProgress(progress + 25);setLoading(false)}
                        }}
                        sx={{
                        '&:disabled' : {
                            backgroundColor: '#C6C6C6',
                            cursor: 'not-allowed'
                        }
                        }}
                    >
                    Próximo
                    </HeaderBTNStyle>
                </div>
            </div>:
            <div className='w-[100%] text-center' >
                <div className="flex justify-center mb-[80px]">
                    <HeaderBTNStyle2 onClick={()=>{navigate('/myorders')}}>
                    Meus trabalhos
                    </HeaderBTNStyle2>
                    <div className='w-[26px]'></div>
                    <HeaderBTNStyle
                        onClick={()=>{navigate('/home'); window.scrollTo(0, 0);}}
                            sx={{
                            '&:disabled' : {
                                backgroundColor: '#C6C6C6',
                                cursor: 'not-allowed'
                            }
                        }}>
                    Início
                    </HeaderBTNStyle>
                </div>
            </div>
        }
        </RootStyle>
    )
}