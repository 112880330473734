/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef } from 'react';
import Membership from './membership';
import API from '../../redux/API';
import { useSelector} from 'react-redux';

import planimage from '../../assets/image/planimage.png'

export default () => {
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb:mt-[15px] xl:mt-[10px] mb-[10px] w-[272px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131]"
    }

    const { currentUser } = useSelector(state => state.auth);
    const [payment,setpayment]=useState(0)
    useEffect(()=>{
        if(currentUser){
            var aux = currentUser.plans
            aux.map((single)=>{
                if(single.ic_planactive && single.vl_value){
                    setpayment(single.vl_value.toFixed(2).replace(".",","))
                }
            })
        } 
    },[currentUser])
    return (
        <div className='mb:w-full xl:w-[95%] float-right'>
            <div className='w-[100%] flex mb:items-center xl:items-start  flex-col'>
                <div className='mb:w-[96%] xl:w-full mb:block xl:flex justify-between '>
                    <div className='mb:w-full xl:w-[42%] h-[98px] pt-[12px] pb-[12px] mb:pl-[5px] xl:pl-[27px] mb:pr-[5px] xl:pr-[23px] bg-[#17686E]  bg-opacity-[0.11] rounded-[5px]'>
                        <div className='flex justify-between '>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            Seu plano: 
                            </div>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-bold">
                            {currentUser.ic_currentplan == 0 ? 'Iniciante' : (currentUser.ic_currentplan == 1 ? 'Especialista' : 'Assessor')}
                            </div>
                        </div>
                        <div className='flex justify-between mt-[10px]'>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            Data de assinatura:
                            </div>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            {new Date(currentUser.dt_signatureplan).toLocaleDateString('en-GB')}
                            </div>
                        </div>
                        <div className='flex justify-between mt-[10px]'>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            Pagamento:
                            </div>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            {payment?`R$ ${payment}`:`-`}
                            </div>
                        </div>
                    </div>
                    <div className='mb:mt-[25px] xl:mt-[0px] mb:w-full xl:w-[56%] h-[98px] bg-[#E2E4E5]  rounded-[5px]' style={{ overflow: "hidden" }}>
                    <img 
                                src={planimage} 
                                alt='Upgrade Plan 1' 
                                style={{ 
                                objectFit: "cover", 
                                objectPosition: "center", 
                                width: "100%", 
                                height: "calc(100% + 80px)", 
                                marginTop:'-40px',
                                marginBottom:'-40px',
                                marginLeft:'0px',
                                marginRight:'0px'
                                }}
                            />

                    </div>
                </div>
                <div className='mt-[20px] mb:w-[96%] xl:w-full  xl:mb-[90px] mb:mb-[80px] rounded-[5px] border-solid border-[#E2E4E5] md:border-[1px] mb:border-[0px]'>
                     <Membership/>
                </div>
            </div>
        </div>
    )
}