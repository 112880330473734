/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useLayoutEffect, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Select, MenuItem } from '@mui/material';
import ReactFlagsSelect from "react-flags-select";
import GeoLocation from '../PersonalInfo/Geolocation';
import { toast } from 'react-toast';
import {
    Box,
    IconButton,
    Avatar
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
import { setUser } from '../../redux/actions/auth.action';
import { useDispatch } from 'react-redux';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default(props) => {

    const isMobile = window.innerWidth < 700;

    const { currentUser } = useSelector(state => state.auth);
    const [country, setCountry] = useState('BR');
    const [currentcountry, setcurrentcountry] = useState({});
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null)
    const [isValidDate, setIsValidDate] = useState(true)
    const [complement, setComplement] = useState('');
    const [number, setNumber] = useState(null);
    const [address, setAddress] = useState(null);
    const [cep, setCep] = useState('');
    const [canNext, setCanNext] = useState(false);
    
    const [stateID, setStateID] = useState(null);
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        if(currentUser){
            // setCountry(currentUser.id_country === undefined ? 'BR' : currentUser.id_country);
            setCep(currentUser.cd_cep === undefined ? '' : currentUser.cd_cep);
            setAddress(currentUser.nm_address === undefined ? '' : currentUser.nm_address);
            setNumber(currentUser.nm_number === undefined ? '' : currentUser.nm_number);
            setComplement(currentUser.nm_complement === undefined ? '' : currentUser.nm_complement);
            setState(currentUser.nm_state === undefined ? null: currentUser.nm_state);
            setStateID(currentUser.nm_state === undefined ? null: currentUser.nm_state);
            setCity(currentUser.nm_city === undefined ? null : currentUser.nm_city)
        }
    }, [currentUser]);

    const [checkChanged, setCheckChanged] = useState(false);
    useEffect(()=>{
        if (address&&number&&stateID&&city) {
            if(currentcountry.cd_isoalpha2 !== country) {
                setCheckChanged(true);
                return;
            }
            else{
                setCheckChanged(false);
            }

            if(cep !== currentUser.cd_cep) {
                setCheckChanged(true);
                return;
            }
            else{
                setCheckChanged(false);
            }

            if(address !== currentUser.nm_address) {
                setCheckChanged(true);
                return;
            }
            else{
                setCheckChanged(false);
            }

            if(number !== currentUser.nm_number) {
                setCheckChanged(true);
                return;
            }
            else{
                setCheckChanged(false);
            }

            if(complement !== currentUser.nm_complement) {
                setCheckChanged(true);
                return;
            }
            else{
                setCheckChanged(false);
            }

            if(stateID !== currentUser.nm_state) {
                setCheckChanged(true);
                return;
            }
            else{
                setCheckChanged(false);
            }

            if(city !== currentUser.nm_city) {
                setCheckChanged(true);
                return;
            }        
            else{
                setCheckChanged(false);
            }
        }        
        else{
            setCheckChanged(false);
        }

    }, [country,currentcountry, cep, address, number, complement, stateID, city])

    useEffect(()=>{
        if (city&&stateID&&cep&&address&&number) {
            //console.log(`city = ${city}`);
            //console.log(`stateID = ${stateID}`);
            //console.log(`cep = ${cep}`);
            //console.log(`address = ${address}`);
            //console.log(`number = ${number}`);
            props.setCanPage3(true)
        }
        else{
            props.setCanPage3(false)
        }
    }, [country,currentcountry, cep, address, number, complement, stateID, city])

    const { currentProfile } = useSelector(state => state.auth);
    const data ={
        id_country: country,
        cd_cep: cep,
        nm_address: address,
        nm_number: number,
        nm_complement: complement,
        nm_state: stateID+"",
        nm_city: city+"",
        ds_email: currentUser.ds_email,
    }
    const setPersonalAddress = async () => {
        props.setLoading(true)
        API.post("user/setaddress", data)
        .then((result) => {
            if (result.data.status === "success") {
                dispatch(setUser(result.data.user))
                toast.success('Atualizado')
                props.setLoading(false)
                props.setpage(3)
            }
        })
        .catch((err) => {
            console.log(err);
            props.setLoading(false)
        });
    }

    

    const saveAddress = async () => {
        const cepUrl = `https://viacep.com.br/ws/${cep}/json/`;
        fetch(cepUrl, {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                
                if (data.hasOwnProperty("erro")) {
                    setWrongCep(true)
                    setAddress(null);
                    setStateID(null);
                    setCity(null);
                } else {
                    setAddress(data.logradouro);
                    setStateID(data.uf);
                    setCity(data.localidade);
                    setWrongCep(false)
                    
                    
                }
            })
            .catch(err => {console.log(err); setWrongCep(true)});
       
    }
    const handleSave = () =>{
        saveAddress();
        saveAddress();
    }
    const [wrongCep, setWrongCep] = useState(false);

    useEffect(()=>{
        if(cep.length === 9){
            saveAddress()
        }
    },[cep])




    const onChangeCep = (e) => {
        var currentValue = e.target.value;
        if(currentValue.length>cep.length){
            const digitsOnly = currentValue.replace(/\D/g, '');
            if (currentValue.length===5) {
                const firstPart = digitsOnly.slice(0, 5);
                const secondPart = digitsOnly.slice(5, 8);
                currentValue = `${firstPart}-${secondPart}`;
            }
        }
        setCep(currentValue)
    }





    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    useEffect(() => {
        if(props.submitEvent === true){
            props.submitStateChange();
        }
        
    }, [props.submitEvent])


    useEffect(()=>{
        if(currentUser){
            props.setLoading(true)
            API.post("user/checkcountrycode", {countryID:currentUser.id_country})
            .then((result) => {
                if (result.data.status === "success") {
                    setcurrentcountry(result.data.countrycode)
                    setCountry(result.data.countrycode.cd_isoalpha2)
                    props.setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                props.setLoading(false)
            });
        }
    },[currentUser])

    return (
    <div style={{ width:'100%', height:'100%'  }}>
        <div 
            // className="mb:w-full sm:w-[95%]" 
            style={
                isMobile?
                {
                // display:'flex', 
                // flexDirection:'column',


                width:'280px',
                maxHeight:'350px',
                overflow:'auto',
                
                
                }
                :
                {                
                width:'100%',
                maxHeight:'100%'
                ,
}
            }
        >
            <div className='w-full flex mb-[25px]'>
            </div>
            <div className='w-full sm:flex mb-[25px] mb:block'>
                <div className='mb:w-full sm:w-[42%] mr-[25px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title}>País origem</div>
                    <div className='w-full'>
                        <ReactFlagsSelect
                            className='w-full'
                            selected={country}
                            onSelect={(code) => {setCountry(code)}}
                        />
                    </div>
                </div>
                <div className='mb:w-[50%] sm:w-[20%] mb:mt-[20px] sm:mt-[0px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title } >CEP</div>
                    <div className='w-full'>
                    <TextField
                            placeholder='_____-___'
                            value={cep}
                            className="w-full"
                            onChange={onChangeCep}
                            InputProps={{
                                className: style.content,
                            }}
                            error={wrongCep}
                            helperText={wrongCep ? "Número do CEP errado!"  : ''}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>

                    </div>
                </div>
            </div>
            <div className='w-full sm:flex mb:block mb-[25px]'>
                <div className='mb:w-full sm:w-[42%] mr-[25px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title}>Endereço</div>
                    <div>
                        <TextField
                            placeholder=''
                            value={address}
                            onChange={(e)=>setAddress(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className='mb:w-[50%] sm:w-[20%] mr-[25px] mb:mt-[20px] sm:mt-[0px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title}>Número</div>
                    <div>
                        <TextField
                            placeholder=''
                            value={number}
                            onChange={(e)=>setNumber(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>

                    </div>
                </div>
                <div className='mb:w-[50%] sm:w-[20%]  mb:mt-[20px] sm:mt-[0px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title}>Complemento</div>
                    <div>
                        <TextField
                            value={complement}
                            onChange={(e)=>setComplement(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"
                            
                            />
                    </div>
                </div>
            </div>
            <div className='w-full sm:flex mb:block mb-[25px]'>
                <div className='mb:w-full sm:w-[42%] mr-[25px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title}>Estado</div>
                    <div>
                        {
                            country === 'BR'?
                            <TextField
                            value={stateID}
                            onChange={(e)=>setStateID(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>:
                            <GeoLocation
                            locationTitle="State"
                            onChange={setStateID}
                            value={state}
                            geoId={country}
                            country={country}
                        />
                        }

                    </div>
                </div>
                <div className='mb:w-full sm:w-[calc(40%+25px)] mr-[25px] mb:mt-[20px] sm:mt-[0px]' style={isMobile?{width:'95%'}:{}}>
                    <div className={style.title}>Cidade</div>
                    <div>                        
                        {
                            country === 'BR'?
                            <TextField
                            value={city}
                            onChange={(e)=>setCity(e.target.value)}
                            className="w-full"
                            InputProps={{
                                className: style.content,
                            }}
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic"
                            variant="outlined"/>:
                            <GeoLocation
                                locationTitle="State"
                                onChange={setCity}
                                value={city}    
                                geoId={stateID}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
        
        <div style={{display:'flex',gap:'10px', padding:'0px 30px 0px 0px' }} >
            <div style={{  width:'100%', height:'1px' }}/>

        {/* {!canNext &&
        <> */}

            {
            isMobile?


            <div style={{  display:'flex', }}>
                    <div style={{  width:'100%', height:'1px' }}>
                    </div>
                    <div style={{  margin:'10px 0px 0px auto' }}>
                        <HeaderBTNStyle disabled={!checkChanged} onClick={setPersonalAddress} sx={!checkChanged? {backgroundColor: '#C6C6C6', cursor: 'not-allowed', width:'80px', }:{width:'80px',}}>Salvar</HeaderBTNStyle>
                </div>
            </div>
            :
            <div style={{  display:'flex'}}>
                    <div style={{  width:'100%', height:'1px' }}>
                    </div>
                    <div style={{  margin:'10px 0px 0px auto' }}>
                        <HeaderBTNStyle disabled={!checkChanged} onClick={setPersonalAddress} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Salvar</HeaderBTNStyle>
                </div>
            </div>
            }
        {/* </>
        } */}

        {props.canPage3 && !checkChanged &&
        <>
            {
            isMobile?


            <div style={{  display:'flex'}}>
                    <div style={{  width:'100%', height:'1px' }}>
                    </div>
                    <div style={{  margin:'10px 0px 0px auto' }}>
                        <HeaderBTNStyle  onClick={()=>{props.setpage(3)}} sx={{ width:'80px', }}>Próximo</HeaderBTNStyle>
                </div>
            </div>
            :
            <div style={{  display:'flex'}}>
                    <div style={{  width:'100%', height:'1px' }}>
                    </div>
                    <div style={{  margin:'10px 0px 0px auto' }}>
                        <HeaderBTNStyle onClick={()=>{props.setpage(3)}} sx={ {}}>Próximo</HeaderBTNStyle>
                </div>
            </div>
            }
        </>
        }
    </div>


    </div>
    )
}