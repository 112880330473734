import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import FilterDropDown from '../DropDown/FilterDropDown.js'
import SearchInput from '../Input/IconInput'
import SearchIcon from '../../assets/icons/Search.svg'
import DownloadIcon from '../../assets/icons/download.svg'
import UsersIcon from '../../assets/icons/users.svg'
import MoreImg from '../../assets/image/More.png'
import UpImage from '../../assets/icons/Polygon_up.svg'
import OutsideAlerter from '../../hooks/OutSideAlerter';
import TableSortLabel from '@mui/material/TableSortLabel';
import SortIcon from '../../assets/icons/sorticon.png';
import DecIcon from '../../assets/icons/decsort.png';
import AscIcon from '../../assets/icons/ascsort.png';
import { setCurrentOrder } from '../../redux/actions/order.action.js';
import { useDispatch } from 'react-redux';
import {
    Box,
    Stack,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';

function MoreIcon(props) {
    return (
        <SvgIcon {...props}>
            {/* <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /> */}
            <path fill="currentColor" d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z"></path>
        </SvgIcon>
    );
}

function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    // const handleFirstPageButtonClick = (event) => {
    //     onPageChange(event, 0);
    // };
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    // const handleLastPageButtonClick = (event) => {
    //     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    // };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            {/* <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton> */}
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            {/* <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton> */}
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


// .sort((a, b) => (a.name < b.name ? -1 : 1))

// iconflag, name, docType, status, updatetime, flag, publicdate, proposal
const NameStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
}));

const DocStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 14,
    color: '#A9A9A9',
}));

const DotBox = styled('div')(({ theme }) => ({
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    margin: '0px 5.5px'
}));

const StausStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
}));

const UpdateStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 10,
    color: '#A9A9A9'
}));

const PublicStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
}));

const ProposalStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 12,
}));

const GrayShadow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
}));

const ViewMoreStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    color: '#C6C6C6',
    '&:hover': {
        color: '#17686E',
        cursor: 'pointer'
    }
}));



export default function CustomPaginationActionsTable(props) {
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState(props.rows);
    const [flag, setFlag] = React.useState(new Array(props.rows.length).fill(null))
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

 
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    React.useLayoutEffect(() => {
        if(props.rows){
            setRows(props.rows);
        }
    }, [props])
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgba(23, 104, 110, 0.11)',
            color: '#313131',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 14,
            padding: '15px 0px',
            textTransform: 'uppercase'
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '11px 0px 10px'
        },
    }));

    const navigate = useNavigate();
    const [keyword, setKeyword] = React.useState('');
    const filterWithmenu = () => {
        setPage(0);
        let temp = [];
        props.rows.map((item) => {
            const str = JSON.stringify(item).toUpperCase();
            if(str.indexOf(keyword.toUpperCase()) > -1){
                if(filtermenu[1].data[0].checked && item.iconflag === 2)
                {
                    temp.push(item);
                }
                if(filtermenu[1].data[1].checked && item.iconflag === 1)
                {
                    temp.push(item);
                }
                if(!filtermenu[1].data[0].checked && !filtermenu[1].data[1].checked )
                {
                    temp.push(item);
                }
            }
        })
        let temp1 = [];
        temp.map((item) => {
            const date = new Date();
            const curdate = new Date(item.createdAt);
            const yy = curdate.getFullYear() % 100;
            const mm = curdate.getMonth() + 1; 
            const dd = curdate.getDate();
            const yy1 = date.getFullYear() % 100;
            const mm1 = date.getMonth() + 1; 
            const dd1 = date.getDate();


            if(filtermenu[2].data[0].checked && yy===yy1 && mm===mm1 && dd===dd1 )
            {
                temp1.push(item);
            }
            if(!filtermenu[2].data[2].checked && filtermenu[2].data[1].checked && yy===yy1 && mm===mm1)
            {
                temp1.push(item);
            }
            
            if(filtermenu[2].data[2].checked && yy===yy1)
            {
                temp1.push(item);
            }
            if(!filtermenu[2].data[0].checked && !filtermenu[2].data[1].checked && !filtermenu[2].data[2].checked )
            {
                temp1.push(item);
            }
        })

        let temp2 = [];
        temp1.map((item) => {
            //console.log(item.flagstatus);
            if(filtermenu[0].data[0].submenu[1].checked && item.flagstatus === 'Aguardando propostas')
            {
                temp2.push(item);
            }
            if(filtermenu[0].data[0].submenu[2].checked && item.flagstatus === 'Proposta disponível')
            {
                temp2.push(item);
            }
            if(filtermenu[0].data[0].submenu[3].checked && item.flagstatus === 'Em negociação')
            {
                temp2.push(item);
            }

            if(filtermenu[0].data[1].submenu[1].checked && item.flagstatus === 'Pesquisando')
            {
                temp2.push(item);
            }
            if(filtermenu[0].data[1].submenu[2].checked && item.flagstatus === 'Aguardando análise')
            {
                temp2.push(item);
            }

            if(filtermenu[0].data[2].submenu[1].checked && item.flagstatus === 'Avaliação disponível')
            {
                temp2.push(item);
            }
            if(filtermenu[0].data[2].submenu[2].checked && item.flagstatus === 'Concluído')
            {
                temp2.push(item);
            }

            if(filtermenu[0].data[3].submenu[0].checked && item.flagstatus === 'Cancelado')
            {
                temp2.push(item);
            }
            if(
                (!filtermenu[0].data[0].submenu[1].checked && !filtermenu[0].data[0].submenu[2].checked && !filtermenu[0].data[0].submenu[3].checked) &&
                (!filtermenu[0].data[1].submenu[1].checked && !filtermenu[0].data[1].submenu[2].checked) &&
                (!filtermenu[0].data[2].submenu[1].checked && !filtermenu[0].data[2].submenu[2].checked) &&
                (!filtermenu[0].data[3].submenu[0].checked) 
            )
            {
                temp2.push(item);
            }
        })
        setRows(temp2)
    }
    const [filtermenu, setFiltermenu]=React.useState([
        {
            name:"Status do pedido",
            data:[
                {menu: 'Em aberto ', nested: true, submenu:[
                    {menu: 'Todos', nested: false, checked : false},
                    {menu: 'Aguardando propostas', nested: false, checked:false},
                    {menu: 'Proposta disponível', nested: false, checked:false},
                    {menu: 'Em negociação', nested: false, checked:false},
                ]},
                {menu: 'Em andamento', nested: true, submenu:[
                    {menu: 'Todos', nested: false, checked : false},
                    {menu: 'Pesquisando', nested: false, checked:false},
                    {menu: 'Aguardando análise', nested: false, checked:false},
                ]},
                {menu: 'Concluído', nested: true, submenu:[
                    {menu: 'Todos', nested: false, checked : false},
                    {menu: 'Avaliação disponível', nested: false, checked:false},
                    {menu: 'Concluído', nested: false, checked:false},
                ]},
                {menu: 'Cancelado', nested: true, submenu:[
                    {menu: 'Cancelado', nested: false, checked:false},
                ]},
            ]
        },
        {
            name:"Tipo de pedido",
            data:[
                {menu: 'Importado', nested: false, checked : false},
                {menu: 'Criada', nested: false, checked : false},
            ]
        },
        {
            name:"Data de criação",
            data:[
                {menu: 'Hoje', nested: false, checked : false},
                {menu: 'Este mês', nested: false, checked : false},
                {menu: 'Este ano', nested: false, checked : false},
            ]
        }
    ]); 

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Nome do antepassado');
    const [direction, setdirection] = React.useState(false) //false is 'desc' \/ true is 'asc'

    
    const {currentOrder} = useSelector(state => state.order)
    
    
    const handleRequestSort = (property) => {
        // const isAsc = orderBy === property && order === 'asc';
        // setOrder(isAsc ? 'desc' : 'asc');
        var aux = []
        if(property==='name'){
            if(order === 'asc'){
                aux = rows.sort((a, b) => a.name.localeCompare(b.name));
            }
            else if(order === 'desc'){
                aux = rows.sort((a, b) => b.name.localeCompare(a.name));
            }
        }
        else if(property==='status'){
            if(order === 'asc'){
                aux = rows.sort((a, b) => a.status.localeCompare(b.status));
            }
            else if(order === 'desc'){
                aux = rows.sort((a, b) => b.status.localeCompare(a.status));
            }
        }
        else if(property==='flagstatus'){
            if(order === 'asc'){
                aux = rows.sort((a, b) => a.flagstatus.localeCompare(b.flagstatus));
            }
            else if(order === 'desc'){
                aux = rows.sort((a, b) => b.flagstatus.localeCompare(a.flagstatus));
            }
        }
        else if(property==='createdAt'){
            if(order === 'asc'){
                aux = rows.sort((a, b) => a.publicdate.localeCompare(b.publicdate));
            }
            else if(order === 'desc'){
                aux = rows.sort((a, b) => b.publicdate.localeCompare(a.publicdate));
            }
        }

        setRows(aux)
        
        setOrderBy(property);
        if(order==='asc'){
            setOrder('desc')
        }
        else if(order ==='desc'){
            setOrder('asc')
        }

      };




    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const getComparator = (order, orderBy) => {
        return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
        return -1;
        }
        if (b[orderBy] > a[orderBy]) {
        return 1;
        }
        return 0;
    }



    return (
        <TableContainer component={Paper} className="overflow-visible !overflow-x-visible">
            <Table sx={{ minWidth: 500, border: '1px solid #E2E4E5', borderRadius: '5px' }} aria-label="custom pagination table">
                <TableHead>
                    <Stack direction='row' sx={{ padding: '15px 0px 15px 18px' }} >
                        <FilterDropDown filtermenu={filtermenu} setFiltermenu={setFiltermenu} filterWithmenu={filterWithmenu}/>
                        <Box sx={{ width: '20px' }} />
                        <SearchInput IconSrc={SearchIcon} holder={'Pesquise'} filterWithkeyword={filterWithmenu} keyword={keyword} setKeyword={setKeyword}/>
                    </Stack>
                    <TableRow>
                        <StyledTableCell align="center" sortDirection={orderBy === "name" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "name"}
                                direction={orderBy === "name" ? order : 'asc'}
                                onClick={()=>handleRequestSort("name")}
                                //  IconComponent={()=> <Box component='img' src={SortIcon}/>}
                                // sx={{
                                //     '& .MuiTableSortLabel-iconDirectionDesc':{
                                //         background: `url(${DecIcon})  no-repeat !important`,
                                //         width: '16px',
                                //         height: '16px',
                                //         '& path':{
                                //             display: 'none'
                                //         }
                                //     },
                                //     '& .MuiTableSortLabel-iconDirectionAsc':{
                                //         width: '16px',
                                //         height: '16px',
                                //         background: `url(${AscIcon})  no-repeat !important`,
                                //         '& path':{
                                //             display: 'none'
                                //         }
                                //     },
                                //     '& .MuiTableSortLabel-icon':{
                                //         width: '16px',
                                //         height: '16px',
                                //         background: `url(${SortIcon}) no-repeat`,
                    
                                //         '& path':{
                                //             display: 'none'
                                //         }
                                //     }
                                // }}
                                // IconComponent={()=> {
                                // return <div className="w-[20px] h-[20px]" style={{backgroundImage: `url(${SortIcon})`}}></div>}}
                                >
                                Nome do antepassado
                                {orderBy === "name" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>

                        </StyledTableCell>
                        <StyledTableCell align="left" sortDirection={orderBy === "status" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "status"}
                                direction={orderBy === "status" ? order : 'asc'}
                                onClick={()=>handleRequestSort("status")}
                                >
                                Status do pedido
                                {orderBy === "status" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="left" sortDirection={orderBy === "flagstatus" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "flagstatus"}
                                direction={orderBy === "flagstatus" ? order : 'asc'}
                                onClick={()=>handleRequestSort("flagstatus")}
                                >
                                Flag do status
                                {orderBy === "flagstatus" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="left" sortDirection={orderBy === "createdAt" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "createdAt"}
                                direction={orderBy === "createdAt" ? order : 'asc'}
                                onClick={()=>handleRequestSort("createdAt")}
                                >
                                Publicado
                                {orderBy === "createdAt" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(
                        rowsPerPage > 0
                        ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row, index) => (
                        <TableRow key={row.id}>
                            <StyledTableCell component="th" scope='row' style={{ width: 360 }}>
                                <Stack direction='row'>
                                    {row.iconflag == 1 &&
                                        <Tooltip title='Pedido criado'>
                                            <Box component={'img'} width={15} height={15} src={UsersIcon} className="ml-[17px] mt-[13px] mr-[28px]"  />
                                        </Tooltip>
                                    }
                                    {row.iconflag == 2 &&
                                        <Tooltip title='Pedido importado'>
                                            <Box component={'img'} width={15} height={15} src={DownloadIcon} className="ml-[17px] mt-[13px] mr-[28px]" />
                                        </Tooltip>
                                    }
                                    <Box>
                                        <NameStyle>{row.name}</NameStyle>
                                        <DocStyle>{row.docType}</DocStyle>
                                    </Box>
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 260 }} align="left">
                                <Stack direction='row' alignItems='center'>
                                    <GrayShadow sx={{ pr: '15px' }}>
                                        <DotBox sx={{
                                            bgcolor: row.flag == 0 ? '#E2BD7B' : row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? '#17686E' : '#BA483A'
                                        }} />
                                        <StausStyle sx={{
                                            color: row.flag == 0 ? '#E2BD7B' : row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? '#17686E' : '#BA483A'
                                        }}>
                                            {row.status}
                                        </StausStyle>
                                    </GrayShadow>
                                </Stack>
                                <UpdateStyle>{row.updatetime}</UpdateStyle>
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 160 }} align="left">
                                <Stack direction='row' alignItems='center'>
                                    <GrayShadow sx={{ pr: '15px' }}>
                                        <DotBox sx={{
                                            bgcolor: row.flag == 0 ? '#E2BD7B' : row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? '#17686E' : '#BA483A'
                                        }} />
                                        <StausStyle sx={{
                                            color: row.flag == 0 ? '#E2BD7B' : row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? '#17686E' : '#BA483A'
                                        }}>
                                            {row.flagstatus}
                                        </StausStyle>
                                    </GrayShadow>
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 260 }} align="left">
                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                    <Box>
                                        <PublicStyle>{row.publicdate}</PublicStyle>
                                        {row.iconflag == 2 ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                                <b>-</b>
                                            </div>
                                            ) : (
                                                <ProposalStyle style={{ textAlign: 'center' }}>
                                                    <b>{row.proposal}</b>&nbsp;Propostas
                                                </ProposalStyle>
                                            )}
                                    </Box>

                                    <ViewMoreStyle onClick={() => {navigate(`/orderdetail/${row.id}`);window.scrollTo(0, 0);}}>Ver mais</ViewMoreStyle>
                                    <div className="relative">
                                        <Box component={'img'} src={MoreImg} className=" !h-[20px] mr-[37px] text-[#C6C6C6] hover:text-[#17686E] cursor-pointer pr-[10px] pl-[10px]" onClick={() => {const temp = [...flag];temp[index] = !temp[index]; setFlag(temp)}} />
                                        <div className='absolute right-[8px] top-[30px] z-10'>
                                        {
                                            flag[index] &&
                                            <OutsideAlerter event={() => {const temp = [...flag];temp[index] = false; setFlag(temp)}}>
                                                <div className="drop-shadow  xl:ml-[0px]">
                                                    <Box component={'img'} src={UpImage} className="ml-[200px] z-10"/>
                                                    <div className="bg-[#FFFFFF] rounded-[5px] w-[250px] mt-[-2px] z-110 flex flex-col justify-around">

                                                        {!row.tp_order &&
                                                        <>
                                                        {row.cd_orderstatus===0 && row.needBoost &&
                                                            <div className=' cursor-pointer h-[40px]  hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {dispatch(setCurrentOrder(props.allorders.find(single=>single._id===row.id)));navigate('/boostorder')}}>
                                                                <div className='h-full flex justify-center items-center'>
                                                                    <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Turbinar pedido</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className=' cursor-pointer h-[40px]  hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate(`/orderdetail/${row.id}`, {state:{initial: 0}})}}>
                                                            <div className='h-full flex justify-center items-center'>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Ver todas as propostas</div>
                                                            </div>
                                                        </div>
                                                        </>
                                                        }
                                                        <div className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate(`/orderdetail/${row.id}`, {state:{initial: 1}})}}>
                                                        <div className='h-full flex justify-center items-center'>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Ver Pedido</div>
                                                            </div>
                                                        </div>         
                                                        {!row.tp_order &&

                                                        <div  className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate(`/orderdetail/${row.id}`, {state:{initial: 2}})}}>
                                                        <div className=' h-full flex justify-center items-center'>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Compartilhar pedido</div>
                                                            </div>
                                                        </div> 
                                                        }
                                                            {row.tp_order && !row.ic_userreviewed &&

                                                            <div  className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate(`/feedback/researcher/${row.id_researcher}/${row.id}`, )}}>
                                                            <div className=' h-full flex justify-center items-center'>
                                                                    <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Avaliar pesquisador</div>
                                                                </div>
                                                            </div> 
                                                            }

                                                        {/* <div className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate(`/orderdetail/${row.id}`, {state:{initial: 3}})}}>
                                                        <div className='h-full flex justify-center items-center'>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Convidar pesquisador</div>
                                                            </div> 
                                                        </div>                         */}
                                                    </div>
                                                </div>
                                            </OutsideAlerter>
                                        }
                                        </div>
                                    </div>
                                </Stack>
                            </StyledTableCell>
                        </TableRow>
                    ))}

                    {/* {emptyRows > 0 && (
                        <TableRow style={{ height: 13 * emptyRows }}>
                            <TableCell colSpan={5} />
                        </TableRow>
                    )} */}
                </TableBody>
                <TableFooter sx={{ bgcolor: 'rgba(23, 104, 110, 0.11)', }} >
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={5}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage='Linhas por página:'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={
                                ({ from, to, count }) => {
                                  return '' + from + '-' + to + ' de ' + count
                                }
                              }
                              className="rounder-[5px] w-[100%] border border-[1px]"
                  
                            sx={{ 
                                '.MuiTablePagination-selectLabel' : {fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600},
                                '.MuiNativeSelect-select' : { fontWeight: 600},
                                '.MuiTablePagination-displayedRows' : {fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600}
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}