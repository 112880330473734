/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import VectorImg from '../../assets/image/Vector.png'
import API from '../../redux/API';
import { toast } from 'react-toast'; 

import { useNavigate } from 'react-router-dom';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    border: '1px solid #17686E',
    borderRadius: '5px',
    backgroundColor: '#fff',
    fontSize: '14px',
    width: '179px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px'
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: '15px',
    //     padding: '10px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '17px',
    //     padding: '15px',
    // },
}));

export default () => {
    const { currentUser } = useSelector(state => state.auth);
    const navigate = useNavigate()
    const [userFees,setuserFees]=useState(0)
    useEffect(()=>{
        API.post('user/gatplaninfo',{userID:currentUser._id})
        .then((res)=>{
            setuserFees(res.data.userFees)
            if (res.data.message) {
                toast.success(res.data.message)
            }
        })
        .catch((error)=>{
            toast.error(error.message?error.message:'Erro ao atualizar dados do plano')
            console.log(error);
            navigate('/account')
        })
    },[])

    return (
        <div className='box-border bg-[#17686E] bg-opacity-10 rounded-[5px] w-[100%] h-[100%]' style={{padding:'10px'}}>
            <div className='h-[28px] font-[Poppins] text-[14px] text-center leading-[21px] text-[#313131] pt-[20px]'>
                Saldo disponível
            </div>
            <div className='h-[28px] font-[Poppins] text-[14px] text-center leading-[21px] text-[#313131] pt-[10px]'>
                para saque
            </div>
            <div className='flex h-[28px] mt-[20px] items-center' >
                <Box component={'img'} src={VectorImg} className='w-[13px] h-[9.75px] ml-[8px]' width={'13px'} height={'9.75px'} />
                <span onClick={()=>{navigate('/account', {state:{initial: 8}})}} className='ml-[6px] font-[Poppins] underline text-[10px] font-light leading-[15px] cursor-pointer'>Configure aqui</span>   
                <span className='ml-[6px] font-[Poppins] text-[10px] font-light leading-[15px]'>sua conta bancária</span>   
            </div>
            <div className='flex justify-between ml-[8px] mr-[9px] mt-[25px]'>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                    {/* Data disponibilidade: */}
                </div>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-normal">
                {/* {currentUser.dt_withdrawable === undefined ? 'N/A' :new Date(currentUser.dt_withdrawable).toLocaleDateString('en-GB')} */}
                </div>
            </div>
            <div className='flex justify-between ml-[8px] mr-[9px] mt-[9px]'>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                    Método de pagamento: 
                </div>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-normal">
                {currentUser.ds_paymentmethod}
                </div>
            </div>
            <div className='flex justify-between ml-[8px] mr-[9px] mt-[9px]'>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                Saldo disponível
                </div>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-normal">
                    R$ {userFees && currentUser.vl_withdrawal?Number.parseFloat(currentUser.vl_withdrawal * ((userFees/100)+1)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.'):'00,00'}
                </div>
            </div>
            <div className='flex justify-between ml-[8px] mr-[9px] mt-[9px]'>
                <div className="font-[Poppins] text-[10px] leading-[18px] font-light">
                    Taxa Docstree ({userFees?userFees:'-'}%):
                </div>
                <div className="font-[Poppins] text-[10px] leading-[18px] font-normal">
                    R$ {userFees?Number.parseFloat(currentUser.vl_withdrawal * (userFees/100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.'):'00,00'}
                </div>
            </div>
            <div className='flex justify-between ml-[8px] mr-[9px] mt-[9px]'>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-semibold">
                    Total para saque:
                </div>
                <div className="font-[Poppins] text-[12px] leading-[18px] font-semibold">
                    R$ {Number.parseFloat(currentUser.vl_withdrawal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}
                </div>
            </div>
            <div className='text-center' style={{marginTop:'10px', marginBottom:'10px'}}>
                <HeaderBTNStyle onClick={()=>{navigate('/account', {state:{initial: 8}})}}>Detalhes</HeaderBTNStyle>
            </div>
            
        </div>
    )
}