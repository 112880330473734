import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DatePickerIcon from '../../assets/icons/datepickericon.svg';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import ReactFlagsSelect from "react-flags-select";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InformationIcon from '../../assets/icons/informationicon.svg';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Custom_Accordion from '../../components/Accordion';
import './request.css'
import {
  Grid,
  InputBase,
  Box,
  TextareaAutosize,
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 600,
    marginTop: '11px',
    lineHeight: '21px',
}));

const Box_right_wrapper = styled('div')(({ theme }) => ({
    paddingLeft: '0px',
    [theme.breakpoints.up('md')]: {
        paddingLeft: '20px',
    }
}));

const TitleStyle_header = styled('div')(({ theme }) => ({
    fontSize: '14px',
    color: '#313131',
    fontWeight: 600,
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '0px',
    }
}));


export default function ControlledAccordions(props) {
    
    const navigate = useNavigate();
    const { width } = useWindowSize();

    const data = props.data
    
    const flag = props.flag
    const country = props.country
    const panelName = props.panelName
    const documentTypeList = props.documentTypeList
    const documentType = props.documentType
    const nm_documentlocation = props.nm_documentlocation
    const ds_familysearchprofile = props.ds_familysearchprofile
    const fullName = props.fullName
    const alterName = props.alterName
    const alterTName = props.alterTName
    const isPublic = props.isPublic
    const comment = props.comment
    const life_event_dates = props.life_event_dates
    const setPanelName = props.setPanelName
    const setFlag = props.setFlag
    const setFullName = props.setFullName
    const setlife_event_dates = props.setlife_event_dates
    const commentChange = props.commentChange
    const setCountry = props.setCountry
    const family_members = props.family_members
    const loading = props.loading







//   const [flag, setFlag] = React.useState(true)
// //   const [country, setCountry] = useState('BR');
//   const [country, setCountry] = useState('');

//   const [panelName, setPanelName] = useState("Mais detalhes")
//   const documentTypeList = ['Nascimento/Batismo', 'Casamento', 'Óbito', 'Imigração', 'Outros'];
//   const [documentType, setDocumentType] = useState('');
//   const [nm_documentlocation, setNm_documentlocation] = useState('')
//   const [ds_familysearchprofile, setDs_familysearchprofile] = useState('')
  

//   const [fullName, setFullName] = useState('');

// //   const [alterName, setAlterName] = useState([]);
// const alterName = data.nm_ancestoralternative

// //   const [alterTName, setAlterTName] = useState("Maria José da Silva\nMaria José\nMaria Jose\n");
//   const [alterTName, setAlterTName] = useState("");

//   const [isPublic, setPublic] = useState(true);
//   const [comment, setComment] = useState('ABCDE ABCDE ABCDE ...');
//   const commentChange = (event) => {
//       setComment(event.target.value);
//   }
//   const [life_event_dates, setlife_event_dates] = useState({
//     baptism: {
//         dt_baptism: null,
//         dt_baptismalternativefrom: null,
//         dt_baptismalternativeto: null,
//         ds_baptismlocation: ""
//     },
//     birth: {
//         dt_birth: null,
//         dt_birthalternativefrom: null,
//         dt_birthalternativeto: null,
//         dt_birthlocation: "",
//     },
//     marriage: {
//         dt_marriage: null,
//         dt_marriageIternativefrom: null,
//         dt_marriagealternativeto: null,
//         ds_marriagelocation: ""
//     },
//     immigration: {
//         dt_immigration: null,
//         dt_immigrationalternativefrom: null,
//         dt_immigrationalternativeto: null,
//         dt_immigrationlocation: "",
//     },
//     death: {
//         dt_death: null,
//         dt_deathalternativefrom: null,
//         dt_deathalternativeto: null,
//         ds_deathlocation: ""
//     },
// });


  const style={
      title: "font-[Poppins] font-medium text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
      content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131]"
  }


  









  return (
    <div>
        <Accordion
          sx={{
            boxShadow: 'none',
            margin: '0px !important',
            borderBottom: '1px solid #E2E4E5',
            '& .MuiAccordionDetails-root' : {
              borderBottom: 'none !important',
            }
          }}
          expanded={flag}
        >
          <AccordionSummary
                onClick={() => {
                    setFlag(!flag);
                    setPanelName(!flag ? "Menos detalhes" : "Mais detalhes")
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel 1bh-content"}
            >
            <Typography sx={{ width: '33%', flexShrink: 0, fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400, color:'#313131'}}>
              {panelName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
                border: 'none ! important',
            }}
          >
                <div className='flex flex-col items-center'>
                    <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around mt-[0px]">
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                                <div className='w-full'>
                                    <div className={style.title}>Qual país de origem desse documento? <span className='text-[#DE2B2B]'>*</span></div>
                                    <div>
                                        <ReactFlagsSelect
                                            className='w-full'
                                            selected={country}
                                            disabled
                                            // onSelect={(code) => {setCountry(code)}}
                                        />
                                    </div>
                                </div>
                        </div>
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>Local de origem do documento</div>
                                <div>
                                    <TextField
                                        autoComplete="email"
                                        type="text"

                                        className="w-full h-[44px]"
                                        InputProps={{
                                            className: style.content+" !h-[44px]",
                                            type: "email",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={nm_documentlocation}
                                        />
                                        
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        !data.nm_otherdocument &&
                            
                            <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around">
                                <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                                    <div className='w-full'>
                                        <div className="flex justify-between">
                                            <div className={style.title}>Qual tipo de documento que você procura? </div>
                                        </div>
                                        <div>

                                        <TextField
                                                autoComplete="email"
                                                type="text"

                                                className="w-full h-[44px]"
                                                InputProps={{
                                                    className: style.content+" !h-[44px]",
                                                    type: "email",
                                                    requried: "true",
                                                    readOnly: true
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={documentType}
                                                />

                                            {/* <Select
                                        
                                                value={documentType}
                                                // onChange={(e) => {
                                                //     setDocumentType(e.target.value)
                                                // }}
                                                displayEmpty="displayEmpty"
                                                className="w-[100%] h-[40px]"
                                                MenuProps={{
                                                    sx:{
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(23, 104, 110, 0.11)"
                                                        },
                                                        '& .MuiMenuItem-root': {
                                                            whiteSpace: 'normal',
                                                        },
                                                    }
                                                }} 
                                                >
                                                {
                                                    //dynamic
                                                    documentTypeList.map((item) => {
                                                        return (
                                                            <MenuItem value={item}>
                                                                <p
                                                                    className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item}</p>
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb:w-[100%] sm:w-[50%] flex '>       
                                    <div className='w-full mb-[25px] '>
                                        <div className="flex justify-between">
                                            <div className={style.title}>Link do perfil do Family Search</div>
                                            <Tooltip title={<div className="text-center font-[Poppins]  pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Escolha o tipo de documento que você precise que seja procurado/entregue pelo pesquisador, para você.</div>}>
                                                <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <a href={ds_familysearchprofile} target="_blank" rel="noopener noreferrer">
                                                <TextField
                                                    autoComplete="email"
                                                    type="text"
                                                    className="w-full "
                                                    InputProps={{
                                                        className: style.content + " !h-[40px]",
                                                        type: "email",
                                                        requried: "true",
                                                        readOnly: true
                                                    }}
                                                    hiddenLabel="hiddenLabel"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={ds_familysearchprofile}
                                                    sx={{
                                                        input: {
                                                        textDecoration: 'underline',
                                                        color: "blue",
                                                        cursor: 'pointer'
                                                        }
                                                    }}
                                                />
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                    }
                    {
                        data.nm_otherdocument &&
                        <>
                            <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around mt-[0px]">
                                <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                                    <div className='w-full'>
                                        <div className="flex justify-between">
                                            <div className={style.title}>Qual tipo de documento que você procura? </div>
                                        </div>
                                        <div>

                                        <TextField
                                                autoComplete="email"
                                                type="text"

                                                className="w-full h-[44px]"
                                                InputProps={{
                                                    className: style.content+" !h-[44px]",
                                                    type: "email",
                                                    requried: "true",
                                                    readOnly: true
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={documentType}
                                                />
                                        </div>
                                    </div>
                                </div>




                                <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px]'>
                                    <div className='w-full'>
                                        <div className="flex justify-between">
                                            <div className={style.title}>Outro tipo de documento</div>
                                        </div>
                                        <div>

                                        <TextField
                                                autoComplete="email"
                                                type="text"

                                                className="w-full h-[44px]"
                                                InputProps={{
                                                    className: style.content+" !h-[44px]",
                                                    type: "email",
                                                    requried: "true",
                                                    readOnly: true
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={data.nm_otherdocument}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            
                            <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around mt-[0px]">
                                <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                                    <div className='w-full mb-[25px] '>
                                        <div className="flex justify-between">
                                            <div className={style.title}>Link do perfil do Family Search</div>
                                            <Tooltip title={<div className="text-center font-[Poppins]  pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Escolha o tipo de documento que você precise que seja procurado/entregue pelo pesquisador, para você.</div>}>
                                                <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <a href={ds_familysearchprofile} target="_blank" rel="noopener noreferrer">
                                                <TextField
                                                    autoComplete="email"
                                                    type="text"
                                                    className="w-full "
                                                    InputProps={{
                                                        className: style.content + " !h-[40px]",
                                                        type: "email",
                                                        requried: "true",
                                                        readOnly: true
                                                    }}
                                                    hiddenLabel="hiddenLabel"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={ds_familysearchprofile}
                                                    sx={{
                                                        input: {
                                                        textDecoration: 'underline',
                                                        color: "blue",
                                                        cursor: 'pointer'
                                                        }
                                                    }}                                                    
                                                    />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb:w-[100%] sm:w-[50%] flex '>       
                                    <div className='w-full mb-[25px] '></div>
                                </div>
                        </div>
                    </>

                    }
                    <div className='mb:w-full sm:w-[96%]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Tipo de solicitação do documento: <span className='text-[#DE2B2B]'>*</span>
                        </div>

{/*  Forma de recebimento do documento*/}
                        <div>

                        <FormControlLabel
                            label={
                                <div className="flex items-center">
                                    <div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131]">
                                        Pesquisa
                                    </div>
                                </div>
                            }
                            sx={{ cursor: 'default' }}
                            control={
                                <Checkbox
                                checked={data&&data.ic_searchdocument?data.ic_searchdocument:false}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                    color: '#17686E',
                                    },
                                    '&:hover': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:focus': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:active': {
                                    backgroundColor: 'transparent',
                                    },
                                    pointerEvents: 'none',
                                }}
                                />
                            }
                        />
                        <FormControlLabel
                            label={
                                <div className="flex items-center">
                                    <div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#313131]">
                                        Busca
                                    </div>
                                </div>
                            }
                            sx={{ cursor: 'default' }}
                            control={
                                <Checkbox
                                checked={data&&data.ic_fetchdocument?data.ic_fetchdocument:false}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                    color: '#17686E',
                                    },
                                    '&:hover': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:focus': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:active': {
                                    backgroundColor: 'transparent',
                                    },
                                    pointerEvents: 'none',
                                }}
                                />
                            }
                        />                        
                        </div>
                    </div>                    
                    <div className='mb:w-full sm:w-[96%]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[10px] mb-[3px]">Forma de recebimento do documento: <span className='text-[#DE2B2B]'>*</span>
                        </div>

{/*  Forma de recebimento do documento*/}
                        <div>
                        <FormControlLabel
                            label={
                                <div className="flex items-center">
                                    <div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131]">
                                        Quero documento digital
                                    </div>
                                    <Tooltip
                                        title={
                                        <div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">
                                            Para que o pesquisador tenha mais informações sobre os seus antepassados, cole o link do perfil do seu antepassado do Family Search. Lembrando que o perfil do seu antepassado é público no Family Search, caso ele seja uma pessoa falecida.
                                        </div>
                                        }
                                    >
                                        <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                    </Tooltip>
                                </div>
                            }
                            sx={{ cursor: 'default' }}
                            control={
                                <Checkbox
                                checked={data.ic_digitaldocument === true}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                    color: '#17686E',
                                    },
                                    '&:hover': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:focus': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:active': {
                                    backgroundColor: 'transparent',
                                    },
                                    pointerEvents: 'none',
                                }}
                                />
                            }
                        />
                        <FormControlLabel
                            label={
                                <div className="flex items-center">
                                    <div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#313131]">
                                        Quero documento físico
                                    </div>
                                    <Tooltip
                                        title={
                                        <div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">
                                            Para que o pesquisador tenha mais informações sobre os seus antepassados, cole o link do perfil do seu antepassado do Family Search. Lembrando que o perfil do seu antepassado é público no Family Search, caso ele seja uma pessoa falecida.
                                        </div>
                                        }
                                    >
                                        <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                    </Tooltip>
                                </div>
                            }
                            sx={{ cursor: 'default' }}
                            control={
                                <Checkbox
                                checked={data.ic_physicaldocument === true}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                    color: '#17686E',
                                    },
                                    '&:hover': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:focus': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:active': {
                                    backgroundColor: 'transparent',
                                    },
                                    pointerEvents: 'none',
                                }}
                                />
                            }
                        />
                        <FormControlLabel
                            label={
                                <div className="flex items-center">
                                    <div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#313131]">
                                        Quero documento apostilado
                                    </div>
                                    <Tooltip
                                        title={
                                        <div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">
                                            Para que o pesquisador tenha mais informações sobre os seus antepassados, cole o link do perfil do seu antepassado do Family Search. Lembrando que o perfil do seu antepassado é público no Family Search, caso ele seja uma pessoa falecida.
                                        </div>
                                        }
                                    >
                                        <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                    </Tooltip>
                                </div>
                            }
                            sx={{ cursor: 'default' }}
                            control={
                                <Checkbox
                                checked={data.ic_stampeddocument === true}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                    color: '#17686E',
                                    },
                                    '&:hover': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:focus': {
                                    backgroundColor: 'transparent',
                                    },
                                    '&:active': {
                                    backgroundColor: 'transparent',
                                    },
                                    pointerEvents: 'none',
                                }}
                                />
                            }
                        />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-[96%] ">
                        <div className="w-full flex">
                            <div className='mb:w-[96%] md:w-[50%] pt-[20px] mr-[25px] '>
                                <div className="font-[Poppins] font-medium text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Sexo <span className='text-[#DE2B2B]'>*</span>
                                </div>
{/*  Sexo*/}
                                <div>
                                <RadioGroup row={width>640? true: false} col={width > 640? false: true} >
                                        <FormControlLabel value="male" sx={{ cursor: 'default' }} control={                                        
                                            <Radio    
                                                checked={data.ds_gender === 'Masculino'}
                                                sx={{
                                                    pointerEvents: 'none',
                                                    cursor: 'none',
                                                    color: '#313131',
                                                    '&.Mui-checked': {
                                                        color: '#17686E',
                                                    }
                                                }}
                                            />
                                        } label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#7E7E7E]">Masculino</div></div>} />                                        
                                        <FormControlLabel
                                            label={
                                                <div className="flex items-center">
                                                    <div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#7E7E7E]">Feminino</div>
                                                </div>
                                            }
                                            value="female"
                                            sx={{ cursor: 'default' }}
                                            control={                                            
                                            <Radio                             
                                                sx={{
                                                    pointerEvents: 'none',
                                                    cursor: 'none',
                                                    color: '#313131',
                                                    '&.Mui-checked': {
                                                        color: '#17686E',
                                                    }
                                                }}      
                                                checked={data.ds_gender === 'Feminino'}
                                            />
                                            }
                                        />                                       
                                        <FormControlLabel value="notspecific" sx={{ cursor: 'default' }} control={
                                            <Radio                             
                                                sx={{
                                                    pointerEvents: 'none',
                                                    cursor: 'none',
                                                    color: '#313131',
                                                    '&.Mui-checked': {
                                                        color: '#17686E',
                                                    }
                                                }}   
                                                checked={data.ds_gender === 'Não especificado'}
                                            />                                        
                                        } label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#7E7E7E]">Não especificado</div></div>} />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className='mb:w-[96%] md:w-[50%] pt-[20px]'>
{/* Visibilidade da solicitação */}
                                <div className="font-[Poppins] font-medium text-[14px] leading-[148%] color-[#313131] ">Visibilidade da solicitação</div>
                                <RadioGroup row={width>640? true: false} col={width > 640? false: true}>
                                            <FormControlLabel       
                                                className='w-fit' value="male" sx={{ cursor: 'default' }} control={<Radio      
                                                checked={isPublic}          
                                                sx={{
                                                    pointerEvents: 'none',
                                                    color: '#313131',
                                                    '&.Mui-checked': {
                                                        color: '#17686E',
                                                    }
                                                }}
                                            />} label={<div className="flex flex-col items-start w-fit"><div className="mr-[15px] font-[Poppins] font-light text-[14px] leading-[148%] text-[#7E7E7E]">Público</div></div>} />
                                            
                                            <FormControlLabel className='w-fit' sx={{ cursor: 'default' }} value="female" control={<Radio        
                                                checked={!isPublic}   
                                                sx={{
                                                    pointerEvents: 'none',
                                                    color: '#313131',
                                                    '&.Mui-checked': {
                                                        color: '#17686E',
                                                    }
                                                }}      
                                            />} label={<div className="flex items-center w-fit"><div className="mr-[15px] font-[Poppins] font-light text-[14px] leading-[130%] text-[#7E7E7E]">Privado </div></div>} />
                                </RadioGroup>
                            </div>
                        </div>
                    
                        <div className='w-full flex'>

{/* Nome completo */}
                            <div className='mb:w-[96%] md:w-[50%] mt-[20px] mr-[25px]'>
                                <div className="font-[Poppins] font-medium text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Nome completo <span className='text-[#DE2B2B]'>*</span>
                                </div>
                                <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        readOnly
                                        value={fullName}
                                        // onChange={(e) => {setFullName(e.target.value)}}
                                        className="w-full"
                                        InputProps={{
                                            className: style.content + " !h-[40px]",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"/>
                                </div>
                            </div>

{/* Nome alternativo */}
                            <div className='mb:w-[96%] md:w-[50%] mt-[20px] '>
                                <div className="font-[Poppins] font-medium text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Nome alternativo</div>
                                <div>


                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        multiline
                                        rows={alterTName !== '' ? 3 : 1}

                                        
                                        value={
                                            alterTName !== '' ?
                                                alterName.map((val,ind) => (
                                                    ind>0?`\n${val}`:val
                                                ))
                                                :
                                                ''
                                        }
                                        


                                        // onChange={(e) => {console.log(e);setAlterTName(e.target.value)}}
                                        className="w-full"
                                        InputProps={{
                                            className: style.content,
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                        
                                        />



                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-[96%] ">

{/* Nome do conjuge */}
                    <div className="mb:w-full sm:w-[full] flex mb:flex-col sm:flex-row items-center justify-around mb:pt-[20px]">
                        <div className='mb:w-[96%] sm:w-[50%] flex mb-[25px] sm:mr-[25px] mb:mr-[0px]'>
                                <div className='w-full'>
                                    <div className={style.title}>Nome completo do cônjuge</div>
                                    <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        className="w-full"
                                        InputProps={{
                                            className: style.content + ' !h-[40px]',
                                            type: "text",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        readOnly
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                        
                                        value={family_members.nm_spouse}
                                        />
                                </div>
                                </div>
                        </div>
                        <div className='mb:w-[96%] sm:w-[50%] flex'>

                        </div>
                    </div>
                    <div className="mb:w-full sm:w-[full] flex mb:flex-col sm:flex-row items-center  justify-around ">
                        <div className='mb:w-[96%] sm:w-[50%] flex mb-[25px]  sm:mr-[25px] mb:mr-[0px]'>
                                
{/* Nome completo do pai */}
                                <div className='w-full'>
                                    <div className={style.title}>Nome completo do pai</div>
                                    <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                       className="w-full !h-[40px]"
                                        InputProps={{
                                            className: style.content + ' !h-[40px]',
                                            type: "text",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"

                                        value={family_members.nm_father}
                                        />
                                </div>
                                </div>
                        </div>
                        <div className='mb:w-[96%] sm:w-[50%] flex mb-[25px]'>

{/* Nome completo da mãe */}

                            <div className='w-full'>
                                <div className={style.title}>Nome completo da mãe</div>
                                <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        className="w-full !h-[40px]"
                                        readOnly
                                        InputProps={{
                                            className: style.content + ' !h-[40px]',
                                            type: "text",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                                                                
                                        value={family_members.nm_mother}
/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb:w-full sm:w-[full] flex mb:flex-col sm:flex-row items-center justify-around ">

{/* Nome completo de outra pessoa */}
                        <div className='mb:w-[96%] sm:w-[50%] flex mb-[25px] sm:mr-[25px] mb:mr-[0px]'>
                                <div className='w-full'>
                                    <div className={style.title}>Nome completo de outra pessoa</div>
                                    <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        readOnly
                                        className="w-full !h-[40px]"
                                        InputProps={{
                                            className: style.content + ' !h-[40px]',
                                            type: "text",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                                                                
                                        value={family_members.nm_otherperson}
/>
                                </div>
                                </div>
                        </div>

{/* Grau de familiaridade */}
                        <div className='mb:w-[96%] sm:w-[50%] flex mb-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>Grau de familiaridade</div>
                                <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        readOnly
                                        className="w-full !h-[40px]"
                                        InputProps={{
                                            className: style.content + ' !h-[40px]',
                                            type: "text",
                                            requried: "true",
                                            readOnly: true
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                                                                
                                        value={family_members.ds_otherpersonfamiliarity}
/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>







                
                <div className="flex flex-col items-center">
                    <div className='w-[96%]'>
                        <div className="font-[Poppins] font-medium text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Eventos da vida  </div>
                        <div className='w-[95%] ml-[20px]'>
                                <Custom_Accordion 
                                readOnly={true} 
                                life_event_dates={life_event_dates} 
                                setlife_event_dates={setlife_event_dates}
                                viewer={true}
                                />
                        </div>
                        <div className="mb:w-full sm:w-[100%] mb:block sm:flex justify-start mb:ml-[0px] mt-[21px]">
                        <div className='mb:w-[100%] sm:w-[100%] flex mb-[25px] mb:mr-[0px]'>
                            <div className='w-full'>
                                <div className="flex justify-between">
                                        <div className={style.title}>Deixe aqui alguma observação  (opcional)</div>
                                        <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Para que o pesquisador tenha mais informações sobre os seus antepassados, cole o link do perfil do seu antepassado do Family Search. Lembrando que o perfil do seu antepassado é público no Family Search, caso ele seja uma pessoa falecida.</div>}>
                                            <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                        </Tooltip>
                                </div>
                                <div>
                                                <TextareaAutosize
                                                id="standard-multiline-static"
                                                label="Multiline"
                                                onChange={commentChange}
                                                readOnly
                                                InputProps={{ disableUnderline: true }}
                                                placeholder="Escreva os detalhes..."
                                                defaultValue={comment}
                                                InputLabelProps={{ shrink: true }}
                                                value={comment}
                                                className="pt-[9px] pl-[13px] resize-y !min-h-[126px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                                            />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="flex flex-col items-center">

                    {
                        data.cd_orderstatus===0 &&

                        <div className='w-[96%] flex justify-end'>
                        
                             <HeaderBTNStyle1 onClick={() => {navigate(`/editorder/${data._id?data._id:data.id?data.id:null}`)}}>Editar perdido</HeaderBTNStyle1>
                        </div>
                    }
                </div>
          </AccordionDetails>
        </Accordion>


    </div>
  );
}