// Importação de bibliotecas necessárias para o funcionamento do código
/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line import/no-anonymous-default-export
import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
// import FacebookIcon1 from '../../assets/icons/facebookicon1.svg';
import _ from 'lodash';
import React, {useState, useEffect, useRef } from 'react';
import { OutlinedInput } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ForgetPassword from '../../components/modal/forgetPassword'
import { useNavigate, Navigate } from 'react-router-dom';
import { gapi } from 'gapi-script';
import { useDispatch } from 'react-redux';
import {login, setCurrentProfile} from '../../redux/actions/auth.action'
import { useSelector } from 'react-redux';
import API from '../../redux/API'
import { setUser } from '../../redux/actions/auth.action';
import { toast } from 'react-toast';
import { Link } from 'react-router-dom';
import Loading from '../../components/loading/Loading';

// Estilo personalizado para um botão usado no header da página
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default () => {
const [loading,setloading]=useState(false)  

    const style= {
        title1: "font-[Poppins] text-[60px] text-[#17686E] font-[700] leading-[90px]",
        title2: "font-[Poppins] text-[60px] text-[#17686E] font-normal leading-[90px]",
        subtitle: "font-[Poppins] text-[32px] text-[#313131] font-normal leading-[148%] mb-[15px]",
        googleTxt: "font-[Poppins] text-[14px] text-[#313131] font-normal leading-[148%]",
        facebookTxt: "font-[Poppins] text-[14px] text-[#FFFFFF] font-normal leading-[148%]",
        ouTxt: "ml-[5px] mr-[5px] font-[Poppins] text-[14px] text-[#7E7E7E] font-normal leading-[148%]",
        googleBtn: "mb-[15px] !rounded-[20px] !border-solid !border-[#C6C6C6] !border-[1px] !bg-[#FFFFFF] w-[300px] h-[40px] flex justify-center",
        facebookBtn: "mb-[15px] !rounded-[20px] !border-solid !border-[#C6C6C6] !border-[1px] !bg-[#3669B7] w-[300px] h-[40px] flex justify-center items-center",
        line: "h-[1px] w-[137px] border-solid border-[1px] border-[#17686E] bg-[#17686E]",
        content: "!w-[300px] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]",
        forgetPassword: "cursor-pointer font-[Poppins] text-[10px] text-[#313131] font-normal leading-[148%] underline",
        line1: "h-[1px] w-[300px] border-solid border-[1px] border-[#17686E] mb-[15px] bg-[#17686E]",
        noaccountTxt: "font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%]",
        registerTxt: "cursor-pointer font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%] underline",
        msgcontainer: "border-solid border-[0.5px] border-[#17686E] rounded-[7px] bg-opacity-[0.11] bg-[#17686E] mb-[15px]",
        msgTxt: "mt-[8px] ml-[9px] mr-[9px] mb-[14px] font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%] text-center"
    }
    // Importando a função "useNavigate" do react-router-dom para navegar para outra página
    const navigate = useNavigate();
    // Importando a função "useDispatch" do react-redux para executar ações no estado geral da aplicação
    const dispatch = useDispatch();

    // // Armazenando o ID do cliente do Google em uma constante
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID
    // Executando o código na primeira vez que a página é carregada
    useEffect(() => {
        // Inicializando o cliente do Google com o ID armazenado na constante "clientId"
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: 'profile'
            });
        };
        // Carregando a biblioteca do cliente do Google com autenticação
        gapi.load('client:auth2', initClient);
    });
    // Função que é executada quando o usuário faz login com sua conta do Google
    const responseGoogle = (response) => {
        // Criando um objeto com os parâmetros necessários para a requisição à API
        let requestParams = {
            token: response.tokenId,
            googleId: response.googleId
        };
        // Enviando uma requisição à API para fazer login com a conta do Google
        API.post("auth/loginwithgoogle", requestParams)
            .then((result) => {
                // Se a requisição foi bem-sucedida, atualizamos o estado geral da aplicação com o usuário logado
                if (result.data.status === "success") {
                    dispatch(setUser(result.data.data.user))
                    dispatch(setCurrentProfile(result.data.data.user.ic_profile % 2))
                    // Removemos qualquer token antigo armazenado no localStorage
                    localStorage.removeItem("token");
                    // Armazenamos o novo token de autenticação no localStorage
                    localStorage.token = result.data.data.token;
                    // Navegamos para a página de conta do usuário
                    navigate('/account')
                }
            })
            .catch((err) => {
                // Se houve algum erro, mostramos uma mensagem de erro
                console.log(err)
                toast.error(JSON.parse(err.request.response).message)
            });
    }


    // Função de callback chamada quando houver erro na autenticação do usuário com a conta Google
    const responseGoogle1 = (response) => {
        console.log('error', response);
    }

    // // Função de callback chamada quando houver sucesso na autenticação do usuário com a conta Facebook
    // const responseFacebook = (response) => {
    //     console.log(response);
    // }

    // State para controlar a abertura do modal de recuperação de senha
    const [openModal, setOpenModal] = React.useState(false);

    // State para controlar o valor do email digitado pelo usuário
    const [email, setEmail] = useState('');

    // State para controlar o valor da senha digitada pelo usuário
    const [password, setPassword] = useState('');

    // State para controlar o status do checkbox de lembrar de mim
    const [isRemembered, setRemembered] = useState(false);

    // State para controlar a visibilidade da mensagem de erro
    const [msgvisisble, setMsgVisible] = useState(false);

    // State para controlar a visibilidade do loading
    const [loadingOpen, setLoadingOpen] = useState(false);

    // Função para realizar o login do usuário
    const handleLogin = () => {
        // Definição dos parâmetros da requisição de login
        let requestParams = {
            ds_password: password,
            ds_email: email,
        };
        //console.log(requestParams);
        // Alteração do estado do loading para verdadeiro
        setLoadingOpen(true);
        // Disparo da ação de login com os parâmetros de requisição e informações para controlar o loading e a navegação
        return dispatch(login(requestParams, setLoadingOpen, navigate))
    }

    // Debounce the handleLogin function
    // const debouncedHandleLogin = _.debounce(handleLogin, 500);

    // Function that listens for keydown events on the input element
    const submitOnEnter = (event) => {
        // Check if the key pressed is 'Enter'
        if (event.key === 'Enter') {
        // Trigger the click event on the 'Sign in' button if 'Enter' is pressed
        document.getElementById("signin-button").click();
        }
    }; 

    // Seleção do usuário atual armazenado no estado da aplicação
    const {
        currentUser
    } = useSelector(state => state.auth);

    // Se o usuário estiver logado e possuir um perfil, redireciona para a página inicial
    if (currentUser && currentUser.profile) {
        return <Navigate to='/'  />
    }

    // Use effect para monitorar a mudança do usuário atual e redirecionar para a página de conta se ele estiver logado
    
    useEffect(() => {
    const has2step = localStorage.has2step 
    const needRedirect = localStorage.redirect
    if(currentUser && !has2step && !needRedirect){
        navigate('/account')
    }
    }, [currentUser])
    return (

        <div className="w-full h-[582px] flex justify-center items-center mb:mt-[50px] md:mt-[180px] mb:mb-[80px] md:mb-[180px]">
            {loadingOpen && <Loading/>}
            <ForgetPassword openModal={openModal} setOpenModal={setOpenModal} showMessage={()=>setMsgVisible(true)} />
            <div className="w-[304px] flex flex-col items-center">
                <Link to="/home" className="flex mb-[50px]">
                    <div className={style.title1}>DOCS</div>
                    <div className={style.title2}>TREE</div>
                </Link>
                <div className={style.subtitle}>Login</div> 
                {
                    msgvisisble &&
                    <div className={style.msgcontainer}>
                        <div className={style.msgTxt}>
                        Se você for cadastrado e tiver um e-mail válido, você receberá um e-mail com as instruções para alterar sua senha.
                        </div>
                    </div>
                }

                 {/* <div>
                     <FacebookLogin
                         className={style.googleBtn}
                         appId="2180693298624739" //APP ID NOT CREATED YET
                        fields="name,email,picture"
                        textButton={<div className={style.facebookTxt}>Continue com Facebook</div>}
                        cssClass={style.facebookBtn}
                        icon={<Box component="img" src={FacebookIcon1} className="mr-[14px]"/>}
                        callback={responseFacebook}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                    />
                </div> */}
                {/*
                <div>
                    <GoogleLogin
                        className={style.googleBtn}
                        clientId={clientId} //CLIENTID NOT CREATED YET
                        buttonText={<div className={style.googleTxt}>Continue com Google</div>}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle1}
                        isSignedIn={true}
                    />
                </div>
                */}
                <div className="flex justify-center items-center mb-[15px]">
                    <div className={style.line}></div>
                    {/*<div className={style.ouTxt}>Ou</div>*/}
                    <div className={style.line}></div>
                </div>

                <div className='mb-[15px]'>
                    <OutlinedInput
                        value = {email}
                        onChange={(e) => {setEmail(e.target.value)}}                        
                        InputProps={{
                            className: style.content
                        }}
                        className={style.content}
                        placeholder='E-mail*'
                        hiddenLabel="hiddenLabel"
                        id="outlined-basic1"
                        variant="outlined"
                        onKeyDown={submitOnEnter}
                    />                        
                </div>
                <div>
                    <OutlinedInput
                        value = {password}
                        onChange={(e) => {setPassword(e.target.value)}}
                        InputProps={{
                            className: style.content
                        }}
                        type="password"
                        className={style.content}
                        placeholder='Senha*'
                        hiddenLabel="hiddenLabel"
                        id="outlined-basic2"
                        variant="outlined"
                        onKeyDown={submitOnEnter}
                    />                        
                </div>
                <div className="flex w-[300px] justify-between items-center">
                    <div>
                        <FormControlLabel
                        label={
                        <div className="flex items-center"><div className="mt-[2px] font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]">Lembrar senha</div>                 
                        </div>}
                        control={<Checkbox      
                            checked ={isRemembered}  
                            onChange={(e)=>{
                                setRemembered(e.target.checked);
                            }}   
                            sx={{
                                '& .MuiSvgIcon-root':{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '3px'
                                },
                                color: '#17686E',
                                '&.Mui-checked': {
                                    color: '#17686E',
                                },

                            }}      
                        />}/> 
                    </div>
                    <div className={style.forgetPassword} onClick={()=>setOpenModal(true)}>
                        Esqueceu sua senha?
                    </div>
                </div>
                <div className="mb-[15px]">
                    <HeaderBTNStyle
                        id="signin-button"
                        onClick={()=>{handleLogin()}}
                            sx={{
                            '&:disabled' : {
                                backgroundColor: '#C6C6C6',
                                cursor: 'not-allowed'
                            }
                        }}>
                    Entrar
                    </HeaderBTNStyle>
                </div>
                <div className={style.line1}></div>
                <div className='flex justify-center'>
                    <div className={style.noaccountTxt}>Não tem uma conta?&nbsp;</div>
                    <div className={style.registerTxt} onClick={()=>{
                        window.scrollTo(0, 0);
                        navigate('/selectprofile');
                    }}>Cadastre-se</div>
                </div>
            </div>
        </div>
    )
}