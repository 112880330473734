import { Icon } from '@iconify/react';
import useWindowSize from '../../../hooks/useWindowSize'
import { styled } from '@mui/material/styles';
import { Box, Stack, Divider } from '@mui/material';
import flag_red from '../../../assets/icons/Flag1.svg'
import flag_yellow from '../../../assets/icons/Flag2.svg'

import * as React from 'react';
import SubmitModal from '../../modal'

import { IMAGE_BASE } from '../../../config/constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { setCurrentProfile } from '../../../redux/actions/auth.action';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Publications from '../../../pages/Orders';
import { toast } from 'react-toast';
import { getCalendarPickerSkeletonUtilityClass } from '@mui/x-date-pickers';

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    border: '1px solid #c6c6c6',
    borderRadius: '7px',
    padding: '19px 20px 10px 16px',
    margin: '20px 0',
    position: 'relative'
}));

const TitleStyle = styled('div')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '18px',
});

const TextStyle_name = styled('div')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: '5px',
})

const InfoStyle = styled('div')(({ theme }) => ({
    width: 'fit-content',
    backgroundColor: '#F1F1F1',
    borderRadius: '7px',
    display: 'flex',

    margin: '6px 0px 18px',
    padding: '2px 27px 1px 4px',
}));

const InfoTextStyle = styled('div')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '10px',
    padding: '0 5px',
    margin: 0,
    whiteSpace: 'nowrap',
});

const View_details_button = styled('div')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',    
    fontSize: '12px',
    padding: '0 5px',
    margin: 0,
    cursor: 'pointer',
});

const InfoSpanStyle = styled('span')({
    color: '#313131',
    fontFamily: 'Poppins',
    marginLeft: '5px',
    fontWeight: 'bold',
});

const MoreContainerStyle = styled('div')(({ theme }) => ({
    backgroundColor: '#E2E4E546',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '12px',
    overflow:'auto',

}));

const MoreTitleStyle = styled('div')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '13px',
    margin: 0
});

const InnerContainerStyle = styled('div')(({ theme }) => ({
    margin: '0 30px',
    // overflowWrap: 'break-word',

}));

const MakeProposalBTNStyle = styled('button')(({ theme }) => ({
    color: '#17686E',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',    
    fontSize: '14px',
    backgroundColor: '#fff',
    border: '1px solid #17686E',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'pointer',
}));

const MakeProposalBTNStyleDISABLED = styled('button')(({ theme }) => ({
    color: '#C6C6C6',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',    
    fontSize: '14px',
    backgroundColor: '#fff',
    border: '1px solid #C6C6C6',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'not-allowed',
}));


function handleDocumentType(singleCard){
      if (singleCard.ic_stampeddocument == true) {
         return `Digital, Físico e Apostilado`;
      } else if (singleCard.ic_physicaldocument == true) {
         return `Digital e Físico`;
      } else if (singleCard.ic_digitaldocument == true) {
         return `Digital`;
      }

}

function getTime(time) {
    var publicationTime = Date.parse(time);
    var atualTime = Date.parse(new Date());
  
    var difTime = atualTime - publicationTime;
  
    if (difTime / (1000 * 60) <= 60) {
      difTime = difTime / (1000 * 60);
      difTime = `${difTime.toFixed(0)} minutos`;
      return difTime;
    } else if (difTime / (1000 * 60 * 60) <= 24) {
      difTime = difTime / (1000 * 60 * 60);
      difTime = `${difTime.toFixed(0)} horas`;
      return difTime;
    } else if (difTime / (1000 * 60 * 60 * 24) >= 1) {
      difTime = difTime / (1000 * 60 * 60 * 24);
      difTime = `${difTime.toFixed(0)} dias`;
      return difTime;
    }
  }
  
// -------------------------------------------------------------
 
export default function CardItem(props) {

    useEffect(()=>{
        // console.log(props);
    },[props])


    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const card = props.card

    const { width } = useWindowSize()

    const [openModal, setOpenModal] = React.useState(false);

    const handleClickOpen = () => {
        if(!currentUser){
            toast.info('Você precisa estar logado para efetuar esta ação.')
            navigate('/login')
        }
        else if(currentUser.ic_profile===0 ){
            toast.info('Você precisa de uma conta de pesquisador para efetuar esta ação.')
        }

        if(currentUser.ic_currentplan===0 &&card.tp_orderexperience && card.tp_orderexperience!=='Beginner'){
            toast.info('Você precisa fazer upgrade para trabalhar nesta ordem.')
            navigate('/account', {state:{initial: 7}})
        }
        else if(currentUser.ic_currentplan===1 && card.tp_orderexperience && card.tp_orderexperience==='Assessor'){
            toast.info('Você precisa fazer upgrade para trabalhar nesta ordem.')
            navigate('/account', {state:{initial: 7}})
        }
        else{
            // alert(currentUser.ic_currentplan,card.tp_orderexperience)
            setOpenModal(true);
        }
    };

    const setSinglePublication = Publications

    const handleSingleCard = (event) => {
        const newWindow = window.open(`/order/${card._id}`, '_blank');
        if (newWindow) {
          newWindow.focus();
          newWindow.scrollTo(0, 0); // Scroll to the top
        }
        event.preventDefault(); // Prevent the default link behavior
      };
      
    const [canClick,setcanClick] = useState(true)

    useEffect(()=>{
        if(currentUser){
            const userID = currentUser.id?currentUser.id:currentUser._id
            if(card.hasProposal){
                setcanClick(false)
            }
            if(card.cd_orderstatus>0){
                setcanClick(false)
            }
            if(currentUser.ic_profile===0){
                setcanClick(false)
            }
            if(userID===card.id_customer._id){
                setcanClick(false)
            }
        }
        else{
            setcanClick(false)
        }

    },[currentUser,])


    const [isHovered, setIsHovered] = useState(false);
    const [imageName, sethovervalue] = useState('');
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (e,value) => {
        setPosition({ x: e.clientX, y: e.clientY });
        setIsHovered(true);
        sethovervalue(value)
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
    
    return (
        <RootStyle  
            sx={{
                border: Object.values(card.boosts).some(item => item.tp_boost === 'Urgency'   && item.ic_boostactive )? '2px solid #C53842' : Object.values(card.boosts).some(item => item.tp_boost === 'Hightlight'   && item.ic_boostactive) ? '2px solid #FFC229' : '1px solid #c6c6c6'
            }}
        >
            <SubmitModal openModal={openModal} setOpenModal={setOpenModal} orderID={card._id} />

            {
            card.boosts && 
                Object.values(card.boosts).some(item => item.tp_boost === 'Urgency'   && item.ic_boostactive ) && Object.values(card.boosts).some(item => item.tp_boost === 'Hightlight'   && item.ic_boostactive ) ?
                    (
                        width > 500?
                        <>
                        <Box component="img" src={flag_red} sx={{ top: '-6px', right: '85px', position: 'absolute' }} alt="urgencia-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Urgência')}} onMouseLeave={handleMouseLeave} />   
                        <Box component="img" src={flag_yellow} sx={{ top: '-6px', right: '45px', position: 'absolute' }} alt="destaque-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Destaque')}} onMouseLeave={handleMouseLeave} />   
                        </>
                            :
                        <>
                            <Box component="img" src={flag_red} sx={{ top: '-6px', right: '45px', position: 'absolute' }} alt="urgencia-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Urgência')}} onMouseLeave={handleMouseLeave}/>    
                            <Box component="img" src={flag_yellow} sx={{ top: '-6px', right: '5px', position: 'absolute' }} alt="destaque-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Destaque')}} onMouseLeave={handleMouseLeave}/>  
                        </>
                    )
                    
                    :
                    Object.values(card.boosts).some(item => item.tp_boost === 'Urgency'   && item.ic_boostactive )?
                        (
                            width > 500?
                            <>    <Box component="img" src={flag_red} sx={{ top: '-6px', right: '45px', position: 'absolute' }} alt="urgencia-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Urgência')}} onMouseLeave={handleMouseLeave}/>     </>
                                :
                            <>    <Box component="img" src={flag_red} sx={{ top: '-6px', right: '5px', position: 'absolute' }} alt="urgencia-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Urgência')}} onMouseLeave={handleMouseLeave}/>      </>
                        )
                    :
                    Object.values(card.boosts).some(item => item.tp_boost === 'Hightlight'   && item.ic_boostactive ) ?
                        (
                            width > 500?
                            <>    <Box component="img" src={flag_yellow} sx={{ top: '-6px', right: '45px', position: 'absolute' }} alt="destaque-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Destaque')}} onMouseLeave={handleMouseLeave}/>      </>
                                :
                            <>    <Box component="img" src={flag_yellow} sx={{ top: '-6px', right: '5px', position: 'absolute' }} alt="destaque-logo" onMouseEnter={(e)=>{handleMouseEnter(e,'Destaque')}} onMouseLeave={handleMouseLeave}/>       </>
                        )
                    :
                    <></> 
            }
            {isHovered && <div style={{display:'flex', position:'fixed', padding:'7px', fontWeight:'600',fontFamily:'poppins', color:'white', border:'2px', borderRadius:'5px', top: position.y, left: position.x, backgroundColor:imageName==='Destaque'?'#FFC229':imageName==='Urgência'?'#C53842':'#c6c6c6' }}>{imageName}</div>}

            <Stack direction="row">
                <TitleStyle>Nome completo: </TitleStyle>
                <TextStyle_name>{card.nm_ancestor}</TextStyle_name>
            </Stack>
            <InfoStyle>
                <InfoTextStyle>Publicado há: {getTime(card.createdAt)}</InfoTextStyle>
                <InfoTextStyle>Propostas: {card.bids.length}</InfoTextStyle>
            </InfoStyle>

            <Stack direction="row" justifyContent="space-between" sx={{ pr: '20px', mt: '6px' }}>

                <Stack direction="row">
                    <TitleStyle sx={{ fontSize: '14px' }}>Tipo do documento:</TitleStyle>
                    <TextStyle_name sx={{ fontSize: '14px' }}>{card.id_documenttype.nm_documenttype}</TextStyle_name>
                </Stack>
                {width > 710 && <Stack direction="row" alignItems="center">
                    <TitleStyle sx={{ fontSize: '14px' }}>País de pesquisa:</TitleStyle>
                    <TextStyle_name sx={{ fontSize: '14px', pr: '5px' }}>{props.showCountryName(card.nm_documentcountry, props.filterCountry).toUpperCase()}</TextStyle_name>
                    <Box sx={{ paddingLeft: '15px', display: 'flex' }}>
                        <Icon
                            icon={
                            props.showFlag(card.nm_documentcountry, props.filterCountry)
                            }
                            width="24"
                        />
                    </Box>
                </Stack>
                }

                {width > 1200 &&
                    <Stack direction="row">
                        <TitleStyle sx={{ fontSize: '14px' }}>
                            Nível exigido:
                        </TitleStyle>
                        <TextStyle_name sx={{ fontSize: '14px' }}>{card.tp_orderexperience && card.tp_orderexperience==='Beginner'?'Iniciante':card.tp_orderexperience==='Specialist'?"Especialista":card.tp_orderexperience==='Assessor'?'Assessor':''}</TextStyle_name>
                    </Stack>
                }
            </Stack>

            <Divider />

            <MoreContainerStyle>
                <MoreTitleStyle>Mais informações:</MoreTitleStyle>
                <InnerContainerStyle>
                    <Stack direction="row" justifyContent="space-between" sx={{ my: '3px' }}>
                        <InfoTextStyle sx={{ fontSize: '12px' }}>
                            Local do país de origem:
                            <InfoSpanStyle>{props.showCountryName(card.nm_documentcountry, props.filterCountry)}</InfoSpanStyle>
                        </InfoTextStyle>
                        {width > 780 && card.nm_otherdocument && 
                        <InfoTextStyle sx={{ fontSize: '12px' }}>
                            Outro tipo de documento:
                            <InfoSpanStyle>{card.nm_otherdocument}</InfoSpanStyle>
                        </InfoTextStyle>
                        }
                    </Stack>
                    <InfoTextStyle sx={{ fontSize: '12px' }}>
                        Tipo de solicitação:
                        <InfoSpanStyle>
                            {card.ic_searchdocument ? "Pesquisa" : ""}{card.ic_searchdocument && card.ic_fetchdocument ? ", " : ""}{card.ic_fetchdocument ? "Busca" : !card.ic_searchdocument && !card.ic_fetchdocument ? "-" : ""}
                        </InfoSpanStyle>
                    </InfoTextStyle>
                    {width > 780 && <InfoTextStyle sx={{ fontSize: '12px' }}>
                        Forma de recebimento do documento:
                        <InfoSpanStyle>{handleDocumentType(card)}</InfoSpanStyle>
                    </InfoTextStyle>
                    }                    
                    {/*<InfoTextStyle sx={{ fontSize: '12px', my: '3px' }}>
                        Nome completo do cônjuge:
                        <InfoSpanStyle>{card.family_members.nm_spouse}</InfoSpanStyle>
                    </InfoTextStyle>*/}
                    <InfoTextStyle sx={{ fontSize: '12px', my: '3px' }}>
                        {card.ds_familysearchprofile ? (
                            <a href={card.ds_familysearchprofile} target="_blank" rel="noopener noreferrer">
                            Link do perfil do Family Search:
                            <InfoSpanStyle style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}>
                                {card.ds_familysearchprofile}
                            </InfoSpanStyle>
                            </a>
                        ) : (
                            <span>
                                Link do perfil do Family Search: <InfoSpanStyle>-</InfoSpanStyle>
                            </span>
                        )}
                    </InfoTextStyle>
                </InnerContainerStyle>
            </MoreContainerStyle>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: '10px' }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {/* <Icon icon="healthicons:ui-user-profile-outline" color="#E2E4E5" width="27" /> */}
                    {/* <Box component={'img'} src={IMAGE_BASE+currentUser.nm_customeravatarfile} width={'41px'} height={'41px'} className="rounded-[20px]"/> */}
                    <Box component={'img'} src={`${IMAGE_BASE+`accounts/${card.id_customer._id}/`+card.id_customer.nm_customeravatarfile}`} width={'41px'} height={'41px'} className="rounded-[20px]"/>
                    <TitleStyle sx={{ fontSize: '12px' }}>{card.id_customer.nm_user}</TitleStyle>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    {/* <View_details_button onClick={handleSingleCard}>Ver mais detalhes</View_details_button>*/}
                    <a
                        href={`/order/${card._id}`}
                        onClick={handleSingleCard}
                        style={{    
                            color: '#313131',
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            padding: '0 5px',
                            margin: 0,
                            cursor: 'pointer',}}
                    >
                    Ver mais detalhes
                    </a>
                    {currentUser?
                        <>
                            {canClick?
                                <MakeProposalBTNStyle   onClick={handleClickOpen}>Fazer proposta</MakeProposalBTNStyle>
                                :
                                <MakeProposalBTNStyleDISABLED   onClick={()=>{toast.info('Você não pode fazer uma proposta para este pedido')}}>Fazer proposta</MakeProposalBTNStyleDISABLED>
                            }
                        </>
                    :
                        <>
                            <MakeProposalBTNStyleDISABLED   onClick={handleClickOpen}>Fazer proposta</MakeProposalBTNStyleDISABLED>
                        </>
                    }
                </Stack>
            </Stack>

        </RootStyle>
    )
}