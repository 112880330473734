import { styled } from '@mui/material/styles';

// components
import ReviewCard from '../../components/card/ReviewCard'

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: '20px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    marginTop: '3px',
    padding: '5px 20px 19px 12px'
}));

export default function DataCard({ data, count }) {

    return (
        <RootStyle>
            <TitleText>Opinião dos clientes ({count}) </TitleText>
            <ContainerStyle>
                { data.map((item, index) => (
                    <ReviewCard key={index} reviewData={item} />
                ))}
            </ContainerStyle>
        </RootStyle>
    )
}