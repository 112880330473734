import { styled } from '@mui/material/styles';
import React, {useState, useLayoutEffect} from 'react';
import {
    Divider,
} from '@mui/material';
// components
import ProfileClient from './profile_client'
import ProfileResearcher from './profile_researcher'
import TableInfoClient from './tableInfo_client'
import TableInfoResearcher from './tableinfo_researcher'
import AvailableBalance from './balance';
import Membership from './membership';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentProfile } from '../../redux/actions/auth.action';
import upgradePlanImg1 from '../../assets/image/upgrade_plan1.png'
import upgradePlanImg2 from '../../assets/image/upgrade_plan1.png'
import upgradePlanImg3 from '../../assets/image/upgrade_plan3.png'
import useWindowSize from '../../hooks/useWindowSize';

import Checkout from '../../components/Checkout';
import API from '../../redux/API';
import { setUser } from '../../redux/actions/auth.action';
import { toast } from 'react-toast'; 

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    padding: '0px 22px 0px 19px',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        padding: '0px 30px',    
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        padding: '0px',
    }
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#C6C6C6',
    whiteSpace: 'nowrap',
    paddingRight: '10px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        paddingRight: '20px',
    }
}));

const TextWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '17px',
    minHeight: '31px',
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        paddingTop: '53px',
        paddingBottom: '26px',
        minHeight: '39px'
    }
}));

export default function MyOrders() {
    const {width} = useWindowSize()
    
    const active1 = "!text-[#17686e]";    
    const active2 = "!text-[#BA483A]";
    const [option, setOption] = useState(true);
    const selectOption = (value) => {
        setOption(value);
    }
    const dispatch = useDispatch()
    const { currentUser, currentProfile } = useSelector(state => state.auth)
    const location = useLocation();


    const [checkout, setcheckout] = useState(false);
    const [paymentConfirmed, setPaymentConfirmed] = useState(false);
    const [ic_plan, setic_plan] = useState(null);



    useLayoutEffect(() => {
        if(location.state && location.state.initial != undefined){
            
            setOption(false)
            setInitial(true);
        }
    }, [location])
    useLayoutEffect(() => {
        if(currentProfile === 0){
            setOption(true);
        }
        else setOption(false);
    })
    const [initial, setInitial] = useState(false);
    const [profileData, setProfileData] =useState({})
    const [checkoutData, setcheckoutData] =useState({
            bidname:'',
            bidImage:'',
            normalprice:0,
            promoprice:0,
            moreinfo:[]
    })


    return (
        <RootStyle>


            {checkout && <Checkout 
                type={'membership'} 
                checkoutData={checkoutData}
                setopenModal={setcheckout}
            />}


            <ContainerStyle>
                <TextWrapper>
                    {
                        currentUser.ic_profile == 2 &&
                        <>
                            <TitleText className={(option ? (active1 + " ") :"") } onClick={() => {dispatch(setCurrentProfile(0));selectOption(true)}}>Perfil como Cliente</TitleText>
                            <Divider orientation="vertical" flexItem />
                            <TitleText className={(!option ? (active2 + " ") :"") + 'pl-[7px] sm:pl-[30px] '} onClick={() => {dispatch(setCurrentProfile(1));selectOption(false)}}>Perfil como Pesquisador</TitleText>
                        </>
                    }
                    {
                        currentUser.ic_profile == 1 &&
                        <>
                            <TitleText className={active2} >Perfil como Pesquisador</TitleText>
                        </>
                    }
                                        {
                        currentUser.ic_profile == 0 &&
                        <>
                            <TitleText className={active1} >Perfil como Cliente</TitleText>
                        </>
                    }
                </TextWrapper>
            </ContainerStyle>

            <ContainerStyle className="!mb-[62px]" style={{ position: 'relative',}} >
                {option ? 
                    <ProfileClient data={profileData}/> 
                        : 
                    <ProfileResearcher data={profileData}
                    />
                }
                {
                    !option ? 
                    <div className='lg:flex mb:block justify-between'>
                        <div className='mb:w-full lg:w-[483px] mt-[10px]'><AvailableBalance/></div>
                        {/* {currentUser.ic_currentplan !== 2 && <div className='max-w-[1100px] w-[100%] mt-[10px] mb:ml-[0px] lg:ml-[10px]  rounded-[5px] border-1 border-[#E2E4E5] border overflow-x-auto'><Membership/></div>} */}
                        {currentUser.ic_currentplan === 0 && <div className='max-w-[1100px] w-[100%] mt-[10px] mb:ml-[0px] lg:ml-[10px]  rounded-[5px] border-1 border-[#E2E4E5] border overflow-x-auto'><Membership  setcheckout={setcheckout} setic_plan={setic_plan} setcheckoutData={setcheckoutData}/></div>}
                        {currentUser.ic_currentplan === 1 && <div className=' w-[100%] mt-[10px] mb:ml-[0px] lg:ml-[10px]  rounded-[5px] border-1 border-[#E2E4E5] border overflow-x-auto' style={width>600? {maxWidth:'300px'} : {marginRight:'auto', marginLeft:'auto', maxWidth:'100%'}}><Membership  setcheckout={setcheckout} setic_plan={setic_plan} setcheckoutData={setcheckoutData}/></div>}
                        {currentUser.ic_currentplan !== 2 ? 
                        <>
                            {currentUser.ic_currentplan===0 &&
                                <div className='xl:block mb:hidden max-w-[230px] w-[100%] ml-[10px] mt-[10px] bg-[#E2E4E5] rounded-[5px] border-1 border-[#E2E4E5] border' style={{ overflow: "hidden" }}>
                            <img 
                                src={upgradePlanImg1} 
                                alt='Upgrade Plan 1' 
                                style={{ 
                                objectFit: "cover", 
                                objectPosition: "center top", 
                                width: "100%", 
                                height: "calc(100% + 80px)", 
                                marginTop:'-40px',
                                marginBottom:'-40px',
                                marginLeft:'0px',
                                marginRight:'0px'
                                }}
                            />
                            </div>
                            }
                            {currentUser.ic_currentplan===1 &&
                                <div className='xl:block mb:hidden max-w-[600px] w-[100%] ml-[10px] mt-[10px] bg-[#E2E4E5] rounded-[5px] border-1 border-[#E2E4E5] border' style={{ overflow: "hidden" }}>
                            <img 
                                src={upgradePlanImg2} 
                                alt='Upgrade Plan 1' 
                                style={{ 
                                objectFit: "cover", 
                                objectPosition: "center top", 
                                width: "100%", 
                                height: "calc(100% + 400px)", 
                                marginTop:'-200px',
                                marginBottom:'-200px',
                                marginLeft:'0px',
                                marginRight:'0px'
                                }}
                            />
                            </div>
                            }
                        </>
                        : 
                        <div className='xl:block mb:hidden max-w-[1300px] w-[100%] ml-[10px] mt-[10px]   bg-[#E2E4E5] rounded-[5px] border-1 border-[#E2E4E5] border' 
                            style={{ 
                                overflow: "hidden",
                                height:'323px',
                            }}
                        >
                            <img src={upgradePlanImg3} alt='Upgrade Plan 3'
                            style={{ 
                            objectFit: "cover", 
                            objectPosition: "center top", 
                            width: "100%", 
                            height: "calc(100% + 200px)", 
                            marginTop:'-160px',
                            marginBottom:'-100px',
                            marginLeft:'0px',
                            marginRight:'0px'
                            }}
/>
                        </div>}
                    </div>
                    : ""
                }
                {option ? 
                <TableInfoClient data={profileData} /> 
                    : 
                <TableInfoResearcher data={profileData} initial={initial}/>}                
                </ContainerStyle>
        </RootStyle>
    )
}