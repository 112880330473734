import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const RootStyle_1 = styled('div')(({ theme }) => ({
    width: '370px',
    boxShadow: '-2px 3px 50px rgba(0, 0, 0, 0.15)',
    background: 'white',
    borderRadius: '7px',
    marginRight: '7px',
    padding: '23px 10px 12px 10px',
    [theme.breakpoints.up('sm')]: {
        width: '365px',
        padding: '29px 14px 14px 21px',
        marginRight: '10px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '551px',
        padding: '39px 24.86px 24.56px 31.82px',
        marginRight: '23.86px',
    }
}));

const Header_text = styled('div')(({ theme }) => ({
    color: '#313131',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '22px',
    }
}));

const Content_text = styled('div')(({ theme }) => ({
    paddingTop: '24.7px',
    color: '#313131',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '13px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
    }
}));


const BTN_style_1 = styled('button')(({ theme }) => ({
    color: '#fff',
    fontFamily: 'Poppins',
    backgroundColor: '#17686E',
    borderRadius: '5px',
    border: 'none',
    fontSize: '14px',
    padding: '7.5px 2px 8.5px 2px',
    float: 'left',
    marginTop: '15px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
        padding: '12px 13px',
        float: 'right',
        marginTop: '25px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        padding: '15px 14px',
        float: 'right',
        marginTop: '25px',
    }
}));




export default function small_card({ card }) {

    return (
        <>
            <RootStyle_1>
                <Header_text>{card.header}</Header_text>
                <Content_text>{card.content}</Content_text>
                <BTN_style_1 onClick={()=>{window.location.href='/selectprofile'}}>{card.button_text}</BTN_style_1>
            </RootStyle_1>
        </>
    )
}