/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Box, Divider,
} from '@mui/material';
import Item from './member'
import { useSelector, useDispatch } from 'react-redux';
import API from '../../redux/API';
import { setUser } from '../../redux/actions/auth.action';
import { toast } from 'react-toast';

import Checkout from '../../components/Checkout';

export default () => {
    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    // const setCurrentPlan = (ic_plan) => {
    //     const currentDate = new Date
    //     const dt_planend = new Date(currentDate.getTime() + 1000*60*60*24*365)   //add 365 days to current date


    //     const data = {
    //         ds_email: currentUser.ds_email,
    //         ic_currentplan: ic_plan,
    //         singlePlan:{
    //             ic_plan:ic_plan,
    //             dt_planstart: currentDate,
    //             dt_planend: dt_planend,
    //             nr_plandurationleft:365,
    //             ic_planactive:true,
    //         },  
    //       }
    //       API.post('/user/setcurrentplan', data)
    //       .then((result) => {
    //         if(result.data.status === 'success'){
    //            toast.success('Sucesso.');
    //            dispatch(setUser(result.data.user))
    //         }
    //       })
    //       .catch((err) => {
    //         toast.error(JSON.parse(err.request.response).message)
    //         console.log(err);
    //     });
    // }'


    
    useEffect(()=>{
        API.post('order/getallplans',{nm_language:'BR'})
        .then((res)=>{
            if (res.data.allPlans) {
                const allPlans = res.data.allPlans
                allPlans.map((single)=>{
                    let aux = {}
                    aux.name=single.nm_plan
                    aux.text=single.plan_detail
                    aux.oldprice=single.vl_normal
                    aux.price=single.vl_current
                    aux.ic_plan=single.ic_plan
                    aux._id=single._id
                    if(single.ic_plan===0){
                        setdata(aux)
                    }
                    if(single.ic_plan===1){
                        setdata1(aux)
                    }
                    else if(single.ic_plan===2){
                        setdata2(aux)
                    }
                })
            }
        })
        .catch((err)=>{
            toast.error(err.message?err.message:'Erro ao buscar planos. Contate o suporte.')
        })
    },[])

    const [data,setdata] = useState({name:'',oldprice:0,price:0,ic_plan:0,text:[]})
    const [data1,setdata1] = useState({name:'',oldprice:0,price:0,ic_plan:1,text:[]})
    const [data2,setdata2] = useState({name:'',oldprice:0,price:0,ic_plan:2,text:[]})

    // const data = {
    //     ic_plan: 0,
    //     name: "Iniciante",
    //     oldprice: 0,
    //     price: 0,
    //     text: [
    //         '<strong>1</strong> país como especialidade',
    //         '<strong>7</strong> dias úteis para receber',
    //         '<strong>20%</strong> de taxa de intermediação',
    //         'Propostas <strong>de iniciantes</strong>',
    //         '<strong>30</strong> propostas mensais',
    //         '<strong>4</strong> pesquisas em execução',
    //         ''
    //     ]
    // }
    // const data1 = {
    //     ic_plan: 1,
    //     name: "Especialista",
    //     oldprice: 69,
    //     price: 59,
    //     text: [
    //         '<strong>2</strong> país como especialidade',
    //         '<strong>4</strong> dias úteis para receber',
    //         '<strong>15%</strong> de taxa de intermediação',
    //         'Propostas <strong>até especialista</strong>',
    //         '<strong>90</strong> propostas mensais',
    //         '<strong>10</strong> pesquisas em execução',
    //         ''
    //     ]
    // }
    // const data2 = {
    //     ic_plan: 2,
    //     name: "Assessor",
    //     oldprice: 179,
    //     price: 159,
    //     text: [
    //         '<strong>4</strong> país como especialidade',
    //         '<strong>2</strong> dias úteis para receber',
    //         '<strong>10%</strong> de taxa de intermediação',
    //         'Propostas de <strong>todos os níveis</strong>',
    //         '<strong>Ilimitadas</strong> propostas',
    //         '<strong>Ilimitados</strong> pesquisas em execução',
    //         '<strong>Selo</strong> premium&nbsp;&nbsp;&nbsp;'
    //     ]
    // }
    const itemAvailable = 3;


    const [checkoutData, setcheckoutData] =useState({
        bidname:'',
        bidImage:'',
        normalprice:0,
        promoprice:0,
        moreinfo:[]
})

const [checkout, setcheckout] = useState(false);

const formatThousand = (value)=>{
    value = Number(value)
    if(value>0){
        return value.toLocaleString('pt-BR', {
            style:'decimal',
            useGrouping:true,
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2,           
        })
    }
    return value
}

    return (
        <div className='box-border bg-[#FFFFFF] bg-opacity-10 rounded-[5px] w-[100%]'>
            {itemAvailable > 0 ? 
                <div className='flex justify-center'>
                    <div className='h-[28px] font-[Poppins] text-[20px] text-center font-semibold leading-[21px] text-[#BA483A] pt-[18px]'>
                        Upgrade&nbsp;
                    </div>
                    <div className='h-[28px] font-[Poppins] text-[20px] text-center leading-[21px] text-[#313131] pt-[18px]'>
                    para seu trabalho!
                    </div>
                </div>
            : ""}

        {checkout && <Checkout 
            type={'membership'} 
            checkoutData={checkoutData} 
            setopenModal={setcheckout}
        />}


            <div className='mb:block xl:flex place-content-around pt-[13px] pb-[10px]'>
                {itemAvailable > 0 ? <Item data={data} setcheckout={setcheckout}  setcheckoutData={setcheckoutData} formatThousand={formatThousand} ></Item> : ""}
                {itemAvailable > 1 ? <Item data={data1} setcheckout={setcheckout}  setcheckoutData={setcheckoutData} formatThousand={formatThousand} ></Item> : ""}
                {itemAvailable > 2 ? <Item data={data2} withDiamond={false} setcheckout={setcheckout}  setcheckoutData={setcheckoutData} formatThousand={formatThousand} ></Item> : ""}
            </div>
        </div>
    )
}