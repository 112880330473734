// Aqui está definido o objeto "data" com um array vazio "notification".
const data = {
    notification: [],
};

// eslint-disable-next-line default-param-last
// Aqui estamos criando o reducer "notificationReducer" que tem o estado inicial definido pelo objeto "data".
export default function authReducer(state = data, action) {
    // Criamos uma cópia do objeto "state".
    const temp = { ...state };
    // Verificamos o tipo da ação recebida.
    switch (action.type) {
        // Se o tipo da ação for "setNotification", atualizamos o valor de "notification" na cópia de "state".
        case "setNotification":
            temp.notification = action.data;
            // Retornamos a cópia atualizada.
            return temp;
        // Se não houver ações reconhecidas, retornamos o estado original.
        default:
            return temp;
    }
}