import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux';
import { setTempUser } from '../../redux/actions/auth.action';
import {
    Grid,
    Stack,
    OutlinedInput,
} from '@mui/material';


import { useDispatch } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));



const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));

export default function Modal({ openModal, setOpenModal, showMessage }) {
    const navigate = useNavigate();

    const [verifyHuman, setVerifyhuman] = useState();
    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpenModal(false);
    };
    const handleConfirm = () => {
        showMessage();
        dispatch(setTempUser(email))
        setOpenModal(false);
        navigate('/validatecode')
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                </BootstrapDialogTitle>
                <DialogContent>
                    <GridWrapper className="mt-[50px]">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className="flex justify-center"> <HeaderText>Esqueceu sua senha?</HeaderText></div>
                                <div className="flex justify-center">
                                    <div className="w-[55%] font-[Poppins] font-light text-[16px] leading-[120%] text-[#313131] mt-[16px]">
                                    Informe seu e-mail de cadastro e enviaremos para você as intruções para redefinir a sua senha.
                                    </div>
                                </div>
                                <div className='w-[100%] flex justify-center mt-[30px]'>
                                    <OutlinedInput
                                    value = {email}
                                    onChange={(e) => {setEmail(e.target.value)}}
                                    InputProps={{
                                        className: style.content
                                    }}
                                    className={style.content}
                                    placeholder='E-mail*'
                                    hiddenLabel="hiddenLabel"
                                    id="outlined-basic"
                                    variant="outlined"/>
                                </div>
                                <div className="flex justify-center mt-[12px] font-[Poppins] font-light text-[16px] leading-[120%] text-[#313131]">
                                    <div>Captcha</div>
                                </div>
                                <div className="flex justify-center mt-[10px]">
                                    <ReCAPTCHA
                                        sitekey="6Ld1-g0kAAAAAH7gGajMrpDvpw3oPpZOSuhjrklv"
                                        onChange={(e)=>{setVerifyhuman(e);}}
                                    />
                                </div>
                            </Grid>

                        </Grid>
                    </GridWrapper>
                </DialogContent>
                <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} className="items-center" >
                    <HeaderBTNStyle onClick={handleConfirm}  disabled={(!verifyHuman || !email) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar</HeaderBTNStyle>
                </Stack>
                <DialogActions></DialogActions>
                <DialogActions></DialogActions>
            </BootstrapDialog>
        </div >
    );
}
