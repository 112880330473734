import { styled } from '@mui/material/styles';
import {
    Divider,
    TextareaAutosize,
} from '@mui/material';
// components

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: '20px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    padding: '10px 16px',
    marginTop: '3px',
    minHeight: '200px',
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
    textAlign: 'justify'
}));

export default function DataCard({ data }) {

    return (
        <RootStyle>
            <TitleText>Sobre mim</TitleText>
            {/* <ContainerStyle> */}
                {/* <DescriptionText>{data}</DescriptionText> */}
                <TextareaAutosize
                            id="standard-multiline-static"
                            label="Multiline"
                            InputProps={{ disableUnderline: true }}
                            placeholder="Escreva sobre você"
                            defaultValue={data}
                            InputLabelProps={{ shrink: true }}
                            value={data}
                            className="pt-[9px] pl-[13px] resize-y !min-h-[126px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                />
            {/* </ContainerStyle> */}
        </RootStyle>
    )
}