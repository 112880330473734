import { styled } from '@mui/material/styles';
import CardItem from './jd_card';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../../redux/API';
import { toast } from 'react-toast';
import { useState } from 'react';
import { json } from 'react-router-dom';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%'
}));

export default function JdCardList(props) {

    const [totalPages,settotalPages]=useState(1)
    const [curentPage,setcurentPage]=useState(1)
    const [orderPerPage,setorderPerPage]=useState(10)
    const [thisPageContent,setthisPageContent]=useState([])
    
    useEffect(()=>{
        setcurentPage(1)
        setthisPageContent(props.cardContent.slice(0,10))
        var aux = 0
        aux = props.cardContent.length/orderPerPage
        aux = Math.ceil(aux)
        settotalPages(aux)
    },[props])

    const handleChange = (event, value) => {

        var aux = [...props.cardContent]        
        var start = (value-1) * orderPerPage;
        var end = start + orderPerPage;
        var auxContent = aux.slice(start,end)

        setcurentPage(value)
        setthisPageContent(auxContent)
        // props.setPage(value);
        // props.showOrderByPage(value);
    };      

    return (
        <RootStyle>
            {thisPageContent.map((card, index) => (
                <CardItem 
                    key={index} 
                    card={card}  
                    filterCountry={props.filterCountry}
                    showFlag={props.showFlag}
                    showCountryName={props.showCountryName}
                    orderTypeList={props.orderTypeList}

                />
            ))}

            <Stack alignItems="center" sx={{ my: 5 }} >
            
            <Pagination
                count={totalPages}
                onChange={handleChange}
                page={curentPage}
                sx={{
                    "& .MuiPaginationItem-root": {
                        "&:hover:not(.Mui-selected)": {
                            backgroundColor: "#17686E",                            
                            color: "white"
                        }
                    },
                    "& .Mui-selected": {
                        backgroundColor: "#17686E",
                        color: "white",
                    "&:hover": {
                        backgroundColor: "#17686E",
                        color: "white"
                    }
                    }
                }}
            />            
            </Stack>
        </RootStyle> 
    )
}