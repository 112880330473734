import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { styled } from '@mui/material/styles';
import {
    Box,
    Stack,
} from '@mui/material';
import checkitem from '../../../assets/icons/checkitem_green.svg';
import { checkNeedRedirect , createRedirect} from '../../CheckNeedRedirect';

const Card = styled('div')(({ theme }) => ({
    width: '280px',
    textAlign: 'center',
    background: 'white',
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    [theme.breakpoints.up('900')]: {
        width: '362px',
    },
}));

const Text_style_3 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#17686E',
    fontSize: '20px',
    paddingTop: '21px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
        fontSize: '30px', paddingTop: '24px',
    },
}));

const Text_style_4 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400, color: '#17686E',
    lineHeight: '130%',
    fontSize: '40px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
        fontSize: '60px',
    },
}));

const Text_style_5 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 600, color: '#17686E',
    fontSize: '12px',
    paddingLeft: '12px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        paddingLeft: '16px',
    },
}));

const Text_style_6 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#17686E',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
    },
}));


const Text_style_7 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#17686E',
    fontSize: '12px', paddingLeft: '12px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px', paddingLeft: '16px',
    },
}));


const Text_style_8 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 600, color: '#17686E',
    fontSize: '12px', paddingLeft: '3px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px', paddingLeft: '6px',
    },
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    border: '1px solid #17686E',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '18px',
    padding: '10px 18px 11px 18px',
    margin: '55px 0px 29px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 60px 17px 60px',
        margin: '55px 0px 29px',
    },
}));

const Sub_header = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#C6C6C6',
    fontSize: '16px',
    paddingTop: '10px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '20px'
    },
}));

const Sub_header_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400, color: '#17686E',
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
    },
}));

const Details_card = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '18px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '33px',
    },
}));

export default function Card_2(props) {
    const navigate = useNavigate()
    const formatThousand = (value)=>{
        value = Number(value)
            return value.toLocaleString('pt-BR', {
                style:'decimal',
                useGrouping:true,
                minimumFractionDigits: 2, 
                maximumFractionDigits: 2,           
            })
    }

    return (
        <Card>
            <Text_style_3>Especialista</Text_style_3>
            <Sub_header><del>{props.single&&props.single.normalPrice?`R$${props.single.normalPrice/12}` : ""}</del></Sub_header>
            <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                <Text_style_4>{props.single&&props.single.currentPrice?`R$${props.single.currentPrice/12}` : ""}</Text_style_4>
                <Sub_header_1>/Mês</Sub_header_1>
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                <Sub_header_1 sx={{ color: '#C6C6C6' }}><del>R$ {props.single&&props.single.normalPrice?formatThousand(props.single.normalPrice):''}</del></Sub_header_1>
                <Sub_header_1 >&nbsp;R$ {props.single&&props.single.currentPrice?formatThousand(props.single.currentPrice):''}/ano</Sub_header_1>
            </Stack>

            <Details_card>
                <Stack flexDirection={'column'} alignItems={'left'}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>2</Text_style_5>
                        <Text_style_6>&nbsp;país como especialidade</Text_style_6>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>4</Text_style_5>
                        <Text_style_6>&nbsp;dias úteis para receber</Text_style_6>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>15%</Text_style_5>
                        <Text_style_6>&nbsp;de taxa de intermediação  </Text_style_6>
                    </Stack>

                    {/* reverse */}
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_7>Propostas </Text_style_7>
                        <Text_style_8>até especialista</Text_style_8>
                    </Stack>

                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>90</Text_style_5>
                        <Text_style_6>&nbsp;propostas mensais</Text_style_6>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>10</Text_style_5>
                        <Text_style_6>&nbsp;pesquisas em execução</Text_style_6>
                    </Stack>
                </Stack>
            </Details_card>

            <HeaderBTNStyle onClick={()=>{
                if(props.currentUser && props.currentUser.ic_profile!==0){
                    if(props.currentUser.ic_currentplan<1){
                        props.setopenModal(true);
                        props.setCardContent(props.single);
                        props.setcheckoutData({
                            bidname:props.single.title,
                            bidImage:props.single.image,
                            normalprice:props.single.normalPrice,
                            promoprice:props.single.currentPrice,
                            moreinfo:props.single.benefits,     
                            ic_currentplan:props.single.ic_currentplan,  
                            id_plan:props.single._id,                          
                        })
                    }
                    else{
                        toast.success('Você já possui o plano')
                    }
                }
                else{
                    createRedirect({type:'membership',path:'/account',state:{state:{initial: 7}},condition:'afterlogin'})
                    navigate('/login')
                }
                        
                }}
                    >Assinar</HeaderBTNStyle>
        </Card >
    )
}