import useWindowSize from '../../hooks/useWindowSize'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Avatar, Box, Stack, Divider, IconButton,} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
// components
import PremiumCard from '../../components/card/PremiumCard'
import RatingStar from '../../components/RatingStar'
import CopyIcon from '../../assets/icons/copy.svg'
import LinkIcon from '../../assets/icons/link.svg'
import { IMAGE_BASE } from '../../config/constants';
import { toast } from 'react-toast';

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: '37px',
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    paddingLeft: '8px',
    width: '100%',
}));

const NameText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    color: '#313131',
    paddingTop: '10px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '25px',
    },
}));

const RatingText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#313131',

        boxShadow: theme.shadows[1],
        fontSize: 10,
        fontFamily: 'Poppins',
        fontWeight: 400,
        zIndex:99
    },
}));

export default function DataCard({ profileData, currentUser,avgrating,showFlag, showCountryName, filterCountry }) {
    const { width } = useWindowSize()
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        // navigator.clipboard.writeText(currentUser.cd_researcher);
        // setOpen(true);
        toast.success("Copiado com sucesso.")
    };
    const getFirstLetter = (arr) =>{
        return arr[0][0] + "." + arr[1][0];
    }

    const [specialitylist, setSpecialitylist] = React.useState([])
    React.useLayoutEffect(() => {
       
        if(specialitylist.length === 0)
        {
            API.get(`user/getspecialitylist?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.allspeciality;
                setSpecialitylist(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    },[])
    
    const getSpeciality = (id) => {
        let temp;
        specialitylist.map((item) => {
            if(item._id == id) temp = item.nm_country_pt;
        })
        return temp;
    }
    const getcd_isoalpha2 = (id) => {
        let temp;
        specialitylist.map((item) => {
            if(item._id == id) temp = item.cd_isoalpha2;
        })
        return temp;
    }

    return (
        <RootStyle>
            {/* {width < 900 && <Box sx={{ float: 'right' }}> <Icon icon="bi:three-dots" /> </Box> } */}
            

            <Stack flexDirection={'row'}>
                <Stack flexDirection={'row'} alignItems={'center'}>
                <Box sx={{ width: width >= 500 ? 100 : 55, height: width >= 500 ? 100 : 55 }} className="rounded-[50%]">
                    <Avatar
                        alt="Profile Picture"
                        src={IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+currentUser.nm_researcheravatarfile}
                        sx={{ width: width >= 500 ? 100 : 55, height: width >= 500 ? 100 : 55, borderRadius: '50%' }}
                    />
                </Box>
                </Stack>
                <ContentStyle>
                    <NameText>{currentUser.ic_researchertype ? getFirstLetter(currentUser.nm_user.split(' ')) : currentUser.nm_company}</NameText>
                    <Stack flexDirection={'row'} alignItems={'center'} mt={'4px'}>
                        <PremiumCard  />
                        <RatingText sx={{ fontWeight: 300, paddingLeft: '10px' }} >ID:&nbsp;</RatingText>
                        <RatingText sx={{ fontWeight: 300 }}>{currentUser.cd_researcher}</RatingText>

                        <LightTooltip title={<u>Nº de ID copiado!</u>} placement="top" open={open} onClose={handleClose}>
                            <IconButton aria-label="CopyID" onClick={handleOpen}>
                                <Box component={'img'} src={CopyIcon} width={13.5} />
                            </IconButton>
                        </LightTooltip >

                        {/* <IconButton aria-label="Link">
                            <Box component={'img'} src={LinkIcon} width={20} />
                        </IconButton> */}
                    </Stack>
                </ContentStyle>
            </Stack>
            <Divider color={'#17686E'} sx={{ margin: '20px 0px' }} />
            <Stack flexDirection={'row'} alignItems={'center'} mt={'6px'}>
            <RatingStar value={avgrating} size={24} emptyIconType={2} />
                <RatingText>&nbsp;&nbsp;&nbsp;&nbsp;{avgrating?`${avgrating.toFixed(2)}/5`:'Nenhuma informação'}</RatingText>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'left'} mt={'10px'}>
                <RatingText sx={{ fontWeight: 300 }}>Localização:&nbsp;</RatingText>
                    <RatingText sx={{ fontWeight: 600 }}>{currentUser?.id_country?getSpeciality(currentUser.id_country):''}&nbsp;&nbsp;</RatingText>
                    <Icon icon={
                        currentUser?.id_country?
                        showFlag(getcd_isoalpha2(currentUser.id_country), filterCountry)
                        :
                        ''                            
                    } width={19} />
            </Stack>
            <Stack flexDirection={'column'} alignItems={'left '} mt={'10px'} mb={'20px'} >
                <RatingText sx={{ fontWeight: 300 }}>Especialidade:&nbsp;</RatingText>
                <RatingText sx={{ fontWeight: 600 }}>{
                        currentUser.about_me?.specialities !== undefined && currentUser.about_me.specialities.map((item, index) => {
                            if(index !== currentUser.about_me.specialities.length - 1) return getSpeciality(item)+', '
                            return getSpeciality(item)
                        })
                    }</RatingText>
            </Stack>
        </RootStyle>
    )
}