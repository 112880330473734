import { styled } from '@mui/material/styles';
import {
    Stack,
} from '@mui/material';

// components
import RatingStar from '../../components/RatingStar'
import { useSelector } from 'react-redux';
import API from '../../redux/API';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: '20px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    color: '#313131',
    textAlign: 'center',
}));

const NumberText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '70px',
    lineHeight: '100%',
    color: '#313131',
    textAlign: 'center',

    paddingTop: '5px',
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
    textAlign: 'center',
    paddingTop: '10px'
}));

export default function DataCard({ profileData, currentUser,avgrating,totalreviews }) {
    const getFirstLetter = (arr) =>{
        return arr[0][0] + "." + arr[1][0];
    }

    return (
        <RootStyle>
            <TitleText>Opiniões sobre&nbsp;{currentUser.ic_researchertype ? getFirstLetter(currentUser.nm_user.split(' ')) : currentUser.nm_company} </TitleText>
         {   totalreviews ?
         <>
            <NumberText>{avgrating.toFixed(2)}</NumberText>
            <Stack flexDirection={'row'} justifyContent={'center'}>
                <RatingStar value={avgrating} size={24} emptyIconType={2} />
            </Stack>
            <DescriptionText>Média entre&nbsp;{totalreviews}&nbsp;opiniões</DescriptionText>
         </>
         :
         <>
            <DescriptionText>Sem avaliações até o momento</DescriptionText>
         </>
        }
        </RootStyle>
    )
}