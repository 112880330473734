import { styled } from "@mui/material/styles";
import { Stack, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { useState } from "react";
// -------------------------------------------------------------
const FilterTitleStyle = styled("p")({
  color: "#313131",
  fontFamily: "Poppins",
  fontSize: "14px",
  margin: "40px 0px 10px",
  fontWeight: 600,
});
// -------------------------------------------------------------

// function Checkother(filterList,item){

//     const isapostilado = filterList.filter(result => result == 'Apostilado')
//     const isfisico = filterList.filter(result => result == 'Físico')
//     const isdigital = filterList.filter(result => result == 'Digital')

//     if(isapostilado.length > 0){

//         return true
//         alert(isapostilado)

//     }

// }

export default function Filter7(props) {
  // { filter }

  var filter = props.filter;
  var filterList = props.filterList;
  var setFilterList = props.setFilterList;



  const handleFilterList = (item) => {
    let aux =[...filterList]
    let auxLevelTranslateToNumber = 0

    if (item==='Físico') {
      auxLevelTranslateToNumber = 1
    } else if (item==='Apostilado') {
      auxLevelTranslateToNumber = 2
    }

    const filterType = 'sendType'
    let flag = filterList.some(obj=>obj.name===item)
    if (flag) {
        aux = filterList.filter((e) => e.name != item)
    } else {
        aux.push({name:item,type:'sendType'})
    }
props.postFilters(auxLevelTranslateToNumber, filterType)
    setFilterList(aux);
}


  const [digitalchck, setdigitalchck] = useState(false);
  const [fisicochck, setfisicochck] = useState(false);
  const [apostiladochck, setapostiladochck] = useState(false);

  return (
    <>
      <FilterTitleStyle>Forma de recebimento do documento</FilterTitleStyle>
      <Stack>
        <FormGroup>
          {props.filter.map((item, index) => (
            <FormControlLabel
              key={index}
              onChange={() => {
                handleFilterList(item);
              }}
              control={
                <Checkbox


                checked={props.ischecked(item,'sendType')}

                
                  disabled={props.filtersBlock == true}

                //   checked={(digitalchck == true  && item == 'Digital' ? true : false) || (fisicochck == true  && item == 'Físico' ? true : false) || (apostiladochck == true  && item == 'Apostilado' ? true : false)}
                  
                sx={props.checkboxCSS}
                />
              }
              label={item}
              size="small"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "#313131",
                },
              }}
            />
          ))}
        </FormGroup>
      </Stack>
    </>
  );
}
