import { styled } from "@mui/material/styles";
import useWindowSize from "../../../hooks/useWindowSize";
import { Box, Stack, Divider } from "@mui/material";

// components

// -------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  padding: "0px 10px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "180px",
    padding: "0px 5px",
    width: "100%",
  },
}));

const TitleText = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 300,
  textAlign: "right",
  fontSize: "14px",
  color: "#313131",
  whiteSpace: "nowrap",
}));

const ValueText = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "20px",
  color: "#313131",
  textAlign: "right",
  whiteSpace: "nowrap",
  [theme.breakpoints.up("md")]: {
    fontSize: "28px",
  },
}));

export default function Card({ data }) {
  const { width } = useWindowSize();

  return (
    <RootStyle>
      {width < 900 && <Divider sx={{ margin: "12px 50px 17px" }} />}
      <TitleText>{data.title}</TitleText>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        pt={"8px"}
        sx={{ width: "100%" }}
      >
        <Box
          component={"img"}
          src={data.iconSrc}
          className="w-[35px] h-[38px]  p-[2px] rounded-[50%]"
        />
        <ValueText>{data.number}</ValueText>
      </Stack>
    </RootStyle>
  );
}
