import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import PartyPopperIcon from '../../assets/icons/party-popper.gif'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));


const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));



const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));

export default function Modal({ openModal, setOpenModal,confirmFunc ,reportFunc, isDigital, isPhysical, isStamped}) {
    const navigate = useNavigate();

    const [verifyHuman, setVerifyhuman] = useState();
    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpenModal(false);
    };



    const handleConfirm = () => {
        confirmFunc();
        
        setOpenModal(false);
        
    }



    const handleConfirm1 = () => {
        setOpenModal(false);
    }
    const handleReportProblem = () => {
        setOpenModal(false);
        reportFunc(true);
    }
    return (
        <div>
      
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                        minHeight: '421px'
                    }
                }}
            >
                <DialogContent>
                {isDigital &&
                    <div className="flex justify-between">
                        <div className='flex flex-col mt-[50px] mb:ml-[0px] md:ml-[65px] md:w-[540px] mb:w-full'>
                            <div className='font-[Poppins] font-medium text-[22px] text-[#313131] leading-[100%] mb-[33px]'>Confirmar análise</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[15px]'>Confirmo que analisei os documentos anexados e os mesmos são verídicos.</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[35px]'>Estou confirmando que minha solicitação foi concluída e finalizada.</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[35px]'>Agora seu pesquisador receberá o valor negociado!!!</div>
                            
                        </div>
                        <div className='mt-[28px] mb:mr-[0px] md:mr-[65px]  '>
                            <Box component='img' src={PartyPopperIcon} width="88px" height="80px"/>
                        </div>
                    </div>
                }
                {isPhysical &&
                    <div className="flex justify-between">
                        <div className='flex flex-col mt-[50px] mb:ml-[0px] md:ml-[65px] md:w-[540px] mb:w-full'>
                            <div className='font-[Poppins] font-medium text-[22px] text-[#313131] leading-[100%] mb-[33px]'>Confirmar análise</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[15px]'>Confirmo que analisei os documentos anexados e os mesmos são verídicos.</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[35px]'>Estou confirmando que os documentos digitais estão conforme solicitado.</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[35px]'>Agora seu pesquisador enviará seus documentos para você.</div>
                            
                        </div>
                        <div className='mt-[28px] mb:mr-[0px] md:mr-[65px]  '>
                            <Box component='img' src={PartyPopperIcon} width="88px" height="80px"/>
                        </div>
                    </div>
                }
                {isStamped &&
                    <div className="flex justify-between">
                        <div className='flex flex-col mt-[50px] mb:ml-[0px] md:ml-[65px] md:w-[540px] mb:w-full'>
                            <div className='font-[Poppins] font-medium text-[22px] text-[#313131] leading-[100%] mb-[33px]'>Confirmar análise</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[15px]'>Confirmo que analisei os documentos anexados e os mesmos são verídicos.</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[35px]'>Estou confirmando que os documentos digitais estão conforme solicitado.</div>
                            <div className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[100%] mb-[35px]'>Agora seu pesquisador enviará seus documentos para Apostilamento.</div>
                            
                        </div>
                        <div className='mt-[28px] mb:mr-[0px] md:mr-[65px]  '>
                            <Box component='img' src={PartyPopperIcon} width="88px" height="80px"/>
                        </div>
                    </div>
                }
                  
                </DialogContent>
                <div className=' w-full  mb-[28px] flex items-center justify-center mb:flex-col md:flex-row'>
                    <HeaderBTNStyle1 onClick={handleConfirm1} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                    <HeaderBTNStyle onClick={handleConfirm} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Confirmar análise</HeaderBTNStyle>
                </div>

                <div className="justify-center flex-col items-center flex">
                    <div className='font-[Poppins] font-normal text-[10px] text-[#313131] leading-[100%] mb-[5px]'>Está com problemas no seu documento?</div>
                    <div className='flex'>
                        <span className='font-[Poppins] font-normal text-[10px] text-[#313131] leading-[100%] mb-[20px]'>O assessor não completou o pedido corretamente?</span>
                        <span onClick={handleReportProblem} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>&nbsp;Entre aqui.</span>
                    </div>
                  
                </div>
                {/* <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} className="items-center" >
                <HeaderBTNStyle onClick={handleConfirm}  disabled={(!verifyHuman || !email) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6'}}}>Enviar</HeaderBTNStyle>
                </Stack> */}
                <DialogActions></DialogActions>
                <DialogActions></DialogActions>
            </BootstrapDialog>
        </div >
    );
}
