import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { InputLabel, Stack, Select, MenuItem, FormControl } from '@mui/material';




import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from 'react';

// -------------------------------------------------------------

const FilterTitleStyle = styled('p')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    margin: '20px 0px 10px',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


// -------------------------------------------------------------

export default function Filter2(props) {
    const [age, setAge] = useState('');

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
       
    var adjustCountries = (filterCountry)=>{
        filterCountry.sort(function(a,b){
            return (a[0]).localeCompare(b[0])
        })
    }
    
    var filter = props.filter

    // alert(JSON.stringify(filter[0][0]))


    var filterList = props.filterList
    var setFilterList = props.setFilterList
  

    const handleFilterList = (item) => {
        let aux =[...filterList]
        const filterType = 'countries'
        let flag = filterList.some(obj=>obj.name===item)
        if (flag) {
            aux = filterList.filter((e) => e.name != item)
        } else {
            aux.push({name:item,type:'countries'})
        }
        // alert(item + ' =  ' + filterType)
        

        setAge(item)
        
        props.postFilters(item, filterType)
        setFilterList(aux);
    }


    const checkCountry = (item)=>{
        let flag = filterList.some(obj=>obj.name===item)
        if (flag) {
            return true
        }
    }


    const handleCountriesName =() =>{

        var aux = ''
        var aux2 = []

        filter.map((item,index)=>{
            if (checkCountry(item[0])) {
                aux2.push(item[0])
            }
        })

        aux2.map((item,index)=>{
            if (index===0) {
               return  aux+=`${item}`
            }
            return aux+=`, ${item}`
        })

        return aux
    }



    return (
        <>
            <FilterTitleStyle>País de pesquisa</FilterTitleStyle>
            <Stack sx={{ mb: 2 }}>

            <FormControl            
            sx={{
                // maxHeight:'5px'
            }}

            
            >
                <InputLabel 
                    id="demo-simple-select-label"
                    disabled={props.filtersBlock == true}
                    style={{ fontFamily: "Poppins", fontWeight: 400, color: "#313131" }}
                    
                    
                    
                    >
                        {handleCountriesName()?
                            <>
                                {handleCountriesName()}
                            </>
                            :
                            <>
                                Todos
                            </>

                        }
                </InputLabel>
                    <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disabled={props.filtersBlock == true}
                            

                            sx={{marginBottom:'10px'}}
                            MenuProps={{
                                sx:{
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "rgba(23, 104, 110, 0.11)"
                                    },
                                    '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                    },
                                    maxHeight:'500px',
                                }
                            }}                            
                            >
                            {filter.map(function(item,index){
                                return <MenuItem                                  

                                        onChange={()=>{handleFilterList(item[0])}}
                                
                                        disabled={props.filtersBlock == true}
                                        >
                                    
                                        <FormControlLabel
                                        checked={checkCountry(item[0])}
                                        value={item[0]}
                                        control={
                                            <Checkbox 
                                            sx={props.checkboxCSS}
                                            />
                                        }
                                        label={item[0]}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontFamily: 'Poppins',
                                                fontSize: '14px',
                                                color: '#313131',
                                                maxWidth:'250px',
                                            }
                                        }}
                                        />
                                    </MenuItem>
                            })}                        
                    </Select>                        
            </FormControl>


            </Stack>
        </>
    )
}