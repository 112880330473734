import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Stack,
    Box,
} from '@mui/material';
import Pic1 from '../../assets/image/researcher_how_1.svg';
import Pic2 from '../../assets/image/researcher_how_2.svg';
import Pic3 from '../../assets/image/researcher_how_3.svg';

import {useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: '100%',
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '50px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '65px',
    }
}));

const Text_style_3 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '100%',
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '32px',
    }
}));


const Text_number = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '93px',
    top: '16px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '190px',
        top: '81px',
    },
    [theme.breakpoints.up('lg')]: {
        right: '190px',
        top: '16px',
    },
}));

const Text_first = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: 500,
    color: '#BA483A',
    [theme.breakpoints.up('md')]: {
        fontSize: '150px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '230px',
    }
}));

const Text_second = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: 500,
    textShadow: '3px 0  #F3F3F3, -3px 0  #F3F3F3, 0 3px  #F3F3F3, 0 -3px  #F3F3F3, 1.5px 1.5px  #F3F3F3, -1.5px -1.5px  #F3F3F3, 1.5px -1.5px  #F3F3F3, -1.5px 1.5px  #F3F3F3',
    color: '#BA483A',
    [theme.breakpoints.up('md')]: {
        fontSize: '150px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '230px',
        textShadow: '5px 0  #F3F3F3, -5px 0  #F3F3F3, 0 5px  #F3F3F3, 0 -5px  #F3F3F3, 2.5px 2.5px  #F3F3F3, -2.5px -2.5px  #F3F3F3, 2.5px -2.5px  #F3F3F3, -2.5px 2.5px  #F3F3F3',
    }
}));


const Text_number_2 = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '148px',
    top: '4px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '225px',
        top: '55px',
    },
    [theme.breakpoints.up('lg')]: {
        right: '215px',
        top: '19px',
    },
}));

const Text_number_3 = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '33px',
    top: '-13px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '5%',
        top: '62px',
    },
    [theme.breakpoints.up('1200')]: {
        right: '234px',
        top: '63px',
    },
    [theme.breakpoints.up('1300')]: {
        right: '266px',
        top: '63px',
    },
}));

const Text_header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '37px 0px 13px',
    [theme.breakpoints.up('sm')]: {
        padding: '60px 0px 40px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '96px 0px 54px',
    },
}));

const Text_style_2 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    color: '#313131',
    lineHeight: '150%',
    paddingLeft: '5px',
    paddingRight: '13px',
}));

const BTN_style_1 = styled('button')(({ theme }) => ({
    color: '#fff',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#17686E',
    borderRadius: '5px',
    border: 'none',
    padding: '15px 15px 15px 15px',
    float: 'left',
    marginTop: '15px',
    cursor: 'pointer',
    marginBottom: '41px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        padding: '12px 13px',
        float: 'right',
        marginTop: '25px',
        marginBottom: '20px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        padding: '15px 14px',
        float: 'right',
        marginTop: '25px',
        marginBottom: '39px',
    }
}));


const Grid_Wrapper_1 = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: '#F3F3F3',
    borderRadius: '5px',
    flexDirection: 'column',
    paddingTop: '23px',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '23px',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingTop: '43px',
    }
}));

const Grid_item_1 = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    position: 'relative',
    right: '35%',
    [theme.breakpoints.up('md')]: {
        width: '46%',
        right: '26px',
        minHeight: '358px',
    },
    [theme.breakpoints.up('1259')]: {
        width: '40.6%',
        right: '36px',
        minHeight: '358px',
    }
}));

const Grid_item_2 = styled('div')(({ theme }) => ({
    width: '100%',
    paddingLeft: '5px',
    [theme.breakpoints.up('md')]: {
        width: '54%',
        paddingLeft: '5px',
    },
    [theme.breakpoints.up('1259')]: {
        width: '59.4%',
    }
}));


const Grid_item_3 = styled('div')(({ theme }) => ({
    width: '100%',
    paddingLeft: '5px',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '63px',
        width: '50%',
        paddingLeft: '45px',
        paddingBottom: '20px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '56.4%',
        paddingLeft: '45px',
        paddingTop: '63px',
        paddingBottom: '20px',
    }
}));

const Grid_item_4 = styled('div')(({ theme }) => ({
    width: '111%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    position: 'relative',
    right: '35%',
    [theme.breakpoints.up('sm')]: {
        width: '100%',
        right: '35%',
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
        right: '26px',
        minHeight: '358px',
    },
    [theme.breakpoints.up('1259')]: {
        width: '43.6%',
        right: '36px',
        minHeight: '358px',
    }
}));


const Grid_item_5 = styled('div')(({ theme }) => ({
    width: '111%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    position: 'relative',
    right: '35%',
    [theme.breakpoints.up('sm')]: {
        width: '100%',
        right: '35%',
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
        right: '24px',
        minHeight: '424px',
    },
    [theme.breakpoints.up('1300')]: {
        width: '50%',
        right: '34px',
        minHeight: '424px',
    }
}));


const Grid_item_6 = styled('div')(({ theme }) => ({
    width: '100%',
    paddingLeft: '5px',
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '63px',
        width: '50%',
    },
    [theme.breakpoints.up('1300')]: {
        width: '50%',
    }
}));

const Grid_Wrapper_2 = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: '#F3F3F3',
    flexDirection: 'column-reverse',
    marginTop: '20px',
    padding: '23px 0px 64px 0px',
    [theme.breakpoints.up('sm')]: {
        padding: '23px 0px 0px 0px',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: '0px 5%',
    },
    [theme.breakpoints.up('1600')]: {
        flexDirection: 'row',
        padding: '0px 7%',
    },
    [theme.breakpoints.up('1800')]: {
        flexDirection: 'row',
        padding: '0px 7%',
    }
}));


const Grid_Wrapper_3 = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: '#F3F3F3',
    borderRadius: '5px',
    flexDirection: 'column',
    paddingTop: '23px',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '23px',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingTop: '0px',
    }
}));


export default function How_does_it_works() {

    const {currentUser} = useSelector(state => state.auth);
    const { width } = useWindowSize()
    const navigate = useNavigate();


    return (
        <RootStyle>
            <Text_header>
                <Stack flexDirection={'row'}>
                    <Text_style_1>Como</Text_style_1>
                    <Text_style_1 sx={{ color: '#17686E' }}>&nbsp;funciona</Text_style_1>
                </Stack>
                <Text_style_3>ser um pesquisador</Text_style_3>
            </Text_header>



            {/* comment 1 */}

            <Grid_Wrapper_1>
                <Grid_item_1>
                    {width < 900 && <Box component='img' src={Pic1} height={'101px'} />}
                    {width >= 900 && <Box component='img' src={Pic1} height={'224px'} />}
                    <Text_number>
                        <Text_first>1</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number>
                </Grid_item_1>
                <Grid_item_2>
                    <Text_style_2 sx={{ fontSize: '28px', fontWeight: 700, pt: '19px' }}>Encontre pedidos ideais que se encaixem no seu perfil</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Crie seu perfil como pesquisador</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px' }}>Destaque suas qualificações, especialidades e compartilhe suas experiências para atrair clientes em potencial.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Explore as solicitações disponíveis</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px' }}>Analise os detalhes dos pedidos, como requisitos, informações de localização e outras especificações relevantes, para encontrar as oportunidades mais adequadas ao seu perfil como pesquisador.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Descubra novas oportunidades</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px' }}>Todos os dias, novos pedidos são postados, oferecendo diferentes desafios e se adaptando às suas especialidades, experiências e expectativas.</Text_style_2>
                    <Stack flexDirection={'row'} justifyContent={'center'}>
                        <BTN_style_1 onClick={() =>{ if(currentUser){navigate('/publications')}else{navigate('/login')}}}>Começar a trabalhar</BTN_style_1>
                    </Stack>
                </Grid_item_2>
            </Grid_Wrapper_1>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>

            {/* comment 2 */}

            <Grid_Wrapper_2>
                <Grid_item_3>
                    <Text_style_2 sx={{ fontSize: '28px', fontWeight: 700, }}>Envie propostas personalizadas aos clientes</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '30px' }}>Aqui na DocsTree, você terá acesso a diversos clientes e solicitações para oferecer seus serviços como pesquisador.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Estabeleça o valor da proposta</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px' }}>Negocie com o cliente o preço que você considera justo para o trabalho, levando em conta suas experiências e prazos.</Text_style_2>
                </Grid_item_3>
                <Grid_item_4>
                    {width < 900 && <Box component='img' src={Pic2} height={'131px'} />}
                    {width >= 900 && <Box component='img' src={Pic2} height={'218px'} />}
                    <Text_number_2>
                        <Text_first>2</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number_2>
                </Grid_item_4>
            </Grid_Wrapper_2>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>

            {/* comment 3 */}

            <Grid_Wrapper_3>
                <Grid_item_5>
                    {width < 900 && <Box component='img' src={Pic3} height={'144px'} />}
                    {width >= 900 && <Box component='img' src={Pic3} height={'259px'} />}
                    <Text_number_3>
                        <Text_first>3</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number_3>
                </Grid_item_5>
                <Grid_item_6>
                    <Text_style_2 sx={{ fontSize: '28px', fontWeight: 700, }}>Proteção do seu trabalho e pagamentos com facilidade!</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '30px' }}>Nós garantimos a segurança do seu trabalho e você não precisa se preocupar em perseguir os clientes para receber seus pagamentos.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Garantia de pagamento</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px' }}>Os clientes depositam os fundos na DocsTree, garantindo que seu trabalho seja protegido. Assim que você concluir e entregar o serviço, os pagamentos serão liberados para sua conta.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Múltiplas opções de saque</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px' }}>Escolha entre diversas opções de pagamento, como MercadoPago, PayPal, Payoneer e muito mais, para receber seus ganhos.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 700, pt: '30px' }}>Obtenha feedback dos clientes</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 400, pt: '13px', pb: '38px' }}>Os clientes terão a oportunidade de fornecer feedback sobre o seu trabalho, permitindo que você receba avaliações e comentários sobre a experiência com o serviço prestado.</Text_style_2>

                </Grid_item_6>
            </Grid_Wrapper_3>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} onClick={()=>{navigate('/selectprofile'); window.scrollTo(0, 0);}}><BTN_style_1 >Seja pesquisador</BTN_style_1></Box>
        </RootStyle>
    )
}