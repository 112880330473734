import { styled } from '@mui/material/styles';
import { useState } from 'react'
import {
    InputBase,
} from '@mui/material';
import { useEffect } from 'react';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%'
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    maxWidth: '177px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 8px 6px 12px',
    borderRadius: '7px',
    // border: '1px solid #E2E4E5'
}));

const TextDescription = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    color: '#313131',
}));

const InputWrapper = styled('div')(({ theme }) => ({
    width: '40%',
}));

const TextRed = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 500,
    color: '#AB212F',
}));


export default function AmountDaysInput({ text, holder, expectNumber, redtext, setStateMoney, setStateDay, typeButton, setDays, days }) {
    const [validation, setValidation] = useState(false);
    const [border, setBorder] = useState('1px solid #E2E4E5');
    const [color, setColor] = useState('#313131');

    const valiFunc = (value) => {
        if (value < expectNumber) {
            setValidation(true);
            setBorder('1px solid #AB212F');
            setColor('#AB212F');
            if (typeButton == 1)
                setStateMoney('false');
            else
                setStateDay('false');
        }
        else {
            setValidation(false);
            setBorder('1px solid #E2E4E5 !important');
            setColor('#313131');
            if (typeButton == 1)
                setStateMoney('true');
            else
                setStateDay('true');
        }


    }

    const numericRegex = /^[0-9]+$/;
      
    // useEffect(()=>{
    //     alert(days)
    // },[days])

    return (
        <RootStyle>
            <ContainerStyle sx={{ border: { border } }}>
                <TextDescription sx={{ color: { color } }}>{text}</TextDescription>
                <InputWrapper>
                    <InputBase
                        sx={{ fontFamily: 'Poppins', fontSize: '14px', color: { color },textAlign:'right',alignContent:'end' }}
                        placeholder={holder}
                        fullWidth={true}
                        onChange={(e) => {
                            const value = e.target.value;
                            if ((value && Number.isInteger(Number(value))) || !value) { // check if the value contains only numeric digits
                                setDays(value);
                                valiFunc(value);
                            }
                            else if(value && !Number.isInteger(Number(value))){
                                setDays(0);
                                valiFunc(0);
                            }
                            else{
                                setDays(0);
                                valiFunc(0);
                            }
                        }}
                        type='number'
                        // step="1"
                        value={days >= 1 ? days : null}

                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        ></InputBase>
                </InputWrapper>
            </ContainerStyle>
            {validation && <TextRed>{redtext}</TextRed>}
        </RootStyle>
    )
}