/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import {
    Box,
} from '@mui/material';
import API from '../../redux/API';
import Tooltip from '@mui/material/Tooltip';
import ReactFlagsSelect from "react-flags-select";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InformationIcon from '../../assets/icons/informationicon.svg';
// import Autocomplete from "react-google-autocomplete";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '0px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const optionsToShow = [
    {   
        type:'ic_searchdocument',
        title:'Pesquisa',
        description:'A "Pesquisa de Documento" permite solicitar documentos desejados sem a necessidade de busca. Especialistas em genealogia e pesquisa documental encontrarão e disponibilizarão os documentos em formato digital para download com base em suas descrições e requisitos.'
    },
    {   
        type:'ic_fetchdocument',
        title:'Busca',
        description:'Na opção "Busca de Documento", os pesquisadores obtêm documentos de fontes físicas, como cartórios e instituições oficiais, seguindo regulamentações locais. Isso é útil para documentos físicos ou aqueles que exigem apostilamento para validação internacional. Os pesquisadores garantem a autenticidade e podem enviá-los digitalmente para confirmação antes do envio físico, se necessário.'
    },
]

export default ({
    ic_searchdocument,setic_searchdocument,
    ic_fetchdocument,setic_fetchdocument,
    nm_documentcountry, setnm_documentcountry,
    nm_documentlocation, setnm_documentlocation,
    id_documenttype, setid_documenttype,
    ds_familysearchprofile, setds_familysearchprofile,
    nm_otherdocument, setnm_otherdocument,
    ic_digitaldocument, setic_digitaldocument,
    ic_physicaldocument, setic_physicaldocument,
    ic_stampeddocument, setic_stampeddocument, progress, setProgress,
    needOtherType,setneedOtherType,
    setOtherDocOK,otherDocOK,setLocationSelected,locationSelected,

}) => {

    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [nm_documentlocation_aux, setnm_documentlocation_aux] = useState('');
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    function onPlaceSelect(e, ref) {
        console.error(e)
        console.log(ref.value)
        setnm_documentlocation(ref.value)
    }
    useEffect(()=>{
        if(documentTypeList.length === 0)
        {
            API.get(`order/getdocumenttypes?language=${'pt'}`)
            .then((result) => {
                setDocumentTypeList(result.data.typeList)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    }, []);
    const [stampAvailable, setStampAvailable] = useState(true);
    const checkStampAvailableCountry = (code) => {
        setic_stampeddocument(false)
        setic_physicaldocument(false)
        setic_digitaldocument(false)
        API.post(`order/checkhcchmember`,{code:code})
            .then((result) => {
                if(result.data.status==='success'){
                    setStampAvailable(true);
                }
                else{
                    setStampAvailable(false);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const [allcountries,setallcountries]=useState([])

    const getallcountries =()=>{
        API.post('/order/getallcountries')
        .then((res)=>{
            if(res.data.allcountries){
                var aux = []
                res.data.allcountries.map((single)=>{
                    const cd_isoalpha2 = single.cd_isoalpha2
                    aux.push({cd_isoalpha2:cd_isoalpha2})
                })
            }
            setallcountries(res.data.allcountries)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        getallcountries()
    },[])

    const getCountryNamePt = (isoAlpha2) => {
        const country = allcountries.find((c) => c.cd_isoalpha2 === isoAlpha2);
        return country ? country.nm_country_pt : '';
      };
    

    const customLabels = allcountries.reduce((obj, country) => {
        obj[country.cd_isoalpha2] = country.nm_country_pt;
        return obj;
      }, {});

      const [allLocations, setallLocations] = useState([]);
      const [place, setPlace] = useState('');
  
      useEffect(()=>{
  
      
      },[place])
  

      const handleInputChange = async (event,newInputValue) => {
        setLocationSelected(false)
        try {
            // setnm_documentlocation(newInputValue)
            const url = `https://beta.familysearch.org/platform/places/search?q=partialName:${newInputValue.replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`; 
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/x-gedcomx-atom+json',
                    Authorization: 'Bearer b0-8M20SkEBXmw.4TfUMAXvji6',
                },
            });
            setLocationSelected(false)
            
            if (response.ok) {
                // console.log(response);
                var data = await response.json();
                setLocationSelected(false)
                var uniqueEntries = [...data?.entries]
            if(data&&data.entries){
                setLocationSelected(false)
                for (let i = 0; i < data.entries.length; i++) {
                    setLocationSelected(false)
                    const single = data.entries[i];
                    if(single.content.gedcomx.places[0].display.fullName){
                        setLocationSelected(false)
                        var thisName = single.content.gedcomx.places[0].display.fullName
                        
                        if(data.entries.some((sing,ind)=>sing.content.gedcomx.places[0].display.fullName===thisName && ind!==i)){
                           setLocationSelected(false)
                           uniqueEntries.splice(i,1)
                        }
                    }
                    
                }
                data.entries = uniqueEntries
                setLocationSelected(false)
                const places = data?.entries?.map(singlePlace => singlePlace.content.gedcomx.places[0].display.fullName || '');
                setLocationSelected(false)
                setallLocations(places);
                return setLocationSelected(false)
            }
            return setLocationSelected(false)
          } 
        } catch (error) {
          console.error(error);
          return setLocationSelected(false)
        }
        return setLocationSelected(false)
      };


      useEffect(()=>{
        // handleInputChange()
      },[nm_documentlocation_aux])

    return (
            <ContainerStyle className='flex-col'>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131]" style={{color: "#313131"}}>Qual o objetivo dessa solicitação?</div>
                <div className='flex flex-col items-center'>
                    <div className='mb:w-full sm:w-[96%]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[25px] mb-[5px]">Forma de solicitação do documento: <span className='text-[#DE2B2B]'>*</span>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', gap:'20px', }}>
                            {optionsToShow && optionsToShow.map((single,index)=>{
                                return (
                                    <div key={index}>
                                    <FormControlLabel control={<Checkbox
                                        checked = {
                                            (single?.type === 'ic_searchdocument' && ic_searchdocument ? true
                                            :
                                            single?.type === 'ic_fetchdocument' && ic_fetchdocument ? true
                                            : false) 
                                            || false
                                        }
                                        onChange={(e)=>{
                                            if(!single.type){return}
                                            if(single?.type === 'ic_searchdocument'){return setic_searchdocument(e.target.checked)}
                                            if(single?.type === 'ic_fetchdocument'){return setic_fetchdocument(e.target.checked)}
                                            return
                                        }}
                                        sx={{
                                            color: '#313131',
                                            '&.Mui-checked': {
                                                color: '#17686E',
                                            }
                                        }}      
                                    />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131]" style={{fontWeight:'600'}}>{single?.title || ''}</div>
                                                                    {/*<Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">{single?.description || ''}</div>}>
                                        <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                    </Tooltip>*/}
                                    </div>} />
                                    <div style={{fontFamily:'Poppins', fontWeight:'400', fontSize:'14px', }}>
                                        {single?.description || ''}
                                    </div>
                                </div>    
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="font-[Poppins] font-medium text-[16px] color-[#313131]" style={{color: "#FF0000", fontWeight:'400', margin:'25px 0px 25px 0px'}}>Por favor, esteja ciente de que, ao optar por ambas as opções de 'Pesquisa de Documento' e 'Busca de Documento', o valor da proposta provavelmente será ligeiramente mais elevado devido à maior complexidade do trabalho envolvido.</div>

            </ContainerStyle>
    )
}