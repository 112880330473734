import { styled } from '@mui/material/styles';
import { Stack, FormControlLabel, FormGroup, Checkbox } from '@mui/material';

// -------------------------------------------------------------
const FilterTitleStyle = styled('p')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    margin: '0px 0px 10px',
});

// const InputBaseStyle = styled('input')({
//     color: '#313131',
//     fontFamily: 'Poppins',
//     fontSize: '30px',
// });
// -------------------------------------------------------------


export default function Filter1(props) {

    var filter = props.filter
    var filterList = props.filterList
    var setFilterList = props.setFilterList

    // { filter, filterList, setFilterList }

    // const [tempFilterList, setTempFilterList] = useState([]);
    // const dispatch = useDispatch();
    // // const filterList:String[] = useSelector((list:any) => list.filter.filterList);
    // // const clearFlag = useSelector((state:any) => state.filter.clearFlag);
    // const { flag, setFlag } = useState(false);
    // useEffect(() => {
    //     props.setFlag(false)
    //     setTempFilterList([])
    // }, [props.flag])
    // useEffect(() => {
    //     let removeTemp = tempFilterList;
    //     removeTemp.splice(props.removeList, 1);
    //     setTempFilterList(removeTemp)
    // }, [props.removeList])


    const handleFilterList = (item) => {
        let aux =[...filterList]
        const filterType = 'documentType'
        let flag = filterList.some(obj=>obj.name===item)
        if (flag) {
            aux = filterList.filter((e) => e.name != item)
        } else {
            aux.push({name:item,type:'documentType'})
        }
        props.postFilters(item, filterType)
            setFilterList(aux);
    }

    return (
        <>
            <FilterTitleStyle>Tipo de documento</FilterTitleStyle>
            <Stack>
                <FormGroup>
                    {props.filter.map((item, index) => (
                        <FormControlLabel key={index} 
                        onChange={() => {handleFilterList(item)}}



                            control={
                                <Checkbox


                                checked={props.ischecked(item,'documentType')}

                                
                                disabled={props.filtersBlock == true} 

                                sx={props.checkboxCSS}
                                />
                            }
                            label={item} size="small"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    color: '#313131',
                                }
                            }}
                        />
                    ))}
                </FormGroup>
            </Stack>
        </>
    )
}