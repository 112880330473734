// Importa a API
import API from "../API";

// Importa o componente "toast" do pacote "react-toast"
import { toast } from 'react-toast'
import { checkNeedRedirect, getNeedRedirect } from "../../components/CheckNeedRedirect";




// Função "register" para fazer o registro de um usuário
export function register(data, setLoadingOpen, navigate) {
    // Retorna uma função "async" para ser usada como "dispatch"
    return async (dispatch) => {
        // Faz uma requisição POST para a API com os dados de registro
        API.post("auth/register", data)
          // Caso a requisição tenha sucesso
          .then((result) => {
            // Verifica se o status da resposta é "success"
            if (result.data.status === "success") {
                // Exibe uma mensagem "toast" informando o usuário a verificar o código enviado para o email
                toast.info('Verifique se o código foi enviado para o seu e-mail.', {width: 'content-fit'})
                // Define o carregamento como "fechado"
                setLoadingOpen(false)
                // Executa a ação "setTempUser" passando o email do usuário temporário
                dispatch(setTempUser(result.data.user.ds_email))
                // Navega para a página "/validatecode" com um estado específico
                navigate('/validatecode', {state:{page: 3}})
            }
          })
          // Caso a requisição falhe
          .catch((err) => {
                // Exibe uma mensagem "toast" de erro com a mensagem de erro retornada pela API
                toast.error(JSON.parse(err.request.response).message)
                // Define o carregamento como "fechado"
                setLoadingOpen(false)
          });
      };
}

export function login(data, setLoadingOpen, navigate) {
    return async (dispatch) => {
        var navigateTo = '/account'
        // Faz uma requisição POST para o endpoint de login
        API.post("auth/login", data)
          .then((result) => {
            // Se a requisição retornou com sucesso, atualiza o estado da aplicação
            if (result.data.status === "success") {
                // toast.success('Entrada realizada com sucesso!')
                // Remove o token armazenado no localStorage e atribui o novo token recebido
                localStorage.removeItem("token");
                localStorage.setItem("token",result.data.data.token)
                // Esconde o loading e atualiza o estado da aplicação
                setLoadingOpen(false)
                dispatch(setUser(result.data.data.user));
                dispatch(setCurrentProfile(result.data.data.user.ic_profile%2));    
                if(result.data.data.user.ic_securitymode){
                    toast.info('Você tem a autenticação de dois fatores ativada.')
                    localStorage.setItem("has2step", 'true');
                    localStorage.setItem("is2step", '');
                    navigateTo = '/validatecode'
                }else{
                    localStorage.removeItem('has2step')
                    localStorage.removeItem('is2step')   
                }
                    const needRedirect = checkNeedRedirect()
                    const thisredirect = getNeedRedirect()
                    if (!needRedirect) {
                        return navigate(navigateTo)
                    }
                    
                        if (result.data.data.user.ic_profile>0 && thisredirect.type==='membership'){
                            dispatch(setCurrentProfile(1));    
                        }
                        return navigate(thisredirect.path,thisredirect.state)
                        // return navigate('/state')
                
            }
            else{
                toast.error('Erro ao logar')
            }
        })
          .catch((err) => {
            // Exibe uma mensagem de erro caso a requisição tenha falhado
                toast.error(JSON.parse(err.request.response).message)
                setLoadingOpen(false)
          });
    };
}

// Define a ação de armazenar um usuário temporário
export function setTempUser(data) {
    return { type: "setTempUser", data };
}

// Define a ação de armazenar o perfil atual do usuário
export function setCurrentProfile(data) {
    // Remove o item "currentprofile" do localStorage
    localStorage.removeItem("currentprofile");
    // Armazena o perfil atual do usuário no localStorage como string
    localStorage.setItem("currentprofile", JSON.stringify(data));
    // Retorna a ação "setCurrentProfile" e seus dados
    return { type: "setCurrentProfile", data };
}

// Define a ação de armazenar o usuário logado
export function setUser(data) {
    // Remove o item "user" do localStorage
    localStorage.removeItem("user");
    // Armazena o usuário logado no localStorage como string
    localStorage.setItem("user", JSON.stringify(data));
    // Retorna a ação "setUser" e seus dados
    return { type: "setUser", data };
}

//Função para fazer logout do usuário
export function logout(navigate, afterRoute, gapi) {
    return async (dispatch) => {
        
        //Remove os itens do local storage
        // localStorage.removeItem('token');
        // localStorage.removeItem('notification');
        // localStorage.removeItem('currentprofile')
        // localStorage.removeItem("has2step");
        // localStorage.removeItem("is2step");
        // localStorage.removeItem("redirect");
        const cookieConfirm = localStorage.getItem("cookieConfirm");
        localStorage.clear();
        if (cookieConfirm) {
          localStorage.setItem("cookieConfirm", cookieConfirm);
        }
    
        
        // Define o usuário como nulo
        dispatch(setUser(null));
        
        try {
            //Desconecta a conta do Google
            var auth2 = gapi.auth2.getAuthInstance();
            auth2.signOut().then(function () {
                //console.log('Usuário desconectado.');
            });
        } catch (error) {
            //Exibe o erro no console
            console.log(error)
        }

        //Redireciona para a rota especificada
        navigate(afterRoute);

    }
}

//Função para deletar o idioma do usuário
export function deleteMyLanguage(data) {
    return { type: "deleteMyLanguage", data };
}

//Função para deletar a especialidade do usuário
export function deleteMySpeciality(data) {
    return { type: "deleteMySpeciality", data };
}


