import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Box,
    Stack,
    Divider,
} from '@mui/material';
// components
import PencilIcon from '../../assets/icons/pencil.svg'
import RatingStar from '../../components/RatingStar'
import DetailCard from '../../components/card/DetailCard'
import { useDispatch } from 'react-redux';
import { setCurrentProfile } from '../../redux/actions/auth.action';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import API from '../../redux/API.js';
// icons
import MailBox from '../../assets/icons/mailBox.svg'
import OngoingBox from '../../assets/icons/ongoingBox.svg'
import CompleteBox from '../../assets/icons/completeBox.svg'
import { useSelector } from 'react-redux';
import { IMAGE_BASE } from '../../config/constants'
import { toast } from 'react-toast';
// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
}));

const TextWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '17px',
    minHeight: '31px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '53px',
        paddingBottom: '26px',
        minHeight: '39px'
    }
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#17686E',
    whiteSpace: 'nowrap',
    paddingRight: '10px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        paddingRight: '20px',
    }
}));

const TitleTextDisable = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#C6C6C6',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        paddingLeft: '30px',
    }
}));

const InfoWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    border: '1px solid #E2E4E5',
    borderRadius: '5px',

    flexDirection: 'column',
    alignItems: 'center',
    padding: '26px 0px 0px 0px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 23px 9px 16px',
    }
}));

const RatingText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
    whiteSpace: 'nowrap',
    lineHeight: "147.9%",
    fontStyle: 'normal'
}));

const BalanceText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '12px',
    color: '#313131',
}));

const BalanceNumberText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#313131',
}));

const TotalEarningNumberText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#313131',
}));

const TotalWrapper = styled('div')(({ theme }) => ({
    //   paddingLeft: '10px',
    // [theme.breakpoints.up('md')]: {
    //     paddingTop: '20px',
    //     paddingLeft: '0px',
    // }
}));

const BalanceWrapper =  styled('div')(({ theme }) => ({
    //  paddingTop: '30px',
    //  paddingLeft: '10px',
    // [theme.breakpoints.up('md')]: {
    //     paddingTop: '10px',
    //     paddingLeft: '0px',
    // },
    display: 'flex',
    justifyContent: 'space-between'
}));

const ContactCard = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '700px',
    paddingLeft: '5px',
    paddingTop: '0px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '12px',
        paddingLeft: '0px',
        maxWidth: '290px',
    }
}));

const CardsWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    paddingBottom: '27px',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
}));

export default function ExpertProfile({ data }) {
    const { width } = useWindowSize()
    const [cards, setCards] = useState([
        {
            id: 1,
            title: 'Propostas Enviadas',
            iconSrc: MailBox,
            number: data.openOrders || 0,
        },
        {
            id: 2,
            title: 'Em Andamento',
            iconSrc: OngoingBox,
            number: data.progressOrders || 0,
        },
        {
            id: 3,
            title: 'Concluído',
            iconSrc: CompleteBox,
            number: data.completeOrders || 0,
        }
    ])
    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [total, setTotal] = useState(0);
    useEffect(() => {
        API.get(`/order/getordercntwithresearcher?id_researcher=${currentUser.id === undefined ? currentUser._id : currentUser.id}`)
        .then((result) => {
            if (result.data.status === "success") {
                let temp = cards;
                temp[0].number = result.data.opened;
                temp[1].number = result.data.progress;
                temp[2].number = result.data.concluded;
                setCards(temp);
                setTotal(result.data.total);
            }})
            .catch((err) => {
                console.log(err)
            });  
        }, [])

        const [rating, setrating] = useState(0);
        useEffect(()=>{
            if(currentUser){
                const allreviels = [...currentUser.reviews]
                const allreviels_qtd = allreviels.length
                var allreviels_aux = 0
                allreviels.map((single)=>{
                    allreviels_aux+=single.nr_rating
                })
                setrating(allreviels_aux/allreviels_qtd)

            }
        },[currentUser])

        const [userFees,setuserFees]=useState(0)
        useEffect(()=>{
            API.post('user/gatplaninfo',{userID:currentUser._id})
            .then((res)=>{
                setuserFees(res.data.userFees)
                if (res.data.message) {
                    toast.success(res.data.message)
                }
            })
            .catch((error)=>{
                toast.error(error.message?error.message:'Erro ao atualizar dados do plano')
                console.log(error);
                navigate('/account')
            })
        },[])
    
    
    return (
        <RootStyle>
            <InfoWrapper>
                <ContactCard>
                    <Stack flexDirection={'row'}>
                        <Box component={'img'} src={IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+currentUser.nm_researcheravatarfile} width={'41px'} height={'41px'} className="rounded-[20px]"/>
                        <Box pl={'14px'} width='100%'>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} >
                                <RatingText>{currentUser.nm_user}</RatingText>
                                <Box component={'img'} src={PencilIcon} onClick={()=>{dispatch(setCurrentProfile(1)); navigate('/account')}} className="cursor-pointer w-[12.98px] h-[14px] mb:mr-[14px] lg:mr-[0px]" />
                            </Stack>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ pt: '20px' }}>
                                <RatingStar value={rating} size={20} emptyIconType={2} />
                                <RatingText className='mb:mr-[6.5px] lg:mr-[0px]'>{rating?rating.toFixed(2)+'/5':'Sem avaliações'}</RatingText>
                            </Stack>
                        </Box>
                    </Stack>
                    <TotalWrapper>
                        <BalanceWrapper className="mt-[20.34px]">
                            <BalanceText>Saldo disponível</BalanceText>
                            <BalanceText className='mb:mr-[6.5px] lg:mr-[0px]'>Meus ganhos</BalanceText>
                        </BalanceWrapper>
                    </TotalWrapper>
                    <TotalWrapper className={width>900?"":"mb-[20px]"}>
                        <BalanceWrapper>
                            <BalanceNumberText>R$ {userFees?Number.parseFloat(currentUser.vl_withdrawal*((userFees/100)+1)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.'):'0,00'}</BalanceNumberText>
                            <TotalEarningNumberText className='mb:mr-[6.5px] lg:mr-[0px]'>R$ {Number.parseFloat(currentUser.vl_totalwithdrawn).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}</TotalEarningNumberText>
                        </BalanceWrapper>             
                    </TotalWrapper>
                </ContactCard>
                {width > 900 && <Divider orientation="vertical" flexItem sx={{ marginLeft: '12px', marginRight: '34px' }} />}
                <CardsWrapper>
                    {cards.map((item, index) => (
                        <DetailCard key={index} data={item} />
                    ))}
                </CardsWrapper>
            </InfoWrapper>
        </RootStyle>
    )
}