import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


import locked from "../../assets/icons/locked.svg"

import { API_BASE } from "../../config/constants";


const fullScreen = {
    position: 'fixed',
    top:'0',
    width:'100%',
    height:'100%',
    // minHeight:'10%',
    // zIndex: '99999999',
    backgroundColor: 'rgba(102, 123, 125, 0.4)',
    // color: '#fff',
    // padding: '10px',
    // borderRadius: '10px',
    // display: 'flex', 
    // flexDirection: 'column', 
    // alignItems: 'center', 
    // justifyContent: 'center', 
    zIndex:'9999999999999999999',
    }

const root = {
    position: 'fixed',
    bottom:'5%',
    left:'10%',
    right:'10%',
    minWidth:'40%',
    maxWidth:'90%',
    minHeight:'15%',
    zIndex: '99999999',
    backgroundColor: '#fff',
    borderRadius: '15px',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    padding:'20px 40px 20px 40px',
    margin:'auto auto auto auto',
    gap:'10px',
    }
const rootMobile = {
    position: 'fixed',
    bottom:'5%',
    width:'90%',
    minHeight:'15%',
    zIndex: '99999999',
    backgroundColor: '#fff',
    borderRadius: '15px',
    display: 'flex', 
    flexDirection:'column',
    alignItems: 'center', 
    justifyContent: 'center', 
    padding:'20px 20px 20px 20px',
    marginLeft:'5%',

    }
  
  const BtnConfirm = styled('button')(({ theme }) => ({
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: 'black',
    border: '1px solid #17686E',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    // width: '100%',
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
        // minWidth: '180px',
        width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
        padding: '10px 17px 9px 16px',
    }
}));

const BtnFirst = styled('button')(({ theme }) => ({
    // color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    // backgroundColor: '#fffff',
    border: '1px solid',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontFamily: 'Poppins',
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
        // minWidth: '180px',
        width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
        padding: '10px 17px 9px 16px',
    }
}));
const label = { inputProps: { 'aria-label': 'Switch demo' } };



export default function CookieNotification (){

    const [isMobile, setisMobile]=useState(true)

    useEffect(()=>{
        setisMobile(window.innerWidth < 1000)
    },[])


    const [isCookieConfirmed, setisCookieConfirmed] = useState(false)
    
    const [page, setPage] = useState(0)

    useEffect(()=>{
        const hasconfimation = localStorage.getItem('cookieConfirm');
        if (hasconfimation) {
            setisCookieConfirmed(true)
        }    
        else{
            setisCookieConfirmed(false)
        }
    },[isCookieConfirmed])

    const confirmRead = ()=>{
        localStorage.setItem('cookieConfirm', 'true');
        setisCookieConfirmed(true)
    }



 
    return(
        <div style={isCookieConfirmed?{display:'none'}:fullScreen}>
        <div style={isMobile?rootMobile:root}>

            {page===0 &&
                <>
                    <div style={isMobile?{marginBottom: '20px',}:{ marginBottom: '20px', width:'70%'}}> 
                        <div style={{display:'flex',  }}>
                            <h4 style={{fontWeight:'bold', fontSize:'16px'}}>Sua privacidade</h4>
                            <img src={locked} width='15px' style={isMobile?{margin:'auto auto auto 5px',}:{margin:'5px auto auto 5px', }}/>
                        </div> 
                        <div style={{fontSize:'14px', textAlign:'justify'}}>
                            Usamos cookies e tecnologias semelhantes para ajudar a personalizar o conteúdo, adaptar e enviar anúncios e oferecer uma experiência melhor. Ao clicar em OK ou ativar uma opção em preferências de cookies, você concorda com isso, conforme descrito em nossa Política de Cookies. Para alterar as preferências ou retirar o consentimento, atualize suas Preferências de cookies.
                        </div>
                    </div>
                    <div style={isMobile?{display: 'flex', alignItems: 'center', margin:'auto auto auto auto' , justifyContent:'center'}:{ display: 'flex', alignItems: 'center', margin:'auto auto auto auto' , width:'30%', justifyContent:'end'}}>
                        <BtnFirst  onClick={()=>{setPage(1)}} > Preferências de cookies </BtnFirst>
                        <div style={{padding:'3px'}}></div>
                        <BtnConfirm  onClick={()=>{confirmRead()}} > OK </BtnConfirm>
                    </div> 
                </>
            }
            {page===1 &&
                <>
                    <div style={isMobile?{width:'100%',marginBottom: '20px',}:{ marginBottom: '20px', width:'70%'}}> 
                        <div style={{display:'flex',  }}>
                            <h4 style={{fontWeight:'bold', fontSize:'16px'}}>Sua privacidade</h4>
                            <img src={locked} width='15px' style={isMobile?{margin:'auto auto auto 5px',}:{margin:'5px auto auto 5px', }}/>
                        </div> 
                        <div style={isMobile?{fontSize:'14px', textAlign:'center'}:{fontSize:'14px', textAlign:'justify'}}>
                            Aqui estão todos os tipos de Cookies que utilizamos
                            <br/>
                            <FormControlLabel control={<Switch disabled defaultChecked />}  label="Cookies Essenciais"/>
                        </div>
                    </div>
                    <div style={isMobile?{display: 'flex', alignItems: 'center', margin:'auto auto auto auto' , justifyContent:'center'}:{ display: 'flex', alignItems: 'center', margin:'auto auto auto auto' , width:'30%', justifyContent:'end'}}>
                        <BtnFirst  onClick={()=>{setPage(0)}} > Retornar </BtnFirst>
                        <div style={{padding:'3px'}}></div>
                        <BtnConfirm  onClick={()=>{confirmRead()}} > OK </BtnConfirm>
                    </div> 
                </>
            }

        </div>
        </div>
    )
} 