import { styled } from '@mui/material/styles';
import { toast } from 'react-toast';
import Orders_main from './body';
import { useParams } from 'react-router-dom';
import API from '../../redux/API';
// import { IMAGE_BASE, IMAGE_BASE } from '../../config/constants';
import { useEffect, useState } from 'react';
import { toshowflag } from '../../components/showFlags/showFlags';
import { toshowCountryName } from '../../components/showFlags/showFlags';
import { current } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const IMAGE_BASE = require('../../config/constants')

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',

    margin: '10px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        // padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '10px'
    }
}));

// -------------------------------------------------------------

export default function Publications(props) {
    const navigate = useNavigate()
    const params = useParams()
    const [pageStart , setPageStart] = useState(true)
    const {currentUser} = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);

    const [card,setCard] = useState({})    
    const [ancestoralternative, setAncestoralternative] = useState([])
    const [family, setFamily] = useState({})
    const [life_event_dates, setlife_event_dates] = useState({
        baptism: {
            dt_baptism: null,
            dt_baptismalternativefrom: null,
            dt_baptismalternativeto: null,
            ds_baptismlocation: null,
        },
        birth: {
            dt_birth: null,
            dt_birthalternativefrom: null,
            dt_birthalternativeto: null,
            ds_birthlocation: null,
        },
        marriage: {
            dt_marriage: null,
            dt_marriagealternativefrom: null,
            dt_marriagealternativeto: null,
            ds_marriagelocation: null,
        },
        immigration: {
            dt_immigration: null,
            dt_immigrationalternativefrom: null,
            dt_immigrationalternativeto: null,
            ds_immigrationlocation: null,
        },
        death: {
            dt_death: null,
            dt_deathalternativefrom: null,
            dt_deathalternativeto: null,
            ds_deathlocation: null,
        },
    });
    const [viewer, setViewer] = useState(true)
    const [canAccess, setcanAccess] = useState(true)
    const [filterCountry , setFilterCountry] = useState([])
    const [allorder , setallorder] = useState({})
    const showFlag = toshowflag
    const showCountryName = toshowCountryName
    


    
    
    const isViewer = ()=>{

        // alert(card.authorEmail)
        // alert(currentUser.ds_email)
        if(card.authorEmail == currentUser.ds_email){
            setViewer(false)
        }
    }
const [isImported, setIsImported]=useState(false)
useEffect(()=>{
    if(allorder && allorder.tp_order){
        // is imported
        
        return setIsImported(true)
        
    }
    return setIsImported(false)
},[allorder])
useEffect(()=>{
    if(currentUser && currentUser.cd_researcher && allorder && allorder.tp_ordervisibility && allorder.allowUsers && allorder.allowUsers.length>0 && !(allorder.allowUsers).some(single=>single===currentUser.cd_researcher)){
        return setcanAccess(false)
    }
    return setcanAccess(true)
},[allorder,currentUser])

useEffect(()=>{
    if(!canAccess){
        toast.info('Você não tem acesso à essa ordem')
        return navigate('/account')
    }
    return 
},[canAccess])

useEffect(()=>{
    if (params) {
        
    API.post('order/singleorder', params)
    .then((result) => {
        if(result.data.status === 'success'){
            //toast.success('Sucesso.');
            setCard(result.data.order)
            setAncestoralternative(result.data.order.ancestoralternative)
            setFamily(result.data.order.family_members)
            setlife_event_dates(result.data.order.life_event_dates)
            setFilterCountry(result.data.filterCountry)
            setallorder(result.data.order.allorder)
            return setPageStart(false)
        }
    })
    .catch((err) => {
        toast.error('Erro: ' + err)
        return console.log(err);
    });
    }
},[])  


    return (
        <RootStyle>
            <ContainerStyle>
                <Orders_main
                    card={card}
                    filterCountry={filterCountry} 
                    family={family}
                    ancestoralternative={ancestoralternative}
                    life_event_dates={life_event_dates}
                    setlife_event_dates={setlife_event_dates}
                    viewer={viewer}
                    setViewer={setViewer}
                    isViewer={isViewer}
                    showFlag={showFlag}
                    showCountryName={showCountryName}

                    isImported={isImported}
                    allorder={allorder}

                    currentUser={currentUser}
                    currentProfile={currentProfile}
                />
            </ContainerStyle>
        </RootStyle >
    )
}