export function toshowflag(country, filterCountry){
    // return `twemoji:flag-${country}`

    const findingThisCountry = filterCountry.filter(element => element[2] == country)

    if(findingThisCountry.length > 0){
        return `twemoji:flag-${findingThisCountry[0][1]}`
        // return [`twemoji:flag-${findingThisCountry[0][1]}`, findingThisCountry[0][2]]
    }
    else{
        return 'país não encontrado'

        alert('país não encontrado')
    }
    
}



export function toshowCountryName(country, filterCountry){
    const findingThisCountry = filterCountry.filter(element => element[2] == country)

    
    if(findingThisCountry.length > 0){
        return (findingThisCountry[0][0])
    }
    else{
        return 'país não encontrado'
    }
}