import { styled } from '@mui/material/styles';
import {
    InputBase,
    Box,
} from '@mui/material';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%'
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 8px 10px 10px',
    borderRadius: '5px',
    border: '1px solid #E2E4E5',
    '&:hover' : {
        border: '1px solid #17686E'
    }
}));

export default function AmountInput( {IconSrc, holder, filterWithkeyword, keyword, setKeyword} ) {
    const alterNamekeyPress = (e) =>{
        if(e.keyCode == 13){
            e.preventDefault();
            filterWithkeyword(keyword)
        }
    }
    return (
        <RootStyle>
            <ContainerStyle>
                <Box component={'img'} src={IconSrc} width={20} />
                <InputBase
                    sx={{ fontFamily: 'Poppins', fontSize: '14px', colour: '#313131', paddingLeft: '10px' }}
                    placeholder= {holder}
                    value={keyword}
                    fullWidth={true}
                    onChange={(e)=>{
                        setKeyword(e.target.value);
                    }}
                    onKeyDown={alterNamekeyPress}
                />
                <IconButton
                    className='!ml-[40px]'
                    aria-label="close"
                    onClick={() => setKeyword('')}
                    sx={{
                        color: (theme) => theme.palette.black,
                        width: '20px',
                        height: '20px'
                    }}
                >
                    <CloseIcon className="!text-[20px]"/>
                </IconButton>
            </ContainerStyle>
        </RootStyle>
    )
}