/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toast';
import API from '../../redux/API';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
  fontFamily: 'Poppins',
  color: '#FFFFFF',
  borderRadius: '5px',
  backgroundColor: '#17686E',
  fontSize: '14px',
  width: '180px',
  height: '40px',
  textAlign: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  padding: '8px',
  marginTop: '11px',
  lineHeight: '21px',
}));

export default () => {
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    const dispatch = useDispatch();
    const [curPassword, setCurPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [renewPassword, setRenewPassword] = useState('');
    
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const handleClickShowPassword1 = () => {
      setShowPassword1(!showPassword1);
    };
  
    const handleMouseDownPassword1 = (event) => {
      event.preventDefault();
    };
    const handleClickShowPassword2 = () => {
      setShowPassword2(!showPassword2);
    };
  
    const handleMouseDownPassword2 = (event) => {
      event.preventDefault();
    };
    const { currentUser } = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);

    const changePassword = () =>{
      if(newPassword !== renewPassword){
          toast.error('A nova senha não corresponde.');
      }
      else{
        const data = {
          ds_email: currentUser.ds_email,
          oldpassword: curPassword,
          newpassword: newPassword
        }
        API.post('/user/setnewpassword', data)
        .then((result) => {
          if(result.data.status === 'success'){
             toast.success('A senha foi alterada com sucesso.');
          }
        })
        .catch((err) => {
          toast.error(JSON.parse(err.request.response).message)
          console.log(err);
      });
      }
    }
    return (
      <>
        <div className='w-[95%] float-right'>
            <div className='w-[100%] '>
                <div className='w-full '>
                    <div className='w-full mb-[20px]'>
                        <div className={style.title}>Senha atual</div>
                        <div>
                            <OutlinedInput
                                className="w-full"
                                value = {curPassword}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => {setCurPassword(e.target.value)}}
                                InputProps={{
                                    className: style.content
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        { showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                hiddenLabel="hiddenLabel"
                                variant="outlined"/>
                        </div>
                    </div>
                    <div className='w-full mb-[20px]'>
                        <div className={style.title}>Nova Senha</div>
                        <div>
                            <OutlinedInput
                                className="w-full"
                                value = {newPassword}
                                type={showPassword1 ? 'text' : 'password'}
                                onChange={(e) => {setNewPassword(e.target.value)}}
                                InputProps={{
                                    className: style.content
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword1}
                                        edge="end"
                                      >
                                        { showPassword1 ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                }
                                hiddenLabel="hiddenLabel"
                                variant="outlined"/>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className={style.title}>Redigite a nova senha</div>
                        <div>
                            <OutlinedInput
                                className="w-full"
                                value = {renewPassword}
                                type={showPassword2 ? 'text' : 'password'}
                                onChange={(e) => {setRenewPassword(e.target.value)}}
                                InputProps={{
                                    className: style.content
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword2}
                                        onMouseDown={handleMouseDownPassword2}
                                        edge="end"
                                      >
                                        { showPassword2 ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                hiddenLabel="hiddenLabel"
                                variant="outlined"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mb:mt-[0px] xl:mt-[258px] mb:text-center xl:float-right mb:mr-[0px] xl:mr-[20px] mb-[16px] ">
          <HeaderBTNStyle disabled={!curPassword || !newPassword || !renewPassword} onClick={changePassword} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Salvar</HeaderBTNStyle>
        </div>
        </>
    )
}