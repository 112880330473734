import { styled } from '@mui/material/styles';
// components
import Slide_part from './slideParts';
import How_does_it_works from './howDoesItWorks';
import Why_we_use_this_tool from './whyWeUse';
import FAQs from '../../components/FAQs';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    alignItems: 'center',
    // overflow:'auto',

    margin: '10px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        // padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '10px'
    }
}));

export default function Publications(props) {

    const location = useLocation();

    useEffect(() => {
        if (location.hash === "#how") {
          const element = document.getElementById("how");
          if (element) {
            element.scrollIntoView();
          }
        }
      }, [location]);

    return (
        <RootStyle>
            <Slide_part />
            <ContainerStyle id='how'>
                <How_does_it_works />
            </ContainerStyle>
                <Why_we_use_this_tool />
            {/* <ContainerStyle>
                <FAQs />
            </ContainerStyle> */}
        </RootStyle>
    )
}