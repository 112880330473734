import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DatePickerIcon from '../../assets/icons/datepickericon.svg';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Grid,
  InputBase,
  Box,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import 'dayjs/locale/pt-br';
import { useState } from 'react';
import { toast } from 'react-toast';
import ShowLife_event_dates from './showLife_event_dates';
import { type } from '@testing-library/user-event/dist/type';

const TitleStyle_header = styled('div')({
  fontSize: '14px',
  color: '#313131',
  fontFamily: 'Poppins',
});

const Inputbase_wrapper = styled('div')(({ theme }) => ({
  border: '1px solid #E2E4E5',
  borderRadius: '7px',
  padding: '8px 15px',
  marginTop: '3px',
  marginBottom: '25px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '30px',
  }
}));

const panel_lists = ["Batismo", "Nascimento", "Casamento", "Imigração", "Óbito"]

const DateIcon =() => {
  return (
    <Icon  className="">
      <Box component={'img'} src={DatePickerIcon} className='w-[17px] h-[17px] ml-[3px] mt-[3px] flex justify-center items-center'/>
    </Icon>
  );
}

export default function ControlledAccordions(props) {

  const readOnly = props.readOnly
  const life_event_dates = props.life_event_dates
  const setlife_event_dates = props.setlife_event_dates
  const [allLocations, setallLocations] = useState([]);
  const [flag, setFlag] = React.useState(new Array(5).fill(null))
  const [flag1, setFlag1] = React.useState(new Array(5).fill(null))
  const [flag2, setFlag2] = React.useState(new Array(5).fill(null))
  const [flag3, setFlag3] = React.useState(new Array(5).fill(null))
  const [flag4, setFlag4] = React.useState(new Array(5).fill(null))

  const getValue = (flag, index) => {
    if(flag === 0){
      if(index === 0){         return life_event_dates.baptism.dt_baptism}
      else if(index === 1){    return life_event_dates.baptism.dt_baptismalternativefrom}
      else if(index === 2){    return life_event_dates.baptism.dt_baptismalternativeto}
      else if(index === 3){    return life_event_dates.baptism.ds_baptismlocation}
    }
    else if(flag === 1){
      if(index === 0){         return life_event_dates.birth.dt_birth}
      else if(index === 1){    return life_event_dates.birth.dt_birthalternativefrom}
      else if(index === 2){    return life_event_dates.birth.dt_birthalternativeto}
      else if(index === 3){    return life_event_dates.birth.ds_birthlocation}
    }
    else if(flag === 2){
      if(index === 0){         return life_event_dates.marriage.dt_marriage}
      else if(index === 1){    return life_event_dates.marriage.dt_marriagealternativefrom}
      else if(index === 2){    return life_event_dates.marriage.dt_marriagealternativeto}
      else if(index === 3){    return life_event_dates.marriage.ds_marriagelocation}
    }
    else if(flag === 3){
      if(index === 0){         return life_event_dates.immigration.dt_immigration}
      else if(index === 1){    return life_event_dates.immigration.dt_immigrationalternativefrom}
      else if(index === 2){    return life_event_dates.immigration.dt_immigrationalternativeto}
      else if(index === 3){    return life_event_dates.immigration.ds_immigrationlocation}
    }
    else if(flag === 4){
      if(index === 0){         return life_event_dates.death.dt_death}
      else if(index === 1){    return life_event_dates.death.dt_deathalternativefrom}
      else if(index === 2){    return life_event_dates.death.dt_deathalternativeto}
      else if(index === 3){    return life_event_dates.death.ds_deathlocation}
    }
  }
  
  const setValue = (flag, index, value) => {

    console.log(flag, index, value)

    var aux = {...life_event_dates}

    const today = new Date();
    if(index===0||index===1||index===2){
      if(value>today){
        value = null
        toast.error('Digite uma data anterior à atual');
      }
    }

    if(flag === 0){

      if(index === 0){          
        aux.baptism.dt_baptism = value
        setlife_event_dates(aux)
      }
      else if(index === 1){   
        aux.baptism.dt_baptismalternativefrom = value  
         setlife_event_dates(aux)
        }
      else if(index === 2){     
        aux.baptism.dt_baptismalternativeto = value  
        setlife_event_dates(aux)
      }
      else if(index === 3){     
        aux.baptism.ds_baptismlocation = value  
        setlife_event_dates(aux)
      }

    }

    else if(flag === 1){
      if(index === 0){     
        aux.birth.dt_birth = value  
        setlife_event_dates(aux)
      }
      else if(index === 1){     
        aux.birth.dt_birthalternativefrom = value  
        setlife_event_dates(aux)
      }
      else if(index === 2){     
        aux.birth.dt_birthalternativeto = value  
        setlife_event_dates(aux)
      }
      else if(index === 3){     
        aux.birth.ds_birthlocation = value  
        setlife_event_dates(aux)
      }
    }

    else if(flag === 2){
      if(index === 0){     
        aux.marriage.dt_marriage = value  
        setlife_event_dates(aux)
      }
      else if(index === 1){     
        aux.marriage.dt_marriagealternativefrom = value  
        setlife_event_dates(aux)
      }
      else if(index === 2){     
        aux.marriage.dt_marriagealternativeto = value  
        setlife_event_dates(aux)
      }
      else if(index === 3){     
        aux.marriage.ds_marriagelocation = value  
        setlife_event_dates(aux)
      }
    }

    else if(flag === 3){
      if(index === 0){     
        aux.immigration.dt_immigration = value  
        setlife_event_dates(aux)
      }
      else if(index === 1){     
        aux.immigration.dt_immigrationalternativefrom = value  
        setlife_event_dates(aux)
      }
      else if(index === 2){     
        aux.immigration.dt_immigrationalternativeto = value  
        setlife_event_dates(aux)
      }
      else if(index === 3){     
        aux.immigration.ds_immigrationlocation = value  
        setlife_event_dates(aux)
      }
    }

    else if(flag === 4){
      if(index === 0){     
        aux.death.dt_death = value  
        setlife_event_dates(aux)
      }
      else if(index === 1){     
        aux.death.dt_deathalternativefrom = value  
        setlife_event_dates(aux)
      }
      else if(index === 2){     
        aux.death.dt_deathalternativeto = value  
        setlife_event_dates(aux)
      }
      else if(index === 3){     
        aux.death.ds_deathlocation = value  
        setlife_event_dates(aux)
      }
    }
  }

  const handleInputChange = async (event, newInputValue,index) => {
    try {
        setValue(index, 3, newInputValue)
      const url = `https://beta.familysearch.org/platform/places/search?q=partialName:${newInputValue.replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`; 
      const response = await fetch(url, {
        headers: {
          Accept: 'application/x-gedcomx-atom+json',
          Authorization: 'Bearer b0-8M20SkEBXmw.4TfUMAXvji6',
        },
      });

      if (response.ok) {
        var data = await response.json();
        var uniqueEntries = [...data?.entries]
        if(data&&data.entries){
          for (let i = 0; i < data.entries.length; i++) {
            const single = data.entries[i];
            if(single.content.gedcomx.places[0].display.fullName){
              var thisName = single.content.gedcomx.places[0].display.fullName
                
              if(data.entries.some((sing,ind)=>sing.content.gedcomx.places[0].display.fullName===thisName && ind!==i)){
                uniqueEntries.splice(i,1)
              }
            }
          }
        }
        data.entries = uniqueEntries
        const places = data?.entries?.map(singlePlace => singlePlace.content.gedcomx.places[0].display.fullName || '');
      setallLocations(places);
      } 
    } catch (error) {
      console.error(error);
    }
  };
  
  // useEffect(()=>{
  //   alert(JSON.parse(life_event_dates))
  // },[life_event_dates])

  return (
    <div>
      {panel_lists.map((item, index) => (
        <Accordion
        key={index}
          sx={{
            boxShadow: 'none',
            margin: '0px !important',
            borderBottom: '1px solid #E2E4E5',
            '& .MuiAccordionDetails-root' : {
              borderBottom: 'none !important',
            }
          }}
          expanded={flag[index]}
        >
          <AccordionSummary
            onClick={() => {
              const temp = [...flag];
              temp[index] = !temp[index];
              setFlag(temp);
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel" + { index } + "1bh-content"}
          >
            <Typography
              sx={{ width: '33%', flexShrink: 0, fontFamily: 'Poppins', fontSize: '14px', fontWeight:(getValue(index, 0) || getValue(index, 1) || getValue(index, 2) || getValue(index, 3))?'bold':500, color:(getValue(index, 0) || getValue(index, 1) || getValue(index, 2) || getValue(index, 3))?'#17686E':'#313131'}}
            >
              {item}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
                border: 'none ! important',
            }}
          >
            <div className="flex mb:flex-col lg:flex-row ml-[20px]">
                <div className="mr-[20px] mb-[10px]">
                    <TitleStyle_header>Data</TitleStyle_header>
                    <Box sx={{ mt: '6px', width: '177px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
                        <DesktopDatePicker 
                          inputFormat="DD/MM/YYYY"
                          components={{
                            OpenPickerIcon: DateIcon
                          }}                          
                          readOnly={readOnly}
                          value={getValue(index, 0)||''}
                          onChange={(newValue) => {
                            setValue(index, 0, newValue)
                          }}
                          renderInput={(params) => <TextField {...params} size="small" />}
                          disabled={props.viewer == true}
                          disableFuture
                          maxDate={new Date()}
                        />
                      </LocalizationProvider>
                    </Box>
                </div>
                <div className=" mr-[0px] mb-[10px] mb:justify-between md:justify-start mb: flex-col md:flex-row">
                    <TitleStyle_header style={{ whiteSpace: 'nowrap' }}>Período de {item.toLowerCase()} alternativo</TitleStyle_header>
                    <div className='mr-[8px] flex flex-col md:flex-row'>
                      <div className='mr-[8px]'> 
                        <Box className="md:w-[200px] mb:w-[177px] mt-[6px]">
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              components={{
                                OpenPickerIcon: DateIcon
                              }}
                              readOnly={readOnly}
                              value={getValue(index, 1)||''}
                              onChange={(newValue) => {
                                setValue(index, 1, newValue)
                              }}
                              renderInput={(params) => <TextField {...params} sx={{fontSize:'14px'}}size="small" />}
                              disabled={props.viewer == true}
                            />
                          </LocalizationProvider>
                        </Box>
                      </div>
                      <div className='mr-[8px]'>
                        <Box className="md:w-[200px] mb:w-[177px] mt-[6px]">
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
                            <DesktopDatePicker
                              components={{
                                OpenPickerIcon: DateIcon
                              }}                              
                              readOnly={readOnly}
                              inputFormat="DD/MM/YYYY"
                              value={getValue(index, 2)||''}
                              onChange={(newValue) => {
                                setValue(index, 2, newValue)
                              }}
                              renderInput={(params) => <TextField {...params} size="small"
                              />}
                              disabled={props.viewer == true}
                            />
                          </LocalizationProvider>
                        </Box>
                      </div>
                    </div>
                </div>
               
                <div className='w-[100%] mr-[20px] mb-[20px]'>
                    <TitleStyle_header>Localização</TitleStyle_header>


                    <Stack spacing={2} sx={{ width: '100%' }} className="md:w-[200px] mb:w-[177px] mt-[6px]">
                    <Autocomplete
                    disabled={props.viewer === true} 
                                            sx={{
                                                'input': {
                                                    // height:'8px',
                                                },
                                                '& label':{
                                                    // margin:'-5px auto auto 3px',
                                                }
                                              }}
                                              value={getValue(index, 3)||''}
                                              onChange={(event, newValue) => {
                                                setValue(index, 3, newValue)
                                              }}
                                              onInputChange={(event, newInputValue)=>{handleInputChange(event, newInputValue, index)}}
                                            options={allLocations}
                                            renderInput={(params) => { 
                                                // console.log(params); 
                                                return <TextField {...params} size="small"
                                                label="Pesquisar" 
                                                // value={getValue(index, 3)}
                                                // onChange={(event, newValue) => {
                                                //   setValue(index, 3, newValue)
                                                // }}
                                                />
                                            }}
                                        />

                    {/* <Autocomplete
                        disabled={props.viewer === true}                                                        
                        value={getValue(index, 3)}
                        onChange={(event, newValue) => {
                          setValue(index, 3, newValue)
                        }}
                            onInputChange={(event, newInputValue)=>{handleInputChange(event, newInputValue, index)}}
                            options={allLocations}
                            renderInput={(params) => (<TextField {...params} label="Pesquisar" />)}
                    /> */}
                    </Stack>


                        {/* <Autocomplete
                            className={"!h-[40px] !mt-[6px] w-full text-[#313131] text-[14px] border-solidi border-[1px] virder-[#E2E4E5] !rounded-[5px] !pl-[10px]"}
                            placeholder=" "
                            value={getValue(index, 3) }
                            apiKey="AIzaSyBFA1L0AQrXt-kTQvche5bs8iGYJnT_99M"
                            disabled={props.viewer === true}                                                        
                            // onPlaceSelected={(e) => {setValue(index, 3, e.value);alert(JSON.stringify(e))}}                            
                            // onChange={(newValue) => {setValue(index, 3, newValue.target.value)}}
                            // onPlaceSelected={(e, ref)=> {
                            //     console.error(e)
                            //     console.log(ref.value)
                            //   setValue(index, 3, ref.value)
                            // }}                            
                            // onPlaceSelected={(newValue)=> {                              
                            //   setValue(index, 3, newValue.formatted_address)
                            // }}                          
                            onChange={(newValue) => { setValue(index, 3, newValue.target.value)}}
                            onPlaceSelected={(e) => { setValue(index, 3, e.formatted_address)}}
                        /> */}

                </div>
            </div>
          </AccordionDetails>
        </Accordion>
))}
    </div>
  );
}