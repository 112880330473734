/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    TextareaAutosize,
    Box
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Milestone from './request.js';
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import API from '../../redux/API.js';
import GoBackIcon from '../../assets/icons/go_back_icon.svg'
import { useLocation } from 'react-router-dom';

import Loading from '../../components/loading/Loading.js';
import { toast } from 'react-toast';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));


export default (props) => {
    // const {orderid} = useParams();
    const {currentUser} = useSelector(state => state.auth);
    const location = useLocation();


    const [loading,setLoading] = useState(false);


    const [orderid,setOrderID]=useState(null)
    const [order, setOrder] = useState(null);
    const [researcherData, setresearcherData] = useState(null);
    const { currentProfile } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const result = useSelector((state) => state.updateState);    
    const [data, setData] = useState([]);

    useEffect(()=>{
        
        const path = location.pathname;
        const id = path.split('/')[2];
        
        setOrderID(id)

            // API.get(`order/getorderbyid?orderid=${id}`)
            // .then((result) => {
            //     setOrder(result.data.order);
            //     setOptionList(result.data.orderstatuslist);
            // })
            // .catch((err) => {
            //     console.error(err.request.response)
            // });


    },[])

    useLayoutEffect(() => {
        setLoading(true)
        if(!order && orderid){
            API.get(`order/getorderbyid?orderid=${orderid}`)
            .then((result) => {
                setLoading(false)
                setOrder(result.data.order);
                if (result.data.order.cd_orderstatus>1) {
                    setisActive(false)
                }    
                setOptionList(result.data.orderstatuslist);
                setData(result.data.datatoShow);
                setresearcherData(result.data.researcherData)
                if(result.data.order.dt_lastupdatedevent){
                    let cur = new Date();
                    //console.log((cur - new Date(result.data.order.dt_lastupdatedevent)))
                    if(Math.floor((cur - new Date(result.data.order.dt_lastupdatedevent))/ 1000 / 60 / 60 / 24 ) >= 15){
                        setShowWarning(false);
                    }
                    else setShowWarning(true);
                }
            })
            .catch((err) => {
                navigate('/');
                console.error(err.request.response)
                setLoading(false)

            });
        }
    }, [orderid]);


    // useEffect(() => {
    //     API.get(`order/getorderbyid?orderid=${orderid}`)
    //     .then((result) => {
    //         setOrder(result.data.order);
    //         setOptionList(result.data.orderstatuslist);
    //         setData(result.data.datatoShow);
    //         // setresearcherData(result.data.researcherData)
    //         if(result.data.order.dt_lastupdatedevent){
    //             let cur = new Date();
    //             console.log((cur - new Date(result.data.order.dt_lastupdatedevent)))
    //             if(Math.floor((cur - new Date(result.data.order.dt_lastupdatedevent))/ 1000 / 60 / 60 / 24 ) >= 15){
    //                 setShowWarning(false);
    //             }
    //             else setShowWarning(true);
    //         }
    //     })
    //     .catch((err) => {
    //         // navigate('/');
    //         console.error(err.request.response)
    //     });
    // }, [result]);
    const {type} = props;
    const [option, setOption] = useState(0);
    const [comment, setComment] = useState('');
    const [submited, setSubmited] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [isActive, setisActive] = useState(true);
    const orderNotification_expert = "Atualize seu cliente com o status atual do seu pedido, independente se o processo está na mesma etapa da última vez, dessa forma ele poderá ficar frequentemente atualizado, ao invés de aguardar cansalvemente por uma simples atualização.";
    const orderNotification_customer = "Aqui estão os status atuais do seu pedido, independente se o processo está na mesma etapa da última vez, dessa forma você poderá ficar frequentemente atualizado."

    //dynamic separate line property
    const property = {borderColor:"813131", bgColor: "813131", thickness: '4'};
    const lineproperty = {borderColor:`border-[#${property.borderColor}]`, bgColor: `bg-[#${property.bgColor}]` };

    //dynamic optionlist
    const [optionList, setOptionList] = useState([])
    // dynamic timelimit
    const timeLimit = 15;
    const selectOption = (event) => {
        setOption(event.target.value);
    }
    const commentChange = (event) => {
        setComment(event.target.value);
    }
    const submitRequest = (event) => {
        setLoading(true)

        setComment('');
        const temp = {
            nm_event: "Atualização",
            nm_status: "",
            nm_title: option,
            dt_event: new Date(),
            ds_content: comment,
            ic_researcherupdate: true,
            ic_firstevent: true,
            ic_confirmed : false,
            ic_canconfirmed: false
        }
        API.post('order/addevent', {
            id: orderid,
            event: temp
        })
        .then((result) => {
            setLoading(false)

            window.location.reload()
            setOrder(result.data.order);
            setData(result.data.order.event);
            if(result.data.order.dt_lastupdatedevent){
                let cur = new Date();
                //console.log((cur - new Date(result.data.order.dt_lastupdatedevent)))
                if(Math.floor((cur - new Date(result.data.order.dt_lastupdatedevent))/ 1000 / 60 / 60 / 24 ) >= 15){
                    setShowWarning(false);
                }
                else setShowWarning(true);
            }

        })
        .catch((err) => {
            setLoading(false)
            navigate('/');
            console.error(err.request.response)
        });
        setOption(0);
    }
    const { width } = useWindowSize()
    const statusInfo = [
        {
            status: 'Em aberto',
            flags:['Proposta enviada', 'Em negociação', 'Aguardando cliente']
        },
        {
            status: 'Em andamento',
            flags:['Pesquisando', 'Aguardando análise']
        },
        {
            status: 'Concluído',
            flags:['Pagamento em andamento', 'Concluído']
        },
        {
            status: 'Cancelado',
            flags:['Proposta recusada', 'Cancelado']
        }
    ]

    const getFormatedDate = (createdAt) => {
        let date = new Date(createdAt);
        const yy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yy;
    }



    useEffect(()=>{
        // auth if is a valid researcher
        const thisUserID = currentUser.id ? currentUser.id : currentUser._id;
        if(currentUser && order && (currentProfile===0 || currentProfile===1)){
            if(currentProfile===1){
                var id_researcher = order.id_researcher._id?order.id_researcher._id:order.id_researcher.id
                    if(id_researcher !== thisUserID ) {
                        navigate("/publications");
                    }
                }
            if(currentProfile===0){
                //toast.info('Página de update')
                var id_customer = order.id_customer._id?order.id_customer._id:order.id_customer.id
                    if(id_customer !== thisUserID){
                        navigate("/publications");
                    }
            }
        }
    },[order,currentUser,currentProfile])


    return (
       <RootStyle>
            {loading === true && 
            
            <>
                <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                }}
                >

                <Loading />
            </div>
            </>
            
            }


           {
            order &&
            <ContainerStyle className = '!mt-[40px]'>
                <div className='flex justify-between !mb-[20px]'>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">Cliente</div>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]" >Pesquisador</div>                    
                </div>
                <div className='flex justify-between  !mb-[30px]'>
                    <div className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{order.id_customer.nm_user}</div>
                    {/* <Link to={'/expertprofile/'+order.id_researcher._id} className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{order.id_researcher.ic_researchertype ? order.id_researcher.nm_user : order.id_researcher.nm_company}</Link>      */}
                    <div className="flex">
                        {currentProfile === 0 ?
                            <Link to={`/orderdetail/${orderid}`} className="mr-[20px] "><Box component="img" src={GoBackIcon} alt="voltar-icone" width='20px' height='20px'/></Link>
                            :
                            null
                        }
                        {currentProfile === 1 ?
                            <Link to={`/orderdetail_researcher/${orderid}`} className="mr-[20px] "><Box component="img" src={GoBackIcon} alt="voltar-icone" width='20px' height='20px'/></Link>
                            :
                            null
                        }
                        {researcherData!==null?
                            <Link to={'/expertprofile/'+researcherData._id} className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{researcherData.nm_user}</Link>
                            :
                            null
                        }
                    </div>
                </div>
                <div className='border-solid border-[1px] bg-[#C6C6C6] w-[100%] opacity-91 h-[1px]'></div>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px]">Informações do documento</div>
                <div className="rounded-[7px] border-solid border-[1px] border-[#C6C6C6] mt-[20px]">
                    <div className='w-[100%] mb:block xl:flex mt-[18px] ml-[18px] mr-[20px] mb:mb-[30px] xl:mb-[10px] text-left'>
                        <div className='mb:w-full xl:w-[20%] mb:mt-[15px] xl:mt-[0px]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Nome completo:</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{order.nm_ancestor}</div>
                        </div>
                        <div className='mb:w-full xl:w-[12%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Pedido</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{order.cd_order}</div>
                        </div>
                        <div className='mb:w-full xl:w-[20%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Tipo de documento </div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{order.id_documenttype.nm_documenttype}</div>
                        </div>
                        <div className='mb:w-full xl:w-[15%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Status</div>
                            <div className='flex items-center h-[42px]'>
                                <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                    {/* <div className={"w-[6px] h-[6px] rounded-[5px] ml-[5.5px] mr-[5.5px] "+(order.cd_orderstatus == 0 ? 'bg-[#E2BD7B]' : order.cd_orderstatus == 1 ? 'bg-[#4A4AFF]' : order.cd_orderstatus == 2 ? 'bg-[#17686E]' : 'bg-[#BA483A]')}></div> */}
                                    {/* <Box component={'img'} src={Ellipse} className='w-[6.65px] h-[6px] bg-[#4A4AFF] ml-[5.5px] mr-[5.5px] rounded-[50%]'/> */}
                                    <div className={"font-[Poppins] font-medium text-[12px] leading-[15px] mt-[3px] w-[95px] "+(order.cd_orderstatus == 0 ? 'text-[#E2BD7B]' : order.cd_orderstatus == 1 ? 'text-[#4A4AFF]' : order.cd_orderstatus == 2 ? 'text-[#17686E]' : 'text-[#BA483A]')}>{statusInfo[order.cd_orderstatus].status}</div>
                                </div>
                            </div>
                        </div>
                        <div className='mb:w-full xl:w-[12%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Data</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{getFormatedDate(order.createdAt)}</div>
                        </div>
                        <div className='mb:w-full xl:w-[10%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Pagamento</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center mt-[6px] h-[42px]'>
                            
                                {/* {order.id_customer.ds_paymentmethod} {width > 1280 ? <br></br>: ""} {order.vl_budget?"R$"+Number.parseFloat(order.vl_budget).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.'):null} */}

                                {order.id_customer.ds_paymentmethod} 
                                {
                                    width > 1280 ? 
                                        <br></br>
                                        :
                                        ''
                                }
                                {
                                    !order.vl_budget? 
                                        <br></br>
                                        : 
                                        `R$ ${Number.parseFloat(order.vl_budget).toFixed(2).replace('.', ',')}`
                                } 

                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px]">Notificações do pedido</div>
                {isActive &&
                <>
                {
                    showWarning  ?
                    <div className="flex justify-center bg-[#D9D9D9] bg-opacity-20 mt-[30px] ">
                        <div className="font-[Poppins] font-semibold text-center text-[14px] leading-[100%] text-[#BA483A] mb:mt-[10px] mb:mb-[10px] lg:mt-[20px] lg:mb-[20px] mb:w-[90%] md:w-full">Você só poderá {currentProfile === 0  ? 'receber uma nova notificação do pesquisador daqui ' : 'enviar uma nova notificação para o cliente daqui'}  {timeLimit} dias.</div>
                    </div> :
                    null
                }

                <p className="font-[Poppins] font-normal text-[16px] leading-[150%] text-[#313131] mt-[20px] self-stretch">
                    {currentProfile === 1  ? orderNotification_expert : orderNotification_customer}
                </p>
                {
                    currentProfile === 1 ?
                    <div>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[20px] mb-[3px]">Status atual do pedido <span className='text-[#DE2B2B]'>*</span></div>
                        <div>
                            <Select
                                value={option}
                                onChange={selectOption}
                                displayEmpty
                                disabled = {showWarning}
                                className="w-[100%]  "
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={{
                                    sx:{
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(23, 104, 110, 0.11)"
                                        },
                                        '& .MuiMenuItem-root': {
                                            whiteSpace: 'normal',
                                        },
                                    }
                                }} 
                                >
                                    <MenuItem key={0} value={0}><p className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">{'-- Escolha um motivo --'}</p></MenuItem>
                                {   
                                
                                    //dynamic
                                    optionList.map((item) => {
                                        return (
                                            <MenuItem key={item.nm_currentorderstatus} value={item.nm_updatetype}><p className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">{item.nm_updatetype}</p></MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[20px] mb-[3px]">Digite seus comentários <span className='text-[#DE2B2B]'>*</span></div>
                        <div>
                            <TextareaAutosize
                                id="standard-multiline-static"
                                label="Multiline"
                                placeholder="Mensagem"
                                disabled = {showWarning}
                                onChange={commentChange}
                                InputProps={{ disableUnderline: true }}
                                defaultValue={comment}
                                InputLabelProps={{ shrink: true }}
                                value={comment}
                                className="pt-[9px] pl-[13px] !min-h-[160px] resize-y font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                            />
                        </div>
                        <div className="flex mb:justify-center md:justify-end">
                            <HeaderBTNStyle onClick={submitRequest} disabled = {!comment || option == '0' ||  showWarning || !isActive} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar</HeaderBTNStyle>
                        </div>
                    </div>:
                    null
                }
                 </>
            }
                <div className="flex justify-center mb-[80px] relative md:min-h-[711px] lg:min-h-[584px] flex-col-reverse overflow-y mt-[20px]" >
                    <div className={`z-0 absolute border-solid ${lineproperty.bgColor} border-[1px] ${lineproperty.borderColor} h-full text-center w-[0px] lg:left-[50%] mb:left-[45px]`}></div>
                    {
                        data.map((item, index) => {
                            return (
                            <div className="z-10 w-full mb:ml-[24px] lg:ml-[0px]">
                                <Milestone data={item} orderid={orderid} confirmEvent={index}></Milestone>
                            </div>
                            )
                        })
                    }
                </div>
           
               
          </ContainerStyle>
        }
       </RootStyle>
    )
}