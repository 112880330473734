import * as React from 'react';
import { styled } from '@mui/material/styles';

import FilterButton from '../buttons/FilterButton'
import SearchInput from '../Input/IconInput'
import SearchIcon from '../../assets/icons/Search.svg'
import DownloadIcon from '../../assets/icons/download.svg'
import UsersIcon from '../../assets/icons/users.svg'
import useWindowSize from '../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Stack,
    Divider
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FilterDropDown from '../DropDown/FilterDropDown.js'
import UpImage from '../../assets/icons/Polygon_up.svg'
import OutsideAlerter from '../../hooks/OutSideAlerter';
function MoreIcon(props) {
    return (
        <SvgIcon {...props}>
            {/* <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /> */}
            <path fill="currentColor" d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z"></path>
        </SvgIcon>
    );
}
// .sort((a, b) => (a.name < b.name ? -1 : 1))

// iconflag, name, docType, status, updatetime, flag, publicdate, proposal
const NameStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
    padding: '20px 0px 20px 0px'
}));

const DocStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 14,
    color: '#A9A9A9',
    paddingBottom: '15px'
}));

const DotBox = styled('div')(({ theme }) => ({
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    margin: '0px 5.5px'
}));

const StausStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
}));

const UpdateStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 10,
    color: '#A9A9A9',
    textAlign: 'center',
    padding: '15px 0px 15px 0px'
}));

const PublicStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    paddingLeft: '10px'
}));

const DateStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 12,
}));

const ProposalStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 12,
}));

const GrayShadow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
}));

const ViewMoreStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    textAlign: 'center',
    padding: '20px 0px 15px',

    color: '#C6C6C6',
    '&:hover': {
        color: '#17686E',
        cursor: 'pointer'
    }
}));

const Table = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '5px'
}));

const AncestorText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: 'rgba(23, 104, 110, 0.11)',
    color: '#313131',
    textAlign: 'center',
    padding: '15px 0px',
}));

const OrderStatus = styled('div')(({ theme }) => ({
    width: '50%',
}));

const FlagStatus = styled('div')(({ theme }) => ({
    width: '50%',
}));

const SatusText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: 'rgba(23, 104, 110, 0.11)',
    color: '#313131',
    padding: '1px 0px 1px 11px',
}));

const TableFooter = styled('div')(({ theme }) => ({
    // backgroundColor: 'rgba(23, 104, 110, 0.11)',
    borderRadius: '5px',
    padding: '14px 0px 15px',
    display: 'flex',
    justifyContent: 'end'
}));

function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable({ rows , showfilter,singleOrderWithdraw, setsingleOrderWithdraw}) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [flag, setFlag] = React.useState(new Array(rows.length).fill(null))
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const { width } = useWindowSize()
    const navigate = useNavigate();
    return (
        <Table>
            <Box>
                {
                    showfilter &&
                    <Stack direction="row" sx={{ padding: '12px 11px 25px 6px' }} >
                            <FilterDropDown />
                            <Box sx={{ width: '20px' }} />
                            <SearchInput IconSrc={SearchIcon} holder={'Pesquise'} />
                    </Stack>
                }
            </Box>
            <Box>
                
                <AncestorText>CLIENTE</AncestorText>
                {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
                ).map((row, index) => (
                    <Box key={row.id}>
                        <Box>
                            <Stack direction="row" alignItems='center' justifyContent='space-between'>
                                            {row.iconflag == 1 &&
                                    <Tooltip title='Pedido criado'>
                                        <Box component={'img'} width={15} height={15} src={UsersIcon} className="ml-[17px]  mr-[28px]" />
                                    </Tooltip>
                                }
                                {row.iconflag == 2 &&
                                    <Tooltip title='Pedido importado'>
                                        <Box component={'img'} width={15} height={15} src={DownloadIcon} className="ml-[17px]  mr-[28px]" />
                                    </Tooltip>
                                }
                                <Box>
                                    <NameStyle>{row.name}</NameStyle>
                                    <DocStyle>{row.docType}</DocStyle>
                                </Box>
                                <div className="relative">
                                    
                                    <MoreIcon sx={{ fontSize: 30, color: '#C6C6C6', '&:hover': { color: '#17686E' } }} onClick={() => {const temp = [...flag];temp[index] = !temp[index]; setFlag(temp)}} />
                                    <div className='absolute right-[8px] top-[30px] z-10'>
                                    {
                                        flag[index] &&
                                        <OutsideAlerter event={() => {const temp = [...flag];temp[index] = false; setFlag(temp)}}>
                                            <div className="drop-shadow  xl:ml-[0px]">
                                                <Box component={'img'} src={UpImage} className="ml-[227px] z-0"/>
                                                <div className="bg-[#FFFFFF] rounded-[5px] w-[250px] mt-[-2px] z-10 flex flex-col justify-around">
                                                    <div className="bg-[#FFFFFF] rounded-[5px] w-[250px] mt-[-2px] z-10 flex flex-col justify-around">
                                                        <div className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate('/orderdetail_researcher', {state:{initial: 1}})}}>
                                                        <div className='h-full flex justify-center items-center'>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Ver Pedido</div>
                                                            </div>
                                                        </div>         

                                                    
                                                        <div className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10'>
                                                        <div className='h-full flex justify-center items-center' onClick={()=>{setsingleOrderWithdraw(row.allItemData)}}>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Sacar este valor apenas</div>
                                                            </div>
                                                        </div>                        
                                                    </div>      
                                                </div>
                                            </div>
                                        </OutsideAlerter>
                                    }
                                    </div>
                                </div>
                            </Stack>
                        </Box>
                        <Stack direction="row">
                            <OrderStatus>
                                <SatusText>DATA LIBERAÇÃO</SatusText>
                                <Stack direction="row" sx={{ pt: '10px' , ml: '50px'}}>
                                    <DateStyle>{'20/03/2022'}</DateStyle>
                                </Stack>
                            </OrderStatus>

                            <FlagStatus>
                                <SatusText>VALOR</SatusText>
                                <Stack direction="row" sx={{ pt: '10px'}}>
                                    <PublicStyle>R$ {Number.parseFloat(row.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}</PublicStyle>
                                </Stack>
                            </FlagStatus>
                        </Stack>
                        <UpdateStyle>{row.updatetime}</UpdateStyle>

                        <SatusText>STATUS</SatusText>

                        <Stack direction="row" alignItems='center' sx={{ pt: '12px', pl: '10px' }}>
                            <GrayShadow sx={{ pr: '5px' }}>
                                    <DotBox sx={{
                                        bgcolor: row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? 'rgba(206, 133, 0, 0.5)' : row.flag == 3 ? '#92C064' : '#BA483A'
                                    }} />
                                    <StausStyle sx={{
                                        color: row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? 'rgba(206, 133, 0, 0.5)' : row.flag == 3 ? '#92C064' : '#BA483A'
                                    }}>{row.status}</StausStyle>
                            </GrayShadow>
                         </Stack>


                        <ViewMoreStyle>Ver mais</ViewMoreStyle>
                        <Divider orientation="horizontal" flexItem  />
                    </Box>
                ))}
            </Box>
            <TableFooter>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={5}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage='Linhas por página:'
                    labelDisplayedRows={
                        ({ from, to, count }) => {
                          return '' + from + '-' + to + ' de ' + count
                        }
                      }
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    className="rounder-[5px] w-[100%] border border-[1px] bg-[#17686E] !bg-opacity-[0.11]"
                    sx={{
                        '.MuiTablePagination-root':{border:'none!important'},
                        '.css-1zye22':{marginLeft: width< 900 ?'0px': '20px'}, 
                        '.MuiToolbar-root':{justifyContent: 'center'},
                        '.MuiTablePagination-spacer':{display: 'none'}, 
                        '.MuiTablePagination-selectLabel' : {fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600, marginLeft: width< 900 ?'13px': '0px'},
                        '.MuiNativeSelect-select' : { fontWeight: 600}, 
                        '.MuiInputBase-colorPrimary' : { marginLeft: width< 900 ? '0px': '8px', marginRight: width< 900?'0px':'32px'},
                        '.MuiTablePagination-displayedRows' : {fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600} }}
                  />
            </TableFooter>
        </Table>
    );
}
