// Importação do pacote de estilização do material-ui
import { styled } from '@mui/material/styles';
// Importação de componentes do material-ui
import { Divider } from '@mui/material';
// Importação do hook que detecta o tamanho da janela
import useWindowSize from '../../hooks/useWindowSize'
// Importação dos componentes de filtros
import Filter1 from '../../components/filter/Filter1';
import Filter2 from '../../components/filter/Filter2';
import Filter3 from '../../components/filter/Filter3';
import Filter4 from '../../components/filter/Filter4';
import Filter5 from '../../components/filter/Filter5';
import Filter6 from '../../components/filter/Filter6';
import Filter7 from '../../components/filter/Filter7';

// Definição do estilo do elemento raiz da página
const RootStyle = styled('div')(({ theme }) => ({
    // Definição da largura do elemento
    width: '80%',
    // Definição do alinhamento ao centro da página
    margin: 'auto',
    // Definição de estilo para telas acima do tamanho 'sm'
    [theme.breakpoints.up('sm')]: {
        width: '100%',
    }
}));

// Definição do estilo do container de filtros
const ContainerStyle = styled('div')(({ theme }) => ({
    // Definição da borda do container
    border: '1px solid #C6C6C6',
    // Definição da borda arredondada do container
    borderRadius: '5px',
    // Definição do espaçamento interno do container
    padding: '35px 17px 35px'
}));
// -------------------------------------------------------------
// const filterOne = ["Nascimento/Batismo", "Casamento", "Óbito", "Imigração", "Outros"]

// const filterTwo = ["Iniciante", "Especialista", "Assessor"]
// const filterThree = ["Digital", "Físico", "Apostilado"]

// -------------------------------------------------------------

export default function Filter(props) {

    // { filterList, setFilterList }

    const { width } = useWindowSize()

    return (
        <>
            {width > 1000 &&
                <RootStyle>
                    <ContainerStyle
                    // onChange={
                    //     ()=>{
                    //         props.postFilters()
                    //     }
                    // }

                    >
                        <Filter1 
                        checkboxCSS={props.checkboxCSS}
                        filtersBlock={props.filtersBlock}
                        setFiltersBlock={props.setFiltersBlock} 

                        filter={props.filterOne}
                        filterList={props.filterList} 
                        setFilterList={props.setFilterList} 
                        ischecked={props.ischecked}

                        postFilters={props.postFilters}

                        allfilters = {props.allfilters}
                        setallfilters = {props.setallfilters}

                        />
                        <Divider sx={{margin: '20px 0px'}}/>

                        <Filter2 
                        filter={props.filterCountry} 
                        checkboxCSS={props.checkboxCSS}

                        filtersBlock={props.filtersBlock}
                        setFiltersBlock={props.setFiltersBlock} 
                        ischecked={props.ischecked}


                        filterList={props.filterList} 
                        setFilterList={props.setFilterList} 

                        postFilters={props.postFilters}


                        showFlag={props.showFlag}
                        allfilters = {props.allfilters}
                        setallfilters = {props.setallfilters}

                        />
                        <Divider sx={{margin: '20px 0px'}}/>





{/* 
                        <Filter3 />
                        <Divider sx={{margin: '20px 0px'}}/> */}

                                    {/* <Filter4 />
                                    <Divider sx={{margin: '20px 0px'}} /> */}






                        <Filter5 
                        
                        filter={props.filterThree} 
                        checkboxCSS={props.checkboxCSS}

                        
                        filtersBlock={props.filtersBlock}
                        setFiltersBlock={props.setFiltersBlock} 


                        filterList={props.filterList} 
                        setFilterList={props.setFilterList} 

                        postFilters={props.postFilters}
                        allfilters = {props.allfilters}
                        setallfilters = {props.setallfilters}

                        
                        />
                        <Divider sx={{margin: '20px 0px'}}/>

                        <Filter6 
                        filter={props.filterTwo} 
                        checkboxCSS={props.checkboxCSS}

                        filtersBlock={props.filtersBlock}
                        setFiltersBlock={props.setFiltersBlock} 

                        filterList={props.filterList} 
                        setFilterList={props.setFilterList} 
                        ischecked={props.ischecked}
                        allfilters = {props.allfilters}
                        setallfilters = {props.setallfilters}


                        postFilters={props.postFilters}
                        />
                        <Divider sx={{margin: '20px 0px'}}/>




                        <Filter7 
                        filter={props.filterThree} 
                        checkboxCSS={props.checkboxCSS}

                        
                        filtersBlock={props.filtersBlock}
                        setFiltersBlock={props.setFiltersBlock} 

                        filterList={props.filterList} 
                        setFilterList={props.setFilterList} 
                        ischecked={props.ischecked}

                        allfilters = {props.allfilters}
                        setallfilters = {props.setallfilters}

                        postFilters={props.postFilters}
                        />
                    </ContainerStyle>
                </RootStyle>
            }
        </>
    )
}