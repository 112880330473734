import React, { useEffect, useState, useRef } from "react";

import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import Geonames from "geonames.js";
import PropTypes from "prop-types";

const geonames = new Geonames({
  username: "thalesandrade",
  lan: "en",
  encoding: "JSON"
});



export default function GeoLocation(props) {
  const { locationTitle, geoId, onChange, country, value } = props;
  const [options, setOptions] = useState([]);
  const [currentItem, setCurrentItem] = useState(value);
  const [labelWidth, setLabelWidth] = useState(0);


  useEffect(() => {
    try {
      const data = async () => {
        (await country)
          ? geonames.countryInfo({}).then(res => {
              var geoId1;
              res.geonames.map((item) => {
                if(item.countryCode == country){
                    geoId1 =  item.geonameId;
                }
              })
            geonames.children({ geonameId: geoId1 }).then(res => {
              if (res.totalResultsCount) 
              {
                let opt = res.geonames;
                opt.sort((a,b) => (a.name > b.name) ? 1 : -1);
                setOptions(res.geonames);
                
              }
            });
            })
          : geonames.children({ geonameId: geoId }).then(res => {
              if (res.totalResultsCount)
              {
                let opt = res.geonames;
                opt.sort((a,b) => (a.name > b.name) ? 1 : -1);
                setOptions(res.geonames);
                
              }
            });
      };
      data();
      setCurrentItem(value)
    } catch (err) {
      console.error(err);
    }
  }, [geoId, country, value]);


  const handleChange = e => {
    setCurrentItem(e.target.value);
    onChange(e.target.value);
  };

  return (


      <Select
        displayEmpty="displayEmpty"
        className={`w-[100%] h-[40px] ${!currentItem?'!text-[#ABAFB3] whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]':''}`}
        value={currentItem}
        onChange={handleChange}
        MenuProps={{
          sx:{
              "& .Mui-selected": {
                  backgroundColor: "rgba(23, 104, 110, 0.4) !important"
              },
              "& .MuiMenuItem-root:hover": {
                  backgroundColor: "rgba(23, 104, 110, 0.11)"
              },
              '& .MuiMenuItem-root': {
                  whiteSpace: 'normal',
              },
          }
      }} 
      >
        <MenuItem value="" disabled>
          {country ? <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">Selecione o estado...</p> : <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">Selecione a cidade...</p>}
        </MenuItem>
        {
        options.map((v, index) => (
          <MenuItem key={v.geonameId} value={v.geonameId}>
            <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{v.name}</p>
          </MenuItem>
        ))}
      </Select>
  );
}

GeoLocation.propTypes = {
  locationTitle: PropTypes.string,
  geoId: PropTypes.node,
  isCountry: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

GeoLocation.defaultProps = {
  onChange: undefined
};
