/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useLayoutEffect,useRef } from 'react';
import { Link,  useLocation, Navigate } from 'react-router-dom';
import {
    TextareaAutosize,
    Box,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UserIcon1 from '../../assets/icons/usericon.svg';
import UserIcon2 from '../../assets/icons/usericon1.svg';
import AlertIcon1 from '../../assets/icons/alerticon.svg';
import AlertIcon2 from '../../assets/icons/alerticon1.svg';
import SecurityIcon1 from '../../assets/icons/securityicon.svg';
import SecurityIcon2 from '../../assets/icons/securityicon1.svg';
import PasswordIcon1 from '../../assets/icons/passwordicon.svg';
import PasswordIcon2 from '../../assets/icons/passwordicon1.svg';
import AccountIcon1 from '../../assets/icons/accounticon.svg';
import AccountIcon2 from '../../assets/icons/accounticon1.svg';

import AboutMeIcon1 from '../../assets/icons/aboutmeicon.svg'
import AboutMeIcon2 from '../../assets/icons/aboutmeicon1.svg'

import PlanIcon1 from '../../assets/icons/planicon.svg'
import PlanIcon2 from '../../assets/icons/planicon1.svg'

import WithdrawIcon1 from '../../assets/icons/withdrawicon.svg'
import WithdrawIcon2 from '../../assets/icons/withdrawicon1.svg'

import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import TabButton from '../../components/buttons/TabButton';
import PersonalInfo from '../../components/PersonalInfo';
import PersonalInfoExpert from '../../components/PersonalInfoExpert';
import Password from '../../components/Password';
import Security from '../../components/Security'
import Account from '../../components/Account'
import Aboutme from '../../components/Aboutme';
import Plan from '../../components/Plan';
import Withdraw from '../../components/Withdraw';
import { makeStyles } from "@material-ui/styles";
import API from '../../redux/API';
import Notification from '../../components/Notification'
import { toast } from 'react-toast';

import { setUser } from '../../redux/actions/auth.action';

import { CheckPrifile2 } from '../../components/validateProfile2/checkProfile';
import { useNavigate } from 'react-router-dom';


const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '40px',
    marginBottom: '80px',
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));













export default ({initial}) => {

    const {currentUser} = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);


    if (!currentUser){
        return <Navigate to='/'  />
    }
    var type = currentProfile ? 'expert' : 'user';
    const dispatch = useDispatch();
    // useEffect(()=>{
    //     if(currentUser){
    //         type = currentProfile ? 'expert' : 'user';
    //         API.get(`/user/getnotification`)
    //         .then((result) => {
    //             if (result.data.status === "success") {
    //                 // let temp = result.data.totalcount;
    //                 // if(temp % 5 !== 0){
    //                 //     setTotalCnt(Math.floor(temp / 5) + 1 );
    //                 // }
    //                 // else setTotalCnt(temp / 5);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     }
    // }, [currentUser, currentProfile])
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

//   useEffect(() => {
//     let isMounted = true; // Flag to check if the component is still mounted

//     const fetchData = async () => {
//       if (currentUser) {
//         try {
//           const result = await API.post(`/user/getme`, currentUser);
//           if (result.data.status === "success" && isMounted) {
//             dispatch(setUser(result.data.user)); // Updating the currentUser
//           }
//         } catch (err) {
//           console.log(err);
//         }
//       }
//     };

//     // Delay execution by 1 second (adjust as needed)
//     const delayDuration = 2000;
//     const timeout = setTimeout(fetchData, delayDuration);

//     return () => {
//       // Cleanup function to clear the timeout and update isMounted
//       clearTimeout(timeout);
//       isMounted = false;
//     };
//   }, [currentUser]);


  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          const result = await API.post(`/user/getme`, currentUser);
          if (result.data.status === "success") {
            dispatch(setUser(result.data.user)); // Updating the currentUser
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
    // Delay execution 
    const delayDuration = 1000;
    const timeout = setTimeout(fetchData, delayDuration);
  }, []);
          

    const [activeButton, setActiveButton] = useState(1);
    const text = [
        'Informações pessoais',
        'Senha',
        'Notificações e Alertas',
        'Segurança',
        'Conta'
    ]
    const text1 = [
        'Informações pessoais',
        'Senha',
        'Notificações e Alertas',
        'Segurança',
        'Conta',
        'Sobre mim',
        'Planos',
        'Sacar dinheiro'
    ]
    const imgArr2 = [
        UserIcon2,
        PasswordIcon2,
        AlertIcon2,
        SecurityIcon2,
        AccountIcon2
    ]
    const imgArr1 = [
        UserIcon2,
        PasswordIcon2,
        AlertIcon2,
        SecurityIcon2,
        AccountIcon2,
        AboutMeIcon2,
        PlanIcon2,
        WithdrawIcon2
    ]
    
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);
    const [hover4, setHover4] = useState(false);
    const [hover5, setHover5] = useState(false);
    
    const location = useLocation();
    const { width } = useWindowSize()
    useLayoutEffect(() => {
        if(location.state && location.state.initial != undefined){
            setActiveButton(location.state.initial)
        }
    }, [location])



    const navigate = useNavigate();

const [isProfile2, setIsProfile2] = useState(false)

// conferindo se está criando novo perfil + alicando condições
    useEffect(() => {
        const checkPrifile2 = CheckPrifile2(currentUser, currentProfile)
        setIsProfile2(checkPrifile2)
            if(checkPrifile2 ==true){
                navigate('/account')
                setActiveButton(1)

                
            }
    }, [type, currentProfile, currentUser]);
    
    
// impedindo cliques, quando criando novo perfil
      const handleClick = (event) => {
        if (isProfile2 == true) {
            toast.info('Para acessar os recursos, salve sua conta com o novo perfil.')
          event.preventDefault();
          event.stopPropagation();

        }
      };
    




    return (
       <RootStyle>
            <ContainerStyle className='mb:flex-col md:flex-row'>

                <div>


                    {   

                        type == 'user' ?
                        <Select
                            
                        disabled={isProfile2==true}
                        onClick={()=>{handleClick()}}


                        value={activeButton}
                        onChange={(e) => {
                            handleClick()
                            setActiveButton(e.target.value)
                        }}
                        displayEmpty="displayEmpty"
                        className="w-[100%] h-auto !p-0 mb:!flex md:!hidden rounded-t-[5px] "
                        sx={{
                            '& .MuiSelect-select':{
                                padding: '0px !important'
                            }
                        }}
                        MenuProps={{
                            sx:{
                                "& .Mui-selected": {
                                    backgroundColor: "rgba(23, 104, 110, 0.4) !important",
                                    color: '#17686e !important'
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "rgba(23, 104, 110, 0.11)"
                                },
                                '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                },
                            }
                        }} 
                        renderValue={(value) => {
                                return( 
                                <div className="p-0 flex justify-center w-full cursor-pointer  bg-[#17686E] bg-opacity-[0.11]  border-solid border-[1px] border-[#17686E] hover:bg-[#17686e] hover:bg-opacity-[0.11]">
                                    <Box component={'img'} src={imgArr2[value-1]} className='w-[18px] '/>
                                    <button className='hover:text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-semibold text-[14px] leading-[148%] text-[#17686e] w-[215px]' >{text[value-1]}</button>
                                </div>  
                                )
                        }}
                        >
                        <MenuItem
                        value="1" className='w-full p-0' onMouseOver={()=>setHover1(true)} onMouseOut={()=>setHover1(false)}>
                            <div className="flex" >
                                <Box component={'img'} src={hover1?UserIcon2:UserIcon2} className='w-[15px] '/>
                                <button className={(hover1 ? 'text-[#17686e]' :'text-[#17686e]' )+ ' !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Informações pessoais</button>
                            </div>  
                        </MenuItem >      
                        <MenuItem value="2" onMouseOver={()=>setHover2(true)} onMouseOut={()=>setHover2(false)}>
                            <div className="flex" >
                                <Box component={'img'} src={hover2?PasswordIcon2:PasswordIcon2} className='w-[15px] '/>
                                <button className={(hover2 ? 'text-[#17686e]' :'text-[#17686e]' )+ ' !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Senha</button>
                            </div>  
                        </MenuItem>
                        <MenuItem value="3" onMouseOver={()=>setHover3(true)} onMouseOut={()=>setHover3(false)}>
                            <div className="flex" >
                                <Box component={'img'} src={hover3?AlertIcon2:AlertIcon2} className='w-[15px] '/>
                                <button className={(hover3 ? 'text-[#17686e]' :'text-[#17686e]') + ' !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Notificações e Alertas</button>
                            </div>  
                        </MenuItem>
                        <MenuItem value="4" onMouseOver={()=>setHover4(true)} onMouseOut={()=>setHover4(false)}>
                            <div className="flex" >
                                <Box component={'img'} src={hover4?SecurityIcon2:SecurityIcon2} className='w-[15px] '/>
                                <button className={(hover4 ? 'text-[#17686e]' :'text-[#17686e]' )+ ' !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Segurança</button>
                            </div>  
                        </MenuItem>
                        <MenuItem value="5" onMouseOver={()=>setHover5(true)} onMouseOut={()=>setHover5(false)}>
                            <div className="flex" >
                                <Box component={'img'} src={hover5?AccountIcon2:AccountIcon2} className='w-[15px] '/>
                                <button className={(hover5 ? 'text-[#17686e]' :'text-[#17686e]' )+ ' !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Conta</button>
                            </div>  
                        </MenuItem>
                                
                     </Select>
                     
                     :


                     <Select
                     value={activeButton}
                     onChange={(e) => {
                        handleClick()
                        setActiveButton(e.target.value)
                     }}
                     displayEmpty="displayEmpty"
                     className="w-[100%] h-auto !p-0 mb:!flex md:!hidden rounded-t-[5px] "
                     sx={{
                         '& .MuiSelect-select':{
                             padding: '0px !important'
                         }
                     }}
                     MenuProps={{
                         sx:{
                             "& .Mui-selected": {
                                 backgroundColor: "rgba(23, 104, 110, 0.4) !important",
                                 color: '#17686e !important'
                             },
                             "& .MuiMenuItem-root:hover": {
                                 backgroundColor: "rgba(23, 104, 110, 0.11)"
                             },
                             '& .MuiMenuItem-root': {
                                 whiteSpace: 'normal',
                             },
                         }
                     }} 
                     renderValue={(value) => {
                             return( 
                             <div className="p-0 flex justify-center w-full cursor-pointer  bg-[#17686E] bg-opacity-[0.11]  border-solid border-[1px] border-[#17686E] hover:bg-[#17686e] hover:bg-opacity-[0.11]">
                                 <Box component={'img'} src={imgArr1[value-1]} className='w-[18px] '/>
                                 <button className='hover:text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-semibold text-[14px] leading-[148%] text-[#17686e] w-[215px]'>{text1[value-1]}</button>
                             </div>  
                             )
                     }}


                     disabled={isProfile2==true}
                     onClick={()=>{handleClick()}}
                     >


                     <MenuItem value="1" className='w-full p-0' >
                         <div className="flex" >
                             <Box component={'img'} src={UserIcon2} className='w-[15px] '/>
                             <button className={ 'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Informações pessoais</button>
                         </div>  
                     </MenuItem >
                     <MenuItem value="6" >
                         <div className="flex" >
                             <Box component={'img'} src={AboutMeIcon2} className='w-[15px] '/>
                             <button className={'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Sobre mim</button>
                         </div>  
                     </MenuItem>
                     <MenuItem value="2" >
                         <div className="flex" >
                             <Box component={'img'} src={PasswordIcon2} className='w-[15px] '/>
                             <button className={'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Senha</button>
                         </div>  
                     </MenuItem>
                     <MenuItem value="3" >
                         <div className="flex" >
                             <Box component={'img'} src={AlertIcon2} className='w-[15px] '/>
                             <button className={ 'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Notificações e Alertas</button>
                         </div>  
                     </MenuItem>
                     <MenuItem value="7" >
                         <div className="flex" >
                             <Box component={'img'} src={PlanIcon2} className='w-[15px] '/>
                             <button className={'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Planos</button>
                         </div>  
                     </MenuItem>
                     <MenuItem value="4" >
                         <div className="flex" >
                             <Box component={'img'} src={SecurityIcon2} className='w-[15px] '/>
                             <button className={'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Segurança</button>
                         </div>  
                     </MenuItem>
                     <MenuItem value="5" >
                         <div className="flex" >
                             <Box component={'img'} src={AccountIcon2} className='w-[15px] '/>
                             <button className={'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Conta</button>
                         </div>  
                     </MenuItem>
                     <MenuItem value="8" >
                         <div className="flex" >
                             <Box component={'img'} src={WithdrawIcon2} className='w-[15px] '/>
                             <button className={'text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] w-[215px] text-left'}>Sacar dinheiro</button>
                         </div>  
                     </MenuItem>
                             
                  </Select>











                    }
                    
                    


                    {
// bloqueando botões, enquanto não salva perfil novo (se necessário)

                        <>
                        {

                        type == 'user' ?

                        <div
                        
                        className='md:!block mb:!hidden '>
                            <TabButton img={UserIcon1} img1={UserIcon2} text="Informações pessoais" active={activeButton == 1 ? true : false} 
                            event={()=>{
                                handleClick();
                                setActiveButton(1)
                                }}
                            ></TabButton>
                            <TabButton img={PasswordIcon1} img1={PasswordIcon2} text="Senha" active={activeButton == 2 ? true : false}                              
                            event={()=>{
                                handleClick();
                                setActiveButton(2)
                                }}
                            ></TabButton>
                            <TabButton img={AlertIcon1} img1={AlertIcon2} text="Notificações e Alertas" active={activeButton == 3 ? true : false}                              
                            event={()=>{
                                handleClick();
                                setActiveButton(3)
                                }}
                            ></TabButton>
                            <TabButton img={SecurityIcon1} img1={SecurityIcon2} text="Segurança" active={activeButton == 4 ? true : false}                              
                            event={()=>{
                                handleClick();
                                setActiveButton(4)
                                }}
                            ></TabButton>
                            <TabButton img={AccountIcon1} img1={AccountIcon2} text="Conta" active={activeButton == 5 ? true : false}                              
                            event={()=>{
                                handleClick();
                                setActiveButton(5)
                                }}
                            ></TabButton>

                        </div>
                        
                        :


                        <div className='md:!block mb:!hidden '>


                            <TabButton img={UserIcon1} img1={UserIcon2} text="Informações pessoais" active={activeButton == 1 ? true : false} 
                            event={()=>{
                                handleClick();
                                setActiveButton(1)
                                }}
                            ></TabButton>


                            <TabButton img={AboutMeIcon1} img1={AboutMeIcon2} text="Sobre mim" active={activeButton == 6 ? true : false} event={()=>{
                                handleClick();
                                setActiveButton(6)
                                }}
                            ></TabButton>
                            
                            <TabButton img={PasswordIcon1} img1={PasswordIcon2} text="Senha" active={activeButton == 2 ? true : false}  event={()=>{
                                handleClick();
                                setActiveButton(2)
                                }}
                            ></TabButton>
                            <TabButton img={AlertIcon1} img1={AlertIcon2} text="Notificações e Alertas" active={activeButton == 3 ? true : false}  event={()=>{
                                handleClick();
                                setActiveButton(3)
                                }}
                            ></TabButton>
                            <TabButton img={PlanIcon1} img1={PlanIcon2} text="Planos" active={activeButton == 7 ? true : false} event={()=>{
                                handleClick();
                                setActiveButton(7)
                                }}
                            ></TabButton>
                            
                            <TabButton img={SecurityIcon1} img1={SecurityIcon2} text="Segurança" active={activeButton == 4 ? true : false}  event={()=>{
                                handleClick();
                                setActiveButton(4)
                                }}
                            ></TabButton>
                            <TabButton img={AccountIcon1} img1={AccountIcon2} text="Conta" active={activeButton == 5 ? true : false}  event={()=>{
                                handleClick();
                                setActiveButton(5)
                                }}
                            ></TabButton>
                            <TabButton img={WithdrawIcon1} img1={WithdrawIcon2} text="Sacar dinheiro" active={activeButton == 8 ? true : false} event={()=>{
                                handleClick();
                                setActiveButton(8)
                                }}
                            ></TabButton>
                            
                           
                        </div>



                        

                     
                    }

                        </>
                    }

                



                   </div>




                    {
                        activeButton == 1 && (
                            <div className='w-full'>
                                {
                                    type == 'user'?
                                    <PersonalInfo isProfile2={isProfile2}></PersonalInfo>:
                                    <PersonalInfoExpert isProfile2={isProfile2}></PersonalInfoExpert>
                                }
                            </div>
                        )
                        
                    }
                    {
                        activeButton == 6 && (
                            <div className='w-full'>
                                <Aboutme></Aboutme>
                            </div>
                        )
                    } 
                    {
                        activeButton == 2 && (
                            <div className='w-full'>
                                <Password></Password>
                            </div>
                        )
                        
                    }
                    {
                        activeButton == 3 && (
                            <div className='w-full'>
                                <Notification></Notification>
                            </div>
                        )
                        
                    }
                    {
                        activeButton == 7 && (
                            <div className='w-full'>
                                <Plan></Plan>
                            </div>
                        )
                        
                    }
                    {
                        activeButton == 4 && (
                            <div className='w-full'>
                                <Security></Security>
                            </div>
                        )
                        
                    }
                    {
                        activeButton == 5 && (
                            <div className='w-full'>
                                <Account></Account>
                            </div>
                        )
                        
                    }
                                        {
                        activeButton == 8 && (
                            <div className='w-full'>
                                <Withdraw></Withdraw>
                            </div>
                        )
                        
                    }
            </ContainerStyle>
       </RootStyle>
    )




    


}
