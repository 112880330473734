/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/actions/auth.action';
import API from '../../redux/API';
import MySwitch from '../Switcher';
import { toast } from 'react-toast';
export default () => {
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb:mt-[15px] md:mt-[10px] mb-[10px] w-[272px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131]"
    }
    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [ic_securitymode, setic_securitymode] = useState(true);

    useLayoutEffect(()=>{
        if(currentUser){
            setic_securitymode(currentUser.ic_securitymode === undefined ? true : currentUser.ic_securitymode)
        }
    }, [currentUser])
    const [ischanginf,setischanging]=useState(false)
    const setChangeSetting = async (data) => { 
      setischanging(true)
        try{
          const result = await API.post('/user/setsecuritymode', data);
          if (result.data.status === "success") {
               dispatch(setUser(result.data.user))
               if(result.data.user.ic_securitymode){
                toast.success('Ativado')
                setic_securitymode(true);
                setischanging(false)
                localStorage.setItem("has2step", 'true');
                localStorage.setItem("is2step", '');

              }
               else{
                 toast.success('Autenticação de dois fatores desativado')
                 setic_securitymode(false);
                 setischanging(false)
                 localStorage.removeItem('has2step')
                 localStorage.removeItem('is2step')
               }
          }
        }
        catch(err) {
            console.log(err);
        };
  
      }
    const { currentProfile } = useSelector(state => state.auth);
    const handleChange = (e) => {
        const data ={
            ic_securitymode : e.target.checked,
            ds_email: currentUser.ds_email,
            ic_profile: currentProfile
      }
        setChangeSetting(data);
      }
    return (
        <div className='mb:w-full md:w-[95%] float-right'>
            <div className='w-[100%] flex mb:items-center md:items-start  flex-col'>
                <div className='mb:w-[96%] md:w-full  bg-[#17686E] bg-opacity-[0.11]'>
                  <div className='md:ml-[15px] pt-[10px] md:mr-[17px] mb:ml-[6px] mb:mr-[6px]'>
                    <div className="font-[Poppins] font-bold text-[14px] leading-[40px] text-[#464A53] whitespace-nowrap ">Proteja sua conta</div>
                    <div className={style.content + " mb-[25px]"}>Cada vez que iniciar sessão na plataforma, solicitaremos sua senha habitual e enviaremos um código como etapa extra de segurança via e-mail.</div>
                   
                    <div className={style.content + " mb-[19px]"}>- Mesmo que alguém descubra sua senha, não será possível acessar sua conta sem o código.</div>
                  </div>
                </div>
                <div className='mt-[40px] mb:w-[96%] md:w-[340px] md:mb-[323px] mb:mb-[35px]'>
                  <div className='flex items-center justify-between'>
                      <div className="font-[Poppins] font-bold text-[14px] leading-[148%] text-[#313131] ml-[15px]">
                      Habilitar autenticação de dois fatores
                      </div>
                      <div className="flex items-center">
                          <MySwitch checked={ic_securitymode} onChange={handleChange} ischanginf={ischanginf}>  </MySwitch>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    )
}