import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box, TextField,InputAdornment, TextareaAutosize} from '@mui/material';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/successicon.gif'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import API from '../../redux/API';
import { toast } from 'react-toast';



import './style.css'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#BA483A',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));


const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));



const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));

export default function Modal(props) {
    
    const navigate = useNavigate();


    const openModal = props.openModal
    const setOpenModal= props.setOpenModal
    const data = props.data
    const confirmFunc = props.confirmFunc
    const pageNum = props.pageNum
    const orderINFO = props.orderINFO


    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }

    const reasonList = [
    '-- Selecione --',
    'Eu estava recebendo muitas notificações e e-mails',
    'Não encontrei os pesquisadores que eu estava procurando',
    'As propostas foram muito caras para mim',
    'Já encontrei o que eu procurava',
    'Quero fazer a pesquisa depois'
];


    const [reasonType, setReasonType] = useState('-- Selecione --');
    const dispatch = useDispatch();
    const [initialPage, setInitialPage] = useState(pageNum);
    const handleClose = () => {
        setOpenModal(false);
    };
    const handleConfirm = () => {
        
        setOpenModal(false);
        confirmFunc();
        
    }
    const handlePreviousPage = () => {
        if(page === 1)    setOpenModal(false);
        else{
            setPage(page - 1)
        }
    }

    useLayoutEffect(() => {
        setInitialPage(pageNum);
        setPage(pageNum);
      }, [openModal]);
    const [page, setPage] = useState(openModal);
    



    const handleConfirmReason = () => {
        


        const data = {
            orderID: orderINFO._id,
            reasonType: reasonType
        }

        API.post('/order/cancelorder', data)
        .then((result) => {
          if(result.data.status === 'success'){
             toast.success('Ordem cancelada.');
            //  dispatch(setUser(result.data.user))


            setPage(3);

            window.location.reload()

          }
          else if(result.data.status !== 'success'){
            toast.error('Erro ao cancelar ordem.');
           //  dispatch(setUser(result.data.user))


         }
        })
        .catch((err) => {
          toast.error(JSON.parse(err.request.response).message)
          console.log(err);
      });

    }
    const handleNextPage = (e) => {
        setPage(2);
    }


    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <div className='min-h-[350px]'>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
      
                {
                    page === 1 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[68px] !overflow-unset'>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[160px] flex md:flex-row mb:flex-col items-center'>
                                    <div className="mb:mr-[0px] md:mr-[25px] mb:!w-[117px] md:!w-[157px]">
                                    <Box component="img" src={WarningIcon} alt="alerta-icone" className='w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='md:mt-[0px] mb:mt-[10px] font-[Poppins] font-medium mb:text-[18px] md:text-[22px] text-[#313131] leading-[100%]  mb:text-center md:text-left'>
                                    Cancelar pedido
                                    </div>
                                    <div className='mb:w-[100%] md:w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-normal text-[14px] text-[#313131] leading-[120%]">
                                    Porque você deseja cancelar?
                                     </div>
                                     <div className="w-[95%] mt-[10px] font-[Poppins] font-normal text-[14px] text-[#313131] leading-[120%]">
                                     Motivo <span className='text-[#DE2B2B]'>*</span>
                                     </div>
                                     <Select
                                        value={reasonType}
                                        onChange={(e) => {
                                            setReasonType(e.target.value)
                                        }}
                                        displayEmpty="displayEmpty"
                                        className="w-[100%] md:h-[40px] mb:h-fit mt-[3px]"
                                        MenuProps={{
                                            sx:{
                                                '& .MuiList-root':{
                                                    width: width<900 ? '300px !important' : '100% !important',
                                                    paddingRight: '0px !important'
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "rgba(23, 104, 110, 0.11)"
                                                },
                                                '& .MuiMenuItem-root': {
                                                    whiteSpace: 'normal',
                                                    
                                                },
                                            }
                                        }} 
                                        >
                                        {
                                            //dynamic
                                            reasonList.map((item) => {
                                                return (
                                                    <MenuItem value={item}>
                                                        <p
                                                            className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">
                                                                {item}
                                                        </p>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 2 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[68px]  !overflow-unset '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[160px] flex md:flex-row mb:flex-col items-center'>
                                 <div className="mb:mr-[0px] md:mr-[25px] mb:!w-[117px] md:!w-[157px]">
                                    <Box component="img" src={WarningIcon} alt="alerta-icone" className='w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='md:mt-[0px] mb:mt-[10px] font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%]  mb:text-center md:text-left'>
                                    Cancelar pedido
                                    </div>
                                    <div className='mb:w-[100%] md:w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="mb:w-[100%] md:w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%]">
                                    Tem certeza que deseja cancelar seu pedido?
                                     </div>
                                     <div className="mb:w-[100%] md:w-[95%] mt-[10px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%]">
                                     Essa ação não poderá ser desfeita.
                                     </div>
                                </div>
                            </div>
                            
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 3 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px]  !overflow-unset '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                                <div className="b:mr-[0px] md:mr-[25px] !w-[117px]">
                                    <Box component="img" src={SuccessIcon} alt="sucesso-icone" className='max-w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='md:mt-[0px] mb:mt-[10px] font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                    Cancelamento do pedido feito com sucesso!
                                    </div>
                                    <div className='mb:w-[100%] md:w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="mb:w-[100%] md:w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%]  mb:text-center md:text-left">
                                    Lamentamos pelo cancelamento do seu pedido na DocsTree e estamos à disposição para qualquer suporte que possa necessitar.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                    
                </div>
                <div className='md:mt-[0px] mb:mt-[20px]'>
                {
                    page === 1 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleNextPage} disabled={reasonType === '-- Selecione --'} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Cancelar pedido</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 2 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleConfirmReason} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Cancelar pedido</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 3 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                         <HeaderBTNStyle1 onClick={handleConfirm} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar para pedido</HeaderBTNStyle1>
                    </div>
                }
                </div>
            </BootstrapDialog>
        </div >
    );
}
