/* eslint-disable import/no-anonymous-default-export */
// Importa os componentes necessários do React
import React, {useState, useLayoutEffect, useEffect } from 'react';
// Importa o hook "useLocation" do pacote "react-router-dom"
import { useLocation } from 'react-router-dom';
// Importa o componente "Card"
import Card from './card';

// Função que retorna o componente principal da página
export default (props) => {
    // Define o estado inicial da página
    const [page, setPage] = useState(1);
    
    

    // Obtém a localização atual
    const location = useLocation();
    // Roda a função "useLayoutEffect" ao iniciar a página
    useLayoutEffect(() => {
        // Loga as informações da localização
        //console.log(location.state);
        // Verifica se há um objeto "state" na localização e se ele tem uma propriedade "page"
        if(location.state && location.state.page !== undefined){
            // Se sim, atualiza o estado da página
            setPage(location.state.page)
        }
    }, [location])

    const[is2Step,setis2Step]=useState(false)
    useEffect(()=>{
        
        if(localStorage.has2step){
            setis2Step(true)
        }
        else{
            setis2Step(false)
        }
    },[])
    // Retorna o componente com o conteúdo da página
    return (
        <div className="w-full flex justify-center items-center mt-[80px] mb-[80px]">
            <div className="mb:w-[95%] sm:w-[80%] flex flex-col items-center">
                <div className='w-full flex mb:items-center lg:justify-center mb:mb-[25px] lg:mb-[111px] mb:flex-col lg:flex-row'>
                    {/* Passa a propriedade "pageNum" para o componente "Card" */}
                    <Card pageNum={page} is2Step={is2Step} />
                </div>
            </div>
        </div>
    )
}