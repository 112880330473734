import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {useNavigate} from 'react-router-dom'
import useWindowSize from '../../../hooks/useWindowSize';

const RootStyle_1 = styled('div')(({ theme }) => ({
    width: '260px',
    height:'276px',
    boxShadow: '-2px 3px 20px rgba(0, 0, 0, 0.15)',
    background: 'white',
    borderRadius: '7px',
    marginRight: '7px',
    padding: '23px 10px 12px 10px',
    [theme.breakpoints.up('sm')]: {
        width: '510px',
        padding: '29px 14px 14px 21px',
        marginRight: '10px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '510px',
        padding: '25px',
        marginRight: '23.86px',
    }
}));

const RootStyle_2 = styled('div')(({ theme }) => ({
    width: '280px',
    height: '276px',
    boxShadow: '-2px 3px 20px rgba(0, 0, 0, 0.15)',
    background: 'white',
    borderRadius: '7px',
    marginRight: '7px',
    padding: '23px 10px 12px 10px',
    [theme.breakpoints.up('sm')]: {
      width: '510px',
      padding: '29px 14px 14px 21px',
      marginRight: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '510px',
      padding: '25px',
      marginRight: '23.86px',
    },
  }));

  const RootStyle_mobile = styled('div')(({ theme }) => ({
    width: '360px',
    height:'300px',
    boxShadow: '-2px 3px 20px rgba(0, 0, 0, 0.15)',
    background: 'white',
    borderRadius: '7px',
    marginRight: '7px',
    padding: '25px',
    [theme.breakpoints.up('sm')]: {
        width: '510px',
        padding: '25px',
        marginRight: '10px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '510px',
        padding: '25px',
        marginRight: '23.86px',
    }
}));
  
const Header_text = styled('div')(({ theme }) => ({
    color: '#313131',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '22px',
    }
}));

const Content_text = styled('div')(({ theme }) => ({
    paddingTop: '24.7px',
    color: '#313131',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '13px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
    }
}));

const BTN_style_1 = styled('button')(({ theme }) => ({
    color: '#fff',
    fontFamily: 'Poppins',
    backgroundColor: '#17686E',
    borderRadius: '5px',
    border: 'none',
    fontSize: '14px',
    padding: '7.5px 2px 8.5px 2px',
    float: 'left',
    marginTop: '15px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
        padding: '12px 13px',
        float: 'right',
        marginTop: '25px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        padding: '15px 14px',
        float: 'right',
        marginTop: '25px',
    }
}));

const BTN_style_2 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    border: '1px solid #17686E',
    borderRadius: '5px',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontSize: '14px',
    padding: '7.5px 2px 8.5px 2px',
    float: 'left',
    marginTop: '15px',
    [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
        padding: '12px 13px',
        float: 'right',
        marginTop: '25px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        padding: '15px 14px',
        float: 'right',
        marginTop: '25px',
    },
}));

export default function SmallCard({ card }) {
    const navigate = useNavigate();
    const {currentUser} = useSelector(state => state.auth);
    const { width } = useWindowSize()


    return (
        <>
        {width>600 &&
        <>
            {card.id == 1 &&
                <RootStyle_1>
                    <Header_text>{card.header}</Header_text>
                    <Content_text>{card.content}</Content_text>
                    <BTN_style_1 onClick={()=>currentUser?navigate('/createorder'):navigate('/login')}>{card.button_text}</BTN_style_1>
                </RootStyle_1>
            }
            {card.id == 2 &&
                <RootStyle_2>
                    <Header_text>{card.header}</Header_text>
                    <Content_text>{card.content}</Content_text>
                    <BTN_style_2 onClick={() => navigate('/researcher')}>{card.button_text}</BTN_style_2>
                </RootStyle_2>
            }
            </>
        }
        {width<600 &&
            <>
            {card.id == 1 &&
                <RootStyle_mobile>
                    <Header_text>{card.header}</Header_text>
                    <Content_text>{card.content}</Content_text>
                    <BTN_style_1 onClick={()=>currentUser?navigate('/createorder'):navigate('/login')}>{card.button_text}</BTN_style_1>
                </RootStyle_mobile>
            }
            {card.id == 2 &&
                <RootStyle_mobile>
                    <Header_text>{card.header}</Header_text>
                    <Content_text>{card.content}</Content_text>
                    <BTN_style_2 onClick={() => navigate('/researcher')}>{card.button_text}</BTN_style_2>
                </RootStyle_mobile>
            }
            </>
        }
            </>
    )
}