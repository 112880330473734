import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'




export function CheckPrifile2(currentUser, currentProfile){

    var vv = false

    if((currentUser.ic_profile == 1 || currentUser.ic_profile == '1') && currentProfile == 0){
        // alert(  'mudando de 1 => 2'  )
        vv = true
        return vv
    }
    else if((currentUser.ic_profile == 0 || currentUser.ic_profile == '0') && currentProfile == 1){
        // alert(  'mudando de 0 => 2'  )
        vv = true
        return vv
    }
    else if(currentUser.ic_profile == 2 || currentUser.ic_profile == '2'){
        // alert('perfil 2')
        return vv
    }
        return vv
}