/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import { Link, useNavigate , Navigate} from 'react-router-dom';
import {
    TextareaAutosize,
    Box,
} from '@mui/material';

import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'

import Boostorder from './boostorder'
import API from '../../redux/API';
import { setCurrentOrder } from '../../redux/actions/order.action';
import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toast';

import Checkout from '../../components/Checkout';


const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));


const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#313131',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));


export default (props) => {

    
    const { width } = useWindowSize();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [orderTypeList, setOrderTypeList] = useState([]);
    const [selected, setSelected] = useState([false, false, false]);
    const [selectedBoost, setSelectedBoost] = useState([]);
    const { currentOrder } = useSelector(state => state.order);
    
    const [checkout, setcheckout] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    const [checkoutData, setcheckoutData] = useState({});
    
    useEffect(()=>{
        if (!currentOrder || currentOrder===null || currentOrder===undefined){
            navigate('/myorders')
        }
    },[])

    const [isPay,setisPay] =useState(false) 

    const boostorder = () => {

        //console.log(currentOrder);
        // console.log(selectedBoost);

        // setcheckout(false)
            API.post('order/setorderboost', {
                _id: currentOrder._id,
                boosts: selectedBoost
            })
            .then((result) => {
                if (result.data.status === "success") {
                    toast.success('O pedido foi impulsionado com sucesso!')
                    dispatch(setCurrentOrder(result.data.newOrder))
                    setisPay(true)
                    // navigate('/myorders')
                }
            })
            .catch((err) => {
                toast.error(JSON.parse(err.request.response).message)
            });  
    }



    useEffect(()=>{
        if(orderTypeList.length === 0)
        {
            API.get(`order/getorderboosttypes?language=${'pt'}&currency=${'Reais (BRL)'}`)
                .then((result) => {
                    //console.log(result.data.boostlist);
                    setOrderTypeList(result?.data?.boostlist)
                    let aux=[false,false,false]
                    console.log(currentOrder);
                    result.data.boostlist.map(((single,index)=>{
                        const hasthisBoost = currentOrder.boosts?(Object.values(currentOrder.boosts).find(currentBoost=>currentBoost.tp_boost===single.tp_boost && currentBoost.ic_boostactive)) : false
                        if (hasthisBoost) {
                            console.log(`has ${single}`);
                            aux[index]=true
                        }
                    }))
                    setSelected(aux)

                })
                .catch((err) => {
                    console.error(err)
                    navigate('/myorders')
                });
        } 
    }, []);


    useEffect(()=>{
        // console.log(selectedBoost);
        //console.log(currentOrder);
    }, [selectedBoost,currentOrder]);



    return (
       <RootStyle>

        {checkout && <Checkout 
            type={'boostOrder'} 
            currentOrder={currentOrder}
            setopenModal={setcheckout}
            selectedBoost={selectedBoost}
            checkoutData={checkoutData}
            totalPrice={totalPrice}
            
        />}
            <div className='min-h-[600px]'>
                

            <Boostorder orderTypeList={orderTypeList} selected={selected} setSelected={setSelected} setSelectedBoost={setSelectedBoost} selectedBoost={selectedBoost} setcheckoutData={setcheckoutData} setTotalPrice={setTotalPrice} totalPrice={totalPrice} currentOrder={currentOrder}></Boostorder>
            </div>
            <div className="w-[100%] flex justify-center">
                <div className='w-[82.5%] text-center' >
                    <div className="flex justify-start mb-[80px]">
                        <HeaderBTNStyle onClick={()=>{totalPrice>0 ? setcheckout(true) : navigate('/myorders')}}>
                        Turbinar meu pedido
                        </HeaderBTNStyle>
                        <div className='w-[26px]'></div>
                        <HeaderBTNStyle1
                            onClick={()=>{navigate('/myorders')}}
                                sx={{
                                '&:disabled' : {
                                    backgroundColor: '#C6C6C6',
                                    cursor: 'not-allowed'
                                }
                            }}>
                        Fechar
                        </HeaderBTNStyle1>
                    </div>
                </div>
            </div>

       </RootStyle>
    )
}