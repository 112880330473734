import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import { useNavigate } from 'react-router';
import {
    Grid,
    Box,
    Stack,
    TextareaAutosize,
    Divider,
} from '@mui/material';
import ProposalButton from '../buttons/ProposalButton';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/Success.svg'

import { useDispatch } from 'react-redux'
import { setResult } from '../../redux/actions/update.action';
import API from '../../redux/API';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const DescriptionTextBold = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const Text_span = styled('span')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    color: '#DE2B2B',
}));

const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 35px 0px',
    [theme.breakpoints.up('sm')]: {
        padding: '70px 0px 95px 0px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '68px 3px 105px 3px'
    },
}));

export default function Modal({ openModal, setOpenModal, orderid, option, confirmEvent }) {
    const [page, setPage] = React.useState(1);
    const navigate = useNavigate()
    const { width } = useWindowSize()
    const dispatch = useDispatch();
    const [comment, setComment] = React.useState('')
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleBack = () => {
        setPage(page - 1);
    };
    const handleSubmit = () => {
        const temp = {
            nm_event: "Atualização - ",
            nm_status: "Problema negado",
            nm_title: option,
            dt_event: new Date(),
            ds_content: comment,
            ic_researcherupdate: true,
            ic_firstevent: true,
            ic_confirmed : false,
            ic_canconfirmed: false
        }
        API.post('order/addproblemevent', {
            id: orderid,
            event: temp,
            confirmEvent,
            
        })
        .then((result) => {
            dispatch(setResult({result: 2, comment: comment}))
            window.location.reload();
            setOpenModal(false)
        })
        .catch((err) => {
            navigate('/');
            console.error(err.request.response)
        });
    };
    const handleConfirm = () => {
        const temp = {
            nm_event: "Atualização - ",
            nm_status: "Problema confirmado",
            nm_title: option,
            dt_event: new Date(),
            ds_content: "Pesquisador confirmou que o anexo está incorreto, favor aguardar para que ele anexe novamente para que você possa analisar e validar o novo arquivo.",
            ic_researcherupdate: true,
            ic_firstevent: true,
            ic_confirmed : true
        }
        API.post('order/addproblemevent', {
            id: orderid,
            event: temp,
            confirmEvent,
            isConfirm:true,
        })
        .then((result) => {
            dispatch(setResult({result: 1, comment: ""}))
            window.location.reload()
            setOpenModal(false)
        })
        .catch((err) => {
            navigate('/');
            console.error(err.request.response)
        });

    };

    return (
        <div>
           
            {page > 0 && page < 4 &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openModal} sx={{
                        '.MuiPaper-root': {
                            maxWidth: '837px',
                            width: '100%',
                        }
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    </BootstrapDialogTitle>
                    <DialogContent sx={{ padding: '0px 43px !important' }}>
                        <GridWrapper className={page == 1 ? '!pb-[105px]' :'!pb-[5px]'}>
                            <Grid container spacing={2}>
                                {
                                page == 1 &&
                                <Grid item xs={12} sm={12} md={3}>
                                    <Stack alignItems={'center'}>
                                        
                                            <Box component="img" src={WarningIcon} alt="alerta-icone" width="125px" />
                                        
                                    </Stack>
                                </Grid>
                                }
                                <Grid item xs={12} sm={12} md={page == 1 ? 8 : 12 }>

                                    <HeaderText>Confirmar problema relatado</HeaderText>
                                    <Divider sx={{ marginTop: '19px', marginBottom: '18px', maxwidth: '90%'}}/>
                                    {page == 1 &&
                                        <div className="font-[Poppins] font-light text-[14px] leading-[120%] text-[#313131]">
                               
                                                Ao confirmar que há algum problema com o pedido, o status será automaticamente atualizado para 

                                                “<strong className='font-semibold'>Pesquisando</strong>”, necessitando que o cliente passe novamente pelas validações.
                                                <br/>
                                                <br/>
                                                <br/>
                                                Seu pagamento será efetuado assim que este problema for concluído.
                                     
                                        </div>
                                    }
                                    {page == 2 &&
                                        <div className="font-[Poppins] font-light text-[14px] leading-[120%] text-[#313131]">

                                                Ao confirmar que não nenhum problema com o pedido ou com o anexo, o status não será alterado.
                                                <br/>
                                                <br/>
                                                <br/>
                                                Seu pagamento será efetuado assim que o cliente confirmar o anexo ou concluir automaticamente.
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                
                                                <Stack flexDirection={'row'} justifyContent={'space-between'} >
                                                    <DescriptionText>Deixe uma mensagem para o cliente (opcional)</DescriptionText>
                                                </Stack>
                                                <TextareaAutosize
                                                    maxRows={2}
                                                    placeholder=""
                                                    value={comment}
                                                    onChange={(e)=>{setComment(e.target.value)}}
                                                    style={{
                                                        fontFamily: 'Poppins',
                                                        color: '#313131',
                                                        marginTop: '3px',
                                                        width: '100%',
                                                        minHeight: '100px',
                                                        border: '1px solid #E2E4E5',
                                                        borderRadius: '7px',
                                                        resize: 'vertical',
                                                        paddingTop: '9px',
                                                        paddingLeft: '13px'
                                                    }}
                                                />
                                        </div>
                                    }
                                </Grid>

                            </Grid>
                        </GridWrapper>
                    </DialogContent>
                    <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} paddingTop={'20px'} className="items-center" >
                        {page == 1 &&
                            <>
                                <ProposalButton type={3} fontsize={14} text={'Não há problema com este anexo'} event={handleNext} />
                                { width < 640 ? <Box sx={{ marginBottom: '10px' }} />: null} 
                                <ProposalButton type={1} fontsize={14} text={'Confirmar problema'} event={handleConfirm} />
                            </>
                        }
                        {page == 2 &&
                            <>
                                <ProposalButton type={2} fontsize={14} text={'Voltar'} event={handleBack} />
                                <Box sx={{ marginLeft: '25px' }} />
                                { width < 640 ? <Box sx={{ marginBottom: '10px' }} />: null} 
                                <ProposalButton type={1} fontsize={14} text={'Enviar'} event={handleSubmit} />
                            </>
                        }
                    </Stack>
                    <DialogActions></DialogActions>
                    <DialogActions></DialogActions>
                </BootstrapDialog>
            }
        </div >
    );
}
