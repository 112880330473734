import React, { useState } from "react";
import {styled} from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setUser } from "../../redux/actions/auth.action";
import Loading from "../loading/Loading";
import API from "../../redux/API";
import { toast } from "react-toast";

import Paymentdetail from "./Paymentdetail";
import Stripe from "./Stripe";
import Bid from "./Bid";
import Address from "./Address";
import Confirmation from "./Confirmation";
import Logo from '../../assets/image/logo.svg'
import { useEffect } from "react";
import mergedflags from '../../assets/icons/mergedflags.svg'

import { setCurrentOrder } from "../../redux/actions/order.action";

const mainContainerStyles = {
  zIndex:9999,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minHeight: '300px',
    minWidth: '300px',
    maxHeight: '1000px',
    maxWidth: '1500px',
    background: 'white',
    boxShadow: '0px 10px 200px 1px #c2c2c2',
    fontFamily:'poppins',


  };
  
  
  const topLinksStyles = {
    gridArea: 'link',
    listStyleType: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    boxShadow: '0px 1.5px 1px #eaeaea',
  };
  
  const topLinksItemStyles = {
    display: 'block',
    padding: '14px 0px',
    textTransform: 'uppercase',
    fontSize: '.58rem',
    justifySelf: 'center',
    letterSpacing: '.7px',
    fontWeight: 600,
    color: '#4092b5',
    cursor:'pointer'
  };
const topLinksLeftStyles = {
    color: '#c1c1c1',
  };
  const topLinksSelectedStyles = {
    position: 'relative',
    color: '#4092b5',
  };
  const topLinksThirdChildAfterStyles = {
    position: 'absolute',
    content: ' ',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: '#4092b5',
    borderRadius: '2px',
    };
  
  const previousStepStyles = {
    position: 'absolute',
    bottom: '10px',
    height: '20px',
    width: '50px',
    color: '#4092b5',
    left: '10px',
    cursor: 'pointer',
    margin:'10px auto 10px auto'
  };
  
  const previousStepParagraphStyles = {
    marginTop: '-16px',
    marginLeft: '40px',
    fontSize: '10px',
    textShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    letterSpacing: '.5px',
    cursor: 'pointer',
  };
  
  const previousStepArrowStyles = {
    height: '20px',
    width: '30px',
  };
  
  const previousStepArrowSvgStyles = {
    height: '100%',
    width: '100%',
    transform: 'rotate(180deg)',
    fill: '#3580aa',
  };
  






function Render2BoostImages(image1,image2){

  return(
  // <div>
    <img src={image1} width='100%' height='auto'/>
    // <img src={image2} width='100%' height='auto'/>
  // </div>
  )
}


export default function Checkout(props){
  const {currentUser} = useSelector(state => state.auth);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  
  // const [isPay,setisPay] =useState(false) 
  const [canPage3,setCanPage3] =useState(false) 

  const [page,setpage]=useState(1)
  const [isPaymentFail,setisPaymentFail]=useState(false)
  const [isPaymentSucces,setisPaymentSucces]=useState(false)
  const [cardinfo,setcardinfo]=useState(props.checkoutData?props.checkoutData:{
    bidname:'',
    bidImage:Logo,
    normalprice:0,
    promoprice:0,
    moreinfo:[],
    ic_currentplan:0,
  })

  const [cardNumber,setcardNumber] = useState('')
  const [validate,setvalidate] = useState('')
  const [csv,setcsv] = useState('')
  const [ownerName,setownerName] = useState(currentUser&&currentUser.nm_user?currentUser.nm_user:'')
  const [paymentIntent_id, setpaymentIntent_id] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNext=()=>{

  }

  
  const paymentMethod = (e)=>{
    e.preventDefault();
    const cardDetails = {
      type:'card',
      card:{
        number:4242424242424242,
        exp_month:8,
        exp_year:2024,
        cvc:314, 
      },
      // billing_details:{
      //   email:currentUser.ds_email,
      //   name:currentUser.nm_user,
      //   phone:currentUser.ds_telephone,
      // }
    }

    // API.post('https://api.stripe.com/v1/payment_methods',
    // // new URLSearchParams({
    // //   type: 'card',
    // //   'card[number]': 4242424242424242,
    // //   'card[exp_month]': 8,
    // //   'card[exp_year]': 2024,
    // //   'card[cvc]': 314,
    // // }).toString(),
    // cardDetails,
    // {
    //   headers: {
    //     Authorization: 'Bearer sk_test_51N4XzVEz5x7wi1bKvTemdSUUsoFyfx4YFnt0RduysU2czfFuivV06M9r8RoatOk6yEOcHqkhirplhit0x2yKx8ga00OISNWzoF',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   }
    // })
    // .then((res) => {
    //   toast.success('SUCCESS');
    //   return console.log(res);
    // })
    // .catch((error) => {
    //   console.log(error);
    //   return toast.error('ERROR');
    // });
  }

  
  const confirmPayment=()=>{

    if (currentUser) {
      setLoading(true)
      if (props.type) {
        if(props.type==='getMoreImportPlans') {

          //console.log(props.cardContent);

          API.post('user/getmoreimportplans', {currentUser:currentUser, planInfo:props.cardContent, paymentIntent_id:paymentIntent_id})
          .then((res)=>{
            setisPaymentSucces(true)
            dispatch(setUser(res.data.user));
            toast.success('Você comprou novos pedidos com sucesso!')
            setLoading(false)
            return setpage(page+1)

          })
          .catch((err)=>{
            console.log(err);
            toast.error('Erro ao contratar. Entre em contato com o suporte.')
            setLoading(false)
            return setpage(page+1)

          })
        }
        if(props.type==='boostOrder') {
          API.post('order/setorderboost', {
            _id: props.currentOrder._id,
            boosts: props.selectedBoost, 
            paymentIntent_id:paymentIntent_id
          })
          .then((result) => {
              if (result.data.status === "success") {
                  toast.success('O pedido foi impulsionado com sucesso!')
                  dispatch(setCurrentOrder(result.data.newOrder))
                  setisPaymentSucces(true)
                  setLoading(false)
                  return setpage(page+1)
                  // navigate('/myorders')
                }
              })
              .catch((err) => {
                setLoading(false)
                toast.error(JSON.parse(err.request.response).message)
                return setpage(page+1)
          });  
        }
        if(props.type==='membership') {
          const currentDate = new Date
          const dt_planend = new Date(currentDate.getTime() + 1000*60*60*24*365)   //add 365 days to current date
            API.post('user/setcurrentplan',{
              ic_currentplan:cardinfo.ic_currentplan,
              ds_email:currentUser.ds_email,
              singlePlan:{
                ic_plan:cardinfo.ic_currentplan,
                dt_planstart: currentDate,
                dt_planend: dt_planend,
                nr_plandurationleft:365,
                ic_planactive:true,
                vl_value:cardinfo.promoprice,
                vl_originalvalue:cardinfo.normalprice,
                id_plan:cardinfo.id_plan,
              },
              paymentIntent_id:paymentIntent_id})
            .then((result) => {
              if(result.data.status === 'success'){
                toast.success('Sucesso.');
                dispatch(setUser(result.data.user))
                setisPaymentSucces(true)
                setLoading(false)
                return setpage(page+1)
              }
            })
            .catch((err) => {
              toast.error(JSON.parse(err.request.response).message)
              console.log(err);
              setLoading(false)
              return setpage(page+1)
          });
        }
        if(props.type==='sendnegociation'){
          API.post(`order/allowstart/`, {
            researcherID:props.researcherID, 
            orderID:props.orderID, 
            userID:currentUser.id?currentUser.id:currentUser._id,
            paymentIntent_id:paymentIntent_id
          })
          .then((result) => {
            if(result.data.status === 'success'){
              ////toast.success('sucesso')
              setisPaymentSucces(true)
              setLoading(false)
              return setpage(page+1)
            }
          })
          .catch((err) => {
            toast.error(JSON.parse(err.request.response).message)
            console.log(err);
            setLoading(false)
            return setpage(page+1)
        });

        }
        if(props.type==='acceptproposal'){
          API.post(`order/acceptproposal/`, {
            researcherID:props.researcherID, 
            orderID:props.orderID, 
            userID:currentUser.id?currentUser.id:currentUser._id, 
            paymentIntent_id:paymentIntent_id
          })
          .then((result) => {
            if(result.data.status === 'success'){
              ////toast.success('sucesso')
              setisPaymentSucces(true)
              // window.location.reload()
              setLoading(false)
              return setpage(page+1)
            }
          })
          .catch((err) => {
            toast.error(JSON.parse(err.request.response).message)
            console.log(err);
          //   if(err.message === 'Request failed with status code 500'){
          //     toast.error('Você precisa estar logado corretamente para acessar')
          //   }else{
          //       toast.error(`Erro ao obter dados da ordem ||| ${err.message} `) 
          //   }

          // navigate('/login')
          setLoading(false)
            return setpage(page+1)
        });
      }
      }
    }
    else{
      navigate('/login')
    }
  }






useEffect(()=>{

  // changing chackout data from boost order
  if (props.selectedBoost && props.totalPrice) {
    // alert(JSON.stringify(props.selectedBoost))

    // image to show
    var image = null

    const urgencyObject = props.selectedBoost.find(obj => obj.tp_boost === "Urgency");
    const hightlightObject = props.selectedBoost.find(obj => obj.tp_boost === "Hightlight");

    if (hightlightObject && !urgencyObject) {
      image = hightlightObject.image
    }
    else if (urgencyObject && !hightlightObject) {
      image = urgencyObject.image
    }
    else if(hightlightObject && urgencyObject){
      image = mergedflags
    }
    else{
      image = Logo
    }

    setcardinfo({
      bidname:`${props.selectedBoost.map((single,index)=>{if(index===props.selectedBoost.length-1){return ' '+single.nm_boost+'.'}return ` ${single.nm_boost}`})}`,
      bidImage:image ,
      normalprice:null,
      promoprice:props.totalPrice,
      moreinfo:props.selectedBoost.map((single)=>{return single.ds_boost})    
    })
  }

},[props])



const formatpricetoshow=(value)=>{


  var price = Number(value).toFixed(2)

  price = price.toString()
  price = price.replace('.',',')
  if(price.length>6){
    var beforethousand = price.slice(0,price.length-6)
    var afterthousand = price.slice(price.length-6)
    var price = `${beforethousand}.${afterthousand}`
  }
  if(price.length>10){
    var beforethousand = price.slice(0,price.length-10)
    var afterthousand = price.slice(price.length-10)
    var price = `${beforethousand}.${afterthousand}`
  }
  

  return price
}


const isMobile = window.innerWidth < 700;

    return(

        <div style={{ zIndex:999, position: 'fixed',  width:'100%', height:'100%', top:'0', left:'0', fontFamily:'poppins' }} >

        {loading && <Loading/>}
        <div onClick={()=>{
          if (props.type==='boostOrder') {
            props.setopenModal(false);
            navigate('/myorders') 
            
          }
          else if((props.type==='acceptproposal' || props.type==='sendnegociation') && (isPaymentFail||isPaymentSucces)){
            props.setopenModal(false)
            window.location.reload()
          }
          else if(props.type==='getMoreImportPlans'){
            props.setopenModal(false)
            navigate('/importorder')
          }
          else{
            props.setopenModal(false)
          }
          
        }} style={{ position:'absolute',  backgroundColor:'rgba(23, 104, 110,0.3)', width:'100%', height:'100%', top:'0', left:'0'}}></div>


        
        <div style={
          

        isMobile?{    

          zIndex:9999,
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          background: 'white',
          boxShadow: '0px 10px 200px 1px #c2c2c2',
          fontFamily:'poppins',
  
          minHeight:'200px',
          maxHeight: '800px',
          width: '300px',

        }
      :
      {
        zIndex:9999,
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        background: 'white',
        boxShadow: '0px 10px 200px 1px #c2c2c2',
        fontFamily:'poppins',
        
        minHeight: '300px',
        maxHeight: '800px',
        minWidth: '30%',
        maxWidth: '70%',
        // overflow:'auto',

        }
        }>
                  <ul style={topLinksStyles}>
                    <li  onClick={()=>{!isPaymentSucces && setpage(1)}} style={{...topLinksItemStyles,...(page===1&&topLinksSelectedStyles),...(page<1&&topLinksLeftStyles) } }>Seu pedido              {page===1 && <span style={topLinksThirdChildAfterStyles}></span>}</li>
                    <li onClick={()=>{!isPaymentSucces && setpage(2)}} style={{...topLinksItemStyles,...(page===2&&topLinksSelectedStyles),...(page<2&&topLinksLeftStyles) } }>Endereço de pagamento   {page===2 && <span style={topLinksThirdChildAfterStyles}></span>}</li>
                    <li onClick={()=>{!isPaymentSucces && canPage3 &&setpage(3)}} style={{...topLinksItemStyles,...(page===3&&topLinksSelectedStyles),...(page<3&&topLinksLeftStyles) } }>Detalhes do pagamento   {page===3 && <span style={topLinksThirdChildAfterStyles}></span>}</li>
                    <li onClick={()=>{}} style={{...topLinksItemStyles,...(page===4&&topLinksSelectedStyles),...(page<4&&topLinksLeftStyles) } }>Confirmação             {page===4 && <span style={topLinksThirdChildAfterStyles}></span>}</li>
                  </ul>






                {page===1 && <Bid formatpricetoshow={formatpricetoshow}  cardinfo={cardinfo} setpage={setpage}/>}
                {page===2 && 
                  <div style={
                    // isMobile?
                    {
                      // display:'flex', 
                      margin:'20px', 
                      // minWidth:'300px', 
                      // minHeight:'500px',
                      // maxWidth:'700px', 
                      // maxHeight:'1000px', 
                      width:'100%', 
                      height:'100%', 

                      // overflow:'auto'
                    }
                    // :
                    // {margin:'20px', }
                    }
                  > 
                    <Address cardinfo={currentUser} setpage={setpage} isShowingCheckout={true} setCanPage3={setCanPage3} canPage3={canPage3} setLoading={setLoading}/>
                  </div>
                }
                {page===3 && <Paymentdetail formatpricetoshow={formatpricetoshow} setisPaymentSucces={setisPaymentSucces} confirmPayment={confirmPayment} cardinfo={cardinfo} currentUser={currentUser} ownerName={ownerName} setownerName={setownerName} cardNumber={cardNumber} setcardNumber={setcardNumber} validate={validate} setvalidate={setvalidate} csv={csv} setcsv={setcsv} paymentMethod={paymentMethod} setpaymentIntent_id={setpaymentIntent_id} setLoading={setLoading}/>}
                {page===4 &&                   
                    <div style={{
                      margin:'20px', 
                      width:'100%', 
                      height:'100%', 
                    }}
                  > 
                    <Confirmation setpage={setpage} isPaymentSucces={isPaymentSucces} isPaymentFail={isPaymentFail} setLoading={setLoading}/>
                  </div>
                }


                {page>1 && !isPaymentSucces && 
                  <div onClick={()=>{setpage(page-1)}} style={previousStepStyles}>
                    <div style={previousStepArrowStyles}>
                      <svg  style={previousStepArrowSvgStyles}
                        version="1.1"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g transform="translate(0 -540.36)">
                          <path d="m23.678 780.7v31.327h374.07l0.0162 24.857 90.559-40.521-90.559-40.521-0.0162 24.857z" />
                        </g>
                      </svg>
                    </div>
                    <p  style={previousStepParagraphStyles}>Voltar </p>
                  </div>
                }

        </div>

        




        </div>

    )
}