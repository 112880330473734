import { styled } from '@mui/material/styles';
// components
import OurPlans from './body';


// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));


const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',

    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        // padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '10px'
    }
}));

export default function Plan() {

    return (
        <RootStyle>
            <ContainerStyle>
            </ContainerStyle>
            <OurPlans />
        </RootStyle>
    )
}