/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
} from '@mui/material';
import API from '../../redux/API';
import Tooltip from '@mui/material/Tooltip';
import ReactFlagsSelect from "react-flags-select";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InformationIcon from '../../assets/icons/informationicon.svg';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toast';

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '0px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

  

export default ({
    checkCanFetch,
    setStampAvailable,stampAvailable,
    ic_fetchdocument, ic_searchdocument, 
    nm_documentcountry, setnm_documentcountry,
    nm_documentlocation, setnm_documentlocation,
    id_documenttype, setid_documenttype,
    ds_familysearchprofile, setds_familysearchprofile,
    nm_otherdocument, setnm_otherdocument,
    ic_digitaldocument, setic_digitaldocument,
    ic_physicaldocument, setic_physicaldocument,
    ic_stampeddocument, setic_stampeddocument,
    needOtherType,setneedOtherType,
    setOtherDocOK,otherDocOK,setLocationSelected,locationSelected,
}) => {

    const [country, setCountry] = useState('BR');
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [documentType, setDocumentType] = useState('');    
    const { width } = useWindowSize();
    const [allLocations, setallLocations] = useState([]);

    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    function onPlaceSelect(e, ref) {
        console.error(e)
        console.log(ref)
        setnm_documentlocation(ref.value)
    }
    useEffect(()=>{
        if(documentTypeList.length === 0)
        {
            API.get(`order/getdocumenttypes?language=${'pt'}`)
            .then((result) => {
                setDocumentTypeList(result.data.typeList)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    }, []);

    const checkStampAvailableCountry = (code) => {
        checkCanFetch()
        API.post(`order/checkhcchmember`,{code:code})
            .then((result) => {
                if(result.data.status==='success'){
                    setStampAvailable(true);
                }
                else{
                    setStampAvailable(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setStampAvailable(false);
            })
    }

    const [allcountries,setallcountries]=useState([])

    const getallcountries =()=>{
        API.post('/order/getallcountries')
        .then((res)=>{
            if(res.data.allcountries){
                var aux = []
                res.data.allcountries.map((single)=>{
                    const cd_isoalpha2 = single.cd_isoalpha2
                    aux.push({cd_isoalpha2:cd_isoalpha2})
                })
            }
            setallcountries(res.data.allcountries)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        getallcountries()
    },[])

    const getCountryNamePt = (isoAlpha2) => {
        const country = allcountries.find((c) => c.cd_isoalpha2 === isoAlpha2);
        return country ? country.nm_country_pt : '';
      };
    

    const customLabels = allcountries.reduce((obj, country) => {
        obj[country.cd_isoalpha2] = country.nm_country_pt;
        return obj;
      }, {});
          

      const handleInputChange = async (event, newInputValue) => {
        setLocationSelected(false)
        try {
            setnm_documentlocation(newInputValue)
          const url = `https://beta.familysearch.org/platform/places/search?q=partialName:${newInputValue.replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`; 
          const response = await fetch(url, {
            headers: {
              Accept: 'application/x-gedcomx-atom+json',
              Authorization: 'Bearer b0-8M20SkEBXmw.4TfUMAXvji6',
            },
          });
    
          if (response.ok) {
            var data = await response.json();
            var uniqueEntries = [...data?.entries]
            if(data&&data.entries){
                for (let i = 0; i < data.entries.length; i++) {
                    const single = data.entries[i];
                    if(single.content.gedcomx.places[0].display.fullName){
                       var thisName = single.content.gedcomx.places[0].display.fullName
                       
                       if(data.entries.some((sing,ind)=>sing.content.gedcomx.places[0].display.fullName===thisName && ind!==i)){
                            uniqueEntries.splice(i,1)
                       }
                    }
                    
                }
            }
            data.entries = uniqueEntries
            const places = data?.entries?.map(singlePlace => singlePlace.content.gedcomx.places[0].display.fullName || '');
            setallLocations(places);
          } 
        } catch (error) {
          console.error(error);
        }
        setLocationSelected(false)
      };

    return (
        <ContainerStyle className='flex-col'>
            <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131]" style={{color: "#313131"}}>Vamos começar:  </div>
            <div className='flex flex-col items-center'>
                <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around mt-[21px]">
                    <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>Qual país de origem desse documento? <span className='text-[#DE2B2B]'>*</span></div>
                                <div>
                                    <ReactFlagsSelect
                                        className='w-full'
                                        selected={nm_documentcountry}
                                        onSelect={(code) => {checkStampAvailableCountry(code); setnm_documentcountry(code)}}
                                        searchable="true"
                                        searchPlaceholder="Pesquisar"
                                        placeholder='Indique o local exato onde este documento foi localizado'
                                        customLabels={customLabels}
                                    />
                                </div>
                            </div>
                    </div>
                    <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px]'>
                        <div className='w-full'>
                            <div className={style.title}>Local de origem do documento</div>
                            <div>
                            <Stack spacing={2} sx={{ width: '100%' }}>
                            <Autocomplete
                                            sx={{
                                                'input': {
                                                    // width:'100%',
                                                    height:'10px',
                                                },
                                                '& label':{
                                                    margin:'-5px auto auto 3px',
                                                }
                                              }}
                                            value={nm_documentlocation}
                                            options={allLocations}
                                            renderInput={(params) => { 
                                                //console.log(params); 
                                                return <TextField {...params} 
                                                label="Pesquisar"
                                                placeholder='Se não souber o local exato, apenas indique o país ou o lugar mais próximo'
                                                value={nm_documentlocation}
                                                onChange={(e) => {
                                                    const newValue = e.target.value
                                                    handleInputChange(e,newValue)
                                                }}
                                                onSelect={(e) => {
                                                    const newValue = e.target.value
                                                    setnm_documentlocation(newValue)
                                                    setLocationSelected(true)
                                                }}
                                                />
                                            }}
                                        />
                                    </Stack>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around">
                    <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                        <div className='w-full'>
                            <div className="flex justify-between">
                                <div className={style.title}>Qual tipo de documento que foi solicitado? <span className='text-[#DE2B2B]'>*</span></div>
                                <Tooltip title={<div className="text-center font-[Poppins]  pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Escolha o tipo de documento que você precise que seja procurado/entregue pelo pesquisador, para você.</div>}>
                                    <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                </Tooltip>
                            </div>
                            <div>
                                <Select
                                    value={id_documenttype}
                                    onChange={(e) => {
                                        if(e.target.value==='6395949d18b3d1cf0751f005'){
                                            setneedOtherType(true)
                                        }
                                        setid_documenttype(e.target.value)
                                    }}
                                    displayEmpty="displayEmpty"
                                    className="w-[100%] h-[40px]"
                                    MenuProps={{
                                        sx:{
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                            },
                                            '& .MuiMenuItem-root': {
                                                whiteSpace: 'normal',
                                            },
                                        }
                                    }} 
                                    >
                                    {
                                        //dynamic
                                        documentTypeList.map((item) => {
                                            return (
                                                <MenuItem value={item._id} key={item._id}>
                                                    <p
                                                        className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item.nm_documenttype}</p>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className='mb:w-[100%] sm:w-[50%] flex '>
                        {
                            documentTypeList.length != 0 && id_documenttype==='6395949d18b3d1cf0751f005' &&                         
                            <div className='w-full mb-[25px]'>
                                <div className={style.title}>Qual outro documento foi solicitado? <span className='text-[#DE2B2B]'>*</span></div>
                                <div>
                                    <TextField
                                        autoComplete="email"
                                        type="email"
                                        className="w-full "
                                        value={nm_otherdocument}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                            const capitalizedInput = formattedName
                                                .toLowerCase() // Convert the entire input to lowercase
                                                .split(' ')
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(' ');
                                            if (e.target.value) {
                                                setOtherDocOK(true);
                                            } else {
                                                setOtherDocOK(false);
                                            }
                                            setnm_otherdocument(capitalizedInput);
                                        }}
                                        InputProps={{
                                            className: style.content,
                                            type: "email",
                                            requried: "true"
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"/>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-around">
                    <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mb:mr-[0px] sm:mr-[25px]'>
                        <div className='w-full'>
                        <div className="flex justify-between">
                                <div className={style.title}>Link do perfil do Family Search</div>
                                <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Para que o pesquisador tenha mais informações sobre os seus antepassados, cole o link do perfil do seu antepassado do Family Search. Lembrando que o perfil do seu antepassado é público no Family Search, caso ele seja uma pessoa falecida.</div>}>
                                    <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                </Tooltip>
                        </div>
                        <div>
                                <TextField
                                    autoComplete="text"
                                    type="text"
                                    className="w-full"
                                    InputProps={{
                                        className: style.content,
                                        requried: "true"
                                    }}
                                    value={ds_familysearchprofile}
                                    onChange={(e)=>{setds_familysearchprofile(e.target.value)}}
                                    hiddenLabel="hiddenLabel"
                                    placeholder="Exemplo: https://www.familysearch.org/tree/person/details/LZNC-TDG"
                                    id="outlined-basic"
                                    variant="outlined"/>
                            </div>
                        </div>
                    </div>
                    <div className='mb:w-[100%] sm:w-[50%] flex '>
                        
                    </div>
                </div>
                <div className='mb:w-full sm:w-[96%]'>
                    <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Forma de recebimento do documento solicitado: <span className='text-[#DE2B2B]'>*</span>
                    </div>
                    <div>

                            <FormControlLabel control={<Checkbox
                                disabled={ic_searchdocument && !ic_fetchdocument}  
                                checked = {ic_digitaldocument}
                                onChange={(e)=>{
                                    if(!ic_physicaldocument && !ic_stampeddocument) {
                                        setic_digitaldocument(e.target.checked)
                                    }
                                }}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                        color: '#17686E',
                                    }
                                }}      
                            />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131]">Documento digital</div>
                                                            <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Foi enviado somente por via digital para o cliente.</div>}>
                                <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                            </Tooltip>
                            </div>} />
                            <FormControlLabel control={<Checkbox       
                                disabled={ic_searchdocument && !ic_fetchdocument}   
                                checked = {ic_physicaldocument}   
                                onChange={(e)=>{
                                    if(!ic_stampeddocument) {
                                        setic_physicaldocument(e.target.checked);
                                        setic_digitaldocument(e.target.checked);
                                    }
                                }}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                        color: '#17686E',
                                    }
                                }}      
                            />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#313131]">Documento físico</div>
                                                                <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Foi enviado um documento físico para o cliente.</div>}>
                                    <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                </Tooltip>
                            </div>} />
                            <FormControlLabel control={<Checkbox
                                checked = {ic_stampeddocument}
                                disabled = {!stampAvailable || (ic_searchdocument && !ic_fetchdocument)}
                                onChange={(e)=>{
                                    setic_stampeddocument(e.target.checked);
                                    setic_physicaldocument(e.target.checked);
                                    setic_digitaldocument(e.target.checked);
                                }}
                                sx={{
                                    color: '#313131',
                                    '&.Mui-checked': {
                                        color: '#17686E',
                                    }
                                }}      
                            />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#313131]">Documento apostilado</div>
                                    <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Foi enviado um documento físico e apostilado que recebeu um selo oficial da autoridade competente do país signatário da Convenção de Haia.</div>}>
                                    <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                </Tooltip>
                            </div>} />

                    </div>
                </div>
            </div>
        </ContainerStyle>
    )
}