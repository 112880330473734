/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-anonymous-default-export */

import { useState } from "react";
import { Box } from "@mui/material";

import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        height: '4px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '20px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '20px',
      },
    }
}))
export default (props) => {
    const {orderstatusList} = props;
    const classes = useStyles();
    return (
        <div className={classes.root + " flex mt-[20px] width-[926px] overflow-x-auto"}>
            {
                orderstatusList.map((item,index) => {
                    return(
                        <div key={index} className="w-[155px] h-[100px] flex flex-col">
                            <div className='flex justify-center'>
                                {item.status === 0 && <Box component='img' src={item.icon1} width='30px' height='30px'/>}
                                {item.status === 1 && <Box component='img' src={item.icon2} width='30px' height='30px'/>}
                                {item.status === 2 && <Box component='img' src={item.icon3} width='30px' height='30px'/>}
                                
                            </div>
                            <div className='flex justify-center'>
                                {item.status === 0 &&<div className='mt-[8px] border-solid border-[1px] border-[#17686E] w-[150px] h-[0px] border-opacity-90'></div>}
                                {item.status === 1 &&<div className='mt-[8px] border-dashed border-[1px] border-[#C6C6C6] w-[150px] h-[0px] border-opacity-90'></div>}
                                {item.status === 2 && <div className='mt-[8px] border-solid border-[1px] border-[#AB212F] w-[150px] h-[0px] border-opacity-90'></div>}
                            </div>
                            <div className='flex justify-center'>
                                {item.status === 0 && <div className='mt-[8px] font-[Poppins] font-normal text-[11px] leading-[16px] text-[#313131]'>{item.text}</div>}
                                {item.status === 1 && <div className='mt-[8px] font-[Poppins] font-normal text-[11px] leading-[16px] text-[#C6C6C6]'>{item.text}</div>}
                                {item.status === 2 && <div className='mt-[8px] font-[Poppins] font-normal text-[11px] leading-[16px] text-[#313131]'>{item.text}</div>}
                            </div>
                            <div className='flex justify-center'>
                                <div className='mt-[4px] font-[Poppins] font-[275] text-[10px] leading-[15px] text-[#313131] h-[15px]'>{(item.status === 0 || item.status === 2) && item.time}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}