/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { styled } from '@mui/material/styles';
import './request.css';
import {
    Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import SubmitModal from '../../components/modal/confirmProblem.js';
import useWindowSize from '../../hooks/useWindowSize'
import ClockImg from '../../assets/icons/clock2.svg'
import RightImg from '../../assets/icons/Polygon_right.svg'
import LeftImg from '../../assets/icons/Polygon_left.svg'
import TextIcon from '../../assets/icons/texticon.svg'
import TextIcon1 from '../../assets/icons/texticon1.svg'
import TextIcon2 from '../../assets/icons/texticon2.svg'

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginBottom: '22px',
    lineHeight: '21px',
}));

export default (props) => { //dynamic
    const data = props.data;
    const orderid = props.orderid;
    const confirmEvent = props.confirmEvent;
    const { width } = useWindowSize()
    const [openModal, setOpenModal] = React.useState(false);
    const {currentProfile} = useSelector(state => state.auth);
    const getFormatedDate = (dt_event) => {
        const current = new Date();
        let passedtime = "";
        let  offset = Math.floor((current-new Date(dt_event))/1000/60);
        if(offset < 60) passedtime = offset+(offset > 1 ? " mins" :" min")+" atrás";
        else{
            offset = Math.floor(offset / 60);
            if(offset < 24) passedtime = offset+ (offset > 1 ? " horas":" hora")+" atrás";
            else{
                offset = Math.floor(offset / 24);
                if(offset < 7) passedtime = offset+(offset > 1 ? " dias" :" dia")+" atrás";
                else{
                    offset = Math.floor(offset / 7);
                    if(offset < 4) passedtime = offset+(offset > 1 ? " semanas": " semana")+" atrás";
                    else{
                        passedtime = (current.getMonth() - new Date(dt_event).getMonth()) + ((current.getMonth() - new Date(dt_event).getMonth()) > 1 ? " mêss": " mês") + " atrás";
                    }
                }
            }
        }
        passedtime = new Date(dt_event).toLocaleDateString('pt-BR') + ' - '+passedtime;
        return passedtime;
    }
    return (
        
        <div className={!data.ic_researcherupdate || width < 1024 ? "flex items-center mb:mt-[20px] lg:mt-[20px] mb:w-[calc(100%-24px)] lg:w-[calc(50%+30px)] float-left" : "flex items-center mt-[20px] mb:w-[calc(100%-24px)] lg:w-[calc(50%+30px)] float-right "}>
             
          {!data.ic_researcherupdate && data.ic_firstevent ? <SubmitModal openModal={openModal} setOpenModal={setOpenModal}  orderid={orderid} option={data.nm_title} confirmEvent={confirmEvent}/> : null}
            {
            !data.ic_researcherupdate && width > 1024 ? 
                null
                :
                <div>
                    <div className="z-0 mr-[-10px] flex items-center">
                        <div className='mb:w-[50px] mb:h-[42px] lg:w-[70px] lg:h-[60px] bg-[#D9D9D9] rounded-[50%] flex items-center justify-center '>
                            {data.ic_firstevent ? (!data.ic_researcherupdate ? <Box component={'img'} src={TextIcon2 } className="mb:w-[28px] mb:h-[28px] lg:w-[30px] lg:h-[30px] bg-[#D9D9D9]  "/> : <Box component={'img'} src={TextIcon1 } className="mb:w-[28px] mb:h-[28px] lg:w-[30px] lg:h-[30px] bg-[#D9D9D9]  "/>) : <Box component={'img'} src={TextIcon} className="mb:w-[28px] mb:h-[28px] lg:w-[30px] lg:h-[30px] bg-[#D9D9D9]  "/>}
                        </div>
                        <Box component={'img'} src={LeftImg} className="w-[35px] h-[27.5px] bg-white "/>
                       
                    </div>
                </div>
            }
            <div className="flex bubble z-10">
                
                <div className={!data.ic_researcherupdate && width > 1024 ? "mt-[14px] ml-[27px] rounded-[5px]" : "mt-[14px] ml-[10.7px] mr-[40px] rounded-[5px] "}>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[24px] text-[#000000]">{data.nm_event} 
                        <span className="italic font-[Poppins] font-normal text-[14px] leading-[24px] text-[#000000]">{data.nm_status?` - ${data.nm_status}`:''}</span>
                    </div>
                    
                    <div className={!data.ic_firstevent ? "font-[Poppins] font-light text-[14px] leading-[24px] text-[#000000]" : "font-[Poppins] font-normal text-[14px] leading-[24px] text-[#17686E]"}>{data.nm_title ? data.nm_title : ''}</div>
                    <div className="flex items-center">
                        <div className='leading-[24px]'><Box component={'img'} src={ClockImg} className="w-[10px] h-[10px] bg-white"/></div>
                        
                        <div className="font-[Poppins] font-light text-[10px] leading-[24px] ml-[2px]">{getFormatedDate(data.dt_event)}</div>
                    </div>
                    
                    <div className="font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] mb-[23px]">{data.ds_content}</div>
                    {
                        currentProfile == 1 && data.ic_canconfirmed ?
                        <HeaderBTNStyle onClick={() => {setOpenModal(true)}} disabled = {data.ic_confirmed} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Confirmar problema</HeaderBTNStyle>
                        : null
                    }
                </div>

            </div>
            {
                !data.ic_researcherupdate && width > 1024 ? 
                <div>
                    <div className="z-0 ml-[-10px] flex items-center">
                        <Box component={'img'} src={RightImg} className="w-[35px] h-[27.5px] bg-white "/>
                        <div className='mb:w-[50px] mb:h-[42px] lg:w-[70px] lg:h-[60px] bg-[#D9D9D9] rounded-[50%] flex items-center justify-center '>
                            {data.ic_firstevent ? (!data.ic_researcherupdate ? <Box component={'img'} src={TextIcon2 } className="mb:w-[28px] mb:h-[28px] lg:w-[30px] lg:h-[30px] bg-[#D9D9D9]  "/> : <Box component={'img'} src={TextIcon1 } className="mb:w-[28px] mb:h-[28px] lg:w-[30px] lg:h-[30px] bg-[#D9D9D9]  "/>) : <Box component={'img'} src={TextIcon} className="mb:w-[28px] mb:h-[28px] lg:w-[30px] lg:h-[30px] bg-[#D9D9D9]  "/>}
                        </div>
                    </div>
                </div>
                :
                null
            }

        </div>
    )
}