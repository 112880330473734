import { styled } from '@mui/material/styles';
import { useNavigate, Navigate} from 'react-router-dom';
import {
    Box,
    Button,
    Pagination,
    Stack,
} from '@mui/material';
// components
import BackIcon from '../../assets/icons/back.svg'
import NotificationCard from '../../components/card/NotificationCard';
import API from '../../redux/API'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLayoutEffect } from 'react';

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '10px auto',
    minHeight: '700px',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        // padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '10px'
    }
}));

const NavWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '39px 0px 23px',
}));

const NavText = styled('div')(({ theme }) => ({
    width: '100%',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#313131',
    textAlign: 'center',
    fontSize: '20px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '26px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '32px',
    },
}));

const NoDataMessage = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    color: '#313131',
    textAlign: 'center',
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
}));




export default function Notification() {
    const navigate = useNavigate();
    const [navData, setNavData] = useState([]);
    const [totalCnt, setTotalCnt] = useState(0);
    
    const [page, setPage] = useState(1);
    const {currentUser} = useSelector(state => state.auth);
    const {notification} = useSelector(state => state.notification)
   
    useLayoutEffect(()=>{
        if(notification.length !== 0){
            let temp = notification.length;
            if(temp % 5 !== 0){
                setTotalCnt(Math.floor(temp / 5) + 1 );
            }
            else setTotalCnt(temp / 5);
            setNavData(notification.slice((page - 1) * 5, page * 5 ))
        }
    }, [notification])
    if (!currentUser){
        return <Navigate to='/'  />
    }
    const handleChange = (event, value) => {
        
      setPage(value);
      setNavData(notification.slice((value - 1) * 5, value * 5 ))
    };
    return (
        <RootStyle>
            <div className="mb:h-[620px] md:h-[900px]">
                <NavWrapper>
                    <Button onClick={()=>{navigate(-1)}}>
                        <Box component={'img'} src={BackIcon} width={'13px'} />
                    </Button>
                    <NavText>Notificações</NavText>
                </NavWrapper>
                {navData.length === 0 && <NoDataMessage>Não há novas notificações.</NoDataMessage>}

                {navData?.map((item, index) => (
                    <NotificationCard card={item} />
                ))}
            </div>

            <Stack alignItems="center" sx={{ my: 5 }}>
                <Pagination 
                    count={totalCnt}
                    page={page}
                    onChange={handleChange}
                    sx={{
                        "& .MuiPaginationItem-root": {
                            "&:hover:not(.Mui-selected)": {
                                backgroundColor: "#17686E",
                                color: "white"
                            }
                        },
                        "& .Mui-selected": {
                            backgroundColor: "#17686E",
                            color: "white",
                        "&:hover": {
                            backgroundColor: "#17686E",
                            color: "white"
                        }
                        }
                    }}                    
                    />
            </Stack>
        </RootStyle>
    )
}