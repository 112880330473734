/* eslint-disable import/no-anonymous-default-export */
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Rating } from '@mui/material';
import {Avatar, Box, TextareaAutosize} from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize';
import { IMAGE_BASE } from '../../config/constants';
import API from '../../redux/API';
import { toast } from 'react-toast';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '24px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#464A53',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '13px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '24px',
}));

export default (props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const researcherINFO = props.researcherINFO
    const orderID = props.orderID
    const {currentUser} = useSelector(state => state.auth);
    const {width} = useWindowSize();
    const [comment, setComment] = useState('');
    const commentChange = (e) => {
        setComment(e.target.value);
        setCount(e.target.value.length)
    }
    const minlimit = 30;
    const [count, setCount] = useState(0);
    const [rating, setRating] = useState(0);
    const [review, setreview] = useState({})

    const submitRequest = () => {
        const path = location.pathname;
        const id = path.split('/')[3];
        const id_order = currentUser.id?currentUser.id:currentUser._id
        const data = {
            researcherID:id,
            review: review,
            userID: currentUser.id?currentUser.id:currentUser._id,
            id_order:orderID,
        }
        API.post(`order/researchersentfeedback`, data)
          .then((result) => {
            if(result.data.status === 'success'){
                toast.success('Avaliação enviada com sucesso')
                navigate('/myorders')
            }
            else{
                toast.error('Erro ao Avaliar o pesquisador.')
            }
          })
          .catch((err) => {

            if(err.message === 'Request failed with status code 500'){
                toast.error('Você precisa estar logado corretamente para acessar')
            }else{
                toast.error(`Erro ao obter dados do pesquisador ||| ${err.message} `) 
            }
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const ratingLater = () =>{
        navigate(-1);
    }
    const [page, setPage] = useState(1);

    useEffect(()=>{
        // alert(rating)
        setreview({
            nr_rating:rating,
            ds_review:comment,
        })
    },[rating , comment ])

    return(
        <div className='w-[100%] rounded-[7px] shadow-[-2px_3px_50px_rgba(0,0,0,0.15)]'>
            <div className="flex ml-[40px] mr-[40px] md:justify-between mb:flex-col-reverse md:flex-row mb:items-center">
            <HeaderBTNStyle onClick={()=>{setPage(2)}}  sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}} className="mb-[70px] mb:block md:hidden">Continuar</HeaderBTNStyle>
         
                <div className="flex flex-col mt-[37px]  mb:mb-[35px] md:mb-[41px]">
                   
                    <div className='mb:text-center md:text-left font-[Poppins] font-semibold mb:text-[16px] md:text-[20px] text-[#313131] leading-[24px]'>O que você achou de {researcherINFO.nm_user}?</div>
                    <div className='mb:hidden md:block font-[Poppins] font-light text-[14px] text-[#464A53] leading-[24px] mt-[15px]'>O que você achou do pesquisador?</div>
                    <div className="mt-[7px] flex mb:justify-center md:justify-start">
                        <Rating name="half-rating" onChange={(e) => {setRating(e.target.value)}} value={rating} defaultValue={0} precision={0.1} sx={{
                        '& .MuiRating-iconFilled': {
                            color: '#17686E',
                            strokeWidth: '1px',
                        },
                        fontSize: (width > 900 ?'60px':'50px')
                        }}/>
                    </div>
                    <div className='mb:w-[250px] md:w-[300px] flex justify-between md:pl-[5px] md:pr-[5px] mb:pl-[10px] mb:pr-[10px] '>
                        <div className='font-[Poppins] font-normal text-[12px] text-[#464A53] leading-[24px]'>Ruim</div>
                        <div className='font-[Poppins] font-normal text-[12px] text-[#464A53] leading-[24px]'>Excelente</div>
                    </div>
                </div>
                <div className="mb:mt-[25px] md:mt-[40px]">
                <Box className="rounded-[66px]" sx={{ width: 132, height: 132 }}>
                    <Avatar
                        alt="Profile Picture"
                        src={IMAGE_BASE+`accounts/${researcherINFO._id?researcherINFO._id:researcherINFO.id}/`+researcherINFO.nm_researcheravatarfile}
                        sx={{ width: 132, height: 132, borderRadius: '50%' }}
                    />
                </Box>
                </div>
                <div className="font-[Poppins] font-normal text-[12px] text-[#464A53] leading-[24px] mt-[25px] mb:block md:hidden">{page} de 2</div>
            </div>
            <div className='mb:hidden md:block border-solid border-[0.5px] border-[#C6C6C6] w-full'></div>
            <div className='mt-[20px] ml-[40px]'>
                <div className="font-[Poppins] font-normal text-[12px] text-[#464A53] leading-[24px] mt-[25px] justify-between mb:flex md:hidden">
                    <div className="font-[Poppins] font-normal text-[12px] text-[#464A53] leading-[24px]">{page} de 2</div>
                </div>

                <div className='font-[Poppins] font-semibold text-[16px] text-[#313131] leading-[24px]'>
                Conte sobre o pesquisador para outras pessoas
                </div>
                <div className='mt-[14px] w-[440px]'>
                    <TextareaAutosize
                        id="standard-multiline-static"
                        label="Multiline"
                        placeholder="Mensagem"
                        onChange={commentChange}
                        InputProps={{ disableUnderline: true }}
                        defaultValue={comment}
                        InputLabelProps={{ shrink: true }}
                        value={comment}
                        className="pt-[9px] pl-[13px] !min-h-[104px] resize-y font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                    />
                    <div className='flex justify-between'>
                        <div className='font-[Poppins] font-normal text-[13px] text-[#464A53] leading-[24px]'>Digite ao menos {minlimit} caracteres</div>
                        <div className='font-[Poppins] font-normal text-[13px] text-[#464A53] leading-[24px]'>{count}</div>
                    </div>                    
                </div>           
            </div>
            <div className="mt-[20px] ml-[40px] pb-[20px]">
                <HeaderBTNStyle onClick={submitRequest} disabled = {(comment.length < 30 || rating === 0) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar</HeaderBTNStyle>
                <HeaderBTNStyle1 onClick={ratingLater} >Avaliar mais tarde</HeaderBTNStyle1>                
            </div>

        </div>
    )
}