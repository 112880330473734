/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useLayoutEffect, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/styles";
import { setMySpeciality } from '../../redux/actions/profile.action';
import {
    Divider,
    TextareaAutosize,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import AddIcon from '../../assets/icons/addicon.svg'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AddLanguage from '../modal/addLanguage'
import AddSpeciality from '../modal/addSpeciality'
import { useSelector, useDispatch } from 'react-redux';
import API from '../../redux/API';
import { useNavigate } from 'react-router-dom';
import { setUser, deleteMyLanguage, deleteMySpeciality } from '../../redux/actions/auth.action';
import { toast } from 'react-toast';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));


const scrolLayout = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        height: '2px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '20px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '20px',
      },
    },
    root1: {
      "&::-webkit-scrollbar": {
        width: '2px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '5px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '5px',
      },
    },
  }));

export default () => {
    const navigate = useNavigate()
    const classes = scrolLayout();

    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb:mt-[15px] md:mt-[10px] mb-[10px] w-[272px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131]"
    }
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = React.useState(false);
    const [openModal1, setOpenModal1] = React.useState(false);
    const [option, setOption] = useState('-- Escolha um motivo --');
    const [comment, setComment] = useState('');
    const selectOption = (event) => {
        setOption(event.target.value);
    }
    const commentChange = (event) => {
        setComment(event.target.value);
    }
    var  languages  = useSelector(state => state.auth.currentUser?.about_me?.languages)
    if(languages === undefined) languages = [];
    var  specialities  = useSelector(state => state.auth.currentUser?.about_me?.specialities)
    const [showSpecialities, setshowSpecialities] = useState([])
    if(specialities === undefined) specialities = [];
    const [languageList, setLanguageList] = useState([])
    const [levellist, setLevellist] = useState([])
    const [specialitylist, setSpecialitylist] = useState([])
    const [curLang, setCurLang] = useState('');
    const [curLevel, setCurLevel] = useState('');
    const {currentUser} = useSelector(state => state.auth);
    const [userBeforeChanges, setuserBeforeChanges] = useState({});


    const [changed, setChanged] = useState(false);
    const handleDelete = (value) => {  
        dispatch(deleteMyLanguage(value))
    }
    const handleDelete1 = (value) => {
        // alert(value)
        const speciality = showSpecialities.map(single=>{return single._id})
        dispatch(setUser({...currentUser, about_me:{...currentUser.about_me, specialities:speciality}}))
        dispatch(setMySpeciality(speciality));

        dispatch(deleteMySpeciality(value))
    }   
    const getLanguage = (id) => {
        let temp;
        languageList.map((item) => {
            if(item._id == id) temp = item.nm_language;
        })
        return temp;
    }
    const getProficiency = (id) => {
        let temp;
        levellist.map((item) => {
            if(item._id == id) temp = item.nm_proficiency;
        })
        return temp;
    }
    const getSpeciality = (id) => {
        let temp;
        specialitylist.map((item) => {
            if(item._id == id) temp = item.nm_country_pt;
        })
        return temp;
    }

    useEffect(()=>{
        var auxtoorder = []
        specialities.map((single)=>{
            // let auxhere = getSpeciality(single)
            let auxhere = specialitylist.find(singleSpeciality=>singleSpeciality._id===single)
            auxtoorder.push(auxhere)
            // alert(typeof single)
        })

        auxtoorder.sort(function(a, b) {
            var nameA = a.nm_country_pt.toUpperCase(); // convert to uppercase to ensure proper alphabetical sorting
            var nameB = b.nm_country_pt.toUpperCase();
            if (nameA < nameB) {
              return -1; // nameA should come before nameB in the sorted array
            }
            if (nameA > nameB) {
              return 1; // nameA should come after nameB in the sorted array
            }
            return 0; // names are equal, no sorting needed
          });
        
          setshowSpecialities(auxtoorder)

        // alert(auxtoorder)
    },[currentUser,specialitylist,specialities])


    useLayoutEffect(() => {
        if(currentUser.about_me.ds_aboutme){
            setComment(currentUser?.about_me?.ds_aboutme);
        }
        // else setComment('');
    }, [currentUser])
    useLayoutEffect(() => {
        if(languageList.length === 0)
        {
            API.get(`user/getlanguagelist?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.languagelist;
                temp.sort((a, b) => a.nm_language.localeCompare(b.nm_language))
                setLanguageList(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
        if(levellist.length === 0)
        {
            API.get(`user/getlanguageproficiency?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.proficiencylist;
                setLevellist(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
        if(specialitylist.length === 0)
        {
            API.get(`user/getspecialitylist`)
            .then((result) => {
                let temp = [...result.data.allspeciality]
                temp.sort((a, b) => a.nm_country_pt.localeCompare(b.nm_country_pt))
                setSpecialitylist(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    },[])

    const setAboutmeData = () => {
        const data = {
            ds_email: currentUser.ds_email,
            ds_aboutme: comment,
            languages: currentUser.about_me?.languages === undefined ? [] : currentUser.about_me.languages,
            specialities: currentUser.about_me?.specialities === undefined ? [] : currentUser.about_me.specialities
            
          }
          API.post('/user/setaboutmedata', data)
          .then((result) => {
            if(result.data.status === 'success'){
               toast.success('Sucesso.');
               dispatch(setUser(result.data.user))
               setChanged(false)
            }
          })
          .catch((err) => {
            toast.error(JSON.parse(err.request.response).message)
            console.log(err);
            setChanged(false)

        });
    }




    useEffect(()=>{
        console.log(languages);
        console.log(specialities);
        

    },[specialities,languages])

    const [userplan,setuserplan]=useState({})
    useEffect(()=>{
        API.post('user/gatplaninfo',{userID:currentUser._id})
        .then((res)=>{
            setuserplan(res.data.userplan)
            if (res.data.message) {
                toast.success(res.data.message)
            }
        })
        .catch((error)=>{
            toast.error(error.message?error.message:'Erro ao atualizar dados do plano')
            console.log(error);
            navigate('/account')
        })
    },[])
    useEffect(()=>{
        API.post('user/getsingleresearcherinfo',{userID:currentUser._id})
        .then((res)=>{
            setuserBeforeChanges(res.data.researcher)
            if (res.data.message) {
                toast.success(res.data.message)
            }
        })
        .catch((error)=>{
            toast.error(error.message?error.message:'Erro ao atualizar dados do plano')
            console.log(error);
        })
    },[])

    // useEffect(()=>{
    //     if(currentUser && userBeforeChanges){
    //         if ((currentUser.about_me.languages === userBeforeChanges.about_me.languages) && (currentUser.about_me.specialities === userBeforeChanges.about_me.specialities)) {
    //             return setChanged(false)
    //         }
    //             return setChanged(true)
    //     }
    // },[currentUser,userBeforeChanges])














    return (
        <div className='mb:w-full md:w-[95%] float-right'>
            <AddLanguage openModal={openModal} setOpenModal={setOpenModal} curLang={curLang} curLevel={curLevel} languageList={languageList} setLanguageList={setLanguageList} levellist={levellist} setLevellist={setLevellist} changed={changed} setChanged={setChanged}/> 
            <AddSpeciality userplan={userplan} openModal={openModal1} setOpenModal={setOpenModal1} specialitylist={specialitylist} setSpecialitylist={setSpecialitylist} changed={changed} setChanged={setChanged}/>
            <div className='w-[100%] flex mb:items-center md:items-start flex-col'>
                <div className='mb:w-[90%] md:w-full '>
                    <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[20px] mb-[3px]">Sobre mim</div>
                    <div>
                        <TextareaAutosize
                            id="standard-multiline-static"
                            label="Multiline"
                            onChange={commentChange}
                            // InputProps={{ disableUnderline: true }}
                            placeholder="Escreva sobre você"
                            // defaultValue={comment}
                            // InputLabelProps={{ shrink: true }}
                            value={comment}
                            className="pt-[9px] pl-[13px] resize-y !min-h-[126px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                        />
                    </div>
                </div>
               
                <div className=' mb:w-[90%] md:w-full mb:block md:flex justify-between mt-[25px] mb-[25px]'>
                    <div className='mb:w-full md:w-[48%]'>
                    <div className="flex justify-between items-center mb-[5px]">
                            <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mb-[3px]">Idiomas</div>
                            <Box onClick={() => {setCurLang(""); setCurLevel(""); setOpenModal(true)}} component={'img'} src={AddIcon} className="mb:mr-[0px] md:mr-[20px] cursor-pointer"/>
                        </div>
                        <div>


                        <List dense className={"rounded-[7px] border-solid border-[#E2E4E5] bg-[#FFFFFF] border-[1px] h-[232px] "+ classes.root} style={{overflow:'auto'}}>
                            {languages.map((value,index) => {
                                return(
                                    <>
                                    {value&&
                                    <>
                                    <ListItem key={index} className='h-[38px]'>
                                        {value.id_language&&
                                        <ListItemText
                                        className="w-[180px]"
                                            primary={<div className="w-[180px] font-normal text-[12px] text-[#313131] leading-[148%] font-['Poppins']">{getLanguage(value.id_language)}</div>}
                                        />
                                        }
                                        {value.id_languageproficiency&&
                                        <ListItemText
                                        className="w-[140px]"
                                            primary={<div className="w-[140px] font-[275] text-[12px] text-[#313131] leading-[148%] font-['Poppins']"> {getProficiency(value.id_languageproficiency)}</div>}
                                        />
                                        }
                                        {value.id_language&&value.id_languageproficiency&&
                                        <IconButton onClick={()=>{setCurLang(value.id_language); setCurLevel(value.id_languageproficiency); setOpenModal(true)}}>
                                            <EditOutlinedIcon/>
                                        </IconButton>
                                        }
                                        <IconButton onClick={()=>{setChanged(true); handleDelete(value)}} edge="end" aria-label="delete">
                                            <ClearOutlinedIcon/>
                                        </IconButton>
                                    </ListItem>
                                    <Divider className='!ml-[10px] !mr-[10px]'/>
                                    </>
                                    }
                                    </>
                                );
                            })}
                        </List>
                        </div>

                    </div>
                    <div  className='mb:w-full md:w-[48%] mb:mt-[25px] md:mt-[0px]'>
                        <div className="flex justify-between items-center mb-[5px]">
                            <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mb-[3px]">Especialidades</div>
                            <Box onClick={() => setOpenModal1(true)} component={'img'} src={AddIcon} className="mb:mr-[0px] md:mr-[20px] cursor-pointer"/>
                        </div>
                        <div>
                        <List dense className={"rounded-[7px] border-solid border-[#E2E4E5] bg-[#FFFFFF] border-[1px] h-[232px] "+ classes.root} style={{overflow:'auto'}}>
                            {showSpecialities && showSpecialities.map((value,index) => {
                                return(
                                    <>
                                    {value&& 
                                    <>
                                    <ListItem key={index} className='h-[38px]'>

                                        <ListItemText
                                        className="w-[180px]"
                                            primary={<div className="w-[180px] font-normal text-[12px] text-[#313131] leading-[148%] font-['Poppins']">{value.nm_country_pt?`${value.nm_country_pt}`:''}</div>}
                                        />{value._id&&
                                        <IconButton onClick={()=>{setChanged(true); handleDelete1(value._id)}} edge="end" aria-label="delete">
                                            <ClearOutlinedIcon/>
                                        </IconButton>
                                        }
                                    </ListItem>
                                    <Divider className='!ml-[10px] !mr-[10px]'/>
                                    </>
                                    }
                                </>
                                );
                            })}
                        </List>
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex mb:justify-center md:justify-end mb-[54px]">
                <HeaderBTNStyle 
                            onClick={setAboutmeData} 
                            disabled={comment == currentUser.about_me.ds_aboutme && !changed} 
                            sx={{
                                '&:disabled': 
                                {backgroundColor: '#C6C6C6',
                                cursor: 'not-allowed'}
                            }}>Salvar
                        </HeaderBTNStyle>
                </div>
        </div>
    )
}