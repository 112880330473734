import React from 'react';
import { styled } from '@mui/material/styles';

const FAQContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  paddingTop: '50px',
});

const FAQTitle = styled('h1')({
  fontSize: '50px',
  fontFamily: 'Poppins',
  color: '#17686E',
});

const FAQSubtitle = styled('h2')({
  fontSize: '16px',
  fontFamily: 'Poppins',
  color: '#313131',
});

const FAQFiltersContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '0.4rem',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  paddingBottom: '2.4rem',
  paddingTop: '2.4rem',
});

const FAQFilterButton = styled('button')(({ active }) => ({
  margin: 0,
  font: 'inherit',
  touchAction: 'manipulation',
  userSelect: 'none',
  WebkitAppearance: 'button',
  backgroundImage: 'none',
  display: 'inline-block',
  cursor: 'pointer',
  borderRadius: '3.6rem',
  backgroundColor: active ? '#17686E' : 'transparent',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  paddingBottom: '17px',
  paddingTop: '17px',
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '20px',
  fontWeight: 800,
  textTransform: 'uppercase',
  lineHeight: 1,
  letterSpacing: '0.025em',
  color: active ? '#fff' : '#17686E',
  transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
  transitionDuration: '0.3s',
  '&:hover': {
    backgroundColor: active ? '#17686E' : 'rgba(23, 104, 110, 0.1)',
  },
}));

const FAQFilters = ({ activeFilter, handleFilterClick, filters }) => {
  const filterNames = Object.keys(filters).map((filter) => ({
    name: filter,
    testFilter: filter,
  }));

  return (
    <FAQContainer>
      <FAQTitle>FAQs</FAQTitle>
      <FAQSubtitle>Veja as perguntas frequentes abaixo</FAQSubtitle>
      <FAQFiltersContainer>
        {filterNames.map((filter, index) => (
          <FAQFilterButton
            key={index}
            type="button"
            active={activeFilter === filter.name}
            onClick={() => handleFilterClick(filter.name)}
            data-test-filter={filter.testFilter}
          >
            {filter.name}
          </FAQFilterButton>
        ))}
      </FAQFiltersContainer>
    </FAQContainer>
  );
};

export default FAQFilters;