/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box
} from '@mui/material';
import StatusBar from './orderstatus'
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import Custom_Accordion from './moredetail';
import AttachmentIcon from '../../assets/icons/attachment_icon.svg'
import InviteResearcher from './InviteResearcher';
import FilePreview from "./MyFilePreview";
import { makeStyles } from "@material-ui/styles";
import ReportIcon from '../../assets/icons/report_icon.svg';
import DownloadIcon from '../../assets/icons/download_icon.svg'
import NotificationIcon from '../../assets/icons/notification_icon.svg'
import ConfirmAnalyze from '../../components/modal/ConfirmAnalyse'
import RequestIcon1 from '../../assets/icons/request_active.svg';
import ReceiveIcon1 from '../../assets/icons/receive_active.svg';
import ReceiveIcon2 from '../../assets/icons/receive_passive.svg';
import ReceiveIcon3 from '../../assets/icons/receive_inactive.svg';
import ResearchIcon1 from '../../assets/icons/research_active.svg';
import ResearchIcon2 from '../../assets/icons/research_passive.svg';
import ValidateIcon1 from '../../assets/icons/validate_active.svg';
import ValidateIcon2 from '../../assets/icons/validate_passive.svg';
import CompleteIcon1 from '../../assets/icons/complete_active.svg';
import CompleteIcon2 from '../../assets/icons/complete_passive.svg';
import DeliverIcon1 from '../../assets/icons/deliver_active.svg';
import DeliverIcon2 from '../../assets/icons/deliver_passive.svg';
import ConfirmIcon1 from '../../assets/icons/confirm_active.svg';
import ConfirmIcon2 from '../../assets/icons/confirm_passive.svg';
import ApostilleIcon1 from '../../assets/icons/apostille_active.svg';
import ApostilleIcon2 from '../../assets/icons/apostille_passive.svg';
import PaymentIcon1 from '../../assets/icons/payment_active.svg';
import PaymentIcon2 from '../../assets/icons/payment_passive.svg';
import PlusIcon from '../../assets/icons/plusicon.svg'
import DeleteIcon from '../../assets/icons/delete_icon.svg'
import AskValidation from '../../components/modal/AskValidation'
import { TextField,InputAdornment, TextareaAutosize} from '@mui/material';
import DeclineNegotiation from '../../components/modal/DeclineNegotiation';
import GiveUpResearch from '../../components/modal/GiveUpResearch'
import SendDocument from '../../components/modal/SendDocument'
import { useLocation } from 'react-router-dom';
import API from '../../redux/API';
import { toast } from 'react-toast';
import { API_BASE, IMAGE_BASE } from '../../config/constants';

import Checkout from '../../components/Checkout';
import { current } from '@reduxjs/toolkit';

import ShareLink from '../../components/ShareLink';
import Loading from '../../components/loading/Loading';


const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      height: '4px',
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: '20px',
        backgroundColor: 'rgb(217,217,217,0.3)'

    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#7A7A7A",
      borderRadius: '20px',
    },
  },
  root1: {
    "&::-webkit-scrollbar": {
      width: '4px',
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: '5px',
        backgroundColor: 'rgb(217,217,217,0.3)'

    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#7A7A7A",
      borderRadius: '5px',
    },
  },
}));

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 600,
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    border: '1px solid #17686E',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginBottom: '5px',

}));

export default (props) => {

    const location = useLocation();


    const {currentUser} = useSelector(state => state.auth);

    const { width } = useWindowSize()
    const classes = useStyles();
    const navigate = useNavigate();
    const [orderstatusList, setOrderStatusList] = useState([
        {
            icon1: RequestIcon1,
            icon2: null,
            icon3: null,
            text: 'Proposta enviada',
            time: '',
            status: 1,
        },
        {
            icon1: ReceiveIcon1,
            icon2: ReceiveIcon2,
            icon3: ReceiveIcon3,
            text: 'Proposta aceita',
            time: '',
            status: 1,
        },
        {
            icon1: ResearchIcon1,
            icon2: ResearchIcon2,
            icon3: null,
            text: 'Pesquisando',
            time: '',
            status: 1,
        },
        {
            icon1: ValidateIcon1,
            icon2: ValidateIcon2,
            icon3: null,
            text: 'Aguardando validação',
            time: '',
            status: 1,
        },        
        {
            icon1: PaymentIcon1,
            icon2: PaymentIcon2,
            icon3: null,
            text: 'Pagamento em andamento',
            time: '',
            status: 1,
        },
        {
            icon1: CompleteIcon1,
            icon2: CompleteIcon2, 
            icon3: null,
            text: 'Pedido concluído',
            time: '',
            status: 1,
        },
    ]);
    const [orderstatusList1, setOrderStatusList1] = useState([
        {
            icon1: RequestIcon1,
            icon2: null,
            icon3: null,
            text: 'Pedido publicado',
            time: '',
            status: 1,
        },
        {
            icon1: ReceiveIcon1,
            icon2: ReceiveIcon2,
            icon3: ReceiveIcon3,
            text: 'Proposta aceita',
            time: '',
            status: 1,
        },
        {
            icon1: ResearchIcon1,
            icon2: ResearchIcon2,
            icon3: null,
            text: 'Pesquisa realizada',
            time: '',
            status: 1,
        },
        {
            icon1: ValidateIcon1,
            icon2: ValidateIcon2,
            icon3: null,
            text: 'Documentos validados',
            time: '',
            status: 1,
        },
        {
            icon1: DeliverIcon1,
            icon2: DeliverIcon2,
            icon3: null,
            text: 'Documento enviado ',
            time: '',
            status: 1,
        },
        {
            icon1: ConfirmIcon1,
            icon2: ConfirmIcon2,
            icon3: null,
            text: 'Confirmar recebimento ',
            time: '',
            status: 1,
        },
        {
            icon1: PaymentIcon1,
            icon2: PaymentIcon2,
            icon3: null,
            text: 'Pagamento em andamento',
            time: '',
            status: 1,
        },
        {
            icon1: CompleteIcon1,
            icon2: CompleteIcon2,
            icon3: null,
            text: 'Pedido concluído',
            time: '',
            status: 1,
        },
    ]);

    const [orderstatusList2, setOrderStatusList2] = useState([
        {
            icon1: RequestIcon1,
            icon2: null,
            icon3: null,
            text: 'Pedido publicado',
            time: '',
            status: 1,
        },
        {
            icon1: ReceiveIcon1,
            icon2: ReceiveIcon2,
            icon3: ReceiveIcon3,
            text: 'Proposta aceita',
            time: '',
            status: 1,
        },
        {
            icon1: ResearchIcon1,
            icon2: ResearchIcon2,
            icon3: null,
            text: 'Pesquisa realizada',
            time: '',
            status: 1,
        },
        {
            icon1: ValidateIcon1,
            icon2: ValidateIcon2,
            icon3: null,
            text: 'Validar trabalho',
            time: '',
            status: 1,
        },
        {
            icon1: ApostilleIcon1,
            icon2: ApostilleIcon2,
            icon3: null,
            text: 'Validar trabalho',
            time: '',
            status: 1,
        },
        {
            icon1: DeliverIcon1,
            icon2: DeliverIcon2,
            icon3: null,
            text: 'Documento enviado ',
            time: '',
            status: 1,
        },
        {
            icon1: ConfirmIcon1,
            icon2: ConfirmIcon2,
            icon3: null,
            text: 'Confirmar recebimento ',
            time: '',
            status: 1,
        },
        {
            icon1: PaymentIcon1,
            icon2: PaymentIcon2,
            icon3: null,
            text: 'Pagamento em andamento',
            time: '',
            status: 1,
        },
        {
            icon1: CompleteIcon1,
            icon2: CompleteIcon2,
            icon3: null,
            text: 'Pedido concluído',
            time: '',
            status: 1,
        },
    ]);

    const [attachments, setAttachments] = useState([])
    const [needReSend, setneedReSend] = useState(false)
    const [needReSendDate, setneedReSendDate] = useState(null)

    const [statusList, setStatusList] = useState([
        'Proposta enviada',
        'Pesquisando',
        'Concluído',
        'Cancelado',
        'Aguardando pesquisador',
        
    ])
    const [statusNum, setStatusNum] = useState(0);
    const [comment, setComment] = useState('');
    const [showDocCtrl, setShowDocCtrl] = useState(0);
    const preview = useRef([]);
    const [openConfirmAnalyze, setOpenConfirmAnalyze] = React.useState(false);
    const [showConfirmBtn, setShowConfirmBtn] = useState(false);
    const [openReportProblem, setOpenReportProblem] = useState(false); 
    const limit = 10;
    const [displayAmount, setDisplayAmount] = useState('0')
    const [openDeclineNegotiation, setOpenDeclineNegotiation] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [flag, setFlag] = React.useState(false)
    const [shippingway, setShippingway] = useState(null);
    const [trackingcode, setTrackingcode] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [deliveryDateToShow, setdeliveryDateToShow] = useState(null);
    const [sentDocument, setSentDocument] = useState(false);
    const [acceptNegotiation, setAcceptNegotiation] = useState(false);
    const [ic_canstartwork, setic_canstartwork] = useState(false);
    const [openAskValidation, setOpenAskValidation] = useState(false);
    const [openGiveUpResearch, setOpenGiveUpResearch] = useState(false);
    const [openSendDocument, setOpenSendDocument] = useState(false);
    const [loading, setLoading] = useState(true)
    const [orderID, setOrderID] = useState(null)
    const [orderINFO, setOrderINFO] = useState({})
    const [customer, setCustomer] = useState({})
    const [document, setDocument] = useState({})
    const [datePublication, setDatePublication] = useState(null)
    const [dateFirstBid, setDateFirstBid] = useState(null)
    const [dateStartWork, setDateStartWork] = useState(null)
    const [dateSearch, setDateSearch] = useState(null)
    const [dateCancel, setDateCancel] = useState(null)
    const [paymentMethod,setPaymentMethod] = useState(null)
    const [bids, setBids] = useState([])
    const [negociationHistory, setnegociationHistory] = useState([])
    const [existNegociation, setexistNegociation] = useState(false)
    const [dt_startnegotiation, setdt_startnegotiation] = useState(null)
    const [valueNegotiation, setvalueNegotiation] = useState(null)
    const [commentNegotiation, setcommentNegotiation] = useState('')
    const [dt_bidcanceled, setdt_bidcanceled] = useState(null)
    const [ic_bidcanceled, setic_bidcanceled] = useState(false)
    const [ic_bidrejected, setic_bidrejected] = useState(false)
    const [dt_bidrejected, setdt_bidrejected] = useState(null)
    const [ic_ignorenegotiation, setic_ignorenegotiation] = useState(false)
    const [anyAcceptedBID, setAnyAcceptedBID] = useState(false)
    const [acceptedBID, setAcceptedBID] = useState({})
    const [country, setCountry] = useState('');
    const [panelName, setPanelName] = useState("Mais detalhes")
    const documentTypeList = ['Nascimento/Batismo', 'Casamento', 'Óbito', 'Imigração', 'Outros'];
    const [documentType, setDocumentType] = useState('');
    const [nm_documentlocation, setNm_documentlocation] = useState('')
    const [ds_familysearchprofile, setDs_familysearchprofile] = useState('')
    const [researcherName, setresearcherName] = useState('')
    const [fullName, setFullName] = useState('');
    const [alterName, setAlterName] = useState([{}]);
    const [alterTName, setAlterTName] = useState("");
    const [isPublic, setPublic] = useState(true);
    const [life_event_dates, setlife_event_dates] = useState({
        baptism: {
            dt_baptism: null,
            dt_baptismalternativefrom: null,
            dt_baptismalternativeto: null,
            ds_baptismlocation: null,
        },
        birth: {
            dt_birth: null,
            dt_birthalternativefrom: null,
            dt_birthalternativeto: null,
            ds_birthlocation: null,
        },
        marriage: {
            dt_marriage: null,
            dt_marriagealternativefrom: null,
            dt_marriagealternativeto: null,
            ds_marriagelocation: null,
        },
        immigration: {
            dt_immigration: null,
            dt_immigrationalternativefrom: null,
            dt_immigrationalternativeto: null,
            ds_immigrationlocation: null,
        },
        death: {
            dt_death: null,
            dt_deathalternativefrom: null,
            dt_deathalternativeto: null,
            ds_deathlocation: null,
        },
    });
    const [isAccepted, setisAccepted] = useState(false)
    const [acceptanceDate, setacceptanceDate] = useState(null)
    const [ic_canstartworkDate, setic_canstartworkDate] = useState(null)
    const [proposalDate, setproposalDate] = useState(null)
    const [startwork, setStartWork] = useState(false)
    const [startworkDate, setstartworkDate] = useState(null)    
    const [needValidation, setneedValidation] = useState(false)
    const [needValidationDate, setneedValidationDate] = useState(null)
    const [needValidationStamped, setneedValidationStamped] = useState(false)
    const [needValidationStampedDATE, setneedValidationStampedDATE] = useState(null)
    const [isValidatedSTAMPED, setisValidatedSTAMPED] = useState(false)
    const [isValidatedSTAMPED_DATE, setisValidatedSTAMPED_DATE] = useState(null)
    const [family_members, setfamily_members] = useState({
        nm_spouse: '',
        nm_father: '',
        nm_mother: '',
        nm_otherperson: '',
        ds_otherpersonfamiliarity: '',
    });
    const [currentValueNegociation, setcurrentValueNegociation] = useState(null)
    const [atack, setatack] = useState(false)
    const [isValidatedDIGITAL, setIsValidatedDIGITAL] =useState(false)
    const [isValidatedDateDIGITAL, setIsValidatedDateDIGITAL] =useState(null)
    const [isValidatedNOTDIGITAL, setIsValidatedNOTDIGITAL] =useState(false)
    const [isValidatedDateNOTDIGITAL, setIsValidatedDateNOTDIGITAL] =useState(null)
    const [qt_attachments_beforevalidation, setqt_attachments_beforevalidation] =useState(0)
    const [isDigital, setisDigital] = useState(false)
    const [isPhysical, setisPhysical] = useState(false)
    const [isStamped, setisStamped ] = useState(false)
    const [isSent, setIsSent ] = useState(false)
    const [isSentDate, setIsSentDate ] = useState(null)
    const [isSentInfo, setIsSentInfo ] = useState({})
    const [receiptDateForecast, setreceiptDateForecast ] = useState(null)
    const [isArrived, setisArrived ] = useState(false)
    const [isArrivedDate, setisArrivedDate ] = useState(null)
    const [isReceived, setisReceived ] = useState('Á caminho')
    const [isSelectedResearcher, setisSelectedResearcher ] = useState(false)
    const [isImported, setisImported ] = useState(false)
    const [researcherList, setResearchList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [validateAlertMsg, setValidateAlertMsg] = useState(false)
    const [userFees,setuserFees]=useState(0)
    const active = "!text-[#17686E] !font-semibold border-b-[4px] border-solid border-[#17686E]";
    const hover = "hover:text-[#17686e] ";
    const [canwithdraw, setcanwithdraw] = useState(false);
    const [awardOption, setAwardOption] = useState(true);
    const [daysToReleaseMoney, setdaysToReleaseMoney] = useState(10);
    const [address_to_delivery, setaddress_to_delivery] = useState({})


    useEffect(()=>{
        if(currentUser){
            API.post('user/gatplaninfo',{userID:currentUser._id})
            .then((res)=>{
                setuserFees(res.data.userFees)
                if (res.data.message) {
                    toast.success(res.data.message)
                }
            })
            .catch((error)=>{
                toast.error(error.message?error.message:'Erro ao atualizar dados do plano')
                console.log(error);
                navigate('/account')
            })
        }
    },[currentUser])


    const padTo2Digits = (n) => {
        return n.toString().padStart(2, "0");
      }
      
    const formatDate = (date) => {
        if (!date) {
          return "";
        }
        const formattedDate = new Date(date);
        if (isNaN(formattedDate.getTime())) {
          throw new Error("Invalid date");
        }
      
        const day = padTo2Digits(formattedDate.getDate());
        const month = padTo2Digits(formattedDate.getMonth() + 1);
        const year = formattedDate.getFullYear();
        const hours = padTo2Digits(formattedDate.getHours());
        const minutes = padTo2Digits(formattedDate.getMinutes());
      

        // const text = `${formattedDate.toLocaleString('pt-BR', {
        //     day: '2-digit',
        //     month: '2-digit',
        //     year: 'numeric',
        //     hour: '2-digit',
        //     minute: '2-digit'
        //   })} | GMT-3`
        const text = `${day}/${month}/${year}, ${hours}:${minutes}`

        return text
      }
          
    const formatDateWithoutHours =(date) =>{

        if (!date) {
            return "";
          }
          const formattedDate = new Date(date);
          if (isNaN(formattedDate.getTime())) {
            throw new Error("Invalid date");
          }
        
          const day = padTo2Digits(formattedDate.getDate());
          const month = padTo2Digits(formattedDate.getMonth() + 1);
          const year = formattedDate.getFullYear();
          const hours = padTo2Digits(formattedDate.getHours());
          const minutes = padTo2Digits(formattedDate.getMinutes());
        
          const text = `${day}/${month}/${year}`

        //   return formattedDate.toLocaleString('pt-BR', {
        //     day: '2-digit',
        //     month: '2-digit',
        //     year: 'numeric',
        //   });
          return text
      }


    const selectAwardOption = (value) => {
        setAwardOption(value);
    }

    const commentChange = (e) => {
        setComment(e.target.value);
    }

    const confirmDeclineNegotiation = async() => {
        setLoading(true)
        const data = {
            researcherID: currentUser.id? currentUser.id : currentUser._id ,
            orderID:orderID
        }
        
        API.post(`order/declinenegociation_researcher`, data)

        .then(async(result)=>{
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:'Sucesso')
                window.location.reload()
            }
            else{
                toast.error(result.data.message?result.data.message:'Erro')
                window.location.reload()
            }
            setLoading(false)
        })
        .catch(async(err)=>{
            toast.error(err)
            setLoading(false)
            window.location.reload()
        })


    }
    
    const confirmAskValidation = async() =>{
        setLoading(true)

        const data = {
            researcherID: currentUser.id? currentUser.id : currentUser._id ,
            orderID:orderID
        }

        if(needReSend){
            API.post(`order/revalidatedoc`, data)
            .then(async(result)=>{
                setLoading(false)

                if(result.data.status === 'success'){
                    toast.success(result.data.message?result.data.message:"Sucesso");
                    await orderdetail_researcher();
                }
            })
            .catch(async(err)=>{
                toast.error(err)
                setLoading(false)
                await orderdetail_researcher();

            })
        }

        if(!isValidatedDIGITAL){
            API.post(`order/setvalidate`, data)
            .then(async(result)=>{
                setLoading(false)

                if(result.data.status === 'success'){
                    toast.success(result.data.message?result.data.message:"Sucesso");
                    await orderdetail_researcher();
                }
            })
            .catch(async(err)=>{
                setLoading(false)
                toast.error(err)
                await orderdetail_researcher();

            })
        }
        if(isStamped && isValidatedDIGITAL){
            API.post(`order/setvalidatestamped`, data)
            .then(async(result)=>{
                setLoading(false)

                if(result.data.status === 'success'){
                    toast.success(result.data.message?result.data.message:"Sucesso");
                    await orderdetail_researcher();
                }
                await orderdetail_researcher();

            })
            .catch(async(err)=>{
                setLoading(false)
                await orderdetail_researcher();
                toast.error(err)
            })
        }


    }
    
    const handleUploadFiles = files => {
        const uploaded = [...attachments];
        files.some((file) => {
            if(uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push({url: URL.createObjectURL(file), name: file.name});
            } else {
                window.alert("Você já carregou este arquivo, favor carregar outro arquivo com outro nome.");
                return true; // stop iterating files
            }
        })
        setAttachments(uploaded);
    }
    
    // to send file to Backend
    const handleFileEvent = async(e) => {

        setLoading(true)

        const chosenFiles = Array.prototype.slice.call(e.target.files)
        
        const path = location.pathname;
        const id = path.split('/')[2];

        const formData = new FormData();
      
        for (let i = 0; i < chosenFiles.length; i++) {
            
            const singleFile = chosenFiles[i]
            // const nameLimited = singleFile.name.slice(0, 15)
            const utf8EncodedFilename = encodeURIComponent(singleFile.name); // Encode the filename in UTF-8
            const fileWithUtf8Filename = new File([singleFile], utf8EncodedFilename, { type: singleFile.type }); // Create a new File object with UTF-8 encoded filename
            formData.append("file", fileWithUtf8Filename);

        }
                      
        // Envie o FormData usando uma chamada de API
        API.post(`order/updatefiles_researcher/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async(result)=>{
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:'Sucesso')
                await orderdetail_researcher();
                window.location.reload()
                return setLoading(false)
            }
            else{
                toast.error(result.data.message?result.data.message:'Erro')
                await orderdetail_researcher();
                window.location.reload()
                return setLoading(false)
            }
        })
        .catch(async(err)=>{
            toast.error(err.message?err.message:'Erro ao atualizar arquivos')
            console.log(err);
            await orderdetail_researcher();
            window.location.reload()
            return setLoading(false)
        })
    }

    const handleDeleteAttach = (item) => {
        setLoading(true)

        API.post(`/order/deleteattachment/${orderID}/${item}`)
        .then(async(result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
            }
            await orderdetail_researcher();
        })
        .catch(async(err)=>{
            setLoading(false)
            await orderdetail_researcher();
            toast.error(err)
        })
    }


    const startWork = async() => {
        setLoading(true)

        
        const path = location.pathname;
        const id = path.split('/')[2];

        const data = {
            orderID:id,
            researcherID: currentUser.id?currentUser.id:currentUser._id,
        }

        API.post('order/startwork', data)
        .then(async(result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                setStatusNum(1);
                let temp = [...orderstatusList];
                temp[2].status = 0;
                temp[3].status = 1;
                temp[4].status = 1;
                setOrderStatusList(temp);
                await orderdetail_researcher();
            }
            else{
                toast.error(result.data.message)
                await orderdetail_researcher();
            }
        })
        .catch(async(err)=>{
            setLoading(false)

            toast.error(err)
            console.log(err)
            await orderdetail_researcher();
        })
    }

    const confirmCancelProposal =async () => {
        setLoading(true)

        const path = location.pathname;
        const id = path.split('/')[2];

        const thisdata = {
            orderID:id,
            researcherID: currentUser.id?currentUser.id:currentUser._id,
        }

        API.post('/order/cancelproposal_researcher', thisdata)
        .then(async(result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                // let temp = [...orderstatusList];
                // temp[1].status = 2;
                // temp[1].text = 'Proposta cancelada'
                // temp[2].status = 1;
                // temp[3].status = 1;
                // temp[4].status = 1;
                // setStatusNum(3);
                // setOrderStatusList(temp);        
                window.location.reload()
            }
            else{
                toast.error(result.data.message)
                window.location.reload()

            }
        })
        .catch(async(err)=>{
            setLoading(false)

            toast.error(err)
            window.location.reload()
        })
    }

    const handleAcceptNegotiation =async () => {
        setLoading(true)

        const path = location.pathname;
        const id = path.split('/')[2];

        const thisdata = {
            orderID:id,
            researcherID: currentUser.id?currentUser.id:currentUser._id,
        }

        API.post('/order/acceptnegociation_researcher', thisdata)
        .then(async(result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                let temp1 = [...statusList];
                temp1[1] = 'Em andamento';
                setStatusList(temp1);
                setStatusNum(1);
                setAcceptNegotiation(true);
                await orderdetail_researcher();
            }
            else{
                toast.error(result.data.message)
                await orderdetail_researcher();
            }
        })
        .catch(async(err)=>{
            setLoading(false)

            toast.error(err)
            await orderdetail_researcher();
        })
    }

    const confirmSendDocument = async() => {
        setLoading(true)

        const thisdata = {
            orderID:orderID,
            researcherID: currentUser.id?currentUser.id:currentUser._id,
        }
        API.post('/order/senddoc_researcher', thisdata)
        .then(async(result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                await orderdetail_researcher();
            }
            else{
                toast.error(result.data.message)
                await orderdetail_researcher();
            }
        })
        .catch(async(err)=>{
            setLoading(false)            
            toast.error(err)
            await orderdetail_researcher();
        })
    }




    const confirmCancelOrder = () => {
        let temp = [...orderstatusList];
        temp[1].status = 2;
        temp[1].text = 'Proposta cancelada'
        temp[1].time = dateCancel

        
        // temp[2].status = 1;
        // temp[3].status = 1;
        // temp[4].status = 1;
        setCancel(true)
        setOrderStatusList(temp);

        confirmCancelOrder1()
        confirmCancelOrder2()
    }
    const confirmCancelOrder1 = () => {
        let temp = [...orderstatusList1];
        temp[1].status = 2;
        temp[1].text = 'Proposta cancelada'
        temp[1].time = dateCancel

        
        // temp[2].status = 1;
        // temp[3].status = 1;
        // temp[4].status = 1;
        setCancel(true)
        setOrderStatusList1(temp);
    }
    const confirmCancelOrder2 = () => {
        let temp = [...orderstatusList2];
        temp[1].status = 2;
        temp[1].text = 'Proposta cancelada'
        temp[1].time = dateCancel

        
        // temp[2].status = 1;
        // temp[3].status = 1;
        // temp[4].status = 1;
        setCancel(true)
        setOrderStatusList2(temp);
    }


    const orderdetail_researcher = async ()=>{
        const path = location.pathname;
        const id = path.split('/')[2];
        const thisUserID = currentUser.id ? currentUser.id : currentUser._id;


        setOrderID(id)

        const data = {
            orderID:id,
            researcherID: thisUserID
        }
        
        API.post(`order/orderdetail_researcher`, data)
        .then((result) => {
            if(result.data.status === 'success'){
                //toast.success('sucesso')
                setOrderINFO(result.data.order)
                if(result.data.order.dt_releasemoney){
                    setdaysToReleaseMoney(Math.ceil(((new Date(result.data.order.dt_releasemoney)).getTime()-new Date().getTime())/(1000*60*60*24)))
                }
                if(result.data.bids){
                    setDateFirstBid(formatDate(result.data.dateFirstBid))
                    setBids(result.data.bids)
                }

                if(result.data.customer){
                    setCustomer(result.data.customer)
                }
                setDocument(result.data.document)
                setDatePublication(formatDate(result.data.datePublication))
                setproposalDate(formatDate(result.data.orderStatus.proposalDate))

                if(result.data.isStamped){
                    setisStamped(result.data.isStamped)
                }
                else if(result.data.isPhysical){
                    setisPhysical(result.data.isPhysical)
                }
                else if(result.data.isDigital){
                    setisDigital(result.data.isDigital)
                }
                setisImported(result.data.isImported)
                setPaymentMethod(result.data.paymentMethod)
                setCountry(result.data.order.nm_documentcountry)
                setNm_documentlocation(result.data.order.nm_documentlocation)

                        // show document type
                        if(result.data.order.id_documenttype === '639593ac18b3d1cf0751efff'){
                            setDocumentType('Nascimento/Batismo')
                        }
                        else if(result.data.order.id_documenttype === '6395944018b3d1cf0751f000'){
                            setDocumentType('Casamento')
                        }
                        else if(result.data.order.id_documenttype === '6395946118b3d1cf0751f002'){
                            setDocumentType('Óbito')
                        }
                        else if(result.data.order.id_documenttype === '6395947a18b3d1cf0751f003'){
                            setDocumentType('Imigração')
                        }
                        else if(result.data.order.id_documenttype === '6395949d18b3d1cf0751f005'){
                            setDocumentType('Outros')
                        }

                        setDs_familysearchprofile(result.data.order.ds_familysearchprofile)
                        setFullName(result.data.order.nm_ancestor)
                        setfamily_members(result.data.order.family_members)
                        setlife_event_dates(result.data.order.life_event_dates)
                        setAlterName(result.data.order.nm_ancestoralternative)
                        setComment(result.data.order.ds_comments)
                        setAlterTName(alterName.join("\n"))
                        setStatusNum(result.data.cd_orderstatus)

                        if(result.data.cd_orderstatus === 3){
                            confirmCancelOrder()
                        }

                        if(result.data.orderStatus.setAcceptNegotiation){
                            setAcceptNegotiation(result.data.orderStatus.setAcceptNegotiation)
                            setacceptanceDate(formatDate(result.data.orderStatus.acceptanceDate))
                            
                        }
                        if(result.data.orderStatus.ic_canstartwork){
                            setic_canstartwork(result.data.orderStatus.ic_canstartwork)
                            
                        }
                        if(result.data.existNegociation){
                            setexistNegociation(result.data.existNegociation)
                            // search all negociations, and match with this user
                            result.data.negociationHistory.map((singleBidNegociation)=>{
                                singleBidNegociation.map((singleComment)=>{
                                    if(singleComment.id_usercomment === currentUser.id?currentUser.id:currentUser._id){
                                        setnegociationHistory(singleBidNegociation)
                                    }
                                })
                                setdt_startnegotiation(formatDate(result.data.dt_startnegotiation))
                            })
                        }

            // if(result.data.order.id_researcher === thisUserID){

                if(result.data.orderStatus.ic_ignorenegotiation){
                    setic_ignorenegotiation(result.data.orderStatus.ic_ignorenegotiation)
                    setic_bidcanceled(false)
                }
                if(result.data.existsStart ){
                    setstartworkDate(formatDate(result.data.startworkDate))
                    setStartWork(result.data.existsStart)
                }
                if(result.data.existsValidate ){
                    setneedValidationDate(formatDate(result.data.validationDate))
                    setneedValidation(result.data.existsValidate)
                }
                if(result.data.needValidationStamped){
                    setneedValidationStamped(result.data.needValidationStamped)
                    setneedValidationStampedDATE(formatDate(result.data.needValidationStampedDATE))
                }
                if(result.data.isValidatedSTAMPED){
                    setisValidatedSTAMPED(result.data.isValidatedSTAMPED)
                    setisValidatedSTAMPED_DATE(formatDate(result.data.isValidatedSTAMPED_DATE))
                }

                            // exists accepted Bid
                        setAnyAcceptedBID(result.data.anyAcceptedBID)
                        if(result.data.anyAcceptedBID === true){
                            setAcceptedBID(result.data.acceptedBid)
                            setresearcherName(result.data.orderStatus.researcherName)
                        }

                        // any attackment?
                            if(result.data.orderStatus.attack){
                                setatack(true)
                                // let allAttacks = result.data.orderStatus.attack
                                // setAttachments((result.data.orderStatus.attack).map(item => IMAGE_BASE + `orders/orders_files/${orderID}/${item}`));
                                setAttachments(result.data.orderStatus.attack)
                            }
                            if(result.data.needReSend){
                                setneedReSend(result.data.needReSend)
                                setneedReSendDate(formatDate(result.data.needReSendDate))
                            }

                            
                            if(result.data.orderStatus.isvalidatedDIGITAL){
                                setIsValidatedDIGITAL(result.data.orderStatus.isvalidatedDIGITAL)
                                setIsValidatedDateDIGITAL(formatDate(result.data.orderStatus.isvalidatedDateDIGITAL))
                            }
                            
                            if(result.data.orderStatus.isvalidatedNOTDIGITAL){
                                setIsValidatedNOTDIGITAL(result.data.orderStatus.isvalidatedNOTDIGITAL)
                                setIsValidatedDateNOTDIGITAL(formatDate(result.data.orderStatus.isvalidatedDateNOTDIGITAL))
                            }


                            setqt_attachments_beforevalidation(result.data.order.qt_attachments_beforevalidation)

 
                            if(result.data.isSent){
                                setIsSent(result.data.isSent)
                                setIsSentDate(formatDate(result.data.isSentDate))
                                setIsSentInfo(result.data.sentInfo)
                                setreceiptDateForecast(formatDateWithoutHours(result.data.receiptDateForecast))
                            }

                            if(result.data.isArrived){
                                setisArrived(result.data.isArrived)
                                setisArrivedDate(formatDate(result.data.isArrivedDate))
                                setisReceived(`Entregue em ${formatDateWithoutHours(result.data.isArrivedDate)}`)
                            }
                            if(result.data.orderStatus.ic_bidcanceled ){
                               setic_bidcanceled(result.data.orderStatus.ic_bidcanceled)
                               setdt_bidcanceled(formatDate(result.data.orderStatus.dt_bidcanceled))
                           }
                            if(result.data.orderStatus.ic_bidrejected ){
                               setic_bidrejected(result.data.orderStatus.ic_bidrejected)
                               setdt_bidrejected(formatDate(result.data.orderStatus.dt_bidrejected))
                           }
                        // }
                        // else{

                            
                            
                        //     if(result.data.orderStatus.ic_bidcanceled ){
                        //         setic_bidcanceled(result.data.orderStatus.ic_bidcanceled)
                        //         setdt_bidcanceled(result.data.orderStatus.dt_bidcanceled)
                        //     }


                        //     // confirmCancelOrder()


                        // }
                            
                        //  if(result.data.orderStatus.ic_bidcanceled){
                        //     setic_bidcanceled(result.data.orderStatus.ic_bidcanceled)
                        //     setdt_bidcanceled(result.data.orderStatus.dt_bidcanceled)
                        // }
            

                setLoading(false)
            }else if(result.data.status === 'fail-auth'){
                toast.error(result.data.message)
                navigate(`/order/${id}`)
            }
            else{
                toast.error('Erro ao obter dados da ordem.')
            }
          })
          .catch((err) => {

            if(err.message === 'Request failed with status code 500'){
                toast.error('Você precisa estar logado corretamente para acessar')
            }else{
                toast.error(`Erro ao obter dados da ordem ||| ${err.message} `) 
            }

            navigate('/login')
            console.log(err);
        });

    }

    useEffect(()=>{
        orderdetail_researcher()
    }, [location])

    useEffect(()=>{
            // auth if is a valid researcher
            const thisUserID = currentUser.id ? currentUser.id : currentUser._id;
            let existResearcher = false;
            if (bids.length > 0) {
                for (let i = 0; i < bids.length; i++) {
                    if (bids[i].id_researcher === thisUserID) {
                        existResearcher = true;
                    break;
                }
            }
            if (!existResearcher) {
                navigate("/publications");
            }
        }
    },[bids])

    useEffect(()=>{
        const thisUserID = currentUser.id ? currentUser.id : currentUser._id;
        if(orderINFO.id_researcher){
            if(orderINFO.id_researcher === thisUserID){
                return setisSelectedResearcher(true)
            }
        }
        return setisSelectedResearcher(false)
    },[orderINFO])

    useEffect(()=>{

        // exists info - set pedido publicado
        if(orderINFO._id ){
            const thisUserID = currentUser.id?currentUser.id:currentUser._id

            let newOrderStatusList = [...orderstatusList]; // fazer uma cópia do array
            let newOrderStatusList1 = [...orderstatusList1]; // fazer uma cópia do array
            let newOrderStatusList2 = [...orderstatusList2]; // fazer uma cópia do array
            
            newOrderStatusList[0].time = proposalDate; // modificar o valor de "time" do primeiro objeto
            newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto

            newOrderStatusList1[0].time = proposalDate; // modificar o valor de "time" do primeiro objeto
            newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto

            newOrderStatusList2[0].time = proposalDate; // modificar o valor de "time" do primeiro objeto
            newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
            
            setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
            setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
            setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

            if(existNegociation ){

                newOrderStatusList[0].time = dt_startnegotiation; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[0].text = 'Em Negociação';

                newOrderStatusList1[0].time = dt_startnegotiation; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[0].text = 'Em Negociação';

                newOrderStatusList2[0].time = dt_startnegotiation; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[0].text = 'Em Negociação';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(0)
                negociationHistory.map((singlenegotiation, index, array)=>{
                    if(index === array.length - 1){
                        setComment(singlenegotiation.ds_comment)
                        setvalueNegotiation(singlenegotiation.vl_value)
                    }
                })
                
            }

            if(anyAcceptedBID && !isSelectedResearcher && orderINFO.cd_orderstatus){
                newOrderStatusList[0].time = proposalDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta não aceita'; // modificar o status do primeiro objeto
                
                newOrderStatusList1[0].time = proposalDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta não aceita'; // modificar o status do primeiro objeto

                newOrderStatusList2[0].time = proposalDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta não aceita'; // modificar o status do primeiro objeto

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(4)
            }

            // change state for accepted Bid
            if(anyAcceptedBID && isSelectedResearcher && orderINFO.cd_orderstatus){ 


                newOrderStatusList[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[1].time = acceptanceDate; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta aceita'; // modificar o status do primeiro objeto
                
                newOrderStatusList[2].text = 'Inicie sua pesquisa'; // modificar o status do primeiro objeto
                newOrderStatusList[2].status = 0

                newOrderStatusList1[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].time = acceptanceDate; // modificar o status do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta aceita'; // modificar o status do primeiro objeto

                newOrderStatusList1[2].text = 'Inicie sua pesquisa'; // modificar o status do primeiro objeto
                newOrderStatusList1[2].status = 0

                newOrderStatusList2[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[1].time = acceptanceDate; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta aceita'; // modificar o status do primeiro objeto

                newOrderStatusList2[2].text = 'Inicie sua pesquisa'; // modificar o status do primeiro objeto
                newOrderStatusList2[2].status = 0
                setStatusNum(1)
                
                if(!ic_canstartwork){
                    newOrderStatusList[2].text  = 'Aguardando Pagamento';
                    newOrderStatusList1[2].text = 'Aguardando Pagamento';
                    newOrderStatusList2[2].text = 'Aguardando Pagamento';
                    setStatusNum(0)
                }
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

            }

            if(startwork && isSelectedResearcher && orderINFO.cd_orderstatus){
                newOrderStatusList[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[2].time = startworkDate; // modificar o status do primeiro objeto
                newOrderStatusList[2].text = 'Pesquisando'; // modificar o status do primeiro objeto

                newOrderStatusList1[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[2].time = startworkDate; // modificar o status do primeiro objeto
                newOrderStatusList1[2].text = 'Pesquisando'; // modificar o status do primeiro objeto

                newOrderStatusList2[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[2].time = startworkDate; // modificar o status do primeiro objeto
                newOrderStatusList2[2].text = 'Pesquisando'; // modificar o status do primeiro objeto

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado


                setStatusNum(1)

            }


            if(needValidation && isSelectedResearcher && orderINFO.cd_orderstatus){

                newOrderStatusList[3].time = needValidationDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[3].text = 'Aguardando validação';

                newOrderStatusList1[3].time = needValidationDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[3].text = 'Aguardando validação';

                newOrderStatusList2[3].time = needValidationDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[3].text = 'Aguardando validação';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado


            }

            if(isValidatedDIGITAL && isSelectedResearcher && orderINFO.cd_orderstatus){

                newOrderStatusList[3].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[3].text = 'Pedido validado';
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Aguardando liberar o saque';

                newOrderStatusList1[3].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[3].text = 'Pedido validado';
                
                
                newOrderStatusList2[3].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[3].text = 'Pedido validado';
                // delete newOrderStatusList2[3]
                // newOrderStatusList2[4].time = isValidatedDate;

                if(isStamped){
                newOrderStatusList2[4].status = 0; 
                    newOrderStatusList2[4].text = 'Apostilando documento';
                }
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

            }

            if(needValidationStamped && isSelectedResearcher && orderINFO.cd_orderstatus){

                newOrderStatusList[4].time = needValidationStampedDATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Aguardando validação';

                newOrderStatusList1[4].time = needValidationStampedDATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].text = 'Aguardando validação';

                newOrderStatusList2[4].time = needValidationStampedDATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].text = 'Aguardando validação';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado


            }
            if(isValidatedSTAMPED && isSelectedResearcher && orderINFO.cd_orderstatus){

                newOrderStatusList[4].time = isValidatedSTAMPED_DATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Documento validado';

                newOrderStatusList1[4].time = isValidatedSTAMPED_DATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].text = 'Documento validado';

                newOrderStatusList2[4].time = isValidatedSTAMPED_DATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].text = 'Documento validado';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado


            }

            
            if(isSent && isSelectedResearcher && orderINFO.cd_orderstatus){

                newOrderStatusList1[4].time = isSentDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].text = 'Pedido enviado';
                
                
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].text = 'Documento apostilado';
                newOrderStatusList2[5].time = isSentDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[5].text = 'Pedido enviado';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

            }

            
            if(isArrived && isSelectedResearcher && orderINFO.cd_orderstatus){

                newOrderStatusList1[5].time = isArrivedDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[5].text = 'Pedido entregue';
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].text = 'Aguardando liberar o saque';
                
                
                // newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                // newOrderStatusList2[4].text = 'Documento apostilado';
                newOrderStatusList2[6].time = isArrivedDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[6].text = 'Pedido entregue';
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].text = 'Aguardando liberar o saque';


                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(1)
            }

            if(daysToReleaseMoney&&daysToReleaseMoney<=0){

                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Saque liberado';
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].text = 'Saque liberado';
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].text = 'Saque liberado';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(1)

            }



            if(orderINFO.ic_iswithdrawing){

                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Pagamento em andamento';
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].text = 'Pagamento em andamento';
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].text = 'Pagamento em andamento';
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(1)

            }
            if(orderINFO.ic_complete || orderINFO.ic_orderpaid || orderINFO.ic_ordercomplete){

                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Pagamento efetuado';
                newOrderStatusList[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[5].text = 'Pedido concluído';
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].text = 'Pagamento efetuado';
                newOrderStatusList1[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[7].text = 'Pedido concluído';
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].text = 'Pagamento efetuado';
                newOrderStatusList2[8].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[8].text = 'Pedido concluído';
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(2)

            }







            if(ic_bidcanceled ){

                newOrderStatusList[0].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[0].text = 'Proposta cancelada';
                newOrderStatusList[1].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta cancelada';
                newOrderStatusList[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[5].status = 1; // modificar o status do primeiro objeto

                newOrderStatusList1[0].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[0].text = 'Proposta cancelada';
                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta cancelada';
                newOrderStatusList1[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList1[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[5].status = 1; // modificar o status do primeiro objeto


                newOrderStatusList2[0].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[0].text = 'Proposta cancelada';
                newOrderStatusList2[1].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta cancelada';
                newOrderStatusList2[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[5].status = 1; // modificar o status do primeiro objeto


                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

                setStatusNum(3)

            }
            if(ic_bidrejected ){

                newOrderStatusList[0].time = dt_bidrejected; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[0].text = 'Proposta Recusada';
                newOrderStatusList[1].time = dt_bidrejected; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta Recusada';
                newOrderStatusList[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[5].status = 1; // modificar o status do primeiro objeto

                newOrderStatusList1[0].time = dt_bidrejected; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[0].text = 'Proposta Recusada';
                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].time = dt_bidrejected; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta Recusada';
                newOrderStatusList1[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList1[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[5].status = 1; // modificar o status do primeiro objeto


                newOrderStatusList2[0].time = dt_bidrejected; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[0].text = 'Proposta Recusada';
                newOrderStatusList2[1].time = dt_bidrejected; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta Recusada';
                newOrderStatusList2[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[5].status = 1; // modificar o status do primeiro objeto


                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

                setStatusNum(3)

            }

            if(orderINFO.id_researcher && orderINFO.id_researcher != thisUserID){
                newOrderStatusList[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta recusada';

                newOrderStatusList1[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta recusada';
                
                newOrderStatusList2[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta recusada';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(3)
            }
 

            if(isImported){
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[5].status = 0; // modificar o status do primeiro objeto

                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[7].status = 0; // modificar o status do primeiro objeto

                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[8].status = 0; // modificar o status do primeiro objeto
                
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(2)

            }

            
            if(statusNum === 3){
                confirmCancelOrder()
            }



        }

    },[orderINFO,isSelectedResearcher,isImported])      

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 700px)');
        setIsMobile(mediaQuery.matches);
      }, []);
    
    useEffect(() => {

        if (orderINFO.dt_releasemoney && new Date(orderINFO.dt_releasemoney).getTime() < new Date().getTime()) {
            setcanwithdraw(true);
        }
        if (orderINFO.address_to_delivery) {
            var aux = {...orderINFO.address_to_delivery}
            return setaddress_to_delivery(aux)
        }
    

      }, [orderINFO]);
    

    return (
       <RootStyle>

            {loading === true && 
            
            <>
                <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                }}
                >

                <Loading />
            </div>
            </>
            
            }

            <DeclineNegotiation openModal={openDeclineNegotiation} setOpenModal={setOpenDeclineNegotiation} confirmFunc={confirmDeclineNegotiation} pageNum={1}/>
            <AskValidation openModal={openAskValidation} setOpenModal={setOpenAskValidation} pageNum={1} confirmFunc={confirmAskValidation} orderID={orderID} researcherID={currentUser.id? currentUser.id : currentUser._id} isDigital={isDigital} isPhysical={isPhysical} isStamped={isStamped} isValidatedDIGITAL={isValidatedDIGITAL}/>
            <GiveUpResearch openModal={openGiveUpResearch} setOpenModal={setOpenGiveUpResearch} pageNum={1} confirmFunc={confirmCancelProposal}/>
            <SendDocument 
                openModal={openSendDocument} 
                setOpenModal={setOpenSendDocument} 
                pageNum={0} 
                orderINFO={orderINFO}
                confirmFunc={confirmSendDocument} 

                setTrackingcode={setTrackingcode} 
                trackingcode={trackingcode}

                setDeliveryDate={setDeliveryDate} 
                deliveryDate={deliveryDate}
                setdeliveryDateToShow={setdeliveryDateToShow}

                setShippingway={setShippingway} 
                shippingway={shippingway}

                orderID={orderID} 
                researcherID={currentUser.id? currentUser.id : currentUser._id}

                loading={loading}
                setLoading={setLoading}
            />
            <ContainerStyle className = '!mt-[40px]'>
                <div className='flex justify-between !mb-[20px]'>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">Cliente</div>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]" >Pesquisador</div>     
                </div>
                {!isImported &&
                    <div className='flex justify-between  !mb-[30px]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{customer.nm_user}</div>
                        {startwork &&
                            <div className="flex">
                                <Link to={`/update_report_researcher/${orderID}`} className="mr-[20px] "><Box component="img" src={NotificationIcon} alt="notificacao-icone" width='20px' height='20px' style={{animation: "stake 5s infinite"}}/></Link>    
                                <Link to={`/expertprofile/${currentUser.id?currentUser.id:currentUser._id}`} className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E] ">{ researcherName}</Link>    
                            </div>  
                        }
                    </div>
                }
                {isImported &&
                    <div className='flex justify-between  !mb-[30px]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{customer.nm_user}</div>
                        <div className="flex">
                        <Link to={`/expertprofile/${currentUser.id?currentUser.id:currentUser._id}`} className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E] ">{currentUser.nm_user}</Link>    
                        </div>  
                    </div>
                }
                <div className='border-solid border-[1px] bg-[#C6C6C6] w-[100%] opacity-91 h-[1px]'></div>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px]">Informações do documento</div>
                <div className="rounded-[7px] border-solid border-[1px] border-[#C6C6C6] mt-[20px]">
                    <div className='w-[100%] mb:block xl:flex mt-[18px] ml-[18px] mr-[20px] mb:mb-[30px] xl:mb-[10px] text-left'>
                        <div className='mb:w-full xl:w-[20%] mb:mt-[15px] xl:mt-[0px]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Nome completo:</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{orderINFO.nm_ancestor}</div>
                        </div>
                        <div className='mb:w-full xl:w-[12%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Pedido</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{orderINFO.cd_order}</div>
                        </div>
                        <div className='mb:w-full xl:w-[20%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Tipo de documento </div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{document.nm_documenttype}</div>
                        </div>
                        <div className='mb:w-full xl:w-[15%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Status</div>
                            <div className='flex items-center h-[42px]'>
                                <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>

                                    {/* <Box component={'img'} src={Ellipse} className='w-[6.65px] h-[6px] bg-[#4A4AFF] ml-[5.5px] mr-[5.5px] rounded-[50%]'/> */}
                                    {
                                loading === true &&

                                <>
                                    {
                                        statusNum === 0 &&
                                        <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                            <div className="w-[6px] h-[6px] rounded-[5px] bg-[#E2BD7B] ml-[5.5px] mr-[5.5px]"></div>
                                            <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#E2BD7B] w-[95px]">Carregando...</div>
                                        </div>
                                    }
                                </>
                                }
                                
                                {
                                loading === false &&

                                <>
                                    {
                                        statusNum === 0 &&
                                        <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                            <div className="w-[6px] h-[6px] rounded-[5px] bg-[#E2BD7B] ml-[5.5px] mr-[5.5px]"></div>
                                            <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#E2BD7B] w-[95px]">{statusList[statusNum]}</div>
                                        </div>
                                    }
                                    {
                                        statusNum === 4 &&
                                        <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                            <div className="w-[6px] h-[6px] rounded-[5px] bg-[#E2BD7B] ml-[5.5px] mr-[5.5px]"></div>
                                            <div className="font-[Poppins] font-medium text-[11px] leading-[15px] text-[#E2BD7B] w-[95px]">{statusList[statusNum]}</div>
                                        </div>
                                    }
                                    {
                                        statusNum === 1 &&
                                        <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                            <div className="w-[6px] h-[6px] rounded-[5px] bg-[#4A4AFF] ml-[5.5px] mr-[5.5px]"></div>
                                            <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#4A4AFF] w-[95px]">{statusList[statusNum]}</div>
                                        </div>
                                    }
                                    {
                                        statusNum === 2 &&
                                        <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                            <div className="w-[6px] h-[6px] rounded-[5px] bg-[#17686E] ml-[5.5px] mr-[5.5px]"></div>
                                            <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#17686E] w-[95px]">{statusList[statusNum]}</div>
                                        </div>
                                    }
                                    {
                                        statusNum === 3 &&
                                        <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                            <div className="w-[6px] h-[6px] rounded-[5px] bg-[#BA483A] ml-[5.5px] mr-[5.5px]"></div>
                                            <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#BA483A] w-[95px]">{statusList[statusNum]}</div>
                                        </div>
                                    }
                                </>
                                }                            
                                </div>
                            </div>
                        </div>
                        <div className='mb:w-full xl:w-[12%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Data</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{datePublication}</div>
                        </div>
                        <div className='mb:w-full xl:w-[10%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Pagamento</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>

                                {/* {paymentMethod}                                 */}
                                {
                                    anyAcceptedBID && isSelectedResearcher ? 
                                    `R$ ${Number.parseFloat(acceptedBID.vl_researcherbid ).toFixed(2).replace('.', ',')}`
                                    : 
                                    '-'
                                } 
                            </div>
                        </div>
                    </div>

                </div>
                <div className='w-[100%] flex justify-center'>
                    <div className='w-[96%]'>
                        <Custom_Accordion                        
                        data={orderINFO}                        
                        flag={flag}
                        country={country} 
                        panelName={panelName} 
                        documentTypeList={documentTypeList}
                        documentType={documentType}
                        nm_documentlocation ={nm_documentlocation}
                        ds_familysearchprofile={ds_familysearchprofile}
                        fullName={fullName}
                        alterName={alterName}
                        alterTName={alterTName}
                        isPublic={isPublic}
                        comment={comment}
                        life_event_dates={life_event_dates}                        
                        setPanelName={setPanelName}
                        setFlag={setFlag}
                        setFullName={setFullName}
                        setlife_event_dates={setlife_event_dates}
                        setCountry={setCountry}
                        commentChange={commentChange}
                        family_members={family_members}                        
                        loading={loading}
                        />
                    </div>

                </div>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px]">Status do Pedido</div>
                
                {isDigital &&
                    <>
                        <StatusBar orderstatusList={orderstatusList}/>
                    </>
                }
                {isPhysical &&
                    <>
                        <StatusBar orderstatusList={orderstatusList1}/>
                    </>
                }
                {isStamped &&
                    <>
                        <StatusBar orderstatusList={orderstatusList2}/>
                    </>
                }


                {
                acceptNegotiation && !ic_canstartwork &&
                    <>
                        <div
                            className='w-[100%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                            <div
                                className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[100%]">
                                <div
                                    className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                    Estamos apenas aguardando o cliente liberar o pagamento. Assim que finalizarmos, você poderá começar sua pesquisa.
                                </div>

                            </div>
                        </div>
                    </>       

                }




{
// isSelectedResearcher && 

<>
                {
                    !ic_bidcanceled && 
                <>

                <div className='w-full flex justify-end mt-[32px]'>
                    {  !cancel && !existNegociation &&  !acceptNegotiation && !anyAcceptedBID && !isImported && !ic_bidcanceled && !ic_bidrejected &&
                            <HeaderBTNStyle onClick={confirmCancelProposal}>Cancelar proposta</HeaderBTNStyle>
                    }
                </div>
                <div className='flex justify-end items-center'>
                { !isValidatedDIGITAL && attachments.length>0 && !needValidation &&
                    attachments.length !== 0 &&  statusNum === 1 && !validateAlertMsg && 
                      <HeaderBTNStyle1 onClick={()=> {setOpenAskValidation(true)}}>Pedir validação</HeaderBTNStyle1>
                }
                { isStamped && isValidatedDIGITAL && !needValidationStamped && !isValidatedSTAMPED && attachments.length>qt_attachments_beforevalidation &&
                    attachments.length !== 0 &&  statusNum === 1 && !validateAlertMsg && 
                      <HeaderBTNStyle1 onClick={()=> {setOpenAskValidation(true)}}>Pedir validação</HeaderBTNStyle1>
                }
                { needReSend && attachments.length>qt_attachments_beforevalidation &&
                    attachments.length !== 0 &&  statusNum === 1 && !validateAlertMsg && 
                      <HeaderBTNStyle1 onClick={()=> {setOpenAskValidation(true)}}>Pedir validação</HeaderBTNStyle1>
                }
                {
                    attachments.length === 0 && acceptNegotiation && <HeaderBTNStyle onClick={() => {setOpenGiveUpResearch(true)}}>Desistir da pesquisa</HeaderBTNStyle>
                }
                {
                     acceptNegotiation && ic_canstartwork && !startwork && <HeaderBTNStyle1 onClick={startWork} className="ml-[20px]">Iniciar trabalho</HeaderBTNStyle1>
                }
                </div>
                </>
 
                }
                {
                    ic_bidcanceled &&
                    <div className='w-full flex justify-end mt-[32px]'>
                        <HeaderBTNStyle disabled className="mb:ml-[0px] xl:ml-[30px]" sx={{'&:disabled': {backgroundColor: '#FFFFFF', color: '#C6C6C6', border:'1px solid #C6C6C6', cursor: 'not-allowed'}}}>Proposta cancelada</HeaderBTNStyle>
                    </div>

                }
                <div className='flex justify-between items-center mt-[20px] md:flex-row mb:flex-col'>

                { isPhysical &&
                    <>
                {
                needValidation && !isValidatedDIGITAL && !isSent &&
                    <>
                        <div
                            className='w-[90%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                            <div
                                className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[78%]">
                                <div
                                    className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                    O cliente terá o prazo de {limit} dias corridos para analisar, após esse prazo, a
                                    confirmação é automatica.
                                </div>

                            </div>
                        </div>
                    </>       

                }
                {isValidatedDIGITAL && !isSent && orderINFO.address_to_delivery && 
                    <>
                    <div
                        className='w-[100%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                            <div
                                className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[100%]">
                                <div
                                        className="flex mb:flex-col mb:text-center mb:gap-[10px] md:flex-row md:justify-between md:gap-[10px]  text-[#BA483A] font-[Poppins] text-[12pt] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                
                                <div style={{fontWeight:'600', fontSize:'16pt', }}>ATENÇÃO!</div>

                                <div>O cliente já informou o endereço de entrega do  documento. Acompanhe clicando no botão a seguir:</div>
                            </div>

                        </div>
                    </div>
                    <HeaderBTNStyle1 onClick={() => {setOpenSendDocument(true)}} className=''>Enviar documento</HeaderBTNStyle1>
                </>         
                }
                </>
            }
                { isStamped &&

                <>
                    {
                    needValidation && !isValidatedDIGITAL &&   !isValidatedSTAMPED      &&
                    !isSent &&
                    <>
                        <div
                            className='w-[90%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                            <div
                                className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[78%]">
                                <div
                                    className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                    O cliente terá o prazo de {limit} dias corridos para analisar, após esse prazo, a
                                    confirmação é automatica.
                                </div>

                            </div>
                        </div>
                    </>         
                    }
                    {
                    needValidation && isValidatedDIGITAL &&   !isValidatedSTAMPED  && needValidationStamped    &&
                    !isSent &&
                    <>
                        <div
                            className='w-[90%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                            <div
                                className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[78%]">
                                <div
                                    className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                    O cliente terá o prazo de {limit} dias corridos para analisar, após esse prazo, a
                                    confirmação é automatica.
                                </div>

                            </div>
                        </div>
                    </>         
                    }
                    {
                        isValidatedSTAMPED  && !isSent && orderINFO.address_to_delivery && 
                        <>
                        <div
                            className='w-[80%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                                <div
                                    className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[100%]">
                                    <div
                                        className="flex mb:flex-col mb:text-center mb:gap-[10px] md:flex-row md:justify-between md:gap-[10px]  text-[#BA483A] font-[Poppins] text-[12pt] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                                                            
                                    <div style={{fontWeight:'600', fontSize:'16pt', }}>ATENÇÃO!</div>
                                    <div>
                                    O cliente já informou o endereço de entrega do  documento. Acompanhe clicando no botão a seguir:                                
                                    </div>
                                </div>
    
                            </div>
                        </div>
                            <HeaderBTNStyle1 onClick={() => {setOpenSendDocument(true)}} className=''>Enviar documento</HeaderBTNStyle1>
                    </>         
                    
                    }
                    {/* {
                    isValidatedDIGITAL &&   isValidatedSTAMPED      &&
                    !isSent &&
                    <>
                        <div
                            className='w-[90%] flex justify-between  min-h-[54px] h-fit items-center mb:flex-col md:flex-row '>
                            <div
                                className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start min-h-[54px] w-[78%]">
                                <div
                                    className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] mb:pb-[18px] md:pb-[0px]">
                                    O cliente terá o prazo de {limit} dias corridos para analisar, após esse prazo, a
                                    confirmação é automatica.
                                </div>

                            </div>
                        </div>
                        <HeaderBTNStyle1 onClick={() => {setOpenSendDocument(true)}} className=''>Enviar documento</HeaderBTNStyle1>
                    </>         
                    } */}
                    </>
                }
                </div>

                {/* {JSON.stringify(orderINFO)} */}

                {!isImported && orderINFO.cd_orderstatus===2 && orderINFO.dt_releasemoney && !orderINFO.ic_orderpaid && !orderINFO.ic_iswithdrawing && !orderINFO.ic_complete  && !orderINFO.ic_ordercomplete &&

                <div className='flex justify-between mt-[20px]'>     
                    <div className='w-full flex justify-between min-h-[54px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex  mb:items-center mb:justify-center md:items-start md:justify-start w-[80%] min-h-[54px] md:flex-row mb:flex-col">
                                {daysToReleaseMoney&&daysToReleaseMoney>0?
                            <div className='mb:w-full md:w-[50%] flex mt-[18px] md:justify-start mb:justify-between mb:pl-[5px] mb:pr-[5px] md:pl-[0px] md:pr-[5px]'>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal mb:ml-[0px] md:ml-[10px]">
                                Seu pagamento será liberado em:
                                </div>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-bold">
                                &nbsp;{daysToReleaseMoney} dias
                                </div>
                            </div>
                            :
                            <div className='mb:w-full md:w-[50%] flex mt-[18px] md:justify-start mb:justify-between mb:pl-[5px] mb:pr-[5px] md:pl-[0px] md:pr-[5px]'>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal mb:ml-[0px] md:ml-[10px] font-bold">
                                Seu pagamento está liberado!
                                </div>
                            </div>

                                }
                            <div className='mb:w-full md:w-[50%] flex mb:mt-[10px] md:mt-[18px] mb:mb-[18px] md:mb-[0px] md:justify-start mb:justify-between mb:pl-[5px] mb:pr-[5px] md:pl-[0px] md:pr-[5px]'>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal">
                                Previsto: 
                                </div>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-bold">
                                &nbsp;{orderINFO.dt_releasemoney? formatDate(orderINFO.dt_releasemoney):''}
                                {/* &nbsp;{orderINFO.dt_releasemoney} */}
                                </div>
                            </div>
                            {/* <div className="text-[#D9D9D9] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">
                            O cliente terá o prazo de 10 dias corridos para analisar, após esse prazo, a confirmação é automatica.
                            </div> */}                                    
                        </div>      
                        {

                            orderINFO.dt_releasemoney && canwithdraw &&
                            // (orderINFO.dt_releasemoney.getTime() - (new Date()).getTime())<0 &&

                            <HeaderBTNStyle1 onClick={()=>{navigate('/account_researcher', {state:{initial: 8}})}} className="mb:ml-[0px] md:ml-[20px]">Sacar</HeaderBTNStyle1>
                        }     
                    </div>
                </div>
                }

{/* EXIST NEGOCIATION */}


                { existNegociation &&

                        <>
                        {
                            isMobile?
                            
                    <div className='justify-between mt-[20px]' style={{margin:'10px'}}>     
                    <div className='w-full  justify-between min-h-[93px] h-fit items-center mb:flex-col md:flex-row'
                    
                    >
                        <div className="bg-[#D9D9D9] bg-opacity-20 justify-start w-[100%] min-h-[93px]  xl:flex-row mb:flex-col"
                            style={{padding:'15px'}}
                        >
                            <div className='mb:w-full xl:w-full flex mb:mt-[10px] xl:mt-[11px]  flex-col xl:mb-[11px] md:mb-[0px]'
                                style={{
                                    width:'80%',
                                    marginLeft:'auto',
                                    marginRight:'auto',
                                }}
                            
                            >
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal mb:ml-[10px] xl:ml-[0px]"
                                >
                                Valor da proposta:
                                </div>
                                <TextField
                                    id="outlined-start-adornment"
                                    value={valueNegotiation?`${Number.parseFloat(valueNegotiation).toFixed(2).replace('.', ',')}`:'0,00'}
                                    className={classes.root1 + "  mb:mb-[10px] xl:mb-[0px] mb:ml-[10px] xl:ml-[0px] resize-none !overflow-y-auto mt-[5px] pt-[10px] pl-[13px] !h-[48px] !max-h-[48px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] mb:w-[90%] xl:w-[95%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"}
                                    placeholder="0,00"
                                //     sx={{'& .MuiOutlinedInput-root': {height: '33px', width: '177px'},
                                //     '& fieldset': {
                                //         border: 'none !important'
                                // }}}
                                sx={{
                                    margin:'10px'
                                }}
                                    InputProps={{
                                        className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5] bg-[#FFFFFF]",
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"><span className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[148%]'>R$</span></InputAdornment>,
                                    }}
                                    />
                            </div>
                            <div className='mb:w-full xl:w-full flex mb:mt-[10px] xl:mt-[11px]  flex-col xl:mb-[11px] md:mb-[0px]'
                                style={{
                                    width:'80%',
                                    marginLeft:'auto',
                                    marginRight:'auto',
                                }}
                            
                            >
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal mb:ml-[10px] xl:ml-[0px]">
                                Mensagem do cliente
                                </div>
                                <TextareaAutosize
                                        id="standard-multiline-static"
                                        label="Multiline"
                                        defaultValue={comment}
                                        value={comment}
                                        className={classes.root1 + "pt-[9px] pl-[13px] resize-y !min-h-[100px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"}
                                    />
                            </div>
                            {/* <div className="text-[#D9D9D9] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">
                            O cliente terá o prazo de 10 dias corridos para analisar, após esse prazo, a confirmação é automatica.
                            </div> */}
                                    
                        {/* </div> */}
                        <div className='flex mb:w-[70%] md:w-[60%] justify-center h-[93px] mb:items-center md:items-end xl:items-center mb:flex-col xl:flex-row'
                            style={{
                                width:'80%',
                                marginLeft:'auto',
                                marginRight:'auto',
                                marginBottom:'10px',
                            }}
                        
                        >
                            {
                                !acceptNegotiation  && !ic_bidcanceled &&
                                <>
                                    <HeaderBTNStyle1 onClick={handleAcceptNegotiation} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}} disabled={declined} className="mb:ml-[0px] xl:ml-[20px]">Aceitar negociação</HeaderBTNStyle1>
                                    <HeaderBTNStyle onClick={()=>{setOpenDeclineNegotiation(true)}} disabled={declined} className="mb:ml-[0px] xl:ml-[30px]" sx={{'&:disabled': {backgroundColor: '#FFFFFF', color: '#C6C6C6', border:'1px solid #C6C6C6', cursor: 'not-allowed'}}}>Recusar</HeaderBTNStyle>
                                </> 

                            }

                            { acceptNegotiation && !ic_bidcanceled && !ic_ignorenegotiation &&
                                <div className=' font-[Poppins] text-[16px] leading-[24px] font-semibold' style={{color:'#17686E'}}>Negociação aceita!</div>
                            }
                            {
                                ic_bidcanceled &&
                                <div className='w-full flex justify-end mt-[32px]'>
                                    <HeaderBTNStyle disabled className="mb:ml-[0px] xl:ml-[30px]" sx={{'&:disabled': {backgroundColor: '#FFFFFF', color: '#C6C6C6', border:'1px solid #C6C6C6', cursor: 'not-allowed'}}}>Proposta cancelada</HeaderBTNStyle>
                                </div>

                            }
                            </div>
                        </div>
                        </div>
                    </div>

                            :
                        
                            
                    <div className='flex justify-between mt-[20px]'>     
                    <div className='w-full flex justify-between min-h-[93px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex  justify-start w-[100%] min-h-[93px]  xl:flex-row mb:flex-col">
                            <div className='mb:w-full md:w-[30%] flex mt-[11px]  flex-col'>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal ml-[10px] ">
                                Valor da proposta:
                                </div>
                                <TextField
                                    id="outlined-start-adornment"
                                    value={valueNegotiation?`${Number.parseFloat(valueNegotiation).toFixed(2).replace('.', ',')}`:'0,00'}
                                    className="!ml-[10px] !mt-[5px] !md:mb-[26px] !mb:mb-[20px]"
                                    placeholder="0,00"
                                    sx={{'& .MuiOutlinedInput-root': {height: '33px', width: '177px'},
                                    '& fieldset': {
                                        border: 'none !important'
                                }}}
                                    InputProps={{
                                        className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5] bg-[#FFFFFF]",
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"><span className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[148%]'>R$</span></InputAdornment>,
                                    }}
                                    />
                            </div>
                            <div className='mb:w-full xl:w-full flex mb:mt-[10px] xl:mt-[11px]  flex-col xl:mb-[11px] md:mb-[0px]'>
                                <div className="text-[#313131] font-[Poppins] text-[12px] leading-[18px] font-normal mb:ml-[10px] xl:ml-[0px]">
                                Mensagem do cliente
                                </div>
                                <TextareaAutosize
                                        id="standard-multiline-static"
                                        label="Multiline"
                                        defaultValue={comment}
                                        value={comment}
                                        className={classes.root1 + "pt-[9px] pl-[13px] resize-y !min-h-[100px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"}
                                    />
                            </div>
                            {/* <div className="text-[#D9D9D9] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">
                            O cliente terá o prazo de 10 dias corridos para analisar, após esse prazo, a confirmação é automatica.
                            </div> */}
                                    
                        {/* </div> */}
                        <div className='flex mb:w-[70%] md:w-[60%] justify-center h-[93px] mb:items-center md:items-end xl:items-center mb:flex-col xl:flex-row'>
                            {
                                !acceptNegotiation  && !ic_bidcanceled &&
                                <>
                                    <HeaderBTNStyle onClick={()=>{setOpenDeclineNegotiation(true)}} disabled={declined} className="mb:ml-[0px] xl:ml-[30px]" sx={{'&:disabled': {backgroundColor: '#FFFFFF', color: '#C6C6C6', border:'1px solid #C6C6C6', cursor: 'not-allowed'}}}>Recusar</HeaderBTNStyle>
                                    <HeaderBTNStyle1 onClick={handleAcceptNegotiation} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}} disabled={declined} className="mb:ml-[0px] xl:ml-[20px]">Aceitar negociação</HeaderBTNStyle1>
                                </> 

                            }

                            { acceptNegotiation && !ic_bidcanceled && !ic_ignorenegotiation &&
                                <div className=' font-[Poppins] text-[16px] leading-[24px] font-semibold' style={{color:'#17686E'}}>Negociação aceita!</div>
                            }
                            {
                                ic_bidcanceled &&
                                <div className='w-full flex justify-end mt-[32px]'>
                                    <HeaderBTNStyle disabled className="mb:ml-[0px] xl:ml-[30px]" sx={{'&:disabled': {backgroundColor: '#FFFFFF', color: '#C6C6C6', border:'1px solid #C6C6C6', cursor: 'not-allowed'}}}>Proposta cancelada</HeaderBTNStyle>
                                </div>

                            }
                            </div>
                        </div>
                        </div>
                    </div>
                        }
                        </>
                        
                       
                       
                    


                }

                {/* {
                    !showConfirmBtn &&

                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                    <div className="bg-[#D9D9D9] bg-opacity-20 flex items-center justify-start w-[80%]">
                        <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] pb-[18px]">Caso não confirme a análise em 10 dias corridos, a cofirmação será feita automaticamente, liberando o pagamento para seu pesquisador.</div>
                    </div>
                    <div className="flex flex-col">
                        <HeaderBTNStyle1 onClick={() => {setOpenConfirmAnalyze(true)}}>Confirmar análise</HeaderBTNStyle1>
                        <div className='flex justify-center mt-[10px]'>
                        <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                        <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                        </div>
                    </div>
                </div>
                }
                {
                    showConfirmBtn &&
                    <div className='w-full flex justify-end mt-[32px]'>
                        <HeaderBTNStyle1 onClick={()=>{navigate('/feedback/researcher')}}>Avaliar pesquisador</HeaderBTNStyle1>
                    </div>
                } */}

                {
                    isSent &&
                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start w-[80%]  min-h-[93px]">
                            
                            <div className="flex mt-[18px] mb:ml-[15px] md:ml-[32px] justify-between mb:w-[90%] md:w-[75%] mb:mb-[18px] md:mb-[39px] md:flex-row mb:flex-col">
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Forma de envio:</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold'>{isSentInfo.cd_shippingway}</div>    
                            </div>          
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Código de rastreio:</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold'>{isSentInfo.cd_trackingcode}</div>    
                            </div>   
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Data prevista de entrega:</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold'>{receiptDateForecast? receiptDateForecast : ''}</div>    
                            </div>   
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Status</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold text-[#17686E]'>{isReceived}</div>    
                            </div>   
                            </div>

                            {!isArrived &&
                                <div className='font-[Poppins] mb:ml-[15px] md:ml-[20px] text-[14px] leading-[21px] font-normal text-[#BA483A]' style={{marginTop:'-35px'}}>
                                Caso o cliente não confirme o recebimento do documento físico em {limit} dias corridos após a data prevista, a confirmação será feita automaticamente.
                                </div>
                            }

                  
                        </div>
                    </div>
                }

            <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Anexar documento soliticado:</div>
                <div className='flex justify-center w-full mb-[80px]'>              
                    <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[220px] flex flex-row items-center justify-center">
                        {
                            attachments&&attachments.length > 0 ? 
                            <Box  className={classes.root + " w-[96%] overflow-x-auto flex h-[175px]"}>
                                {
                                    orderID && 
                                    attachments.map((item, index) => {
                                        const fileName = decodeURIComponent(item);
                                        return (
                                          <div key={index} className='mt-[0px] mr-[25px] flex-col flex items-center relative' 
                                            onMouseOver={()=>{setShowDocCtrl(index+1); preview.current[index].style.filter = 'blur(5px)'}} 
                                            onMouseOut={()=>{setShowDocCtrl(false); preview.current[index].style.filter = ''}}>
                                            {showDocCtrl == index+1 && 
                                              <>
                                                <a 
                                                href={      API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                download={  API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                className={validateAlertMsg?"absolute z-10 cursor-pointer top-[50px]":"absolute z-10 cursor-pointer top-[25px]"}
                                                >
                                                  <Box component="img" src={DownloadIcon} alt="download-icone"/>
                                                </a>
                                                { attachments.length > 1 &&!validateAlertMsg && !needValidation  && <Box onClick={()=>{handleDeleteAttach(item)}} component="img" src={DeleteIcon} alt="delete-icone" className="absolute z-10 cursor-pointer top-[70px]"/>}
                                              </>
                                            }
                                            <div ref={el => preview.current[index] = el}>
                                              <FilePreview
                                                width='93px'
                                                height='119px'
                                                type={"file"}
                                                url={IMAGE_BASE + `orders/orders_files/${orderID}/${item}`}
                                                onError={(err) =>console.error(err)}
                                              />
                                            </div>
                                            <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[5px] w-[93px] text-center break-all' style={{ whiteSpace: 'normal', wordBreak: 'break-all', width: '93px' }}>{item}</div>
                                          </div>
                                        )
                                    })                                    
                                }
                                { isDigital && !needValidation  &&
                                <div key={attachments.length} className='mt-[16px] mr-[25px] flex-col flex items-center relative' >
                                    <div className="mb-[0px]">
                                        <input id='fileUpload' type='file' multiple onChange={handleFileEvent} className='hidden' accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'/>
                                        <label htmlFor='fileUpload'>
                                            <a>
                                                <div className='w-[93px] h-[119px] border-dashed border-[0.5px] border-[#C6C6C6] flex items-center justify-center cursor-pointer' >
                                                    <Box component='img' src={PlusIcon} width="25px" height="25px"></Box>
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                    <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[3px]'></div>
                                </div>
                                }
                                { isPhysical && !needValidation  &&
                                <div key={attachments.length} className='mt-[16px] mr-[25px] flex-col flex items-center relative' >
                                    <div className="mb-[0px]">
                                        <input id='fileUpload' type='file' multiple onChange={handleFileEvent} className='hidden' accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'/>
                                        <label htmlFor='fileUpload'>
                                            <a>
                                                <div className='w-[93px] h-[119px] border-dashed border-[0.5px] border-[#C6C6C6] flex items-center justify-center cursor-pointer' >
                                                    <Box component='img' src={PlusIcon} width="25px" height="25px"></Box>
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                    <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[3px]'></div>
                                </div>
                                }
                                { (isStamped && !needValidation) &&
                                <div key={attachments.length} className='mt-[16px] mr-[25px] flex-col flex items-center relative' >
                                    <div className="mb-[0px]">
                                        <input id='fileUpload' type='file' multiple onChange={handleFileEvent} className='hidden' accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'/>
                                        <label htmlFor='fileUpload'>
                                            <a>
                                                <div className='w-[93px] h-[119px] border-dashed border-[0.5px] border-[#C6C6C6] flex items-center justify-center cursor-pointer' >
                                                    <Box component='img' src={PlusIcon} width="25px" height="25px"></Box>
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                    <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[3px]'></div>
                                </div>
                                }
                                { isStamped && isValidatedDIGITAL && !needValidationStamped  &&
                                <div key={attachments.length} className='mt-[16px] mr-[25px] flex-col flex items-center relative' >
                                    <div className="mb-[0px]">
                                        <input id='fileUpload' type='file' multiple onChange={handleFileEvent} className='hidden' accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'/>
                                        <label htmlFor='fileUpload'>
                                            <a>
                                                <div className='w-[93px] h-[119px] border-dashed border-[0.5px] border-[#C6C6C6] flex items-center justify-center cursor-pointer' >
                                                    <Box component='img' src={PlusIcon} width="25px" height="25px"></Box>
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                    <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[3px]'></div>
                                </div>
                                }
                                { needReSend  &&
                                <div key={attachments.length} className='mt-[16px] mr-[25px] flex-col flex items-center relative' >
                                    <div className="mb-[0px]">
                                        <input id='fileUpload' type='file' multiple onChange={handleFileEvent} className='hidden' accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'/>
                                        <label htmlFor='fileUpload'>
                                            <a>
                                                <div className='w-[93px] h-[119px] border-dashed border-[0.5px] border-[#C6C6C6] flex items-center justify-center cursor-pointer' >
                                                    <Box component='img' src={PlusIcon} width="25px" height="25px"></Box>
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                    <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[3px]'></div>
                                </div>
                                }
                            </Box>
                        :
                        <div className='flex flex-col items-center'>
                            <input 
                                disabled={!startwork && !isImported} 
                                id='fileUpload' 
                                type='file' 
                                multiple 
                                onChange={handleFileEvent} 
                                className='hidden' 
                                accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'
                            />
                            {
                                !isImported &&

                            <label htmlFor='fileUpload'>
                                <a>
                                <div className={`flex flex-col items-center cursor-pointer ${!startwork ? 'opacity-50' : ''}`} style={{ pointerEvents: !startwork ? 'none' : 'auto' }}>
                                    <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    { !startwork ? 'Ainda não é possível anexar arquivos' : 'Anexar arquivo' }
                                    </div>
                                    { startwork ? (
                                        <div>
                                        <Box component='img' src={AttachmentIcon} width="60px" height='65px' />
                                    </div>
                                    ) : null }
                                </div>
                                </a>
                            </label>
                            }
                            {
                                isImported &&
                            <label htmlFor='fileUpload'>
                                <a>
                                <div className={`flex flex-col items-center cursor-pointer `} style={{ pointerEvents:  'auto' }}>
                                    <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    { 'Anexar arquivo' }
                                    </div>
                                        <div>
                                        <Box component='img' src={AttachmentIcon} width="60px" height='65px' />
                                    </div>
                                </div>
                                </a>
                            </label>
                            }
                        </div>
                        }                                                
                    </div>
                </div>
            </>

               }

                { isImported && 
                
                <>
                {attachments.length>0 ?
                <div id='shareimportorder' className='flex flex-col items-center w-full justify-center mb-[80px]'>
                    <ShareLink researcherList={researcherList} setResearchList={setResearchList}  orderid={orderID}/>
                </div>
                :
                <div id='shareimportorder' className='flex flex-col items-center w-full justify-center mb-[80px]'
                    style={{padding:'20px', border:'1px solid red', borderRadius:'10px', width:'50%', margin:'10px auto 50px auto', color:'red'}}
                    >
                    Você precisa adicionar os arquivos antes de compartilhar seu pedido.
                </div>
                }
                </>
                }
                
          </ContainerStyle>
       </RootStyle>
    )
}