import useWindowSize from '../../hooks/useWindowSize';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import {
    Grid,
    Stack,
    Box,
    Divider,
    InputBase,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import * as React from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components
import InformationIcon from '../../assets/icons/Information.svg'
import Custom_Accordion from '../../components/Accordion';
import SubmitModal from '../../components/modal'
import AcceptImportOrderModal from '../../components/modal/acceptImportOrderModal'
import { toast } from 'react-toast';
import { IMAGE_BASE } from '../../config/constants';
import MenuItem from '@mui/material/MenuItem'; 
// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    fontFamily: 'Poppins',
}));

const Textstyle_1 = styled('div')(({ theme }) => ({
    fontSize: '25px',
    fontWeight: 400,
    color: '#313131',
    textAlign: 'center',
    padding: '27px 0px 15px',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
    }
}));

const MakeProposalBTNStyle = styled('button')(({ theme }) => ({
    color: '#17686E',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#fff',
    border: '1px solid #17686E',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
        padding: '11.28px 20px 12.67px',
    }
}));

const MakeProposalBTNStyleDISABLED = styled('button')(({ theme }) => ({
    color: '#C6C6C6',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',    
    fontSize: '14px',
    backgroundColor: '#fff',
    border: '1px solid #C6C6C6',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'not-allowed',
    width:'100%'
}));

const Grid_wrapper = styled('div')(({ theme }) => ({
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
        padding: '0px 28px',
    }
}));

const TitleStyle = styled('div')({
    color: '#313131',
    fontSize: '18px',
    fontWeight: 300,
});

const TitleStyle_header = styled('div')(({ theme }) => ({
    fontSize: '14px',
    color: '#313131',
    fontWeight: 600,
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '0px',
    }
}));

const Textstyle_2 = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
}));


const Textstyle_3 = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: '22px',
    color: '#313131',

    paddingBottom: '0px',
    [theme.breakpoints.up('sm')]: {
        paddingBottom: '44px',
    }
}));

const Box_left_wrapper = styled('div')(({ theme }) => ({
    paddingRight: '0px',
    [theme.breakpoints.up('md')]: {
        paddingRight: '20px',
    }
}));

const Box_right_wrapper = styled('div')(({ theme }) => ({
    paddingLeft: '0px',
    [theme.breakpoints.up('md')]: {
        paddingLeft: '20px',
    }
}));

const InfoStyle = styled('div')(({ theme }) => ({
    backgroundColor: '#F1F1F1',
    borderRadius: '7px',
    display: 'flex',
    marginTop: '15px',
    padding: '2px 31px 1px 4px',
    width: 'fit-content',
}));

const InfoTextStyle = styled('div')({
    color: '#313131',
    fontSize: '10px',
    whiteSpace: 'nowrap',
});

const TextStyle_name = styled('div')({
    color: '#313131',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: '5px',
});

const Stack_sub_1 = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0px',
}));

const InfoTextStyle_1 = styled('div')(({ theme }) => ({
    color: '#313131',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    padding: '10px 0px',
}));

const Inputbase_wrapper = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    padding: '8px 15px',
    marginTop: '3px',
    marginBottom: '25px',
    [theme.breakpoints.up('md')]: {
        marginBottom: '30px',
    }
}));

const Button_wrapper = styled('div')(({ theme }) => ({
    width: '130px',
    [theme.breakpoints.up('sm')]: {
        width: '250px',
    }
}));

const Stack_buttons_wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: '42px',
    marginTop: '25px',
    [theme.breakpoints.up('sm')]: {
        marginBottom: '186px',
        marginTop: '20px',
    }
}));

const Accordion_Wrapper = styled('div')(({ theme }) => ({
    padding: '18px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '18px 68px 20px 20px',
    }
}));
// ------------------------------------------

const Gender_filter = ["Masculino", "Feminino", "Não especificado"]


const currencies = ["Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado","Masculino", "Feminino ", "Não especificado"]

export default function Publication(props) {

    const { width } = useWindowSize();
    const [openModal, setOpenModal] = useState(false);
    const [openAcceptModal, setopenAcceptModal] = useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        if(!props.currentUser){
            toast.info('Você precisa estar logado para efetuar esta ação.')
            return navigate('/login')
        }
        else if(props.currentUser.ic_profile===0 ){
            return toast.info('Você precisa de uma conta de pesquisador para efetuar esta ação.')
        }

        //console.log(`props.currentUser.ic_currentplan = ${props.currentUser.ic_currentplan}`)
        //console.log(`card.tp_orderexperience = ${JSON.stringify(card)}`)
        if(props.currentUser.ic_currentplan===0 && card.tp_orderexperience && card.tp_orderexperience!=='Beginner'){
            toast.info('Você precisa fazer upgrade para trabalhar nesta ordem.')
            // return navigate('/account', {state:{initial: 7}})
        }
        else if(props.currentUser.ic_currentplan===1 && card.tp_orderexperience && card.tp_orderexperience==='Assessor'){
            toast.info('Você precisa fazer upgrade para trabalhar nesta ordem.')
            return navigate('/account', {state:{initial: 7}})
        }
        else{
            return setOpenModal(true);
        }
    };
    
    const handleClickOpenAcceptModal = () => {
        if(!props.currentUser){
            toast.info('Você precisa estar logado para efetuar esta ação.')
            return navigate('/login')
        }
        else if(props.currentUser.ic_profile === 1 || !props.isImported){
            return toast.info('Você precisa de uma conta de cliente para efetuar esta ação.')
        }
        else{
            return setopenAcceptModal(true);
        }
    };

    // data of card--------------

    const card = props.card?props.card:{}
    const life_event_dates = props.life_event_dates?props.life_event_dates:{}
    const ancestoralternative = props.ancestoralternative?props.ancestoralternative:{}
    const [isTheSameResearcher,setisTheSameResearcher] = useState(false)
    // alert(JSON.stringify(ancestoralternative))
    const [canClick,setcanClick] = useState(true)

    useEffect(()=>{
        if(props.currentUser){
            var can = true
            const userID = props.currentUser.id?props.currentUser.id:props.currentUser._id
            if(card.proposals){
                const auxbids=[...card.auxbids]
                for (let index = 0; index < auxbids.length; index++) {
                    const single = auxbids[index];
                    if(single.id_researcher===userID){
                        can=false
                    }

                }
            }
            if(card.isWorking){
                can=false
            }
            if(card.tp_order && props.currentUser.ic_profile===1){
                can=false
            }
            if(!card.tp_order && props.currentUser.ic_profile===0){
                can=false
            }
            if(userID===card.authorID){
                can=false
            }
            return setcanClick(can)
        }
        return setcanClick(false)
    },[props,card])

    useEffect(()=>{
        if(props.card&&props.currentUser){
            const researcherID = props.card.researcherID || '' 
            const card = props.card || '' 
            const userID = props.currentUser._id.toString() || props.currentUser.id.toString() || ''
            const isTheSameResearcher = researcherID === userID;
            return setisTheSameResearcher(isTheSameResearcher)
        }
            return setisTheSameResearcher(false)
    },[props.card.researcherID, props.currentUser])

   //useEffect(()=>{
   //console.log("familyLink: " + card.familyLink)
   //},[card.familyLink])

    return (
        <RootStyle>
            

            <SubmitModal setcanClick={setcanClick} orderID={card.orderID} openModal={openModal} setOpenModal={setOpenModal}/>
            <AcceptImportOrderModal setcanClick={setcanClick}  orderID={card.orderID} researcherID={card.researcherID} openModal={openAcceptModal} setOpenModal={setopenAcceptModal}/>
            <Textstyle_1                 
            // onChange={props.isViewer()}

            >{card.fullName}</Textstyle_1>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: '10px' }}>
                {!props.isImported ? 
                <Textstyle_2>Cliente</Textstyle_2>
                :
                <Textstyle_2>Pesquisador</Textstyle_2>
                
            }

            {canClick &&
                <>
                    {!props.isImported&&
                        <MakeProposalBTNStyle onClick={handleClickOpen}>Fazer proposta</MakeProposalBTNStyle>
                    }
                    {props.currentUser  && props.currentUser.ic_profile!==1 && !isTheSameResearcher &&  !props.allorder.id_customer &&
                            <MakeProposalBTNStyle onClick={handleClickOpenAcceptModal}>Aceitar ordem importada</MakeProposalBTNStyle>
                    }   
                </>
            }
            </Stack>
            {!props.isImported?

            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: '6.5px' }}>
                <Box component={'img'} src={`${IMAGE_BASE+`accounts/${card.authorID}/`+card.authorAvatar}`} width="27px" className="rounded-[20px]"/>
                    <TitleStyle 
                        sx={{ fontSize: '12px' }}
                        >{card.author}
                    </TitleStyle>
            </Stack>
            :
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: '6.5px' }}>
                <Box component={'img'} src={`${IMAGE_BASE+`accounts/${card.thisResearcher._id}/`+card.thisResearcher.nm_researcheravatarfile}`} width="27px" className="rounded-[20px]"/>
                    <TitleStyle 
                        sx={{ fontSize: '12px' }}
                    >{card.thisResearcher.nm_user}
                    </TitleStyle>
            </Stack>
            }

            <InfoStyle>
                <InfoTextStyle>Publicado há: {card.time}</InfoTextStyle>
                <InfoTextStyle sx={{ pl: '15px' }}>Propostas: {card.proposals}</InfoTextStyle>
            </InfoStyle>

            <Grid container>
                <Grid item xs={12} md={3} lg={3}>
                    <Stack_sub_1>
                        <TitleStyle sx={{ fontSize: '14px' }}>Tipo do documento:</TitleStyle>
                        <TextStyle_name sx={{ fontSize: '14px' }}>{card.documentType}</TextStyle_name>
                    </Stack_sub_1>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Stack_sub_1>
                        <TitleStyle sx={{ fontSize: '14px' }}>Tipo de solicitação:</TitleStyle>
                        <TextStyle_name sx={{ fontSize: '14px' }}>
                            {props.allorder.ic_searchdocument ? "Pesquisa" : ""}{props.allorder.ic_searchdocument && props.allorder.ic_fetchdocument ? ", " : ""}{props.allorder.ic_fetchdocument ? "Busca" : !props.allorder.ic_searchdocument && !props.allorder.ic_fetchdocument ? "-" : ""}
                        </TextStyle_name>
                    </Stack_sub_1>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Stack_sub_1>
                        <TitleStyle sx={{ fontSize: '14px' }}>País de pesquisa:</TitleStyle>
                        <TextStyle_name sx={{ fontSize: '14px', pr: '5px' }}>
                        
                        {props.showCountryName(card.researchCountry, props.filterCountry).toUpperCase()}
                        
                        </TextStyle_name>
                        <Box sx={{ paddingLeft: '15px', display: 'flex' }} >
                            <Icon 
                                icon={
                                props.showFlag(card.researchCountry, props.filterCountry)
                                }
                                width="24"
                            />
                        </Box>
                    </Stack_sub_1>
                </Grid>
                    {!props.isImported ? 
                    <>
                        <Grid item xs={12} md={4} lg={2}>
                            <Stack_sub_1>
                                <TitleStyle sx={{ fontSize: '14px' }}>
                                    Nível exigido:
                                </TitleStyle>
                                <TextStyle_name sx={{ fontSize: '14px' }}>{card.level && card.level==='Beginner'?'Iniciante':card.level==='Specialist'?"Especialista":card.level==='Assessor'?'Assessor':''}</TextStyle_name>
                            </Stack_sub_1>
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                            <InfoTextStyle_1>
                                Forma de recebimento do documento:
                            </InfoTextStyle_1>
                            <TextStyle_name sx={{ fontSize: '14px' }}>{card.documentForm}</TextStyle_name>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12} md={6} lg={4} style={{display:'flex', alignItems:'center', alignContent:'center'}}>
                            <InfoTextStyle_1>
                                Forma de recebimento do documento:
                            </InfoTextStyle_1>
                            <TextStyle_name sx={{ fontSize: '14px' }}>{card.documentForm}</TextStyle_name>
                        </Grid>
                    </>
                    }
            </Grid >

            {width > 600 && <Divider sx={{ mt: '20px', mb: '30px' }} />}
            {width <= 600 && <Divider sx={{ mt: '10px', mb: '10px' }} />}

            <Textstyle_3>Informações do documento</Textstyle_3>


            {/* xs= 0-767 pixels
                sm = 768-991 pixels
                md = 992-1199 pixels
                lg = 1200 pixels and up */}
            <Grid_wrapper>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_left_wrapper>
                            <TitleStyle_header>Qual país de origem desse documento?</TitleStyle_header>
                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                    placeholder=''
                                    fullWidth={true}
                                    value={props.showCountryName&&props.filterCountry&&card.nm_documentcountry?props.showCountryName(card.nm_documentcountry, props.filterCountry).toUpperCase():''}
                                    disabled={props.viewer == true}
                                />
                            </Inputbase_wrapper>
                        </Box_left_wrapper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_right_wrapper>
                            <TitleStyle_header>Local do país de origem</TitleStyle_header>

                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                    placeholder=''
                                    fullWidth={true}
                                    disabled={props.viewer == true}
                                    value={card.nm_documentlocation?card.nm_documentlocation:''}
                                />
                            </Inputbase_wrapper>
                        </Box_right_wrapper>
                    </Grid>
                </Grid>

                {card.documentType!=='Outros' ?
                                
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box_left_wrapper>
                                <TitleStyle_header>Qual tipo de documento que você pesquisou?</TitleStyle_header>
                                <Inputbase_wrapper>
                                    <InputBase
                                        sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                        placeholder=''
                                        fullWidth={true}
                                        disabled={props.viewer == true}
                                        value={card.documentType?card.documentType:''}
                                    />
                                </Inputbase_wrapper>
                            </Box_left_wrapper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box_right_wrapper>
                                <Stack direction={'row'} justifyContent={'space-between'} width={'99%'}>
                                    <TitleStyle_header>Link do perfil do Family Search</TitleStyle_header>
                                    <Box component="img" src={InformationIcon} alt="informacao-icone" width="17px" title="Para que o pesquisador possa ter uma ajuda na busca do seu pedido, compartilhe o perfil do Family Search do seu antepassado." />
                                </Stack>
                                <Inputbase_wrapper >
                                        <InputBase
                                            type='text'
                                            onClick={()=>{
                                                var link = document.createElement("a")
                                                link.href = card?.familyLink || window.location.pathname
                                                link.target = "_blank"
                                                link.click()
                                            }}
                                            sx={{ fontFamily: 'Poppins', fontSize: '14px', textDecoration: 'underline', color: "blue",
                                            'input':{ cursor: 'pointer !important'}
                                            }}
                                            readOnly={props.viewer}
                                            placeholder=''
                                            fullWidth={true}
                                            value={card.familyLink||''}
                                        />
                                </Inputbase_wrapper>
                            </Box_right_wrapper>
                        </Grid>
                    </Grid>
                    :
                    <>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box_left_wrapper>
                                <TitleStyle_header>Qual tipo de documento que você pesquisou?</TitleStyle_header>
                                <Inputbase_wrapper>
                                    <InputBase
                                        sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                        placeholder=''
                                        fullWidth={true}
                                        disabled={props.viewer == true}
                                        value={card.documentType||''}
                                    />
                                </Inputbase_wrapper>
                            </Box_left_wrapper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box_right_wrapper>
                                <TitleStyle_header>Outro tipo de documento</TitleStyle_header>
                                    <Inputbase_wrapper>
                                        <InputBase
                                            sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                            placeholder=''
                                            fullWidth={true}
                                            disabled={props.viewer == true}
                                            value={card.otherDoc||''}
                                        />
                                </Inputbase_wrapper>
                            </Box_right_wrapper>
                        </Grid>
                    </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box_left_wrapper>
                                    <Stack direction={'row'} justifyContent={'space-between'} width={'99%'}>
                                        <TitleStyle_header>Link do perfil do Family Search</TitleStyle_header>
                                        <Box component="img" src={InformationIcon} alt="informacao-icone" width="17px" title="Para que o pesquisador possa ter uma ajuda na busca do seu pedido, compartilhe o perfil do Family Search do seu antepassado." />
                                    </Stack>
                                    <Inputbase_wrapper>
                                    <InputBase
                                            type='text'
                                            onClick={()=>{
                                                var link = document.createElement("a")
                                                link.href = card?.familyLink || window.location.pathname
                                                link.target = "_blank"
                                                link.click()
                                            }}
                                            sx={{ fontFamily: 'Poppins', fontSize: '14px', textDecoration: 'underline', color: "blue",
                                            'input':{ cursor: 'pointer !important'}
                                            }}
                                            readOnly={props.viewer}
                                            placeholder=''
                                            fullWidth={true}
                                            value={card.familyLink||''}
                                        />
                                    </Inputbase_wrapper>
                                </Box_left_wrapper>
                            </Grid>
                        </Grid>
                        </>
                }

                <TitleStyle_header>
                    Sexo
                </TitleStyle_header>

                <Stack>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="3"
                            name="radio-buttons-group"
                            sx={{ display: 'flex', flexDirection: 'row' }}
                        >

                            {Gender_filter&&Gender_filter.map((item, index) => (

                                <FormControlLabel value={index}
                                key={index}
                                disabled={props.viewer == true}
                                checked={(card.ds_gender) == item}
                                label={item}
                                    control={<Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 15 },
                                            color: '#313131',
                                            '&.Mui-checked': {
                                                color: '#313131',
                                            },
                                        }}
                                    />}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontFamily: 'Poppins',
                                            color: '#7E7E7E',
                                        },
                                        marginRight: '40px'
                                    }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Stack>

                <Box sx={{ pt: '8px' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box_left_wrapper>
                                <TitleStyle_header>Nome completo</TitleStyle_header>
                                <Inputbase_wrapper>
                                    <InputBase
                                        sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                        placeholder=''
                                        fullWidth={true}
                                        value={card.fullName||''}
                                        disabled={props.viewer == true}
                                        />
                                </Inputbase_wrapper>
                            </Box_left_wrapper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box_right_wrapper>
                                <TitleStyle_header>Nome alternativo</TitleStyle_header>
                                    <TextField
                                        disabled={props.viewer == true}
                                        fullWidth
                                        placeholder={""}
                                        sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                        multiline
                                        rows={ancestoralternative.length>1 ? 3 : 1}
                                        value={
                                            ancestoralternative.map((val,ind) => (
                                                ind>0?`\n${val}`:val
                                            ))||''
                                        }
                                    >
                                    </TextField>
                            </Box_right_wrapper>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_left_wrapper>
                            <TitleStyle_header>Nome completo do cônjuge</TitleStyle_header>
                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                    placeholder=''
                                    fullWidth={true}
                                    value={props.family.nm_spouse||''}
                                    disabled={props.viewer == true}
                                />
                            </Inputbase_wrapper>
                        </Box_left_wrapper>
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_left_wrapper>
                            <TitleStyle_header>Nome completo do pai</TitleStyle_header>
                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                    placeholder=''
                                    fullWidth={true}
                                    disabled={props.viewer == true}
                                    value={props.family.nm_father||''}
                                />
                            </Inputbase_wrapper>
                        </Box_left_wrapper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_right_wrapper>
                            <TitleStyle_header>Nome completo da mãe</TitleStyle_header>
                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
                                    placeholder=''
                                    fullWidth={true}
                                    disabled={props.viewer == true}
                                    value={props.family.nm_mother||''}
                                />
                            </Inputbase_wrapper>
                        </Box_right_wrapper>
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_left_wrapper>
                            <TitleStyle_header>Nome completo de outra pessoa</TitleStyle_header>

                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#313131' }}
                                    placeholder=''
                                    fullWidth={true}
                                    disabled={props.viewer == true}
                                    value={props.family.nm_otherperson||''}
                                />
                            </Inputbase_wrapper>
                        </Box_left_wrapper>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box_right_wrapper>
                            <TitleStyle_header>Grau de familiaridade</TitleStyle_header>
                            <Inputbase_wrapper>
                                <InputBase
                                    sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#313131' }}
                                    placeholder=''
                                    fullWidth={true}
                                    disabled={props.viewer == true}
                                    value={props.family.ds_otherpersonfamiliarity||''}
                                />
                            </Inputbase_wrapper>
                        </Box_right_wrapper>
                    </Grid>
                </Grid>

                <TitleStyle_header>
                    Eventos da vida
                </TitleStyle_header>

                <Accordion_Wrapper>
                    <Custom_Accordion 
                    life_event_dates={life_event_dates}
                    viewer={props.viewer}
                    // setlife_event_dates={props.setlife_event_dates}
                    />
                </Accordion_Wrapper>
                <Stack direction={'row'} justifyContent={'space-between'} width={'99%'}>
                        {props.viewer == true &&
                            <TitleStyle_header>Deixe aqui alguma observação  (opcional)</TitleStyle_header>
                        }
                        {props.viewer !== true &&
                            <TitleStyle_header>Deixe aqui alguma observação  (opcional)</TitleStyle_header>
                        }
                    <Box component="img" src={InformationIcon} alt="informacao-icone" width="17px" title="Mensagem opcional que o cliente anexou no pedido." />
                </Stack>
                <TextareaAutosize
                    maxRows={4}
                    placeholder=""
                    style={{
                        padding: '10px',
                        fontFamily: 'Poppins',
                        color: '#313131',
                        marginTop: '3px',
                        width: '100%',
                        minHeight: '100px',
                        border: '1px solid #E2E4E5',
                        borderRadius: '7px',
                        resize: 'vertical'
                    }}
                    disabled={props.viewer == true}
                    value={card.ds_comments||''}
                />
            </Grid_wrapper>
            <Stack_buttons_wrapper>
                <Button_wrapper >
                    <MakeProposalBTNStyle sx={{ fontWeight: 'normal', width: '100%' }} 
                    onClick={()=>{navigate(-1); window.scrollTo(0,0);}}
                    >Voltar</MakeProposalBTNStyle>
                </Button_wrapper>
                <Button_wrapper sx={{ pl: '25px' }}>
                    {!props.isImported?
                    <>
                    {props.currentUser?
                        <>
                            {canClick?
                                <MakeProposalBTNStyle onClick={handleClickOpen} sx={{ width: '100%', background: '#17686E', color: 'white' }}>Fazer proposta</MakeProposalBTNStyle>
                                :
                                <MakeProposalBTNStyleDISABLED   onClick={()=>{toast.info('Você não pode fazer uma proposta para este pedido')}}>Fazer proposta</MakeProposalBTNStyleDISABLED>
                            }
                        </>
                    :
                        <>
                            <MakeProposalBTNStyleDISABLED   onClick={handleClickOpen}>Fazer proposta</MakeProposalBTNStyleDISABLED>
                        </>
                    }
                    </>
                    :
                    <>
                    {props.currentUser?
                        <>
                        {props.currentUser  && props.currentUser.ic_profile!==1 && !isTheSameResearcher &&  !props.allorder.id_customer &&
                            <MakeProposalBTNStyle onClick={handleClickOpenAcceptModal}>Aceitar ordem importada</MakeProposalBTNStyle>
                        }
                        </>
                    :
                        <>
                            <MakeProposalBTNStyleDISABLED   onClick={handleClickOpen}>Aceitar ordem importada</MakeProposalBTNStyleDISABLED>
                        </>
                    }
                    </>
                    }
                </Button_wrapper>
            </Stack_buttons_wrapper>
        </RootStyle >
    )
}