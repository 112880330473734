/* eslint-disable import/no-anonymous-default-export */
import { styled } from '@mui/material/styles';
import React, {useState} from 'react';
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux';
import Address from './address';
import PersonalData from './personalData';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '20px',
    // color: '#17686E',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginBottom: '5px',
}));

export default (props)=> {
    const { width } = useWindowSize()
    const active = "!text-[#17686E] !font-semibold";
    const hover = "hover:text-[#17686e] ";
    const [option, setOption] = useState(true);
    const selectOption = (value) => {
        setOption(value);
    }
    const [submit, setSubmit] = useState(false);
    const { currentProfile } = useSelector(state => state.auth);
    const { currentUser } = useSelector(state => state.auth);

    return (
        <RootStyle>
            <div className="mb:w-[95%] md:w-[82%] ">
                <div className="flex justify-center">
                    <div className="mt-[27px] font-[Poppins] font-normal mb:text-[30px] lg:text-[32px] leading-[148%] text-[#313131]">
                        {currentProfile !== currentUser.ic_profile
                            ? "Deseja criar um novo perfil?"
                            : "Editar minha conta"
                        }
                    </div>
                </div>
                <div className="flex mt-[35px]  mb-[30px]">
                    <div className='mr-[25px]'>
                        <TitleText
                            className={(option ? (active + " ") :"") + hover}
                            onClick={() => selectOption(true)}>Dados pessoais</TitleText>
                        {option ?
                        <div className="bg-[#17686E] h-[4px]"></div>
                        : ""}
                    </div>
                    <div>
                        <TitleText
                            className={(!option ? (active + " ") :"") + hover}
                            onClick={() => selectOption(false)}>Endereço</TitleText>
                        {!option ?
                        <div className="bg-[#17686E] h-[4px]"></div>
                        : ""}
                    </div>
                </div>
                {
                    option ? <PersonalData isProfile2={props.isProfile2} submitEvent={submit} submitStateChange={() => setSubmit(false)}></PersonalData>:
                    <Address></Address>
                }

            </div>
        </RootStyle>

    )
}