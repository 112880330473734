/* eslint-disable import/no-anonymous-default-export */
import { styled } from '@mui/material/styles';
import React, {useState} from 'react';
import useWindowSize from '../../hooks/useWindowSize'
import PersonalDataExpert from './personalDataExpert';
import { useSelector } from 'react-redux';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
}));

export default (props)=> {
    const { width } = useWindowSize()
    const [submit, setSubmit] = useState(false);
    const { currentProfile } = useSelector(state => state.auth);
    const { currentUser } = useSelector(state => state.auth);

    return (
        <RootStyle>
            <div className="mb:w-[95%] md:w-[95%] ">
                <div className="flex justify-center">
                    <div className="mt-[27px] font-[Poppins] font-normal mb:text-[30px] lg:text-[32px] leading-[148%] text-[#313131]">
                        {currentProfile !== currentUser.ic_profile
                            ? "Deseja criar um novo perfil?"
                            : "Editar minha conta"
                        }
                    </div>
                </div>
                <PersonalDataExpert isProfile2={props.isProfile2} submitEvent={submit} submitStateChange={() => setSubmit(false)}></PersonalDataExpert>
            </div>
        </RootStyle>

    )
}