/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import {
    Box
} from '@mui/material';
import SuccessIcon from '../../assets/icons/successicon.gif'
import ShareLink from '../../components/ShareLink';

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '0px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

export default ({researcherList, setResearchList, submitClientID }) => {
   
    return (
            <ContainerStyle className='items-center flex-col mb:!mb-[30px] md:!mb-[40px] mb:pt-[20px] md:pt-[80px]'>
                <div className='flex flex-col items-center'>
                    
                    <div className='mb:w-full xl:w-[40%] flex mb:flex-col md:flex-row mb:items-center md:items-start'>
                    <Box component={'img'} src={SuccessIcon} className='w-[110px] h-[110px] mr-[10px]'/>
                        <div className="w-full font-[Poppins] font-bold text-[32px] leading-[148%] text-[#313131] text-center">
                            Parábens, você importou uma pesquisada realizada anteriormente!
                        </div>
                    </div>
                    <div className='mb:w-full xl:w-[40%] flex mb:flex-col md:flex-row'>
                        <div className="w-[120px]"></div>
                        <div className="w-full font-[Poppins] font-light text-[22px] leading-[150%] text-[#313131] text-center mt-[25px]">
                        Agora só compartilhar esta pesquisa com o cliente, para que ele possa te avaliar. Lembrando que para que este pedido conste no seu perfil, é necessário ter avaliação de um cliente.
                        </div>
                    </div>
                </div>
                <ShareLink researcherList={researcherList} setResearchList={setResearchList} 
                // submitClientID={submitClientID}
                />
           </ContainerStyle>
    )
}