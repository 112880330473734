import React, { useState } from "react";
import { useEffect, } from "react";


import { useSelector } from 'react-redux';

import Success from '../../assets/icons/successicon.gif'
import Fail from '../../assets/icons/fail.gif'



 


export default (props)=>{

  const {currentUser} = useSelector(state => state.auth);

  const isMobile = window.innerWidth < 700;

    return(
        <div>
            {props.isPaymentSucces && (
            <div style={isMobile ? { padding: '10px', textAlign: 'center' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                <div>
                <img src={Success} width={'200px'} height={'auto'} alt="Success" />
                </div>
                <div>
                <div style={{ fontFamily: 'poppins', fontSize: '16pt', color: '#17686E', marginBottom: '10px', marginRight: '10px'}}>
                    Parabéns, sua compra foi confirmada, e suas informações já foram atualizadas!
                </div>
                <div style={{ fontFamily: 'poppins', fontSize: '15pt', color: '#17686E', marginRight: '10px' }}>
                    Agora é só aproveitar todas as vantagens!
                </div>
                </div>
            </div>
            )}
            {props.isPaymentFail &&
                
            <div style={isMobile?{ display:'flex',flexDirection:'column',    padding:'10px', alignContent:'center', alignItems:'center', justifyItems:'center', justifyContent:'center',}:{display:'flex', flexDirection:'column', width:'700px',height:'200px',   padding:'10px', alignContent:'center', alignItems:'center', justifyItems:'center', justifyContent:'center', }}>

                    <div style={isMobile?{}:{display:'flex',  width:'700px',height:'200px',   padding:'10px', alignContent:'center', alignItems:'center', justifyItems:'center', justifyContent:'center',}}>
                        <div style={isMobile?{}:{marginLeft:'-50px'}}>
                            <img src={Fail} width={'200px'} height={'auto'}/>
                        </div>        
                        <div style={isMobile?{margin:'auto', padding:'10px'}:{}}>
                            <div style={{fontFamily:'poppins', fontSize:'16pt', marginBottom:'10px' , textAlign:'justify', }}>
                                IIHH... algo deu errado!
                            </div>
                            <div style={{display:'flex', fontFamily:'poppins', fontSize:'15pt',  textAlign:'justify', }}>
                                Verifique os dados e tente novamente.
                            </div>
                        </div>
                    </div>

                    <div onClick={()=>{props.setpage(3)}} 
                        style={isMobile?
                            {
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                                justifyContent:'center',
                                justifyItems:'center',
                                padding:'5px 10px 5px 10px',
                                maxHeight: '100px',
                                maxWidth: '200px',
                                fontWeight:'400', 
                                backgroundColor: '#fcad03',
                                borderRadius: '5px',
                                border: 'none',
                                fontSize: '12pt',
                                fontWeight:'600',
                                cursor: 'pointer',
            
        
                                margin:'10px auto 20px auto'
        
                            }
                            :
                            { 
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                            justifyContent:'center',
                            justifyItems:'center',
                            padding:'5px 10px 5px 10px',
                            maxHeight: '100px',
                            maxWidth: '180px',
                            fontWeight:'400', 
                            backgroundColor: '#fcad03',
                            borderRadius: '5px',
                            border: 'none',
                            fontSize: '12pt',
                            fontWeight:'600',
                            cursor: 'pointer',
        

                            margin:'0px auto 20px auto'
                        
                            }}
                        >
                            Corrigir dados
                    </div>

            
            </div>
            }
        </div>
    )
}