// Define o objeto data com os estados iniciais da store do Redux
const data = {
    // Verifica se o usuário está armazenado no localStorage e se sim, faz o parse dele para JSON
    currentUser: localStorage.user ? JSON.parse(localStorage.user) : "",
    // Estado inicial do usuário temporário é uma string vazia
    tempUser: '',
    // Estado inicial do perfil atual é null
    currentProfile: null
};

// Cria o reducer authReducer com o estado inicial data
// eslint-disable-next-line default-param-last
export default function authReducer(state = data, action) {
    // Faz uma cópia imutável do estado atual
    const temp = { ...state };
    // Verifica o tipo de ação e atualiza o estado da store de acordo
    switch (action.type) {
        case "setUser":
            // Atualiza o usuário atual com o dado da ação
            temp.currentUser = action.data;
            return temp;
        case "setTempUser":
            // Atualiza o usuário temporário com o dado da ação
            temp.tempUser = action.data;
            return temp;
        case "setCurrentProfile":
            // Atualiza o perfil atual com o dado da ação
            temp.currentProfile = action.data;
            return temp;
        case "deleteMyLanguage":
            // Remove a linguagem do perfil do usuário atual
            const language = [...temp.currentUser.about_me.languages];
            language.splice(language.indexOf(action.data), 1);
            temp.currentUser.about_me.languages = language;
            return temp;
        case "deleteMySpeciality":
            // Remove a especialidade do perfil do usuário atual
            const speciality = [...temp.currentUser.about_me.specialities];
            speciality.splice(speciality.indexOf(action.data), 1);
            temp.currentUser.about_me.specialities = speciality;
            return temp;
        // Retorna o estado imutável atual caso não haja ações correspondentes
        default:
            return temp;
    }
}