/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    TextareaAutosize,
} from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import InformationIcon from '../../assets/icons/informationicon.svg';

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '0px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#313131',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default ({ds_comments, setds_comments}) => {
    const { width } = useWindowSize();

    const commentChange = (event) => {
        const inputValue = event.target.value;
        const formattedName = inputValue.replace(/\s{2,}/g, ' ');
        const capitalizedInput = formattedName
          .toLowerCase() // Convert the entire input to lowercase
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');      
        setds_comments(capitalizedInput);
    }

    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    return (
            <ContainerStyle className='flex-col mb:!mb-[0px] md:!mb-[160px]'>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131] mt-[60px]" style={{color: "#313131"}}>Quais informações você possui?</div>
                <div className='flex flex-col items-center'>
                    <div className="mb:w-full sm:w-[96%] mb:block sm:flex justify-start mb:ml-[0px] sm:ml-[20px] mt-[21px]">
                        <div className='mb:w-[100%] sm:w-[80%] flex mb-[25px] mb:mr-[0px] sm:mr-[25px]'>
                            <div className='w-full'>
                            <div className="flex justify-between">
                                    <div className={style.title}>Deixe aqui alguma observação  (opcional)</div>
                                    <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Se você tiver alguma informação adicional que possa compartilhar referente ao pedido trabalhado, pode deixar uma observação aqui. Isso pode incluir detalhes sobre a pessoa ou família que você trabalhou, locais específicos onde os documentos podem ser encontrados, ou quaisquer outras informações relevantes que você acredite que ajudaram na busca.</div>}>
                                        <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                    </Tooltip>
                            </div>
                            <div>
                                <TextareaAutosize
                                    id="standard-multiline-static"
                                    label="Multiline"
                                    onChange={commentChange}
                                    InputProps={{ disableUnderline: true }}
                                    placeholder="Escreva os detalhes..."
                                    defaultValue={ds_comments}
                                    InputLabelProps={{ shrink: true }}
                                    value={ds_comments}
                                    className="pt-[9px] pl-[13px] resize-y !min-h-[126px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ContainerStyle>
    )
}