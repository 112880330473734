import React, {useState, useLayoutEffect, useRef, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import {
    Pagination,
    Stack,
} from '@mui/material';
// components
import DataCard from '../../components/card/DataCard';
import ContactInfo from './contactInfor.js'
import ContactInfoMobile from './contactInforMobile.js'
import AboutInfo from './aboutInfo.js'
import LanguageInfo from './languageInfo.js'
import ReviewInfo from './reviewInfo.js'
import OpinionInfo from './opinionInfo.js'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../redux/API';
import { toast } from 'react-toast';
import { useParams } from 'react-router-dom';
import { toshowCountryName,toshowflag } from '../../components/showFlags/showFlags';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    padding: '0px 15px',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        padding: '0px 30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        padding: '0px',
        paddingTop: '70px',
    }
}));

const BodyWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    }
}));

const BodyPart = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
        width: '74%',
    }
}));

const DataCardPart = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: '40px',
        width: '26%',
    }
}));

const profileData = {
    avatarUrl: '/avata.png',
    name: 'G.M',
    planPremium: true,
    ratingNum: 4.3,
    location: 'Brasil',
    specialty: 'Itália, Portugal',
    id: 'PQ0001',
    aboutMe: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    languages: [
        { id: 1, lang: 'Português', level: 'Nativo' },
        { id: 2, lang: 'Italiano', level: 'Avançado' },
        { id: 3, lang: 'Inglês', level: 'Avançado' },
    ],
    reviewData: [
        {
            id: 1,
            ratingNum: 4.1,
            title: 'Pesquisador muito bom',
            time: '22/03/22 - 23h 05',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
            id: 2,
            ratingNum: 4.2,
            title: 'Pesquisador muito bom',
            time: '22/03/22 - 23h 05',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        }
    ],
    Data: {
        ratingNum: 4.3,
        lastLogIn: 'Há 2min ',
        registerSince: '10 de Setembro 2020',
        proposalMade: 500,
        acceptedProposl: 15
    }
}

export default function ExpertProfile() {
    const { id } = useParams();
    //console.log(id);
    const { currentUser } = useSelector(state => state.auth)
    const { currentProfile } = useSelector(state => state.auth)
    const [opinions, setOpinions] = React.useState([]);
    const [filterCountry, setfilterCountry] = React.useState([]);
    const [researcherProfile, setResearcherProfile] = React.useState(null);
    const [data, setData] = React.useState({})
    
    const showFlag = toshowflag
    const showCountryName = toshowCountryName
    

    const [page, setPage] = React.useState(1);
    const [disopinion, setDisopinion] = React.useState([]);
    const [totalCnt, setTotalCnt] = React.useState(0);
    const [avgrating, setavgrating] = React.useState(0);
    const [totalreviews, settotalreviews] = React.useState(0);
    
    useEffect(() => {
        if(opinions.length === 0){
    
            //console.log(id)
            API.get(`user/getreviewofresearcher?id_researcher=${!id ? (currentUser.id === undefined ? currentUser._id : currentUser.id) : id}`)
            .then((result) => {

                
                let temp = [];
                settotalreviews(result.data.reviews.length)
                result.data.reviews.map((item) => {
                    // toast.info('aqui')
                    let date = new Date(item.dt_review);
                    const yy1 = date.getFullYear() % 100;
                    let mm1 = date.getMonth() + 1; // Months start at 0!
                    let dd1 = date.getDate();
                    let hour = date.getHours();
                    let minute = date.getMinutes()
                    if (dd1 < 10) dd1 = '0' + dd1;
                    if (mm1 < 10) mm1 = '0' + mm1;
                    if (hour < 10) hour = '0' + hour;
                    if (minute < 10) minute = '0' + minute;
                    const formatteddate = dd1 + '/' + mm1 + '/' + yy1 + ' - '+hour + 'h'+minute;
                    let titem = {id: item._id, ratingNum: item.nr_rating, 
                        // title:item.ds_review ,
                        time:formatteddate, description: item.ds_review};
                    temp.push(titem);
                })
                setOpinions(temp)
                const current = new Date(new Date().toUTCString());
                let passedtime = "";
                let  offset = Math.floor((current-new Date(result.data.profile.dt_lastlogin))/1000/60);
                if(offset < 60) passedtime = 'Há '+offset+"min";
                else{
                    offset = Math.floor(offset / 60);
                    if(offset < 24) passedtime = 'Há '+offset+"hora";
                    else{
                        offset = Math.floor(offset / 24);
                        if(offset < 7) passedtime = 'Há '+offset+"dia";
                        else{
                            offset = Math.floor(offset / 7);
                            if(offset < 4) passedtime = 'Há '+offset+"semana";
                            else passedtime = 'Há muito tempo';
                        }
                    }
                }
                setavgrating(result.data.avgrating)
                let temp1 = {lastLogIn:passedtime, registerSince:new Date(result.data.profile.createdAt).toLocaleDateString('pt-BR'), ratingNum: result.data.avgrating, proposalMade: result.data.proposalcnt, acceptedProposl:result.data.acceptedcnt };
                setData(temp1)
                if(temp.length % 5 !== 0){
                    setTotalCnt(Math.floor(temp.length / 5) + 1 );
                }
                else setTotalCnt(temp.length / 5);
                setDisopinion(temp.slice((page - 1) * 5, page * 5 ))
                setResearcherProfile(result.data.profile);
            })
            .catch((err) => {
                // navigate('/');
                console.error(err);
                console.error(err.request.response)
            });
        } 
    },[])

    useEffect(()=>{
        API.post('order/getfilterstypes')
        .then((res)=>{
            setfilterCountry(res.data.filterCountry)
        })
        .catch((error)=>{
            console.error(error);
        })
    },[])

    const handleChange = (event, value) => {
        
        setPage(value);
        setDisopinion(opinions.slice((value - 1) * 5, value * 5 ))
      };
    const navigate = useNavigate();
    if(!currentUser){
        navigate('/');
        return;
    }


    return (
        <RootStyle>
            {
                researcherProfile && 
                <ContainerStyle>
                <BodyWrapper>
                    <BodyPart>
                        <ContactInfo filterCountry={filterCountry} showFlag={showFlag} showCountryName={showCountryName}  profileData={profileData} currentUser={researcherProfile} avgrating={avgrating}/>
                        <AboutInfo data={researcherProfile.about_me?.ds_aboutme} />
                        <LanguageInfo languages={profileData.languages} currentUser={researcherProfile}/>
                        <ReviewInfo profileData={opinions}  currentUser={researcherProfile} avgrating={avgrating} totalreviews={totalreviews}/>
                        <OpinionInfo data={disopinion} count={opinions.length}/>
                    </BodyPart>
                    <DataCardPart>
                        <DataCard card={data} />
                    </DataCardPart>
                    <ContactInfoMobile filterCountry={filterCountry}  showFlag={showFlag} showCountryName={showCountryName} profileData={profileData} currentUser={researcherProfile} avgrating={avgrating}/>
                </BodyWrapper>

                <Stack alignItems="center" sx={{ my: 5 }}>
                    <Pagination 
                        count={totalCnt}
                        page={page}
                        onChange={handleChange}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                "&:hover:not(.Mui-selected)": {
                                    backgroundColor: "#17686E",
                                    color: "white"
                                }
                            },
                            "& .Mui-selected": {
                                backgroundColor: "#17686E",
                                color: "white",
                            "&:hover": {
                                backgroundColor: "#17686E",
                                color: "white"
                            }
                            }
                        }}
                        />
                </Stack>
            </ContainerStyle>
            }
            
        </RootStyle>
    )
}