// Importação dos estilos do Material UI
import { styled } from '@mui/material/styles';
import {
    Box, // Caixa para organizar elementos
    Stack, // Pilha para organizar elementos
} from '@mui/material';

// Importação do ícone de filtro
import FilterIcon from '../../assets/icons/Filter.svg'

// Estilo para o botão de filtro
const FilterB = styled('button')(({ theme }) => ({
    border: '1px solid #E2E4E5', // Borda do botão
    borderRadius: '5px', // Arredondamento das bordas do botão
    backgroundColor: 'white', // Cor de fundo do botão
    cursor: 'pointer', // Cursor para quando o usuário passa o mouse sobre o botão
    padding: '10px 9px 10px 10px', // Espaçamento interno do botão
    [theme.breakpoints.up('md')]: {
        // Estilos adicionais para resoluções maiores (md = medium)
    },
    '&:hover': { // Estilo para quando o usuário passa o mouse sobre o botão
        border: '1px solid #17686E', // Cor da borda do botão quando o usuário passa o mouse
    }
}));

// Estilo para o texto do botão de filtro
const TitleText = styled('div')(({
    theme
}) => ({
    fontFamily: 'Poppins', // Fonte do texto
    fontWeight: 400, // Peso da fonte
    fontSize: '16px', // Tamanho da fonte
    color: '#313131', // Cor do texto
    borderRadius: '5px', // Arredondamento das bordas do texto
    whiteSpace: 'nowrap', // Não permite quebra de linha
    paddingLeft: '10px', // Espaçamento à esquerda do texto
    [theme.breakpoints.up('md')]: {
        // Estilos adicionais para resoluções maiores (md = medium)
    }
}));

// Função que renderiza o botão de filtro
export default function FilterButton({ text, event }) {
    return (
        <FilterB onClick={event} className="w-[88px] h-[51px]">
            <Stack flexDirection={'row'} alignItems={'center'} >
                <Box component={'img'} src={FilterIcon} width={20} />
                <TitleText>{text}</TitleText>
            </Stack>
        </FilterB>
    )
}