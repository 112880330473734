/* eslint-disable import/no-anonymous-default-export */
import { styled } from '@mui/material/styles';
import React, {useState} from 'react';
import useWindowSize from '../../hooks/useWindowSize'

import AccountForm from './accountForm';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '20px',
    // color: '#17686E',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginBottom: '5px',
}));

export default ()=> {
    const { width } = useWindowSize()
    const active = "!text-[#17686E] !font-semibold";
    const hover = "hover:text-[#17686e] ";
    const [option, setOption] = useState(true);
    const [submit, setSubmit] = useState(false);

    return (
        <RootStyle>
            <div className="mb:w-full md:w-[95%] ">
                <div className="flex justify-center mb:mb-[25px] md:mb-[14px]">
                    <div className="mt-[27px] font-[Poppins] font-normal mb:text-[30px] lg:text-[32px] leading-[148%] text-[#313131] mb:text-center ">Encerrar conta</div>
                </div>
                {
                    <AccountForm></AccountForm>
                }
            </div>
        </RootStyle>

    )
}