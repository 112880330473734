import { styled } from '@mui/material/styles';
import publicationsImage from '../../assets/image/publications_image.png';
import { useSelector } from 'react-redux';// -------------------------------------------------------------
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import researcher_top2 from '../../assets/image/researcher_top2.png'

// define the subheader color
const publications_subheaderr_color = '#4C786D'

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',

    // width: '100%',
    padding: '30px 0',
    backgroundColor: publications_subheaderr_color,
    overflow: 'hidden',

    alignItems: 'flex-start',
    // margin: '0px auto',
    marginLeft: '0px',
    marginight: '0px',
    paddingLeft:'8.8%',
    maxHeight: '330px',
    gap: '20px',
    [theme.breakpoints.up('sm')]: {
        // width: '82.5%',
        // padding: '0px 30px',
    },
    [theme.breakpoints.up('md')]: {
        // width: '82.5%',
        // padding: '0px'
    }

    //backgroundImage: `url(${publicationsImage})`,
    //backgroundSize: 'cover',
    //backgroundPosition: 'center',
}));

const BannerContentStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
    minHeight: Math.max(
        parseInt(theme.typography.pxToRem(100)), // BannerTitleStyle max height
        parseInt(theme.typography.pxToRem(40)),  // BannerTextStyle max height
        parseInt(theme.typography.pxToRem(60))   // BannerBTNStyle max height
      ),
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 15px',
    margin: '0px auto',
    width: '90%',
    height: '270px',
    gap: '20px',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        padding: '0px 30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        padding: '0px'
    }
}));

const BannerTitleStyle = styled('p')(({ theme }) => ({
    color: '#FFFF',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',    
    fontSize: '50px',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
        fontSize: '80px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '100px',
    }
}));

const BannerTextStyle = styled('p')(({ theme }) => ({
    color: '#FFFF',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',      
    fontSize: '10px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
    }
}));

const BannerBTNStyle = styled('button')(({ theme }) => ({
    color: '#fff',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    padding: '10px 11px',
    backgroundColor: '#17686E',
    borderRadius: '5px',
    border: 'none',
    [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        padding: '12px 13px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        padding: '15px 16px',
    }
}));

// -------------------------------------------------------------

export default function Banner() {
    const { width } = useWindowSize()

    const { currentProfile } = useSelector(state => state.auth);
    const navigate = useNavigate()

    useEffect(()=>{
        // alert(currentProfile)
    },[currentProfile])


    return (
        <RootStyle>
            {/* <ContainerStyle> */}
                <BannerContentStyle>
                    <BannerTitleStyle>Publicações</BannerTitleStyle>
                    {currentProfile ===null &&
                    <>
                    <BannerTextStyle>Crie uma publicação e encontre um pesquisador ideal.</BannerTextStyle>
                    <BannerBTNStyle onClick={()=>{navigate('/login')}}>Solicitar documento</BannerBTNStyle>
                    </>
                    }
                    {currentProfile===0 &&
                    <>
                    <BannerTextStyle>Crie uma publicação e encontre um pesquisador ideal.</BannerTextStyle>
                    <BannerBTNStyle onClick={()=>{navigate('/createorder')}}>Solicitar documento</BannerBTNStyle>
                    </>
                    }
                    {currentProfile===1 &&
                    <>
                    <BannerTextStyle>Encontre seu pedido ideal.</BannerTextStyle>
                    </>
                    }
                </BannerContentStyle>
                { width >1200 &&
                    <div style={{width:'100%',  height:'auto', minHeight:'400px', maxHeight:'400px',    display: 'flex', justifyContent: 'center', alignItems: 'center' , marginTop:'-30px', }}>
                        <img src={researcher_top2} style={{ height:'auto', minHeight: '400px', maxHeight:'400px', width:'100%', objectFit:'cover' }} />
                    </div>
                }
            {/* </ContainerStyle> */}
        </RootStyle>
    )
}