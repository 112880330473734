//Declaração da constante "data" com um objeto inicial
const data = {
    //Valor inicial do resultado
    result: 0,
    //Comentário inicial
    comment: ""
};        
    //Exportação da função "updateReducer" como padrão (default)
// eslint-disable-next-line default-param-last
    export default function updateReducer(state = data, action) {
    //Cria uma cópia profunda do estado atual
    const temp = { ...state };
    //Verifica o tipo de ação
    switch (action.type) {
        //Se o tipo de ação for "setResult"
        case "setResult":
            //Atualiza o resultado com o valor da ação
            temp.result = action.data.result;
            //Atualiza o comentário com o valor da ação
            temp.comment = action.data.comment;
            //Retorna o novo estado
            return temp;
        //Se não houver nenhum tipo de ação conhecido
        default:
            //Retorna o estado atual
            return temp;
    }
}