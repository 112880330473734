/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useRef} from 'react';
import TextField from '@mui/material/TextField';
import useWindowSize from '../../hooks/useWindowSize'
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Custom_Accordion from '../../components/Accordion';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '80px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const Accordion_Wrapper = styled('div')(({ theme }) => ({
    padding: '18px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '18px 68px 20px 20px',
    }
}));

export default ({ 
    tabIndex1,
    ds_gender, setds_gender,
    nm_ancestor, setnm_ancestor,
    nm_ancestoralternative, setnm_ancestoralternative,
    family_members, setfamily_members,
    life_event_dates, setlife_event_dates ,
    isCreateOrder,progress, setProgress,

}) => {
   
    const { width } = useWindowSize();
    const [tabIndex, setTabIndex] = useState(tabIndex1);
    const [alterTName, setAlterTName] = useState('');
    const [auxAlt, setAuxAlt] = useState([]);

    useEffect(()=>{
        setTabIndex(tabIndex1);
    }, [tabIndex1])

    const alterNamekeyPress = (e) =>{
        if(e.keyCode == 13){
            const temp = [...nm_ancestoralternative];
            temp.push(e.target.value);
            setnm_ancestoralternative(temp);
            setAlterTName('');
        }
    }

    const deleteAlterName = (index) => {
        const temp = [...nm_ancestoralternative];
        temp.splice(index,1);
        setnm_ancestoralternative(temp);
        setAlterTName('');
    }

    const handleSpouseChange = (e) => {
        const inputValue = e.target.value;
        const formattedName = inputValue.replace(/\s{2,}/g, ' ');
        const capitalizedInput = formattedName
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      
        setfamily_members({ ...family_members, nm_spouse: capitalizedInput });
    }
      
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]",
        tabBtn_active: "text-center ml-[10px] font-semibold cursor-pointer shadow-[1px_-12px_17px_rgba(0,0,0,0.05)] mb:pl-[10px] mb:pr-[10px] sm:pl-[30px] sm:pr-[30px] pt-[10px] pb-[10px] bg-[#FFFFFF] rounded-t-[15px] font-[Poppins] font-light  mb:text-[10px] md:text-[14px] leading-[148%] text-[#17686E]",
        tabBtn: "text-center ml-[10px] cursor-pointer shadow-[1px_-12px_17px_rgba(0,0,0,0.05)]  mb:pl-[10px] mb:pr-[10px] sm:pl-[30px] sm:pr-[30px] pt-[10px] pb-[18px] bg-[#FFFFFF] rounded-t-[15px] font-[Poppins] font-light mb:text-[10px] md:text-[14px] leading-[148%] text-[#A9A9A9] "
    }

    const alterNameInput = useRef(null);

    useEffect(() => {
        if (alterNameInput.current) {
          const instance = tippy(alterNameInput.current, {
            content: "Aperte a tecla 'Enter' depois de digitar para adicionar nomes alternativos",
            trigger: "mouseenter focus focusin click",
            interactive: true,
            placement: 'right',
            hideOnClick: false,
            popperOptions: {
              modifiers: {
                preventOverflow: {
                  boundariesElement: "window"
                }
              },
              styles: {
                popper: {
                  backgroundColor: "#17686E",
                  color: "white",
                  borderRadius: "5px",
                  padding: "5px 10px"
                }
              }
            }
          });
        }
    }, []);
      
    return (
            <ContainerStyle className='flex-col'>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131] mb-[25px]" style={{color: "#313131"}}>Quais informações você possui?</div>
                <div>
                    <div className='flex z-0 mb:justify-between sm:justify-start'>
                        <div className={(tabIndex === 1 ? style.tabBtn_active : style.tabBtn)+" mb:ml-[0px] sm:ml-[20px]"} 
                            onClick={() => { 
                                if(isCreateOrder===true){
                                    setTabIndex(1)
                                    setProgress(50)
                                }
                                else{
                                    setTabIndex(1)
                                    setProgress(100)
                                }
                            }}
                        >Dados pessoais</div>
                        <div className={(tabIndex === 2 ? style.tabBtn_active : style.tabBtn)} 
                            onClick={() => { 
                                        if(isCreateOrder===true) {
                                            setTabIndex(2)
                                            setProgress(60)
                                        }
                                        else{
                                            setTabIndex(2)
                                            setProgress(120)
                                        }
                                    }}
                        >Membros da família</div>
                        <div className={(tabIndex === 3 ? style.tabBtn_active : style.tabBtn)} 
                            onClick={() => { 
                                if(isCreateOrder===true){
                                    setTabIndex(3)
                                    setProgress(70)
                                }
                                else{
                                    setTabIndex(3)
                                    setProgress(140)
                                }
                            }}
                                
                        >Eventos da vida</div>
                    </div>
                    <div className='w-full shadow-[-2px_3px_50px_rgba(0,0,0,0.15)] min-h-[400px] mt-[-10px] z-10'>
                        {tabIndex === 1 &&
                        <div className="">
                            <div className='mb:w-[96%] md:w-[60%] mb:pt-[20px] md:pt-[45px] md:ml-[45px] mb:ml-[2%]'>
                                <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Selecione o sexo <span className='text-[#DE2B2B]'>*</span>
                                </div>
                                <div>
                                    <RadioGroup row={width>640? true: false} col={width > 640? false: true} value={ds_gender} onChange={e => {setds_gender(e.target.value)}}>
                                        <FormControlLabel value="Masculino" control={<Radio  
                                            sx={{
                                                color: '#313131',
                                                '&.Mui-checked': {
                                                    color: '#17686E',
                                                }
                                            }}
                                        />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#7E7E7E]">Masculino</div></div>} />
                                        <FormControlLabel value="Feminino" control={<Radio                             
                                            sx={{
                                                color: '#313131',
                                                '&.Mui-checked': {
                                                    color: '#17686E',
                                                }
                                            }}      
                                        />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#7E7E7E]">Feminino </div></div>} />
                                        <FormControlLabel value="Não especificado" control={<Radio                             
                                            sx={{
                                                color: '#313131',
                                                '&.Mui-checked': {
                                                    color: '#17686E',
                                                }
                                            }}      
                                        />} label={<div className="flex items-center"><div className="mr-[15px] font-[Poppins] font-normal text-[14px] leading-[130%] text-[#7E7E7E]">Não especificado</div></div>} />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className='mb:w-[96%] md:w-[60%] mt-[20px]  md:ml-[45px] mb:ml-[2%]'>
                                <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Nome completo<span className='text-[#DE2B2B]'>*</span>
                                </div>
                                <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"
                                        value={nm_ancestor}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                            const capitalizedInput = formattedName
                                                .split(' ')
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(' ');
                                            setnm_ancestor(capitalizedInput);
                                        }}
                                        className="w-full"
                                        InputProps={{
                                            className: style.content,
                                            requried: "true"
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                        />
                                </div>
                            </div>
                            <div className='mb:w-[96%] md:w-[60%] mt-[20px]  md:ml-[45px] mb:ml-[2%]'>
                                <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[0px] mb-[3px]">Nome alternativo</div>
                                <div>
                                    <TextField
                                        autoComplete="text"
                                        type="text"                                        
                                        value={alterTName}
                                        onKeyDown={alterNamekeyPress}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                            const capitalizedInput = formattedName
                                                .split(' ')
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(' ');
                                                setAlterTName(capitalizedInput);
                                        }}
                                        className="w-full"
                                        InputProps={{
                                            className: style.content,
                                            requried: "true"
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                        ref={alterNameInput}
                                    />
                                </div>
                            </div>
                            <div className='mb:w-[96%] md:w-[60%] mt-[20px]  md:ml-[45px] mb:ml-[10px] flex flex-wrap'>
                                {
                                    nm_ancestoralternative.map((item, index) => {
                                        return(
                                        <div className="mb-[10px] min-w-[145px] flex justify-between items-center mr-[7px] pt-[5px] pb-[5px] pl-[15px] pr-[15px] w-fit rounded-[15px] border-solid border-[1px] border-[#C6C6C6] font-[Poppins] font-normal text-[14px] leading-[18px]" style={{color: "#313131"}}>
                                            <div>{item}</div>
                                            <IconButton
                                                className='!ml-[40px]'
                                                aria-label="close"
                                                onClick={() => {deleteAlterName(index)}}
                                                sx={{
                                                    color: (theme) => theme.palette.black,
                                                    width: '9px',
                                                    height: '9px'
                                                }}
                                            >
                                                <CloseIcon className="!text-[12px]"/>
                                            </IconButton>
                                        </div>
                                    )})
                                }
                            </div>
                        </div>
                        }
                        {
                            tabIndex == 2 && 
                            <div className="flex flex-col items-center">
                            <div className="mb:w-full sm:w-[96%] flex mb:flex-col sm:flex-row items-center justify-around mb:pt-[20px] sm:pt-[45px] ">
                                <div className='mb:w-[96%] sm:w-[48%] flex mb-[25px]'>
                                        <div className='w-full'>
                                            <div className={style.title}>Nome completo do cônjuge</div>
                                            <div>
                                            <TextField
                                                autoComplete="text"
                                                type="text"
                                                className="w-full"
                                                value={family_members.nm_spouse}
                                                onChange={handleSpouseChange}
                                                InputProps={{
                                                    className: style.content,
                                                    type: "text",
                                                    requried: "true"
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"/>
                                        </div>
                                        </div>
                                </div>
                                <div className='mb:w-[96%] sm:w-[48%] flex'>

                                </div>
                            </div>
                            <div className="mb:w-full sm:w-[96%] flex mb:flex-col sm:flex-row items-center  justify-around ">
                                <div className='mb:w-[96%] sm:w-[48%] flex mb-[25px]'>
                                        <div className='w-full'>
                                            <div className={style.title}>Nome completo do pai</div>
                                            <div>
                                            <TextField
                                                autoComplete="text"
                                                type="text"
                                                className="w-full"
                                                value={family_members.nm_father}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                                    const capitalizedInput = formattedName
                                                      .split(' ')
                                                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                      .join(' ');
                                                  
                                                    setfamily_members({ ...family_members, nm_father: capitalizedInput });
                                                }}
                                                InputProps={{
                                                    className: style.content,
                                                    type: "text",
                                                    requried: "true"
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"/>
                                        </div>
                                        </div>
                                </div>
                                <div className='mb:w-[96%] sm:w-[48%] flex mb-[25px]'>
                                    <div className='w-full'>
                                        <div className={style.title}>Nome completo da mãe</div>
                                        <div>
                                            <TextField
                                                autoComplete="text"
                                                type="text"
                                                className="w-full"
                                                value={family_members.nm_mother}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                                    const capitalizedInput = formattedName
                                                      .split(' ')
                                                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                      .join(' ');                                                  
                                                    setfamily_members({ ...family_members, nm_mother: capitalizedInput });
                                                }}
                                                InputProps={{
                                                    className: style.content,
                                                    type: "text",
                                                    requried: "true"
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb:w-full sm:w-[96%] flex mb:flex-col sm:flex-row items-center justify-around ">
                                <div className='mb:w-[96%] sm:w-[48%] flex mb-[25px]'>
                                        <div className='w-full'>
                                            <div className={style.title}>Nome completo de outra pessoa</div>
                                            <div>
                                            <TextField
                                                autoComplete="text"
                                                type="text"
                                                className="w-full"
                                                value={family_members.nm_otherperson}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                                    const capitalizedInput = formattedName
                                                      .split(' ')
                                                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                      .join(' ');                                                  
                                                    setfamily_members({ ...family_members, nm_otherperson: capitalizedInput });
                                                }}
                                                InputProps={{
                                                    className: style.content,
                                                    type: "text",
                                                    requried: "true"
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"/>
                                        </div>
                                        </div>
                                </div>
                                <div className='mb:w-[96%] sm:w-[48%] flex mb-[25px]'>
                                    <div className='w-full'>
                                        <div className={style.title}>Grau de familiaridade</div>
                                        <div>
                                            <TextField
                                                autoComplete="text"
                                                type="text"
                                                className="w-full h-[44px]"
                                                value={family_members.ds_otherpersonfamiliarity}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const formattedName = inputValue.replace(/\s{2,}/g, ' ');
                                                    const capitalizedInput = formattedName
                                                      .split(' ')
                                                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                      .join(' ');
                                                  
                                                    setfamily_members({ ...family_members, ds_otherpersonfamiliarity: capitalizedInput });
                                                }}
                                                InputProps={{
                                                    className: style.content,
                                                    type: "text",
                                                    requried: "true"
                                                }}
                                                hiddenLabel="hiddenLabel"
                                                id="outlined-basic"
                                                variant="outlined"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        }
                        {
                            tabIndex == 3 && 
                            <div className="flex flex-col items-center w-[96%] mb:ml-[10px] md:ml-[40px] sm:pt-[20px]">
                                <Accordion_Wrapper className='w-full'>
                                    <Custom_Accordion life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates} />
                                </Accordion_Wrapper>
                            </div>
                        }
                    </div>
                </div>
            </ContainerStyle>
    )
}